import AvatarPlaceholder from 'assets/avatar.jpg'
import AvatarPlaceholderGroup from 'assets/avatar_group.jpg'
import Compress from 'compress.js'
import InputLabel from '../_InputLabel'
import SvgIcon from 'components/SvgIcon'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }
`

const UploadWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  input[type='file'] {
    display: none;
  }
`

const CustomUpload = styled.label`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;

  button {
    ${defaultInputCss}
    transition: all 0.2s ease, background 0.4s ease;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 8px 14px;

    svg {
      transition: all 0.2s ease;
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.black};
    }
  }
  &:hover {
    button {
      background: ${(props) => props.theme.white}66;
      backdrop-filter: blur(2px);
      svg {
        color: ${(props) => props.theme.primary_light};
      }
    }
  }
`

const ResetWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;

  button {
    border: none;
    padding: 8px;
    background: ${(props) => props.theme.white}44;
    backdrop-filter: blur(10px);

    svg {
      transition: all 0.2s ease;
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.black};
    }
    &:hover {
      svg {
        color: ${(props) => props.theme.warning};
      }
    }
  }
`

/* type Base64FileType = {
  name: string
  type: string
  size: string
  base64: any
  file: any
} */

const AvatarUpload = (props: {
  value: string | null
  setValue: (src: string | null) => void
  label?: string
  isUserGroup?: boolean
}) => {
  const randomId = `avatar-upload-${Math.random() * 100000}`

  const MAX_SIZE_KB = 30

  const reset = () => {
    // BUG - confirm is rendered behind opened pop-up (this is a library problem)
    /* confirmedAction({
      title: t('file.image reset.title'),
      description: t('file.image reset.description').replace(
        '{MAX_SIZE_KB}',
        MAX_SIZE_KB.toString()
      ),
      cancelText: t('file.image reset.no'),
      okText: t('file.image reset.yes'),
      onSubmit: () => {
        props.setValue(null)
      },
    }) */

    props.setValue(null)
  }

  const onChange = (event) => {
    let files = event.target.files
    /* let allFiles: Base64FileType[] = [] */

    for (var i = 0; i < files.length; i++) {
      let file = files[i]

      const compress = new Compress()
      compress
        .compress([file], {
          size: MAX_SIZE_KB / 1000, // MB
          /* CAN ALSO RESIZE, ROTATE ETC. */
        })
        .then((compressedFiles) => {
          const fileBase64 = compressedFiles[0]?.data
          props.setValue(`data:image/jpeg;charset=utf-8;base64,${fileBase64}`)
        })

      /* let reader = new FileReader()
      let img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        //const imageWidth = img.width
        //const imageHeight = img.height

        reader.readAsDataURL(file)
        reader.onload = () => {
          const fileSize = Math.round(file.size / 1000)

          let fileInfo: Base64FileType = {
            name: file.name,
            type: file.type,
            size: fileSize + 'kB',
            base64: reader.result,
            file: file,
          }

          if (fileSize > MAX_SIZE_KB) {
            confirmedAction({
              title: t('file.image too large.title'),
              description: t('file.image too large.description')
                .replace('{FILE_SIZE_KB}', fileSize.toString())
                .replace('{MAX_SIZE_KB}', MAX_SIZE_KB.toString()),
              okText: t('file.image too large.ok'),
            })
          } else {
            allFiles.push(fileInfo)
            if (allFiles.length === files.length) {
              const fileBase64 = allFiles[0]?.base64
              if (fileBase64) {
                props.setValue(fileBase64)
              }
            }
          }
        }
      } */
    }
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}
      <InputWrapper>
        <ImageWrapper>
          <img
            src={
              props.value
                ? props.value
                : props.isUserGroup
                ? AvatarPlaceholderGroup
                : AvatarPlaceholder
            }
            alt="avatar"
          />
        </ImageWrapper>

        <UploadWrapper>
          <CustomUpload htmlFor={randomId}>
            <button>
              <SvgIcon code="icon-upload" />
            </button>
          </CustomUpload>
          <input
            id={randomId}
            type="file"
            onChange={onChange}
            multiple={false}
          />
        </UploadWrapper>

        {props.value && (
          <ResetWrapper>
            <button onClick={reset}>
              <SvgIcon code="icon-delete" />
            </button>
          </ResetWrapper>
        )}
      </InputWrapper>
    </Wrapper>
  )
}

export default AvatarUpload
