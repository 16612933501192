import Compress from 'compress.js'
import InputLabel from '../_InputLabel'
import SvgIcon from 'components/SvgIcon'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
  }
`

const UploadWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  input[type='file'] {
    display: none;
  }
`

const CustomUpload = styled.label`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;

  button {
    ${defaultInputCss}
    transition: all 0.2s ease, background 0.4s ease;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 8px 14px;

    svg {
      transition: all 0.2s ease;
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.black};
    }
  }
  &:hover {
    button {
      background: ${(props) => props.theme.white}66;
      svg {
        color: ${(props) => props.theme.primary_light};
      }
    }
  }
`

const ResetWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;

  button {
    border: none;
    padding: 8px;
    background: ${(props) => props.theme.white}44;
    backdrop-filter: blur(10px);

    svg {
      transition: all 0.2s ease;
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.black};
    }
    &:hover {
      svg {
        color: ${(props) => props.theme.warning};
      }
    }
  }
`

const ImageUpload = (props: {
  src: any
  setSrc: (src: any) => void
  file: any
  setFile: (src: any) => void
  label?: string
}) => {
  const randomId = `img-upload-${Math.random() * 100000}`

  const MAX_SIZE_KB = 4000 // = 4MB

  const reset = () => {
    props.setSrc(null)
    props.setFile(null)
  }

  const onChange = (event) => {
    let files = event.target.files

    for (var i = 0; i < files.length; i++) {
      let file = files[i]
      props.setFile(file)

      const compress = new Compress()
      compress
        .compress([file], {
          size: MAX_SIZE_KB / 1000, // MB
          //CAN ALSO RESIZE, ROTATE ETC.
        })
        .then((compressedFiles) => {
          const fileBase64 = compressedFiles[0]?.data
          props.setSrc(`data:image/jpeg;charset=utf-8;base64,${fileBase64}`)
        })
    }
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}
      <InputWrapper>
        <ImageWrapper>
          {/* TODO - GENERAL IMAGE PLACEHOLDER - just a colored div, not a photo (?)*/}
          <img src={props.src} alt="upload" />
        </ImageWrapper>

        <UploadWrapper>
          <CustomUpload htmlFor={randomId}>
            <button>
              <SvgIcon code="icon-upload" />
            </button>
          </CustomUpload>
          <input
            id={randomId}
            type="file"
            onChange={onChange}
            multiple={false}
          />
        </UploadWrapper>

        {props.src && (
          <ResetWrapper>
            <button onClick={reset}>
              <SvgIcon code="icon-delete" />
            </button>
          </ResetWrapper>
        )}
      </InputWrapper>
    </Wrapper>
  )
}

export default ImageUpload
