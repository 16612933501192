import { DBSongType, SongType } from 'types'

import FloatingButton from 'components/App/FloatingMenu/FloatingButton'
import SvgIcon from 'components/SvgIcon'
import { hasSongPermission } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Button = styled.button`
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  padding-right: 25px;
  align-self: center;
`

const SongButtonDuplicate = (props: {
  song: DBSongType
  onDuplicate: (songBody: SongType) => void
  isFloatingButton?: boolean
  isVisible?: boolean
  isIndented?: boolean
  noShadow?: boolean
  tooltipShortIsVisible?: boolean
  index?: number
}) => {
  const { t } = useTranslation()

  const getAllowDuplicateSong = () =>
    hasSongPermission(props.song.songMembers, 'SONG_duplicateSong')

  //
  // BUTTON CLICK
  //
  const onClick = () => {
    props.onDuplicate(JSON.parse(props.song?.body))
  }

  //
  // RENDER
  //
  const renderButton = () => {
    if (props.isFloatingButton) {
      return (
        <FloatingButton
          button={{
            onClick: onClick,
            tooltip: t('song.duplicate'),
            tooltipShort: t('song.duplicate short'),
            tooltipShortIsVisible: props.tooltipShortIsVisible,
            icon: <SvgIcon code="icon-duplicate" />,
            isVisible: props.isVisible,
            isIndented: props.isIndented,
          }}
          noShadow={props.noShadow}
          index={props.index}
        />
      )
    }
    return (
      <Button className="btn-primary" onClick={onClick}>
        <SvgIcon code="icon-duplicate" /> {t('song.duplicate.label')}
      </Button>
    )
  }

  return getAllowDuplicateSong() ? renderButton() : null
}

export default SongButtonDuplicate
