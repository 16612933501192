/* eslint-disable react-hooks/exhaustive-deps */
import { UserGroupType, UserType } from 'types'
import { hasGroupPermission, hasPermission } from 'utils'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { File } from 'components/Files/FileSystem'
import FileSystemUsers from 'components/Files/FileSystemUsers'
import { FloatingButtonType } from 'components/App/FloatingMenu/FloatingButton'
import FloatingButtonWithMenu from 'components/App/FloatingMenu/FloatingButtonWithMenu'
import FloatingMenu from 'components/App/FloatingMenu'
import { LOGGED_USER_VAR } from 'App'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import SvgIcon from 'components/SvgIcon'
import { USER_GROUP_MODAL } from 'modals/UserGroupModal'
import { USER_MODAL } from 'modals/UserModal'
import UserDeleteButton from 'components/User/UserDeleteButton'
import UserDetail from 'components/User/UserDetail'
import UserGroupDeleteButton from 'components/UserGroup/UserGroupDeleteButton'
import UserGroupDetail from 'components/UserGroup/UserGroupDetail'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const UsersPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  //
  // STATE
  //
  const [userData, setUserData] = useState<UserType>()
  const [userGroupData, setUserGroupData] = useState<UserGroupType>()

  //
  // NAVIGATION
  //
  const onFileOpenClick = (file: File) => {
    setUserData(undefined)
    setUserGroupData(undefined)
    updateParams({ user: file.id })
  }

  const onFolderOpenClick = (file: File) => {
    setUserData(undefined)
    setUserGroupData(undefined)
    updateParams({ userGroup: file.id })
  }

  const onClose = () => {
    if (location.state?.onBackOnClose) {
      // handle closing song opened from different location (e.g. playlist)
      return history.goBack()
    }
    updateParams({})
  }

  //
  // FUNCTIONS
  //
  const onUserEditClick = () => {
    USER_MODAL({
      ...USER_MODAL(),
      isOpen: true,
      isEditMode: true,
      userId: Number(userParam),
    })
  }

  const onUserAddClick = () => {
    USER_MODAL({
      ...USER_MODAL(),
      isOpen: true,
      callback: (newUserId) => {
        updateParams({ user: newUserId })
      },
    })
  }

  const onUserGroupAddClick = () => {
    USER_GROUP_MODAL({
      ...USER_GROUP_MODAL(),
      isOpen: true,
      callback: (newGroupId) => {
        updateParams({ userGroup: newGroupId })
      },
    })
  }

  const onUserGroupEditClick = () => {
    USER_GROUP_MODAL({
      ...USER_GROUP_MODAL(),
      isOpen: true,
      isEditMode: true,
      userGroupId: Number(userGroupParam),
    })
  }

  //
  // URL PARAMS
  //
  const [userParam, setUserParam] = useState('')
  const [userGroupParam, setUserGroupParam] = useState('')

  const createUsersUrlParams = (options: { user?: any; userGroup?: any }) => {
    const { user, userGroup } = options
    let searchParams: {
      user?: any
      userGroup?: any
    } = {}
    if (user || user === 0) {
      searchParams.user = user
    }
    if (userGroup || userGroup === 0) {
      searchParams.userGroup = userGroup
      delete searchParams.user
    }
    return searchParams
  }

  const updateParams = (options: { user?: any; userGroup?: any }) => {
    history.push({
      pathname: location.pathname,
      search: new URLSearchParams(createUsersUrlParams(options)).toString(),
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const userId = searchParams.get('user')
    const userGroupId = searchParams.get('userGroup')

    setUserParam(userId || '')
    setUserGroupParam(userGroupId || '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //
  // FLOATING MENU
  //
  const getAllowEditGroup = () => {
    if (hasPermission('updateAnyUserGroup')) {
      return true
    }
    const userGroup = userGroupData
    const allowUpdateGroup = hasGroupPermission(userGroup, 'GR_updateGroup')
    const allowUpdateMembers = hasGroupPermission(userGroup, 'GR_updateMembers')
    return allowUpdateGroup || allowUpdateMembers
  }

  const renderFloatingMenu = () => {
    const buttons: FloatingButtonType[] = [
      {
        component: (
          <FloatingButtonWithMenu
            icon={<SvgIcon code="icon-add" />}
            buttons={[
              {
                onClick: onUserAddClick,
                tooltip: t('user.new'),
                tooltipShort: t('user.new short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-add-user" />,
                isVisible: hasPermission('createUser'),
              },

              {
                onClick: onUserGroupAddClick,
                tooltip: t('user group.new'),
                tooltipShort: t('user group.new short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-add-user-group" />,
                largeIcon: true,
                isVisible: hasPermission('createUserGroup'),
              },
            ]}
          />
        ),
        isVisible: true,
      },

      {
        component: (
          <FloatingButtonWithMenu
            icon={<SvgIcon code="icon-edit" />}
            buttons={[
              {
                tooltipShortIsVisible: true,
                component: (
                  <UserDeleteButton
                    isFloatingButton
                    user={userData}
                    tooltipShortIsVisible={true}
                    isVisible={
                      userParam && userData && hasPermission('deleteUser')
                    }
                  />
                ),
              },
              {
                onClick: onUserEditClick,
                tooltip: t('user.edit'),
                tooltipShort: t('user.edit short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-edit" />,
                isVisible:
                  userParam?.length &&
                  userData &&
                  (hasPermission('updateAnyUser') ||
                    (userData.id === loggedUser.id &&
                      hasPermission('updateSelf'))),
              },

              {
                tooltipShortIsVisible: true,
                component: (
                  <UserGroupDeleteButton
                    isFloatingButton
                    userGroup={userGroupData}
                    isVisible={
                      userGroupParam &&
                      userGroupData &&
                      hasGroupPermission(userGroupData, 'GR_deleteGroup')
                    }
                  />
                ),
              },
              {
                onClick: onUserGroupEditClick,
                tooltip: t('user group.edit'),
                tooltipShort: t('user group.edit short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-edit" />,
                isVisible:
                  userGroupParam?.length &&
                  userGroupData &&
                  getAllowEditGroup(),
              },
            ]}
          />
        ),
        isVisible: true,
      },

      {
        onClick: onClose,
        tooltip: t('close'),
        icon: <SvgIcon code="icon-close" />,
        isSubdued: true,
        isVisible: userParam?.length || userGroupParam?.length ? true : false,
      },
    ]

    return <FloatingMenu buttons={buttons} />
  }

  //
  // RENDER
  //
  const doRenderDetail = () => userParam?.length > 0
  const doRenderEdit = () => userGroupParam?.length > 0

  return (
    <>
      {/*
       ** VIEW - FILES
       */}
      {!doRenderDetail() && !doRenderEdit() && (
        <LoaderPageLayout
          title={t('navigation.users')}
          isLoading={false}
          isReady={true}
        >
          <FileSystemUsers
            defaultViewMode="grid"
            onFileDoubleClick={onFileOpenClick}
            onFileOpenClick={onFileOpenClick}
            onFolderDoubleClick={onFolderOpenClick}
            onFolderOpenClick={onFolderOpenClick}
          />
        </LoaderPageLayout>
      )}

      {/*
       ** VIEW - SONG DETAIL
       */}
      {doRenderDetail() && (
        <UserDetail userId={userParam} setOutsideUserData={setUserData} />
      )}

      {/*
       ** VIEW - SONG EDIT
       */}
      {doRenderEdit() && (
        <UserGroupDetail
          userGroupId={userGroupParam}
          setOutsideUserData={setUserGroupData}
        />
      )}

      {renderFloatingMenu()}
    </>
  )
}

export default UsersPage
