import { LyricDesignType, TextLyricDesignType } from 'types'

export const LYRICS_DEFAULT_DESIGN: LyricDesignType = {
  ALLOW_EDIT: true,
  editMode: 'chords',

  fontSize: 14,
  lineGap: 4,
  chordNameSize: 14,
  chordDurationSize: 12,
  noteSize: 12,

  showLyricChords: true,
  showLyricNotes: true,
  showSpaces: false,
  showPaperLine: true,
}

export const TEXT_LYRICS_DEFAULT_DESIGN: TextLyricDesignType = {
  contentPaddingTop: 100,
  contentPaddingLeft: 100,
  contentPaddingRight: 0,
  contentHeight: 3000,
  columnGap: 90,
  scaleX: 100,

  fontSize: 35,
  letterSpacing: -1,
  lineGapY: 0,
  sectionGapY: 80,

  showLyricChords: true,
  showLyricNotes: true,
  showSectionNotes: true,

  wrapIndexesSorted: [],
}
