import { css } from 'styled-components'

//
// Default Styles
//
export const gridBackground = css`
  --gridSize: 30px;
  background-size: var(--gridSize) var(--gridSize);
  background-image: radial-gradient(
    circle,
    ${props => props.theme.primary_light}66 1px,
    #0000 1px
  );
`
