import { gql } from '@apollo/client'

export const GET_PLAYLISTS = gql`
  query {
    getPlaylists {
      id
      name
      body

      playlistMembers {
        id
        playlistRole {
          code
          playlistPermissions {
            code
          }
        }

        user {
          id
          name
          username
          avatar
          role {
            code
          }
        }

        userGroup {
          id
          name
          avatar
          userGroupMembers {
            user {
              id
              name
              username
              avatar
            }
          }
        }
      }
    }
  }
`

export const GET_PLAYLIST = gql`
  query getPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      name
      body

      playlistMembers {
        id
        playlistRole {
          code
          playlistPermissions {
            code
          }
        }

        user {
          id
          name
          username
          avatar
          role {
            code
          }
        }

        userGroup {
          id
          name
          avatar
          userGroupMembers {
            user {
              id
              name
              username
              avatar
            }
          }
        }
      }

      songs {
        id
        name
        author
        languageCode
        body
      }
    }
  }
`
