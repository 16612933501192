/* eslint-disable no-loop-func */
import { CHORD_REGEX, getChordsFromText } from 'utils/chordManipulation'
import {
  CompositionSectionType,
  LyricRoleType,
  LyricSectionType,
  SongChordType,
  SongType,
} from 'types'
import {
  EMPTY_SONG,
  LYRICS_ROLES_TRANSLATED,
  LYRIC_ROLES_MISC,
  TEXT_LYRICS_DEFAULT_DESIGN,
} from 'const'
import {
  compareSearchStrings,
  getEmptyString,
  getGlobalCaretIndex,
} from 'utils/text'

import { getUniqueLyricChordId } from './lyricSections'
import { uniqueArray } from 'utils/files'

export type ProcessedLyricLineType = {
  type: 'chords' | 'text' | 'section'
  text?: string
}

export const identifySongPartsInText = (
  songText: string,
  options?: { keepEmptyLines?: boolean }
) => {
  const lyricLines: ProcessedLyricLineType[] = []

  const lines = (
    options?.keepEmptyLines ? songText : songText.replace(/\n\s*\n/g, '\n')
  ).split('\n')

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]

    // skip lines with just empty spaces
    if (line.trim().length === 0) {
      continue
    }

    // is the line a section heading?
    const isSection: boolean = line.trim().startsWith('[')
    if (isSection) {
      lyricLines.push({ type: 'section', text: line })
      continue
    }

    // is the line containing chords?
    const isChordLine: boolean =
      line.replace(CHORD_REGEX, '').trim().length === 0

    if (isChordLine) {
      lyricLines.push({ type: 'chords', text: line.trimEnd() })
      continue
    }

    // the line is lyrics
    lyricLines.push({ type: 'text', text: line.trimEnd() })
  }

  return lyricLines
}

export const getLyricRoleFromText = (text: string) => {
  const roleObjs: { id: string; translated: string }[] = Object.values(
    LYRICS_ROLES_TRANSLATED
  )
    .map((localeRoles) =>
      Object.entries(localeRoles).map(([id, translated]) => {
        return { id, translated }
      })
    )
    .flat()

  let foundRoleObj
  let longestMatch = 0

  /* Check translated song sections */
  for (let i = 0; i < roleObjs.length; i++) {
    const roleObj = roleObjs[i]
    if (
      compareSearchStrings(text, roleObj.translated, { alphabetOnly: true })
    ) {
      const numbers = text.match(/\d/g)
      const roleIndex = numbers?.length ? parseInt(numbers[0]) : 0
      if (longestMatch < roleObj.translated.length) {
        longestMatch = roleObj.translated.length
        foundRoleObj = { role: roleObj.id, roleIndex }
      }
    }
  }

  /* Check additional song sections */
  if (!foundRoleObj) {
    longestMatch = 0
    LYRIC_ROLES_MISC.forEach((keyPair) => {
      if (compareSearchStrings(text, keyPair.input, { alphabetOnly: true })) {
        const numbers = text.match(/\d/g)
        const roleIndex = numbers?.length ? parseInt(numbers[0]) : 0
        if (longestMatch < keyPair.roleId.length) {
          longestMatch = keyPair.roleId.length
          foundRoleObj = { role: keyPair.roleId, roleIndex }
        }
      }
    })
  }

  return foundRoleObj || { role: 'verse', roleIndex: 0 }
}

//
// CREATE LYRIC SECTIONS FROM TEXT
//
const pushLyricText = (section, text) => {
  section.lyrics = section.lyrics?.length ? `${section.lyrics}\n${text}` : text
}

const getChordCenterLength = (chord: string) => {
  if (!chord?.length) {
    return 0
  }
  return Math.floor(chord.length / 2)
}

const getLyricSectionForComparison = (lyricSection: LyricSectionType) => {
  const section = { ...lyricSection }
  delete section.id
  delete section.roleIndex
  return section
}

const getEqualLyricSection = (
  lyricSections: LyricSectionType[],
  lyricSection: LyricSectionType
) => {
  for (let i = 0; i < lyricSections.length; i++) {
    const usedSection: LyricSectionType = lyricSections[i]
    if (
      JSON.stringify(getLyricSectionForComparison(usedSection)) ===
      JSON.stringify(getLyricSectionForComparison(lyricSection))
    ) {
      return usedSection
    }
  }
  return null
}

export const getLyricSectionsFromText = (
  songText: string,
  songChords: SongChordType[]
) => {
  const lyricLines: ProcessedLyricLineType[] = identifySongPartsInText(songText)
  const lyricSections: LyricSectionType[] = []

  let newSection: LyricSectionType = undefined

  for (let i = 0; i < lyricLines.length; i++) {
    const line = lyricLines[i]
    const nextLine = lyricLines[i + 1]
    const prevLine = lyricLines[i - 1]

    //
    // arrived at new section (or just started - handle if there are no sections to be recognized)
    //
    if (line.type === 'section' || i === 0) {
      let id = 0
      if (newSection) {
        // if there is a previous section, store it
        lyricSections.push(newSection)
        id = newSection.id + 1
      }

      //
      // get section role from text
      //
      const { role, roleIndex } = getLyricRoleFromText(line.text) as any

      //
      // create a new section
      //
      newSection = {
        id,
        role,
        roleIndex,
        lyrics: '',
        lyricChords: [],
        lyricNotes: [],
      }
    }

    //
    // arrived at lyric line that is not after chords line (lines AFTER chords are processed with chord lines below)
    //
    if (line.type === 'text' && (!prevLine || prevLine.type !== 'chords')) {
      pushLyricText(newSection, line.text)
    }

    //
    // arrived at chord line
    //
    if (line.type === 'chords') {
      const chordLine = line.text
      const textLine = nextLine?.text

      //
      // process following lyric line (each chord line is followed by lyrics -> if not, insert empty "...   " line
      //
      let processedLine = ''
      if (nextLine?.type === 'text' && textLine) {
        // text line has to be longer than chord line (chords need index in empty string)
        const lineDiff = chordLine.length - textLine.length
        if (lineDiff > 0) {
          processedLine = textLine + getEmptyString(lineDiff)
        } else {
          processedLine = textLine
        }
      } else {
        processedLine = '...' + getEmptyString(chordLine.length)
      }

      pushLyricText(newSection, processedLine)

      //
      // figure out chords and positions
      //
      const textLines = newSection.lyrics.split('\n')
      const lineIndex = textLines.length - 1
      let caret = 0

      chordLine.split(' ').forEach((chord) => {
        if (!chord!.length) {
          // empty space, increase caret
          caret += 1
        } else {
          // calculate chord position (should be centered!)
          const chordPosition = getGlobalCaretIndex(
            caret + getChordCenterLength(chord),
            textLines,
            lineIndex
          )

          newSection.lyricChords.push({
            itemId: getUniqueLyricChordId(newSection),
            position: chordPosition,
            chordId: songChords.find((c) => c.name === chord)?.id,
          })

          caret += chord.length + 1
        }
      })
    }
  }

  if (newSection) {
    // store the last section
    lyricSections.push(newSection)
  }

  return lyricSections
}

export const convertTextToSong = (songText: string) => {
  if (!songText?.length) {
    return EMPTY_SONG
  }

  const sequenceOfAllChords: string[] = getChordsFromText(songText)
  const uniqueChords = uniqueArray(sequenceOfAllChords)

  const chords: SongChordType[] = uniqueChords.map((chord, i) => {
    return { id: i, name: chord }
  })

  const lyricSections: LyricSectionType[] = getLyricSectionsFromText(
    songText,
    chords
  )

  const compositionSections: CompositionSectionType[] = []
  const uniqueLyricSections: LyricSectionType[] = []

  //
  // Create song composition sections and remove duplicate lyric sections
  //
  lyricSections.forEach((lyricSection, i) => {
    let lyricSectionId = lyricSection.id
    const previouslyUsedSameLyricSection = getEqualLyricSection(
      uniqueLyricSections,
      lyricSection
    )

    if (previouslyUsedSameLyricSection) {
      lyricSectionId = previouslyUsedSameLyricSection.id
    } else {
      // if lyric section has no duplicates, store it
      uniqueLyricSections.push(lyricSection)
    }

    // create a new composition section linked to lyric section
    compositionSections.push({
      id: i,
      lyricSectionId,
      note: '',
      note2: '',
    })
  })

  //
  // (Optional if wanted) - Smart role index numbering
  //
  const counters: { role: LyricRoleType; count: number }[] = []

  uniqueLyricSections.forEach((lyricSection, i) => {
    const isOnlyOnceInSong =
      uniqueLyricSections.filter((s) => s.role === lyricSection.role).length ===
      1
    if (!isOnlyOnceInSong) {
      // lyric section is used more than once (and is non-unique)

      const counterIndex = counters.findIndex(
        (c) => c.role === lyricSection.role
      )
      let roleIndex = 1

      if (counterIndex < 0) {
        counters.push({ role: lyricSection.role, count: 1 })
      } else {
        roleIndex = counters[counterIndex].count + 1
        counters[counterIndex].count = roleIndex
      }

      uniqueLyricSections[i].roleIndex = roleIndex
    }
  })

  //
  // Chord Cleanup - for some reason, if e.g. 'C#m' is present, it also adds 'C' chord
  //
  const usedChords: SongChordType[] = []

  uniqueLyricSections.forEach((lyricSection, i) => {
    const lyricSectionChordIds = lyricSection?.lyricChords?.map(
      (c) => c.chordId
    )

    chords.forEach((c) => {
      if (
        lyricSectionChordIds.includes(c.id) &&
        !usedChords.find((usedChord) => usedChord.id === c.id)
      ) {
        usedChords.push(chords.find((chord) => chord.id === c.id))
      }
    })
  })

  //
  // Put together final song object
  //
  const song: SongType = {
    ...EMPTY_SONG,
    chords: usedChords,
    lyricSections: uniqueLyricSections,
    compositionSections,
    textLyricDesign: TEXT_LYRICS_DEFAULT_DESIGN,
  }

  return song
}
