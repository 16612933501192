import {
  A4_COMPONENT_EXPORT_ID,
  A4_COMPONENT_EXPORT_INSTRUMENTS_ID,
} from 'components/Song/SongDetail/IconMenu'
import {
  DBPlaylistType,
  DBSongType,
  PlaylistBodyType,
  PlaylistSongType,
  SongType,
} from 'types'
import {
  convertSongToText,
  downloadAsFile,
  hasPlaylistPermission,
  hasSongPermission,
  simplifySongChords,
  transposeSongChords,
} from 'utils'

import download from 'downloadjs'
import { getPlaylistSongUserSettings } from './playlist'
import { notifyDownloadFinished } from './files'
import { toPng } from 'html-to-image'

//
// FILE
//
const getSongName = (
  props: { songBody?: SongType; transpose?: number; simplifyChords?: boolean },
  t
) => {
  const name = `${props.songBody.author && `${props.songBody.author} - `}${
    props.songBody.name
  }`
  let transformed = ''
  if (props.transpose) {
    transformed += `${t('song.filename transposed')} ${props.transpose}`
  }
  if (props.simplifyChords) {
    if (props.transpose) {
      transformed += ', '
    }
    transformed += t('song.filename simplified')
  }

  return `${name}${transformed?.length ? ` (${transformed})` : ''}`
}

const getFileName = (
  props: {
    songBody?: SongType
    extension: string
    transpose?: number
    simplifyChords?: boolean
  },
  t
) => {
  return `${getSongName(props, t)}${props.extension}`
}

const getPlaylistFileName = (
  props: {
    playlistBody: PlaylistBodyType
    playlistSong: PlaylistSongType
    extension: string
    transpose?: number
    simplifyChords?: boolean
    index: number
  },
  t
) => {
  const name = `${props.playlistBody?.name} - ${
    props.index + 1
  } - ${getSongName({ ...props, songBody: props.playlistSong.songData }, t)}`
  return `${name}${props.extension}`
}

//
// DOWNLOADS
//
export const allowDownloads = (props: {
  song?: DBSongType
  playlist?: DBPlaylistType
}) => {
  if (
    props.song &&
    hasSongPermission(props.song.songMembers, 'SONG_downloadSong')
  ) {
    return true
  }
  if (
    props.playlist &&
    hasPlaylistPermission(
      props.playlist.playlistMembers,
      'PLAYLIST_downloadPlaylist'
    )
  ) {
    return true
  }
  return false
}

export const downloadAsJSON = (
  props: {
    songBody: SongType
    transpose?: number
    simplifyChords?: boolean
  },
  t
) => {
  /* Purposely doesn't take transposition / simplify chords into account
       (isn't meant to be read, it's for importing the base data) */
  downloadAsFile({
    data: JSON.stringify(props.songBody, null, 2),
    fileName: getFileName({ ...props, extension: '.json' }, t),
  })
}

export const downloadAsTXT = (
  props: {
    songBody: SongType
    transpose?: number
    simplifyChords?: boolean
    includeChordsInExport?: boolean
  },
  t
) => {
  downloadAsFile({
    data: convertSongToText({
      songBody: {
        ...props.songBody,
        capo: (props.songBody.capo || 0) - (props.transpose || 0),
        chords: transposeSongChords(
          props.transpose,
          simplifySongChords(props.songBody.chords)
        ),
      },
      t,
      skipInstruments: !props.includeChordsInExport,
    }),
    fileName: getFileName({ ...props, extension: '.txt' }, t),
  })
}

export const downloadElementAsPNG = (
  elementId,
  filename,
  callback?: (filename: string) => void
) => {
  toPng(document.getElementById(elementId)).then((dataUrl) => {
    download(dataUrl, filename)
    if (callback) {
      callback(filename)
      notifyDownloadFinished(filename)
    }
  })
}

export const downloadAsPNG = (
  props: {
    songBody?: SongType
    playlistBody?: PlaylistBodyType
    transpose?: number
    simplifyChords?: boolean
    includeChordsInExport?: boolean
    callback?: (filename: string) => void
  },
  t
) => {
  if (props.songBody) {
    const hasInstruments =
      props.includeChordsInExport && props.songBody?.instrumentSections?.length

    downloadElementAsPNG(
      A4_COMPONENT_EXPORT_ID,
      getFileName({ ...props, extension: '.png' }, t),
      hasInstruments ? undefined : props.callback
    )

    if (hasInstruments) {
      downloadElementAsPNG(
        A4_COMPONENT_EXPORT_INSTRUMENTS_ID,
        getFileName({ ...props, extension: ' (instruments).png' }, t),
        props.callback
      )
    }
  } else if (props.playlistBody) {
    props.playlistBody.playlistSongs?.forEach((ps, i) => {
      const userSettings = getPlaylistSongUserSettings(ps)

      const elemId = `${A4_COMPONENT_EXPORT_ID}_${ps.songId}`
      downloadElementAsPNG(
        elemId,
        getPlaylistFileName(
          {
            playlistBody: props.playlistBody,
            playlistSong: ps,
            transpose: userSettings.transpose,
            simplifyChords: userSettings.simplifyChords,
            index: i,
            extension: '.png',
          },
          t
        ),
        props.callback
      )
    })
  }
}

/* const downloadAsPDF = () => {
    toSvg(document.getElementById(A4_COMPONENT_EXPORT_ID)).then(function (
      dataUrl
    ) {
      download(dataUrl, getFileName('svg'))
    })
  } */
