/* eslint-disable react-hooks/exhaustive-deps */
import { SCLChordType, SCLChordsByRootType } from 'types'
import { useEffect, useMemo, useState } from 'react'

import ChordLibraryList from '../ChordLibraryList'
import StringInput from 'components/Inputs/StringInput'
import { compareSearchStrings } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
`

const InputSection = styled.div`
  position: relative;
`

const Input = styled.div`
  width: 100%;

  & input {
    min-height: 50px;
    font-size: 16px;
    font-weight: 600;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.primary_light};

    &:focus {
      background: ${(props) => props.theme.lighter};
      color: ${(props) => props.theme.primary_light};
    }
  }
`

const ResultSection = styled.div`
  flex: 1;
`

const ChordFinder = (props: {
  tuning: string[]
  chordsByRoot: SCLChordsByRootType
}) => {
  const { t } = useTranslation()

  const chordsFlat: SCLChordType[] = useMemo(
    () => [...Object.values(props.chordsByRoot)].flat(),
    []
  )

  const [search, setSearch] = useState<string>()
  const [foundChords, setFoundChords] = useState<SCLChordType[]>()

  useEffect(() => {
    if (search) {
      setFoundChords(
        chordsFlat.filter((chord) => compareSearchStrings(chord.name, search))
      )
    } else {
      setFoundChords(undefined)
    }
  }, [search])

  return (
    <Wrapper>
      <InputSection>
        <Input>
          <StringInput
            placeholder={t('tools.chord-finder.find')}
            value={search}
            setValue={setSearch}
            liveUpdate
          />
        </Input>
      </InputSection>

      <ResultSection>
        {foundChords ? (
          <ChordLibraryList
            tuning={props.tuning}
            chords={foundChords}
            filterChords={false}
            maxCount={6}
          />
        ) : (
          <div />
        )}
      </ResultSection>
    </Wrapper>
  )
}

export default ChordFinder
