import styled from 'styled-components'
import theme from 'styles/theme'

const shadow_length = 30
const shadow_offset_x = 1
const shadow_offset_y = 1
const shadow_color = theme.black

const Wrapper = styled.div<{
  sizePx?: boolean
  noShadow?: boolean
}>`
  position: relative;
  color: ${props => (props.noShadow ? props.theme.black : props.theme.white)};
  font-size: ${props => (props.sizePx / 50 || 1) * 50}px;
  font-weight: 500;
`

const Shadow = styled.div<{
  sizePx?: boolean
  noShadow?: boolean
}>`
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: ${props => (props.noShadow ? props.theme.black : props.theme.white)};
  font-size: ${props => (props.sizePx / 50 || 1) * 50}px;
`

const AppName = (props: { sizePx?: number; noShadow?: boolean }) => {
  return (
    <Wrapper sizePx={props.sizePx} noShadow={props.noShadow}>
      m-note
      {!props.noShadow && (
        <Shadow sizePx={props.sizePx} noShadow={props.noShadow}>
          <span
            style={{
              textShadow: `${Array.apply(
                null,
                Array(Math.round(shadow_length * (props.sizePx / 50 || 1)))
              ).map(
                (u, i) =>
                  `${i * shadow_offset_x}px ${
                    i * shadow_offset_y
                  }px ${shadow_color}`
              )}`,
            }}
          >
            m-note
          </span>
        </Shadow>
      )}
    </Wrapper>
  )
}

export default AppName
