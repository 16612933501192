/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css, keyframes } from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'

import SvgIcon from 'components/SvgIcon'
import { TOOLS } from 'components/Tools'
import ToolItem from '../ToolItem'
import { gridBackground } from 'styles/GlobalStyle/Backgrounds'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'

const MENU_TOP = '49px'
const MENU_HEIGHT = '100px'

const Wrapper = styled.div<{ isCompact?: boolean }>`
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  box-shadow: 0 0 20px 0 ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.background};
  /* ${gridBackground} */

  transition: all 0.2s ease;

  ${(props) =>
    props.isCompact
      ? css`
          height: ${MENU_HEIGHT};
          bottom: 0;
        `
      : css`
          height: calc(100% - ${MENU_TOP});
        `}
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
`

const ItemsWrapper = styled.div<{ isCompact?: boolean }>`
  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: 0s;

  ${(props) =>
    props.isCompact
      ? css`
          width: 100%;
        `
      : css`
          overflow-y: auto;
          margin: auto;

          --paddingX: 30vw;
          --paddingY: min(100px, 20vw);

          width: calc(100% - var(--paddingX) * 2);
          padding: var(--paddingY) var(--paddingX);

          ${(props) => props.theme.breakpoint.L} {
            --paddingX: 20vw;
          }
          ${(props) => props.theme.breakpoint.M} {
            --paddingX: 15vw;
          }
          ${(props) => props.theme.breakpoint.S} {
            --paddingX: 5vw;
          }
          ${(props) => props.theme.breakpoint.XXS} {
            --paddingX: 0vw;
          }
        `}
`

const Items = styled.div<{ isCompact?: boolean; toolCount?: number }>`
  flex: 1;
  scroll-behavior: smooth;

  --cellSize: 150px;
  --cellSizeCompact: 120px;

  ${(props) => props.theme.breakpoint.S} {
    --cellSize: 100px;
    --cellSizeCompact: 100px;
  }

  display: grid;

  ${(props) =>
    props.isCompact
      ? css`
          overflow-y: hidden;
          overflow-x: auto;
          padding-left: calc(50vw - var(--cellSizeCompact) / 2);
          grid-template-rows: ${MENU_HEIGHT};
          grid-template-columns: repeat(
            ${(props) => props.toolCount},
            minmax(var(--cellSizeCompact), 1fr)
          );

          /* hide scrollbar */
          -ms-overflow-style: none;
          scrollbar-width: none;
          scrollbar-height: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `
      : css`
          align-items: center;
          justify-items: center;
          justify-content: center;
          align-content: center;
          grid-auto-rows: calc(var(--cellSize) * 1.3);
          grid-template-rows: calc(var(--cellSize) * 1.3);
          grid-template-columns: repeat(
            auto-fill,
            minmax(var(--cellSize), 1fr)
          );
        `}
`

const Navigation = styled.div<{ isCompact: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  pointer-events: none;

  ${(props) =>
    props.isCompact
      ? css`
          background: linear-gradient(
            to left,
            ${(props) => props.theme.background}AA 0%,
            ${(props) => props.theme.background}00 15%,
            ${(props) => props.theme.background}00 85%,
            ${(props) => props.theme.background}AA 100%
          );
          opacity: 1;
        `
      : css`
          background: none;
          opacity: 0;
        `}
`

const NavButton = styled.button<{ isActive: boolean }>`
  ${resetButtonCss}
  height: 100%;
  transition: all 0.2s ease;

  svg {
    color: ${(props) => props.theme.primary}88;
    --size: 40px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
  }

  ${(props) =>
    props.isActive
      ? css`
          opacity: 1;
          pointer-events: auto;
        `
      : css`
          opacity: 0.4;
          pointer-events: none !important;
        `}
`

const ToolMenu = (props: {
  activeToolId: string
  setActiveToolId: (v: string) => void
}) => {
  const menuRef = useRef()

  const menuScrollPadding = 40
  const [menuScroll, setMenuScroll] = useState<number>(0)
  const [maxMenuScroll, setMaxMenuScroll] = useState<number>(300)

  const [activeItemRef, setActiveItemRef] = useState<{ current: any }>()
  const [activeItemTrueRef, setActiveItemTrueRef] = useState<{ current: any }>()
  const [isCompact, setisCompact] = useState<boolean>(false)

  //
  // ACTIVE TOOL MANAGEMENT
  //
  useEffect(() => {
    if (props.activeToolId) {
      setisCompact(true)
    } else {
      setisCompact(false)
    }
  }, [props.activeToolId])

  useEffect(() => {
    let timer

    if (isCompact && activeItemRef?.current) {
      timer = setTimeout(() => {
        ;(activeItemRef?.current as HTMLElement).scrollIntoView({
          behavior: 'smooth',
        })
      }, 100)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isCompact, activeItemRef?.current])

  //
  // SCROLL MANAGEMENT
  //
  const scrollMenu = (increment: number) => {
    const menu: HTMLElement = menuRef?.current
    const item: HTMLElement = activeItemTrueRef?.current
    if (item && menu) {
      const itemWidth = item.clientWidth
      menu.scrollLeft += itemWidth * increment
    }
  }

  const scrollLeft = () => {
    scrollMenu(-1)
  }
  const scrollRight = () => {
    scrollMenu(1)
  }

  //
  // DETECT SCROLL CHANGES
  //
  const handleScroll = useCallback((e) => {
    const menu: HTMLElement = e?.target
    const item: HTMLElement = activeItemTrueRef?.current
    if (menu && item) {
      setMenuScroll(menu.scrollLeft)
      setMaxMenuScroll(item.clientWidth * (TOOLS.length - 1))
    }
  }, [])

  useEffect(() => {
    const menu: HTMLElement = menuRef?.current
    if (menu) {
      menu.addEventListener('scroll', handleScroll)
    }
    return () => {
      menu.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  //
  // RENDER
  //
  return (
    <Wrapper isCompact={isCompact}>
      <ItemsWrapper isCompact={isCompact}>
        <Items isCompact={isCompact} ref={menuRef} toolCount={TOOLS.length}>
          {TOOLS.map((tool) => (
            <ToolItem
              setActiveItemRef={setActiveItemRef}
              setActiveItemTrueRef={setActiveItemTrueRef}
              key={tool.id}
              tool={tool}
              activeToolId={props.activeToolId}
              setActiveToolId={props.setActiveToolId}
              isCompact={isCompact}
            />
          ))}
        </Items>
      </ItemsWrapper>

      <Navigation isCompact={isCompact}>
        <NavButton
          onClick={scrollLeft}
          isActive={menuScroll > menuScrollPadding}
        >
          <SvgIcon code="icon-chevron-left" />
        </NavButton>
        <NavButton
          onClick={scrollRight}
          isActive={menuScroll < maxMenuScroll - menuScrollPadding}
        >
          <SvgIcon code="icon-chevron-right" />
        </NavButton>
      </Navigation>
    </Wrapper>
  )
}

export default ToolMenu
