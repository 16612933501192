import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ paddingTop?: string }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 20px;

  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `}
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  row-gap: 3px;
  text-align: center;
`

const Title = styled.div`
  font-size: 12px;
  font-weight: 800;
  color: ${(props) => props.theme.admin};
`

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.black}99;
`

const Line = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.admin};
`

const ModuleSectionTitle = (props: {
  title?: string
  subtitle?: string
  paddingTop?: string
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper paddingTop={props.paddingTop}>
      <Header>
        {props.title && <Title>{props.title}</Title>}
        {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
      </Header>
      <Line />
    </Wrapper>
  )
}

export default ModuleSectionTitle
