import { HEADER_HEIGHT } from 'const'
import { ToastContainer } from 'react-toastify'
import { breakpoints } from 'styles/breakpoints'
import styled from 'styled-components'
import theme from 'styles/theme'

const StyledToastContainer = styled(ToastContainer)`

.Toastify__toast {
  margin-bottom: 5px;
  
  @media screen and (max-width: ${breakpoints.S}){
    margin-bottom: 4px;
  }
}

&.Toastify__toast-container--top-right {
  top: 0;
  padding: 0px;

  margin-top: calc(${HEADER_HEIGHT} + 50px);
  right: 50pX;

  @media screen and (max-width: ${breakpoints.L}){
    margin-top: calc(${HEADER_HEIGHT} + 20px);
    right: 20pX;
  }
  @media screen and (max-width: ${breakpoints.S}){
    margin-top: 0px;
    right: 0px;
    width: 100vw;
  }

  &>div{
    border-radius: 0px;
    margin-top: 0px;
    font-size: 12px;
    font-weight: 600;
    background: ${theme.primary};
    white-space: pre-wrap !important;
  }
}

  .Toastify__close-button,
  .Toastify__close-button--light,
  .Toastify__close-button--default {
    opacity: 0.8;
    --size: 40px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
  
  .Toastify__close-button > svg {
    color: ${theme.white} !important;
    --size: 20px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }

  --toastify-toast-width: min(100%, 300px);
  --toastify-toast-min-height: ${HEADER_HEIGHT};
  --toastify-toast-max-height: 100vh;
  --toastify-font-family: inherit;

  --toastify-text-color-light: ${theme.background};
  --toastify-color-dark: ${theme.white};
  --toastify-color-info:${theme.primary_light};
  --toastify-color-success: ${theme.success};
  --toastify-color-warning:${theme.warning};
  --toastify-color-error: ${theme.error};
  
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
`
const NotificationsContainer = () => {
  return (
    <StyledToastContainer
      position="top-right"
      autoClose={3000}
      newestOnTop
      pauseOnHover
      pauseOnFocusLoss
      closeOnClick
      limit={6}
    />
  )
}

export default NotificationsContainer
