import { TOOLS, ToolType } from 'components/Tools'
import styled, { keyframes } from 'styled-components'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LoaderPageLayout from 'layouts/LoaderPageLayout'
import ToolMenu from 'components/Tools/ToolMenu'
import { useTranslation } from 'react-i18next'

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
`

const ToolDetail = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: 0s;

  align-self: center;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 1400px;
`

const ToolsPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const [activeTool, setActiveTool] = useState<ToolType>()

  //
  // TOOL ID PARAM
  //
  const setActiveToolId = (toolId) => {
    if (toolId) {
      history.push({
        pathname: location.pathname,
        search: new URLSearchParams({ tool: toolId }).toString(),
      })
    } else {
      history.push({
        pathname: location.pathname,
      })
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const toolId = searchParams.get('tool')
    if (toolId) {
      setActiveTool(TOOLS.find((t) => t.id === toolId))
    } else {
      setActiveTool(undefined)
    }
  }, [location])

  //
  // RENDER
  //
  return (
    <>
      <LoaderPageLayout
        title={undefined}
        subtitle={undefined}
        isLoading={false}
        isReady={true}
        isFullWidthPage
      >
        {TOOLS.map((tool) =>
          tool.id === activeTool?.id ? (
            <ToolDetail key={tool.id}>
              <h1>{t(tool.toolName)}</h1>
              {tool.component}
            </ToolDetail>
          ) : null
        )}
      </LoaderPageLayout>

      <ToolMenu
        activeToolId={activeTool?.id}
        setActiveToolId={setActiveToolId}
      />
    </>
  )
}

export default ToolsPage
