import { differenceInDays, differenceInWeeks, isPast, isToday } from 'date-fns'

import i18n from 'i18n'

export const convertLocale = (lang: string) => {
  if (lang === 'cz') {
    return 'cs-CZ'
  }
  if (lang === 'sk') {
    return 'sk-SK'
  }
  return 'en-EN'
}

export const getLocalizedDateTimeString = (datetime: string, lang: string) => {
  if (!datetime) {
    return ''
  }
  return new Intl.DateTimeFormat(convertLocale(lang), {
    timeZone: 'UTC',
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(datetime))
}

export const getLocalizedDateString = (
  datetime: string,
  lang: string,
  options?: { month?: 'numeric' | 'long' | 'short' }
) => {
  if (!datetime) {
    return ''
  }
  return new Intl.DateTimeFormat(convertLocale(lang), {
    timeZone: 'UTC',
    month: options?.month || 'numeric',
    day: 'numeric',
    year: 'numeric',
  }).format(new Date(datetime))
}

export const getLocalizedTimeUntilDate = (dateString: string) => {
  if (!dateString) {
    return null
  }

  const date = new Date(dateString)
  if (isToday(date)) {
    return i18n.t('timing.today')
  }

  if (isPast(date)) {
    return i18n.t('timing.in_past')
  }

  const today = new Date()
  const daysRemaining = differenceInDays(date, today) + 1

  if (daysRemaining === 1) {
    return i18n.t('timing.tomorrow')
  }
  if (daysRemaining <= 4) {
    return `${i18n.t('timing.in')} ${daysRemaining} ${i18n.t('timing.days_2')}`
  }
  if (daysRemaining <= 14) {
    return `${i18n.t('timing.in')} ${daysRemaining} ${i18n.t('timing.days_5')}`
  }

  const weeksRemaining = differenceInWeeks(date, today)

  if (weeksRemaining === 0 || weeksRemaining === 1) {
    return `${i18n.t('timing.in')} ${i18n.t('timing.weeks_1')}`
  }
  if (weeksRemaining <= 4) {
    return `${i18n.t('timing.in')} ${weeksRemaining} ${i18n.t(
      'timing.weeks_2'
    )}`
  }
  return `${i18n.t('timing.in')} ${weeksRemaining} ${i18n.t('timing.weeks_5')}`
}
