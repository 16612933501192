import { gql } from '@apollo/client'

export const CREATE_USER_GROUP = gql`
  mutation createUserGroup(
    $name: String!
    $description: String
    $avatar: String
    $members: [UserGroupMemberInput]
  ) {
    createUserGroup(
      name: $name
      description: $description
      avatar: $avatar
      members: $members
    ) {
      success
      message
      id
    }
  }
`

export const UPDATE_USER_GROUP = gql`
  mutation updateUserGroup(
    $id: ID!
    $name: String
    $description: String
    $avatar: String
    $members: [UserGroupMemberInput]
  ) {
    updateUserGroup(
      id: $id
      name: $name
      description: $description
      avatar: $avatar
      members: $members
    ) {
      success
      message
    }
  }
`

export const DELETE_USER_GROUP = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(id: $id) {
      success
      message
    }
  }
`
