import Dev from 'pages/_Dev'
import Home from 'pages/Home'
import Login from 'pages/Login'
import PlayLists from 'pages/Playlists'
import { ReactElement } from 'react'
import Songs from 'pages/Songs'
import SvgIcon from 'components/SvgIcon'
import Tools from 'pages/Tools'
import Users from 'pages/Users'

type RouteType = {
  slug: string
  page: any
  noLayout?: boolean
}

type RoutesType = {
  songs: RouteType
  playlists: RouteType
  events: RouteType
  users: RouteType
  tools: RouteType

  /* APP */
  dev: RouteType
  login: RouteType
  home: RouteType
}

export const ROUTES: RoutesType = {
  /* MAIN */
  songs: {
    slug: '/songs',
    page: <Songs />,
  },

  playlists: {
    slug: '/playlists',
    page: <PlayLists />,
  },

  events: {
    slug: '/events',
    page: <h1>Events</h1>,
  },

  users: {
    slug: '/users',
    page: <Users />,
  },

  tools: {
    slug: '/tools',
    page: <Tools />,
  },

  /* APP */
  dev: {
    slug: '/_dev',
    page: <Dev />,
  },

  login: {
    slug: '/login',
    page: <Login />,
    noLayout: true,
  },

  home: {
    // HAS TO BE THE LAST!
    slug: '/',
    page: <Home />,
  },
}

export const NAVIGATION: {
  name: string
  slug: string
  icon: ReactElement
  relatedSlugs: string[]
  adminOnly?: boolean
}[] = [
  {
    name: 'navigation.dashboard',
    slug: ROUTES.home.slug,
    icon: <SvgIcon code="icon-nav-dashboard" />,
    relatedSlugs: [], // these slugs highlight the general page (this one) in the header navigation
  },
  {
    name: 'navigation.songs',
    slug: ROUTES.songs.slug,
    icon: <SvgIcon code="icon-nav-songs" />,
    relatedSlugs: [],
  },
  {
    name: 'navigation.playlists',
    slug: ROUTES.playlists.slug,
    icon: <SvgIcon code="icon-nav-playlists" />,
    relatedSlugs: [],
  },
  {
    name: 'navigation.users',
    slug: ROUTES.users.slug,
    icon: <SvgIcon code="icon-nav-users" />,
    relatedSlugs: [],
  },
  {
    name: 'navigation.tools',
    slug: ROUTES.tools.slug,
    icon: <SvgIcon code="icon-nav-tools" />,
    relatedSlugs: [],
  },
  {
    adminOnly: true,
    name: 'navigation.dev',
    slug: ROUTES.dev.slug,
    icon: <SvgIcon code="icon-nav-dev" />,
    relatedSlugs: [],
  },
]
