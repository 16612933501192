import { APP_LANGUAGE_CODES, AppLanguageType } from 'const'
import { Page, Worker } from 'tesseract.js'

const getTesseractLang = (lang: AppLanguageType) => {
  switch (lang) {
    case APP_LANGUAGE_CODES.cz:
      return 'ces'
    case APP_LANGUAGE_CODES.sk:
      return 'slk'
    default:
      return 'eng'
  }
}

export const asyncImageToText = async (props: {
  image: any
  lang: AppLanguageType
  worker: Worker
  callback: (result: Page) => void
}) => {
  const { image, worker, callback } = props

  const lang = getTesseractLang(props.lang)

  await worker.load()
  await worker.loadLanguage(lang)
  await worker.initialize(lang)
  await worker.setParameters({
    preserve_interword_spaces: '1',
  } as any)

  const { data } = await worker.recognize(image)

  callback(data)

  await worker.terminate()
}
