import RootModal from 'modals/base/RootModal'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const Buttons = styled.div`
  align-self: center;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;

  margin-top: 60px;

  button {
    max-width: 46%;
  }

  ${(props) => props.theme.breakpoint.XS} {
    row-gap: 20px;
    align-items: stretch;
    flex-direction: column-reverse;
    button {
      max-width: 100%;
    }
  }
`

/**
 *
 * COMPONENT
 *
 */
const DataModal = (props: {
  title: string
  isOpen: boolean
  hasData: boolean
  onSubmitLabel: string
  onSubmit: () => void
  onCancelLabel: string
  onCancel: () => void
  closeModal: () => void
  closeOnESC?: boolean
  maxWidth?: string
  addZIndex?: number
  children: any
}) => {
  //
  // RENDER
  //
  return (
    <RootModal
      title={props.title}
      isOpen={props.isOpen}
      hasData={props.hasData}
      closeModal={props.closeModal}
      closeOnESC={props.closeOnESC}
      maxWidth={props.maxWidth || '800px'}
      addZIndex={props.addZIndex}
      noLargePadding
      noBackgroundDotsUnderContent
      smallTitle
    >
      <Wrapper>
        {props.children}

        <Buttons>
          <button
            className="btn-primary btn-primary--primary"
            onClick={props.onCancel}
          >
            {props.onCancelLabel}
          </button>
          <button
            className="btn-primary btn-primary--primary"
            onClick={props.onSubmit}
          >
            {props.onSubmitLabel}
          </button>
        </Buttons>
      </Wrapper>
    </RootModal>
  )
}

export default DataModal
