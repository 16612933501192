import { UserGroupType, UserType } from 'types'
import { hasGroupPermission, idAsNumber } from 'utils'
import styled, { keyframes } from 'styled-components'
import { useEffect, useState } from 'react'

import { File } from 'components/Files/FileSystem'
import FileSystemUsers from 'components/Files/FileSystemUsers'
import { GET_USER } from 'api/graphql'
import SvgIcon from 'components/SvgIcon'
import { USER_GROUP_ROLES } from 'const'
import UserGroupMembersList from './UserGroupMembersList'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const AddUserWrapper = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.2s;
  animation-fill-mode: backwards;

  align-self: stretch;
  flex: 1;
`

/**
 *
 *  COMPONENT
 *
 */
const UserGroupMembers = (props: {
  userGroup: UserGroupType
  setUserGroup?: (userGroup: UserGroupType) => void
  isEditMode?: boolean
}) => {
  const { t } = useTranslation()

  const [isAddFocused, setIsAddFocused] = useState<boolean>(false)

  const allowUpdateUserGroupMembers =
    !props.isEditMode || hasGroupPermission(props.userGroup, 'GR_updateMembers')

  //
  // FETCH SELECTED USER
  //
  const [fetchUserId, setFetchUserId] = useState<number>()

  const { data: fetchedUserData, refetch: refetchUserData } = useQuery(
    GET_USER,
    {
      variables: { id: fetchUserId },
      skip: !fetchUserId,
    }
  )

  useEffect(() => {
    if (fetchedUserData) {
      refetchUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserId])

  useEffect(() => {
    const user = fetchedUserData?.getUser
    if (
      user &&
      props.userGroup?.userGroupMembers
        .map((m) => idAsNumber(m.user?.id))
        .includes(idAsNumber(user.id))
    ) {
      toast.info(t('user group.user already a member'))
      return
    }
    if (user) {
      addMember(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserData])

  //
  // FUNCTIONS
  //
  const addMember = (user: UserType) => {
    props.setUserGroup({
      ...props.userGroup,
      userGroupMembers: [
        ...props.userGroup.userGroupMembers,
        {
          user: user,
          groupRole: {
            code: USER_GROUP_ROLES.member,
          },
        },
      ],
    })
  }

  //
  // FILE MANAGEMENT
  //
  const onUserDoubleClick = (file: File) => {
    if (file.id === fetchUserId) {
      toast.info(t('user group.user already a member'))
      return
    }
    setFetchUserId(file.id)
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.setUserGroup && allowUpdateUserGroupMembers && (
        <>
          <UserGroupMembersList
            userGroup={props.userGroup}
            setUserGroup={props.setUserGroup}
            onDeleteCallback={() => {
              setFetchUserId(undefined)
            }}
          />

          {allowUpdateUserGroupMembers && (
            <ButtonWrapper>
              {isAddFocused ? (
                <>
                  <AddUserWrapper>
                    <FileSystemUsers
                      defaultViewMode="grid"
                      onFileAddClick={onUserDoubleClick}
                      onFileDoubleClick={onUserDoubleClick}
                      maxHeight="40vh"
                      noUserGroups
                    />
                  </AddUserWrapper>

                  <button
                    onClick={() => setIsAddFocused(false)}
                    title={t('user group.close add user')}
                    className="btn-action-circular"
                  >
                    <SvgIcon code="icon-close" />
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setIsAddFocused(true)}
                  title={t('user group.add user')}
                  className="btn-action-circular"
                >
                  <SvgIcon code="icon-add" />
                </button>
              )}
            </ButtonWrapper>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default UserGroupMembers
