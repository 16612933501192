import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
  SongType,
  TextLyricDesignType,
} from 'types'
import {
  getGeneralInfoString,
  simplifySongChords,
  transposeSongChords,
} from 'utils'
/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css } from 'styled-components'

import { SONG_ZOOM_MODAL } from 'modals/SongZoomModal'
import SongLyricsAsText from 'components/Song/Paper/SongPaperA4/SongLyricsAsText'
import { useTranslation } from 'react-i18next'

export const PAPER_WIDTH = 2480
export const PAPER_HEIGHT = 3508

export const Paper = styled.div<{ isClickable?: boolean }>`
  width: ${PAPER_WIDTH}px;
  min-width: ${PAPER_WIDTH}px;
  height: ${PAPER_HEIGHT}px;
  min-height: ${PAPER_HEIGHT}px;

  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.white};

  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.01);
          box-shadow: 0 0 100px -40px ${(props) => props.theme.black}22;

        ${(props) => props.theme.breakpoint.M} {
          transform: none;
          box-shadow: none;
      }
    `}
`

export const Header = styled.div<{ paddingLeft?: number }>`
  position: relative;
  margin-top: 100px;
  margin-left: calc(
    ${(props) => (props.paddingLeft ? `${props.paddingLeft}px` : '100px')} -
      40px
  );
`

export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  width: 100%;
`

const HeaderRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 100px;
`

const HeaderTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

const HeaderTopBox = styled.div`
  padding: 20px 40px;
  background: ${(props) => props.theme.black}11;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  color: ${(props) => props.theme.black};
  font-size: 40px;
  text-align: center;
  white-space: pre;
`

export const SongName = styled.div`
  font-size: 64px;
  font-weight: 900;
  margin-top: -10px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.black};
`

export const SongAuthor = styled.div`
  font-size: 50px;
  font-weight: 500;
  color: ${(props) => props.theme.black}AA;
`

const SongChords = styled.div`
  margin-top: 10px;
  font-size: 36px;
  font-weight: 600;
  color: ${(props) => props.theme.primary_light};
`

const SongInfo = styled.div`
  margin-top: 10px;
  font-size: 36px;
  font-weight: 600;
  color: ${(props) => props.theme.dark};
`

const SongNotes = styled.div`
  margin-top: 10px;
  white-space: pre-line;
  font-size: 36px;
  color: ${(props) => props.theme.dark};
  text-align: right;
`

const Capo = styled.div`
  width: 200px;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  font-weight: 900;

  strong {
    font-size: 90px;
    font-weight: 900;
    margin-top: -10px;
    margin-bottom: -20px;
  }
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-right: 40px;
  padding-left: 40px;
  border-left: 14px solid ${(props) => props.theme.black};

  font-size: 36px;
  font-weight: 900;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const SongPaperA4 = (props: {
  id?: string
  generalInfo: SongGeneralInfoType
  lyricSections: LyricSectionType[]
  compositionSections: CompositionSectionType[]
  chords: SongChordType[]
  textLyricDesign: TextLyricDesignType
  setTextLyricDesign?: (d: TextLyricDesignType) => void
  readOnly?: boolean
  transpose?: number
  setTranspose?: (number) => void
  simplifyChords?: boolean
  setSimplifyChords?: (boolean) => void
  capoOffset?: number
  setCapoOffset?: (number) => void
  zoomSongBody?: SongType
  onZoomClick?: () => void
  topHeaderText?: string
  userStart?: string
  userEnd?: string
}) => {
  const { t } = useTranslation()

  const generalInfo = {
    ...props.generalInfo,
    capo: (props.generalInfo.capo || 0) - (props.transpose || 0),
  }

  const chords = transposeSongChords(
    props.transpose,
    props.simplifyChords ? simplifySongChords(props.chords) : props.chords
  )

  //
  // RENDER
  //
  return (
    <Paper
      id={props.id}
      onClick={() => {
        if (props.onZoomClick) {
          return props.onZoomClick()
        }
        if (props.zoomSongBody) {
          SONG_ZOOM_MODAL({
            ...SONG_ZOOM_MODAL(),
            isOpen: true,
            songs: [
              {
                songBody: props.zoomSongBody,
                transpose: props.transpose,
                setTranspose: props.setTranspose,
                simplifyChords: props.simplifyChords,
                setSimplifyChords: props.setSimplifyChords,
                capoOffset: props.capoOffset,
                setCapoOffset: props.setCapoOffset,
                userStart: props.userStart,
                userEnd: props.userEnd,
              },
            ],
          })
        }
      }}
      isClickable={props.zoomSongBody || props.onZoomClick ? true : false}
    >
      <Header paddingLeft={props.textLyricDesign?.contentPaddingLeft}>
        <HeaderLeft>
          <Capo>
            {t('song.capo')}
            <strong>{(generalInfo.capo || 0) + (props.capoOffset || 0)}</strong>
          </Capo>

          <Title>
            <SongName>{generalInfo.name}</SongName>
            {generalInfo.author && (
              <SongAuthor>{generalInfo.author}</SongAuthor>
            )}
          </Title>
        </HeaderLeft>

        <HeaderRight>
          <SongNotes>{generalInfo.note}</SongNotes>
          <SongChords>{chords.map((c) => c.name).join(', ')}</SongChords>
          <SongInfo>{getGeneralInfoString(generalInfo)}</SongInfo>
        </HeaderRight>
      </Header>

      {props.topHeaderText && (
        <HeaderTop>
          <HeaderTopBox>{props.topHeaderText}</HeaderTopBox>
        </HeaderTop>
      )}

      <SongLyricsAsText
        lyricSections={props.lyricSections}
        compositionSections={props.compositionSections}
        chords={chords}
        languageCode={generalInfo.languageCode}
        textLyricDesign={props.textLyricDesign}
        setTextLyricDesign={props.setTextLyricDesign}
        readOnly={props.readOnly}
        userStart={props.userStart}
        userEnd={props.userEnd}
      />
    </Paper>
  )
}

export default SongPaperA4
