import {
  CREATE_USER_GROUP,
  GET_USER_GROUP,
  GET_USER_GROUPS,
  UPDATE_USER_GROUP,
} from 'api/graphql'
import { EMPTY_USER_GROUP, USER_GROUP_ROLES } from 'const'
import {
  UserFromUserGroupMemberType,
  getUsersFromUserGroupMembers,
  hasGroupPermission,
  sendEmailNewUserGroupMember,
} from 'utils'
import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'

import Checkbox from 'components/Inputs/Checkbox'
import DataModal from 'modals/base/DataModal'
import { LOGGED_USER_VAR } from 'App'
import UserGroupForm from 'components/UserGroup/UserGroupForm'
import { UserGroupType } from 'types'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  & > * {
    background: ${(props) => props.theme.background};
  }
`

const CheckboxWrapper = styled.div`
  flex: 0;
  margin: auto;
  margin-top: calc(max(100px, 10vh));
`

/**
 *
 * REACTIVE VARS
 *
 */
const USER_MODAL_DEFAULT = {
  isOpen: false,
  userGroupId: null,
  isEditMode: false,
  callback: undefined,
}

export const USER_GROUP_MODAL = makeVar<{
  isOpen: boolean
  userGroupId?: number
  isEditMode?: boolean
  callback?: (newGroupId: number) => void
}>(USER_MODAL_DEFAULT)

/**
 *
 * COMPONENT
 *
 */
const UserGroupModal = () => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const modalData = useReactiveVar(USER_GROUP_MODAL)
  const closeModal = () => USER_GROUP_MODAL(USER_MODAL_DEFAULT)

  const [userGroup, setUserGroup] = useState<UserGroupType>()
  const [userGroupOriginal, setUserGroupOriginal] = useState<UserGroupType>()

  const [emailNewMembers, setEmailNewMembers] = useState<boolean>(true)
  const [newUsers, setNewUsers] = useState<UserFromUserGroupMemberType[]>([])
  const [newUserGroupData, setNewUserGroupData] = useState<{ userGroupName }>()

  //
  // PERMISSIONS
  //
  const getAllowUpdateGroup = () =>
    !modalData?.isEditMode || hasGroupPermission(userGroup, 'GR_updateGroup')

  const getAllowUpdateMembersOriginal = () =>
    /* if user gives up 'admin' rights, he cannot save that change based on updated data */
    !modalData?.isEditMode ||
    hasGroupPermission(userGroupOriginal, 'GR_updateMembers')

  //
  // MUTATIONS/QUERIES
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUserGroups } =
    useQuery(GET_USER_GROUPS) /* NECESSARY FOR REFETCH */

  const [createUserGroup, { data: newData }] = useMutation(CREATE_USER_GROUP, {
    refetchQueries: [GET_USER_GROUPS],
  })
  const [updateUserGroup, { data: updatedData }] = useMutation(
    UPDATE_USER_GROUP,
    {
      refetchQueries: [GET_USER_GROUPS, GET_USER_GROUP],
    }
  )

  const { data: userGroupData } = useQuery(GET_USER_GROUP, {
    variables: { id: modalData.userGroupId },
    skip: !modalData?.isOpen || !modalData?.isEditMode,
  })

  //
  // WATCH FOR EDIT/ADD RESPONSES
  //
  useEffect(() => {
    if (!modalData?.isEditMode && newData?.createUserGroup) {
      if (newData?.createUserGroup?.success) {
        toast.success(t(newData.createUserGroup.message))
        if (modalData.callback) {
          modalData.callback(newData?.createUserGroup?.id)
        }
        closeModal()
      } else {
        toast.error(t(newData.createUserGroup.message))
      }
    } else if (modalData?.isEditMode && updatedData?.updateUserGroup) {
      if (updatedData?.updateUserGroup?.success) {
        toast.success(t(updatedData.updateUserGroup.message))
        closeModal()
      } else {
        toast.error(t(updatedData.updateUserGroup.message))
      }
    }
    if (emailNewMembers) {
      newUsers.forEach((user) => {
        sendEmailNewUserGroupMember({
          userGroupId: modalData.isEditMode
            ? modalData.userGroupId
            : newData?.createUserGroup?.id,
          userGroupName: newUserGroupData.userGroupName,
          userEmail: user.email,
          userLang: user.lang,
          userCanEdit: user.canEdit,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, updatedData])

  //
  // WATCH FOR 'IS OPEN' CHANGES
  //
  useEffect(() => {
    if (modalData.isOpen) {
      if (modalData.isEditMode && userGroupData) {
        setUserGroup(userGroupData.getUserGroup)
        setUserGroupOriginal(userGroupData.getUserGroup)
      } else if (!modalData.isEditMode) {
        const newGroup = {
          ...EMPTY_USER_GROUP,
          userGroupMembers: [
            {
              user: loggedUser,
              groupRole: { code: USER_GROUP_ROLES.admin },
            },
          ],
        }
        setUserGroup(newGroup)
        setUserGroupOriginal(newGroup)
      }
    } else {
      setUserGroup(null)
      setUserGroupOriginal(null)
    }
    setInvalidFields([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, userGroupData])

  //
  // INVALID FIELDS
  //
  const [invalidFields, setInvalidFields] = useState<string[]>([])

  const getInvalidFields = () => {
    const requiredValues = [{ id: 'name', value: userGroup.name }]
    const updatedInvalidFields = []
    requiredValues.forEach((rv) => {
      if (!rv.value) {
        updatedInvalidFields.push(rv.id)
      }
    })
    return updatedInvalidFields.filter((f) => f)
  }

  //
  // SUBMIT / CANCEL
  //
  const onSubmit = async () => {
    const updatedInvalidFields = getInvalidFields()

    setInvalidFields(updatedInvalidFields)
    if (updatedInvalidFields.length) {
      updatedInvalidFields.forEach((field) =>
        toast.error(
          t('user group.missing field') + ': ' + t(`user group.${field}`)
        )
      )
      return
    }
    if (modalData.isEditMode) {
      updateUserGroup({
        variables: {
          id: userGroup.id,
          name: !getAllowUpdateGroup() ? undefined : userGroup.name,
          description: !getAllowUpdateGroup()
            ? undefined
            : userGroup.description,
          avatar: userGroup.avatar,
          members: !getAllowUpdateMembersOriginal()
            ? undefined
            : userGroup.userGroupMembers.map((m) => {
                return {
                  memberId: m.id,
                  userId: m.user.id,
                  groupRoleCode: m.groupRole.code,
                }
              }),
        },
      })
    } else {
      createUserGroup({
        variables: {
          name: userGroup.name,
          description: userGroup.description,
          avatar: userGroup.avatar,
          members: userGroup.userGroupMembers.map((m) => {
            return { userId: m.user.id, groupRoleCode: m.groupRole.code }
          }),
        },
      })
    }

    if (emailNewMembers) {
      const originalUsers = modalData.isEditMode
        ? getUsersFromUserGroupMembers(userGroupOriginal.userGroupMembers)
        : []
      const updatedUsers = getUsersFromUserGroupMembers(
        userGroup.userGroupMembers
      )
      const newUsers = [...updatedUsers].filter(
        (user) =>
          user.userId !== loggedUser.id &&
          !originalUsers.map((u) => u.userId).includes(user.userId)
      )

      setNewUsers(newUsers)
      setNewUserGroupData({
        userGroupName: userGroup.name,
      })
    }
  }

  const onCancel = async () => {
    closeModal()
  }

  //
  // RENDER
  //
  return (
    <DataModal
      isOpen={modalData.isOpen}
      hasData={userGroup ? true : false}
      title={modalData.isEditMode ? t('user group.edit') : t('user group.new')}
      onSubmitLabel={
        modalData.isEditMode
          ? t('user group.save changes')
          : t('user group.save new')
      }
      onSubmit={onSubmit}
      onCancelLabel={
        modalData.isEditMode
          ? t('user group.dismiss changes')
          : t('user group.dismiss new')
      }
      onCancel={onCancel}
      closeModal={closeModal}
    >
      <Wrapper>
        <UserGroupForm
          userGroup={userGroup}
          setUserGroup={setUserGroup}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          isEditMode={modalData?.isEditMode}
        />

        <CheckboxWrapper>
          <Checkbox
            label={t('playlist.members.email_new_members')}
            value={emailNewMembers}
            setValue={setEmailNewMembers}
          />
        </CheckboxWrapper>
      </Wrapper>
    </DataModal>
  )
}

export default UserGroupModal
