import { DBSongType, SongMemberType, SongType } from 'types'
import { EMPTY_SONG, ROUTES, SONG_ROLES } from 'const'
import {
  PARAM_NEW,
  PARAM_NEW_FROM_IMPORT,
  PARAM_NEW_FROM_TEXT,
} from 'pages/Songs'
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { GET_SONG } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import { Redirect } from 'react-router-dom'
import SongEditor from './SongEditor'
import { hasSongPermission } from 'utils'
import { isSongPrivate } from 'utils'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const SongEditPage = (props: {
  songId: string
  doSubmit: boolean
  setDoSubmit: (value: boolean) => void
  doCancel: boolean
  setDoCancel: (value: boolean) => void
  onSubmit: (newSongId?: string, folderId?: string) => void
  onCancel: () => void
  setOutsideSongData?: (song: DBSongType) => void
  importedSongBody?: SongType
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const isAddNew = props.songId === PARAM_NEW
  const isAddNewFromText = props.songId === PARAM_NEW_FROM_TEXT
  const isImport = props.songId === PARAM_NEW_FROM_IMPORT
  const [notAllowed, setNotAllowed] = useState<boolean>(false)

  //
  // DATA
  //
  const [song, setSong] = useState<DBSongType>()

  const [songBody, setSongBody] = useState<SongType>(
    isAddNew || isAddNewFromText
      ? EMPTY_SONG
      : isImport
      ? props.importedSongBody
      : undefined
  )

  const [songMembers, setSongMembers] = useState<SongMemberType[]>(
    isAddNew || isAddNewFromText
      ? [
          {
            user: loggedUser,
            songRole: { code: SONG_ROLES.admin },
          },
        ]
      : isImport
      ? [
          {
            user: loggedUser,
            songRole: { code: SONG_ROLES.admin },
          },
        ]
      : undefined
  )

  const onSubmit = (newSongId?: string, folderId?: string) => {
    props.onSubmit(newSongId, folderId)
  }

  const onCancel = () => {
    props.onCancel()
  }

  //
  // QUERY SONG DATA
  //
  const { data: fetchedSongData, loading } = useQuery(GET_SONG, {
    variables: { id: props.songId },
    skip: isImport || isAddNew || isAddNewFromText,
  })

  useEffect(() => {
    if (fetchedSongData?.getSong) {
      const song = fetchedSongData?.getSong
      const songBody = JSON.parse(fetchedSongData?.getSong.body)

      if (hasSongPermission(song?.songMembers, 'SONG_updateSong')) {
        if (song?.songMembers) {
          setSong(song)
          setSongBody(songBody)
          setSongMembers(song?.songMembers)

          if (props.setOutsideSongData) {
            props.setOutsideSongData(song)
          }
        }
      } else {
        setNotAllowed(true)
        toast.warning(t('song.no_edit_access_redirect'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSongData, loading])

  //
  // RENDER
  //
  if (
    !isImport &&
    !isAddNew &&
    !isAddNewFromText &&
    !loading &&
    !fetchedSongData
  ) {
    return <Redirect to={ROUTES.songs.slug} />
  }

  if (notAllowed) {
    return <Redirect to={`${ROUTES.songs.slug}?song=${props.songId}`} />
  }

  return (
    <LoaderPageLayout
      title={
        isAddNew || isAddNewFromText || isImport
          ? t('song.new')
          : t('song.edit')
      }
      isPrivate={isSongPrivate(song)}
      loadingText={t('song.loading song data')}
      isLoading={loading}
      isReady={!!songBody}
      isWidePage
    >
      {songBody && (
        <>
          <SongEditor
            song={song}
            songBody={songBody}
            songMembers={songMembers}
            isEditMode={!isAddNew && !isAddNewFromText && !isImport}
            isTextMode={isAddNewFromText}
            doSubmit={props.doSubmit}
            setDoSubmit={props.setDoSubmit}
            doCancel={props.doCancel}
            setDoCancel={props.setDoCancel}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </LoaderPageLayout>
  )
}

export default SongEditPage
