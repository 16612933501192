import styled, { css } from 'styled-components'

import { SingleStringType } from 'types'
import SvgIcon from 'components/SvgIcon'

const FRET_MIN_WIDTH = 20
const FRET_NUMBERS_HEIGHT = 20
const STRING_HEIGHT = 16
const STRING_STROKE = 1
const STRING_STROKE_HIGHLIGHT = 2
const FRET_STROKE = 1
const FRET_START_STROKE = 2
const PREFIX_WIDTH = 25

const Column = styled.div<{ isEditable?: boolean }>`
  pointer-events: none;
  ${(props) =>
    !props.isEditable &&
    css`
      & * {
        cursor: default !important;
        pointer-events: none !important;
      }
    `}

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
`

const Row = styled.div<{ isFretNumbers?: boolean; stringHeight?: number }>`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  --height: ${(props) => props.stringHeight || STRING_HEIGHT}px;
  height: var(--height);
  min-height: var(--height);

  ${(props) =>
    props.isFretNumbers &&
    css`
      --height: ${FRET_NUMBERS_HEIGHT}px;
    `}
`

const RowPrefix = styled.div<{ isFretNumbers?: boolean }>`
  height: var(--height);
  min-height: var(--height);
  --width: ${PREFIX_WIDTH}px;
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: ${FRET_START_STROKE}px solid
    ${(props) => props.theme.instrument.fret};

  ${(props) =>
    props.isFretNumbers &&
    css`
      height: 0px !important;
      min-height: 0px !important;
    `}
`

const Note = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;

  & > div {
    position: absolute;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
  }
`

const Mute = styled.div<{ isMuted: boolean; isPlayed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;

  pointer-events: auto !important;
  cursor: pointer;

  color: ${(props) =>
    props.isMuted
      ? props.theme.instrument.stringMutedWarning
      : props.isPlayed
      ? props.theme.primary_light
      : `${props.theme.black}44`};

  svg {
    --size: 9px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`

const RowString = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
`

const FretboardBackground = styled.div<{
  stringCount: number
  stringHeight?: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${(props) =>
    (props.stringHeight || STRING_HEIGHT) * props.stringCount}px;

  background: ${(props) => props.theme.instrument.fretboard};
  background: linear-gradient(
    ${(props) => props.theme.instrument.fretboardShaded} 0%,
    ${(props) => props.theme.instrument.fretboard} 50%,
    ${(props) => props.theme.instrument.fretboardShaded} 100%
  );
`

const StringLine = styled.div<{ isMuted: boolean }>`
  position: absolute;
  z-index: 1;
  height: var(--stokeWidth);
  top: calc(50% - var(--stokeWidth) / 2);
  left: 0;
  right: 0;

  --stokeWidth: ${(props) =>
    props.isMuted ? STRING_STROKE_HIGHLIGHT : STRING_STROKE}px;

  background: ${(props) =>
    props.isMuted
      ? props.theme.instrument.stringMutedWarning
      : props.theme.instrument.stringPlayed};
`

const FretClickable = styled.div<{ hasOverlay: boolean }>`
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${FRET_MIN_WIDTH}px;

  cursor: pointer;

  pointer-events: ${(props) => (props.hasOverlay ? 'none' : 'auto')} !important;

  height: 100%;
  border-right: ${FRET_STROKE}px solid ${(props) => props.theme.instrument.fret};
`

const Finger = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: 800;
  text-align: center;

  --size: 18px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  border-radius: calc(var(--size) / 2);

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primary_light};
`

const FretNumber = styled.div<{ isHighlighted?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 600;
  text-align: center;

  ${(props) =>
    props.isHighlighted
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.2;
        `}
`

const StringOverlay = styled.div`
  pointer-events: auto !important;
  position: absolute;
  z-index: 3;
  width: 100%;
`

const Fretboard = (props: {
  strings: SingleStringType[]
  setStrings?: (strings: SingleStringType[]) => void
  fretCount: number
  renderFretOffset?: number
  stringHeight?: number
  stringOverlayFns?: (() => void)[]
  noFretNumbers?: boolean
}) => {
  const isEditable = props.setStrings ? true : false

  const fretArray = Array.apply(null, Array(props.fretCount || 3))

  const handleMuteClick = ({ mute, stringIndex }) => {
    if (props.setStrings) {
      const updatedStrings: SingleStringType[] = [...props.strings]
      updatedStrings[stringIndex].mute = mute
      if (!mute) {
        updatedStrings[stringIndex].fret = 0
      } else {
        updatedStrings[stringIndex].fret = null
      }
      props.setStrings(updatedStrings)
    }
  }

  const handleFretClick = ({ fret, stringIndex }) => {
    if (props.setStrings) {
      const updatedStrings: SingleStringType[] = [...props.strings]
      updatedStrings[stringIndex].mute = false
      updatedStrings[stringIndex].fret =
        props.strings[stringIndex].fret === fret ? 0 : fret
      props.setStrings(updatedStrings)
    }
  }

  const getHighlightedFrets = () => {
    const frets = [...props.strings].filter((s) => s.fret).map((s) => s.fret)
    let firstFret = frets.length ? Math.min(...frets) : null
    let lastFret = frets.length ? Math.max(...frets) : null

    const firstString = [...props.strings]
      .reverse()
      .findIndex((c) => c.fret === firstFret)
    const lastString = [...props.strings]
      .reverse()
      .findIndex((c) => c.fret === lastFret)

    if (Math.abs(firstFret - lastFret) <= 1) {
      if (firstString < lastString) {
        lastFret = null
      } else {
        firstFret = null
      }
    }
    return { firstFret, lastFret }
  }

  const { firstFret, lastFret } = getHighlightedFrets()

  const getFretNumber = (fretIndex) => {
    return fretIndex + (props.renderFretOffset || 0) + 1
  }

  const getStringOverlay = (stringIndex) => {
    return props.stringOverlayFns?.length && props.stringOverlayFns[stringIndex]
  }

  return (
    <Column stringCount={props.strings.length} isEditable={isEditable}>
      {props.strings.map((s, stringIndex) => (
        <Row key={stringIndex} stringHeight={props.stringHeight}>
          <RowPrefix>
            <Note>
              <div>{s.note}</div>
            </Note>
            <Mute
              isMuted={s.mute}
              isPlayed={!s.mute && !s.fret}
              onClick={() => handleMuteClick({ mute: !s.mute, stringIndex })}
            >
              {s.mute ? (
                <SvgIcon code="icon-fretboard-string-mute" />
              ) : s.fret ? (
                <SvgIcon code="icon-fretboard-string-played" />
              ) : (
                <SvgIcon code="icon-fretboard-string-open" />
              )}
            </Mute>
          </RowPrefix>

          <RowString>
            {stringIndex === 0 && (
              <FretboardBackground
                stringCount={props.strings.length}
                stringHeight={props.stringHeight}
              />
            )}

            <StringLine isMuted={s.mute} />

            {fretArray.map((u, fretIndex) => (
              <FretClickable
                key={fretIndex}
                hasOverlay={!!getStringOverlay(stringIndex)}
                onClick={() =>
                  handleFretClick({
                    fret: getFretNumber(fretIndex),
                    stringIndex,
                  })
                }
              >
                {s.fret === getFretNumber(fretIndex) && (
                  <Finger>{getFretNumber(fretIndex)}</Finger>
                )}
              </FretClickable>
            ))}

            {!!getStringOverlay(stringIndex) && (
              <StringOverlay>
                {props.stringOverlayFns[stringIndex]()}
              </StringOverlay>
            )}
          </RowString>
        </Row>
      ))}

      {!props.noFretNumbers && (
        <Row isFretNumbers>
          <RowPrefix isFretNumbers />
          {fretArray.map((u, fretIndex) => (
            <FretNumber
              key={fretIndex}
              isHighlighted={[firstFret, lastFret].includes(
                getFretNumber(fretIndex)
              )}
            >
              {getFretNumber(fretIndex)}
            </FretNumber>
          ))}
        </Row>
      )}
    </Column>
  )
}

export default Fretboard
