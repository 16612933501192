import { LyricDesignType, LyricNoteType, LyricSectionType } from 'types'
import {
  calculateFontCharSizeMonospace,
  convertGlobalCaretIndexToLocal,
} from 'utils'

import LyricNote from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText/LyricNote'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
`

const HeightReference = styled.div`
  width: 100%;
  opacity: 0;
  pointer-events: none;
`

const ItemWrapper = styled.div<{
  offset: number
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: calc(1px + ${(props) => props.offset}px);
  width: 100%;
  margin-left: -16px;

  //transition: all 0.1s ease;
`

/**
 *
 *  COMPONENT
 *
 */
const LyricNotes = (props: {
  notes: LyricNoteType[]
  lyricSection: LyricSectionType
  setLyricSection: (lyricSection: LyricSectionType) => void
  textLines: string[]
  lineIndex: number
  design?: LyricDesignType
}) => {
  const updateLyricNote = (updatedNote: LyricNoteType) => {
    props.setLyricSection({
      ...props.lyricSection,
      lyricNotes: props.lyricSection.lyricNotes.map((note) =>
        note.itemId === updatedNote.itemId ? updatedNote : note
      ),
    })
  }

  const deleteLyricNote = (deleteId) => {
    props.setLyricSection({
      ...props.lyricSection,
      lyricNotes: props.lyricSection.lyricNotes.filter(
        (note) => note.itemId !== deleteId
      ),
    })
  }

  //
  // RENDER
  //
  return (
    props.notes?.length > 0 && (
      <Wrapper>
        {props.notes.map((lyricNote, itemIndex) => (
          <div key={itemIndex}>
            <ItemWrapper
              offset={
                convertGlobalCaretIndexToLocal(
                  lyricNote.position,
                  props.textLines,
                  props.lineIndex
                ) * calculateFontCharSizeMonospace(props.design.fontSize)
              }
            >
              <LyricNote
                note={lyricNote}
                design={props.design}
                updateLyricNote={updateLyricNote}
                deleteLyricNote={deleteLyricNote}
                lyricSection={props.lyricSection}
              />
            </ItemWrapper>

            <HeightReference>
              <LyricNote isHeightRef note={lyricNote} design={props.design} />
            </HeightReference>
          </div>
        ))}
      </Wrapper>
    )
  )
}

export default LyricNotes
