import styled, { css } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import SvgIcon from 'components/SvgIcon'
import { useState } from 'react'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  box-shadow: 0 0 10px 0 ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.light};
  border-radius: 16px;
`

const Header = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px;
  padding-left: 30px;

  transition: all 0.15s ease;
  cursor: pointer;
`

const Icon = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  --size: 34px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);

  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.light};
  border-radius: 50%;

  transition: all 0.2s ease;

  ${(props) =>
    props.isOpen &&
    css`
      outline: 1px solid ${(props) => props.theme.light};
      color: ${(props) => props.theme.neutral};
      background: none;
      transform: rotateZ(-90deg);
    `}

  svg {
    width: 70%;
  }
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.black};
`

const Content = styled.div`
  width: 95%;
  background: ${(props) => props.theme.background};

  & > * > * {
    /* AnimateHeight Component */
    width: calc(100% - 2 * 10px);
    padding: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ToolSection = (props: {
  title: string
  subtitle?: string
  isOpen?: boolean
  children: any
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen)

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Header onClick={() => setIsOpen(!isOpen)}>
        <Title>{props.title}</Title>
        <Icon isOpen={isOpen}>
          <SvgIcon code="icon-chevron-left" />
        </Icon>
      </Header>

      <Content>
        <AnimateHeight
          height={isOpen ? 'auto' : 0}
          animateOpacity
          style={{ opacity: isOpen ? 1 : 0 }}
        >
          {isOpen && props.children}
        </AnimateHeight>
      </Content>
    </Wrapper>
  )
}

export default ToolSection
