import axios from 'axios'

const { REACT_APP_API_URL } = process.env

export const sendEmail = async (payload: {
  emailTo: string
  subject: string
  message: string
  html: string
}) => {
  const response = await axios.post(REACT_APP_API_URL + '/email', payload)
  return response
}
