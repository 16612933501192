import { HEADER_HEIGHT, NAVIGATION, ROUTES } from 'const'
import { Link, useLocation } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { useRef, useState } from 'react'

import AppName from 'components/App/AppName'
import { LOGGED_USER_VAR } from 'App'
import SvgIcon from 'components/SvgIcon'
import UserCard from 'components/User/UserCard'
import { isLoggedAppAdmin } from 'utils/auth'
import { useClickOutside } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const MENU_BREAKPOINT = 'L'

const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 5000;
`

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.primary};
  box-shadow: 0 0 20px 0 ${(props) => props.theme.black}22;

  height: ${HEADER_HEIGHT};
  padding: 0 4%;
  overflow: hidden;
`

const AppNameWrapper = styled(Link)`
  padding-bottom: 8px !important;
  text-decoration: none;
`

const DesktopUserCard = styled.div`
  transform: scale(0.8);
  outline: 35px solid ${(props) => props.theme.background};
  border-radius: 50%;
`

const DesktopMenu = styled.div`
  ${(props) => props.theme.breakpoint[MENU_BREAKPOINT]} {
    display: none;
  }
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  color: ${(props) => props.theme.white};
  background: none;
  border: none;
  height: 100%;

  svg {
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 30px;
    height: 30px;
  }
`

const DesktopItemWrapper = styled.div<{ active?: string; adminOnly?: boolean }>`
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;

    --size: 40px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);

    transition: all 0.2s ease;
    color: ${(props) =>
      props.adminOnly ? props.theme.admin : props.theme.white}DD;
    background: ${(props) => props.theme.primary_light}00;

    border-radius: 50%;

    svg {
      transition: all 0.1s ease;
      width: 20px;
    }

    div {
      color: ${(props) => props.theme.primary};
      transition: all 0.2s ease;
      opacity: 0;
      position: absolute;
      top: 0;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
    }

    &:hover {
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.primary_light}AA;
      div {
        opacity: 1;
        top: calc(100% + 5px);
      }
    }

    ${(props) =>
      props.active === 'true' &&
      css`
        background: ${(props) => props.theme.primary_light}AA;
        color: ${(props) => props.theme.white};
      `}
  }
`

const MenuIconAnimation = keyframes`
  0% {
    transform: rotateZ(90deg)
  }
`

const MenuButton = styled.button`
  display: none;
  ${(props) => props.theme.breakpoint[MENU_BREAKPOINT]} {
    display: block;
  }
  color: ${(props) => props.theme.white};
  border: none;
  height: 100%;

  box-shadow: none !important;
  background: none !important;
  filter: none !important;

  svg {
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 30px;
    height: 30px;

    animation: ${MenuIconAnimation} 0.15s backwards
      cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`
const HorizontalSpacer = styled.div`
  width: 3%;
`

const FullscreenWrapper = styled.div<{ showFullscreenNav?: boolean }>`
  position: fixed;
  z-index: 9;
  overflow-y: auto;

  display: none;
  ${(props) => props.theme.breakpoint[MENU_BREAKPOINT]} {
    display: block;
  }

  top: ${HEADER_HEIGHT};
  left: 0;
  right: 0;
  height: calc(100% - ${HEADER_HEIGHT});

  background: ${(props) => props.theme.primary};
  overscroll-behavior: contain;

  transform: translateX(100%);
  ${(props) =>
    props.showFullscreenNav &&
    css`
      transform: none;
    `}
  transition: all 0.2s ease;
`

const MobileMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const MobileMenuItems = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`

const MobileUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MobileUserCard = styled.div`
  aspect-ratio: 1;
  background: ${(props) => props.theme.background};
  padding: 8px;
  border-radius: 50%;
  transform: scale(1.5);
`

const Name = styled.div`
  margin-top: 5vh;
  font-size: 20px;
  color: ${(props) => props.theme.background};
`

const Username = styled.div`
  margin-top: 1vh;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.background};
`

const MobileSpacer = styled.div`
  flex: 1;
`

const MobileItem = styled.div<{ active?: string; adminOnly?: boolean }>`
  flex: 1;
  width: 90vw;
  border-radius: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;

  cursor: pointer;
  font-size: 15px;
  text-decoration: none;

  transition: all 0.2s ease;

  color: ${(props) =>
    props.adminOnly ? props.theme.admin : props.theme.white};

  svg {
    width: 20px;
  }

  &:hover {
    background: ${(props) => props.theme.primary_light}AA;
    color: ${(props) => props.theme.white};
  }

  ${(props) =>
    props.active === 'true' &&
    css`
      background: ${(props) => props.theme.primary_light}AA;
      color: ${(props) => props.theme.white};
    `}

  span {
    min-width: 100px;
  }
`

const AppHeader = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const [showFullscreenNav, setShowFullscreenNav] = useState<boolean>(false)

  const ref = useRef()
  useClickOutside(ref, () => {
    if (showFullscreenNav) {
      setShowFullscreenNav(false)
    }
  })

  const isPageActive = (page) => {
    const isActive =
      page.slug === location.pathname ||
      page.relatedSlugs
        ?.map((s) => s.split('/')[1])
        .includes(location.pathname.split('/')[1])

    return isActive ? 'true' : 'false'
  }

  return (
    <Layout>
      <Wrapper>
        <AppNameWrapper to={ROUTES.home.slug}>
          <AppName sizePx={30} />
        </AppNameWrapper>

        {/******************************************/}
        {/* DESKTOP MENU                           */}
        {/******************************************/}
        <DesktopMenu>
          {/* DESKTOP MENU ITEMS */}
          {NAVIGATION.map((page, i) =>
            !page.adminOnly || isLoggedAppAdmin() ? (
              <DesktopItemWrapper
                key={i}
                adminOnly={page.adminOnly}
                title={t(page.name)}
                active={isPageActive(page)}
              >
                <Link to={page.slug}>{page.icon ? page.icon : null}</Link>
              </DesktopItemWrapper>
            ) : null
          )}
          <HorizontalSpacer />

          {/* DESKTOP USER AVATAR */}
          <DesktopUserCard>
            <UserCard forceLoggedUserName useLink noBackground />
          </DesktopUserCard>
        </DesktopMenu>

        {/* HAMBURGER ICON */}
        <MenuButton
          ref={ref}
          onClick={() => setShowFullscreenNav(!showFullscreenNav)}
        >
          <SvgIcon code={showFullscreenNav ? 'icon-menu-close' : 'icon-menu'} />
        </MenuButton>
      </Wrapper>

      {/******************************************/}
      {/* MOBILE MENU                            */}
      {/******************************************/}
      <FullscreenWrapper showFullscreenNav={showFullscreenNav}>
        <MobileMenu>
          <MobileMenuItems>
            <MobileSpacer />

            {/* MOBILE MENU ITEMS */}
            {NAVIGATION.map((page, i) =>
              !page.adminOnly || isLoggedAppAdmin() ? (
                <MobileItem
                  key={i}
                  active={isPageActive(page)}
                  adminOnly={page.adminOnly}
                  onClick={() => {
                    setShowFullscreenNav(false)
                    history.push(page.slug)
                  }}
                >
                  {page.icon ? page.icon : null}
                  <span>{t(page.name)}</span>
                </MobileItem>
              ) : null
            )}

            <MobileSpacer />

            <MobileUserContainer
              onClick={() => {
                setShowFullscreenNav(false)
                history.push(
                  `${ROUTES.users.slug}?user=${loggedUser?.id.toString()}`
                )
              }}
            >
              {/* MOBILE USER AVATAR */}
              <MobileUserCard>
                <UserCard forceLoggedUserName noBackground isScaledUp />
              </MobileUserCard>
              <Name>{loggedUser?.name}</Name>
              <Username>{loggedUser?.username}</Username>
            </MobileUserContainer>

            <MobileSpacer />
          </MobileMenuItems>
        </MobileMenu>
      </FullscreenWrapper>
    </Layout>
  )
}

export default AppHeader
