/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css, keyframes } from 'styled-components'

import Loader from 'components/App/Loader'
import SvgIcon from 'components/SvgIcon'
import { getTabName } from 'utils'
import { useEffect } from 'react'

export const PAGE_LAYOUT_H1_PADDING_TOP = '5vh'

const FileItemAnimationIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10vh);
  }
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div<{
  isSlimPage?: boolean
  isWidePage?: boolean
  isFullWidthPage?: boolean
  isAdminOnly?: boolean
}>`
  animation-name: ${FileItemAnimationIn};
  animation-timing-function: cubic-bezier(0.09, 0.85, 0.34, 1);
  animation-duration: 0.3s;
  animation-fill-mode: backwards;

  margin: auto;
  max-width: 970px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h1 {
    position: relative;
    margin-top: ${PAGE_LAYOUT_H1_PADDING_TOP};

    ${(props) =>
      props.isAdminOnly &&
      css`
        &:after {
          border-color: ${(props) => props.theme.admin} !important;
        }
      `}
  }

  display: flex;
  flex-direction: column;
  gap: 60px;

  padding: 0 2%;

  ${(props) =>
    props.isSlimPage &&
    css`
      max-width: 800px
      padding: 0 10%;

      ${props.theme.breakpoint.S} {
        padding: 0 5%;
      }
      ${props.theme.breakpoint.XS} {
        padding: 0 2%;
      }
    `}

  ${(props) =>
    props.isWidePage &&
    css`
      max-width: 1100px;
    `}

  ${(props) =>
    props.isFullWidthPage &&
    css`
      max-width: 100vw;
    `}
`

const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.neutral};
  position: absolute;
  left: 0;
  right: 0;
  --offsetTop: 60px;
  top: calc(${PAGE_LAYOUT_H1_PADDING_TOP} + var(--offsetTop));

  ${(props) => props.theme.breakpoint.L} {
    --offsetTop: 54px;
  }
  ${(props) => props.theme.breakpoint.M} {
    --offsetTop: 45px;
  }
`

const LoaderWrapper = styled.div`
  margin: 15vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const View = styled.div<{ paddingTop?: string; paddingBottom?: string }>`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-top: ${(props) => props.paddingTop || '0px'};
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

const PrivacyBadge = styled.div`
  position: absolute;
  top: 5px;
  right: -4px;

  --size: 14px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.warning};
    width: 90%;
    height: 90%;
  }
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const LoaderPageLayout = (props: {
  title?: string
  subtitle?: string
  loadingText?: string
  isPrivate?: boolean
  isAdminOnly?: boolean
  isLoading?: boolean
  isReady: boolean
  isSlimPage?: boolean
  isWidePage?: boolean
  isFullWidthPage?: boolean
  paddingTop?: string
  paddingBottom?: string
  children?: any
}) => {
  useEffect(() => {
    document.title = getTabName(props.title)
  }, [])

  //
  // RENDER
  //
  return (
    <Wrapper
      isSlimPage={props.isSlimPage}
      isWidePage={props.isWidePage}
      isFullWidthPage={props.isFullWidthPage}
      isAdminOnly={props.isAdminOnly}
    >
      {props.isLoading ? (
        <LoaderWrapper>
          <Loader />
          {!!props.loadingText && <div>{props.loadingText}...</div>}
        </LoaderWrapper>
      ) : (
        props.isReady && (
          <View
            paddingTop={props.paddingTop}
            paddingBottom={props.paddingBottom}
          >
            {props.title && (
              <h1>
                {props.title}
                {props.isPrivate && (
                  <PrivacyBadge>
                    <SvgIcon code="icon-private" />
                  </PrivacyBadge>
                )}
              </h1>
            )}
            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}

            <Content>{props.isReady && props.children}</Content>
          </View>
        )
      )}
    </Wrapper>
  )
}

export default LoaderPageLayout
