import styled, { css } from 'styled-components'

import ChordName from 'components/Instruments/_base/ChordName'
import Fretboard from 'components/Instruments/_base/Fretboard'
import { ReactElement } from 'react'
import { SingleStringType } from 'types'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{ stretch?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 100px;

  ${(props) =>
    props.stretch &&
    css`
      width: 100%;
    `}
`

const Header = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  overflow: hidden;

  input {
    margin-top: 4px;
  }
`

const StringSettingsContainer = styled.div<{ fretHeight: number }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.fretHeight}px;
  max-height: ${(props) => props.fretHeight}px;
  display: flex;
  align-items: center;
`

const StringSettings = styled.div`
  margin-left: 10%;
  margin-right: 8px;
  gap: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StringNoteName = styled.div<{ fretHeight: number }>`
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 46px;
    height: ${(props) => props.fretHeight - 2}px;
    min-height: ${(props) => props.fretHeight - 2}px;
    padding: 0 5px;

    cursor: auto !important;
    pointer-events: auto !important;

    border: none;
    border-radius: ${(props) => (props.fretHeight - 2) / 2}px;
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primary_light};

    font-size: 12px;
    font-weight: 800;
    text-align: center;

    &:focus {
      background: ${(props) => props.theme.background};
    }
  }
`

const StringSettingsButton = styled.button<{
  isDelete?: boolean
  isBetweenStrings?: boolean
  isBetweenStringsLast?: boolean
  fretHeight: number
}>`
  padding: 0 !important;
  cursor: pointer !important;
  pointer-events: auto !important;

  --size: 20px !important;
  svg {
    width: 16px !important;
    height: 16px !important;
  }

  ${(props) =>
    props.isDelete &&
    css`
      color: ${(props) => props.theme.warning} !important;
    `}

  ${(props) =>
    props.isBetweenStrings &&
    css`
      margin-top: ${(props) => -props.fretHeight}px !important;
      --size: 16px !important;
    `}

  ${(props) =>
    props.isBetweenStringsLast &&
    css`
      right: 8px;
      position: absolute;
      margin-top: ${(props) => props.fretHeight}px !important;
    `}
`

const StringTuning = (props: {
  tuningName?: string
  setTuningName?: (tuningName: string) => void
  notes: string[]
  setNotes?: (notes: string[]) => void
  stretch?: boolean
  fretHeight?: number
}) => {
  const { t } = useTranslation()

  const fretHeight = props.fretHeight || 24

  //
  // FUNCTIONS
  //
  const updateTuningNotes = (note, index) => {
    if (props.notes?.length) {
      /* Do not update if the value is the same (tab switching between inputs) */
      if (props.notes[index] !== note) {
        const updatedNotes = [...props.notes].map((n, noteIndex) =>
          noteIndex === index ? note : n
        )
        props.setNotes(updatedNotes)
      }
    }
  }

  const addTuningNote = (index) => {
    let updatedNotes
    const newNote = '?'

    if (index === 0) {
      updatedNotes = [newNote, ...(props.notes || [])]
    } else if (index === props.notes.length) {
      updatedNotes = [...(props.notes || []), newNote]
    } else {
      updatedNotes = [...(props.notes || [])]
        .map((note, i) => (i === index ? [newNote, note] : note))
        .flat()
    }

    props.setNotes(updatedNotes)
  }

  const removeTuningNote = (index) => {
    props.setNotes([...props.notes].filter((note, i) => i !== index))
  }

  //
  // RENDER
  //
  const renderStringButton = (options: {
    onClick: () => void
    icon: ReactElement
    tooltip?: string
    isDelete?: boolean
    isBetweenStrings?: boolean
    isBetweenStringsLast?: boolean
  }) => {
    return (
      <StringSettingsButton
        onClick={options.onClick}
        title={options.tooltip}
        className="btn-action-circular"
        isDelete={options.isDelete}
        isBetweenStrings={options.isBetweenStrings}
        isBetweenStringsLast={options.isBetweenStringsLast}
        fretHeight={fretHeight}
        tabIndex="-1"
      >
        {options.icon}
      </StringSettingsButton>
    )
  }

  const renderStringOverlaySetting = (note, index) => {
    return (
      <StringSettingsContainer fretHeight={fretHeight}>
        <StringSettings>
          {props.setNotes ? (
            <>
              <StringNoteName fretHeight={fretHeight}>
                <StringInput
                  value={note}
                  setValue={(note) => updateTuningNotes(note, index)}
                />
              </StringNoteName>

              {renderStringButton({
                icon: <SvgIcon code="icon-delete" />,
                onClick: () => removeTuningNote(index),
                tooltip: t('instrument.delete string'),
                isDelete: true,
              })}

              {renderStringButton({
                icon: <SvgIcon code="icon-add" />,
                onClick: () => addTuningNote(index),
                tooltip: t('instrument.add string'),
                isBetweenStrings: true,
              })}

              {index === props.notes?.length - 1 &&
                renderStringButton({
                  icon: <SvgIcon code="icon-add" />,
                  onClick: () => addTuningNote(props.notes?.length),
                  tooltip: t('instrument.add string'),
                  isBetweenStrings: true,
                  isBetweenStringsLast: true,
                })}
            </>
          ) : null}
        </StringSettings>
      </StringSettingsContainer>
    )
  }

  return (
    <Wrapper stretch={props.stretch}>
      <Header>
        <ChordName
          name={props.tuningName}
          setName={props.setTuningName}
          liveUpdate={false}
        />
      </Header>

      <Fretboard
        strings={props.notes?.map((note) => {
          return {
            note,
          } as SingleStringType
        })}
        stringOverlayFns={props.notes?.map(
          (note, i) => () => renderStringOverlaySetting(note, i)
        )}
        fretCount={1}
        stringHeight={fretHeight}
        noFretNumbers
      />
    </Wrapper>
  )
}

export default StringTuning
