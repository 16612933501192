import { APP_LANGUAGE_CODES, AppLanguageType } from 'const'
import { Page, createWorker } from 'tesseract.js'

import Droplist from 'components/Inputs/Droplist'
import ImageUpload from 'components/Inputs/ImageUpload'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import TextInput from 'components/Inputs/TextInput'
import { asyncImageToText } from 'utils'
import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Row = styled.div`
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  gap: 20px;

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`

const ProgressBar = styled.div`
  height: 20px;
  border: 1px solid ${(props) => props.theme.admin};
`

const Progress = styled.div<{ value: number }>`
  width: ${(props) => props.value * 100}%;
  height: 100%;
  background: ${(props) => props.theme.admin};
`

const DevModuleConvertImageToText = (props: { isOpenDefault?: boolean }) => {
  const { t } = useTranslation()

  const [src, setSrc] = useState<any>()
  const [image, setImage] = useState<any>()
  const [text, setText] = useState<string>('')
  const [lang, setLang] = useState<AppLanguageType>(APP_LANGUAGE_CODES.en)

  const [progress, setProgress] = useState<number>(0)

  const worker = createWorker({
    logger: (m) => {
      if (m.status === 'recognizing text') {
        setProgress(m.progress)
      }
    },
  })

  const imageToTextCallback = (data: Page) => {
    console.log(data)
    setText(data.text)
    setProgress(0)
  }

  const onGetTextFromImage = async () => {
    await asyncImageToText({
      image,
      lang,
      worker,
      callback: imageToTextCallback,
    })
  }

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="Convert Image to Text"
      subtitle="convert image/photo of song on a paper into plain text"
      isOpen={props.isOpenDefault}
    >
      <Row>
        <Column>
          <ModuleSectionTitle
            title="Image Input"
            subtitle="Photo of the song paper"
          />

          <ImageUpload
            src={src}
            setSrc={setSrc}
            file={image}
            setFile={setImage}
            label="Image Upload"
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          {
            'TODO: view rectangle (multiple for columns) + DISABLE repeated run! (try to terminate the worker before running ?)'
          }
          <ModuleSectionTitle
            title="Text Output"
            subtitle="Plain text taken from the image"
          />

          <Droplist
            label={t('song.language')}
            options={Object.values(APP_LANGUAGE_CODES).map((lang) => {
              return { id: lang, name: t('languages.' + lang) }
            })}
            value={lang}
            onChange={(val) => setLang(val as AppLanguageType)}
          />

          <button onClick={onGetTextFromImage}>Get Text!</button>

          <ProgressBar>
            <Progress value={progress} />
          </ProgressBar>

          <TextInput
            value={text}
            setValue={setText}
            isMultiline
            isResizable
            isSongFont
            liveUpdate
            height="800px"
          />
        </Column>
      </Row>
    </ModuleWrapper>
  )
}

export default DevModuleConvertImageToText
