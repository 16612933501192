import { DBSongType, SongCategoryType, UserType } from 'types'
import { idAsNumber, uniqueArray } from './files'

import { File } from 'components/Files/FileSystem'
import { USER_ROLES } from 'const'

export const isSongPrivate = (song: DBSongType) => {
  if (song?.songMembers?.length === 1 && song?.songMembers[0]?.user) {
    // if song has only one member and it's a user (could also be a group!), it has to be the logged user who sees the song
    return true
  }
  return false
}

export const fileFromSong = (song: DBSongType) => {
  const file: File = {
    type: 'file',
    id: idAsNumber(song.id),
    name: song.name,
    name2: song.author?.trim(),
    isPrivate: isSongPrivate(song),
  }
  return file
}

export const folderFromSongCategory = (category: SongCategoryType) => {
  const folder: File = {
    type: 'folder',
    id: idAsNumber(category.id),
    name: category.name,
    name2: category.name2,
    parentId: idAsNumber(category.parentCategoryId),
  }
  return folder
}

export const uniqueSongCategoryId = (songCategories: SongCategoryType[]) => {
  let i =
    (songCategories?.length
      ? Math.max(...songCategories.map((c) => c.id))
      : 0) + 1
  return i
}

export const getSongCategoryParentIdOfParentId = (props: {
  songCategories: SongCategoryType[]
  id: number
}) => {
  return props.songCategories?.find((c) => c.id === props.id)?.parentCategoryId
}

export const getSongCategoryParentChain = (props: {
  songCategories: SongCategoryType[]
  id: number
}) => {
  const songCategoryParentChain = []
  let parentId = props.id
  let category

  while (parentId) {
    // eslint-disable-next-line no-loop-func
    category = props.songCategories?.find((c) => c.id === parentId)
    if (category) {
      songCategoryParentChain.push(category)
      parentId = category?.parentCategoryId
    }
  }

  return songCategoryParentChain
}

export const createFilesFromSongCategories = (props: {
  currentFolderId: number
  allSongs: DBSongType[]
  rootCategory: SongCategoryType
  songCategories: SongCategoryType[]
}) => {
  const { allSongs, songCategories, rootCategory, currentFolderId } = props

  const files: File[] = []
  const currentCategory = songCategories.find((c) => c.id === currentFolderId)

  songCategories?.forEach((category) => {
    if (
      category.parentCategoryId === currentFolderId &&
      category.id !== rootCategory.id
    )
      files.push(folderFromSongCategory(category))
  })

  uniqueArray(currentCategory?.songIds)?.forEach((songId) => {
    const song: DBSongType = allSongs.find(
      (s) => idAsNumber(s.id) === idAsNumber(songId)
    )
    if (song) {
      files.push(fileFromSong(song))
    }
  })

  return files
}

export const parseFetchedSongCategories = (props: {
  user: UserType
  allSongs: DBSongType[]
  rootCategory: SongCategoryType
  isAdminCreateUserFolders?: boolean
}) => {
  const { user, allSongs, rootCategory } = props

  const storedSongCategories: SongCategoryType[] = user?.songCategories
    ? JSON.parse(user?.songCategories)
    : []

  let songCategories = []
  let sortedSongIds = []

  storedSongCategories.forEach((category) => {
    sortedSongIds.push(...category.songIds.map((id) => idAsNumber(id)))
    songCategories.push({
      ...category,
      id: idAsNumber(category.id),
      songIds: category.songIds.map((id) => idAsNumber(id)),
      parentCategoryId: idAsNumber(
        category.parentCategoryId &&
          /* only parentId os existing categories are allowed */
          storedSongCategories
            ?.map((c) => idAsNumber(c.id))
            .includes(idAsNumber(category.parentCategoryId))
          ? category.parentCategoryId
          : rootCategory.id
      ),
    })
  })

  sortedSongIds = uniqueArray(sortedSongIds)

  let unsortedSongs = allSongs.filter(
    (s) => !sortedSongIds.includes(idAsNumber(s.id))
  )

  /* ADMIN - CREATE FOLDERS BY USERS */
  if (props.isAdminCreateUserFolders && user?.role?.code === USER_ROLES.admin) {
    let userSongCategories: SongCategoryType[] = []
    const sortedUserSongIds = []

    const getDevUserCategoryId = (id: any) => -1 * idAsNumber(id)

    unsortedSongs.forEach((song) => {
      // process only other users (logged 'user' is admin)
      if (idAsNumber(song.createdByUser?.id) !== idAsNumber(user.id)) {
        let stored = false

        userSongCategories = userSongCategories.map((userCategory) => {
          if (
            idAsNumber(userCategory.id) !==
            getDevUserCategoryId(song.createdByUser.id)
          ) {
            return userCategory
          }

          stored = true
          return {
            ...userCategory,
            songIds: [...userCategory.songIds, song.id],
          }
        })

        if (!stored) {
          userSongCategories.push({
            id: getDevUserCategoryId(song.createdByUser.id),
            parentCategoryId: rootCategory.id,
            name: `*${song.createdByUser.name}*`,
            name2: `id = ${song.createdByUser.id}`,
            songIds: [song.id],
          })
        }

        sortedUserSongIds.push(idAsNumber(song.id))
      }
    })

    songCategories.push(...userSongCategories)
    unsortedSongs = unsortedSongs.filter(
      (s) => !sortedUserSongIds.includes(idAsNumber(s.id))
    )
  }

  // POPULATE ROOT CATEGORY
  songCategories.push({
    ...rootCategory,
    songIds: unsortedSongs.map((s) => idAsNumber(s.id)),
  })

  return songCategories
}

export const moveSongsAndCategories = (options: {
  songIds: number[]
  categoryIds: number[]
  originalCategoryId: number
  targetCategoryId: number
  songCategories: SongCategoryType[]
}) => {
  const {
    songIds,
    categoryIds,
    originalCategoryId,
    targetCategoryId,
    songCategories,
  } = options
  const updatedCategories: SongCategoryType[] = songCategories?.map(
    (category) => {
      let updatedCategory: SongCategoryType = { ...category }

      // Remove files from original
      if (category.id === originalCategoryId) {
        updatedCategory = {
          ...category,
          songIds: category.songIds.filter((id) => !songIds.includes(id)),
        }
      }

      // Add files to target
      if (category.id === targetCategoryId) {
        updatedCategory = {
          ...category,
          songIds: [...category.songIds, ...songIds],
        }
      }

      // Update category parent
      if (
        categoryIds.includes(category.id) &&
        category.id !== targetCategoryId
      ) {
        updatedCategory = {
          ...updatedCategory,
          parentCategoryId: targetCategoryId,
        }
      }

      return updatedCategory
    }
  )

  return updatedCategories
}

export const deleteSongCategory = (props: {
  songCategories: SongCategoryType[]
  deleteCategoryId: number
}) => {
  const { songCategories, deleteCategoryId } = props

  const categoryToDelete = songCategories?.find(
    (category) => category.id === deleteCategoryId
  )

  if (!categoryToDelete) {
    return songCategories
  }

  const categoryIds: number[] = [...songCategories]
    .filter((c) => c.parentCategoryId === deleteCategoryId)
    .map((c) => idAsNumber(c.id))

  const updatedCategories = moveSongsAndCategories({
    songIds: categoryToDelete.songIds,
    categoryIds: categoryIds,
    originalCategoryId: deleteCategoryId,
    targetCategoryId: categoryToDelete.parentCategoryId,
    songCategories,
  }).filter((category) => category.id !== categoryToDelete.id)

  return updatedCategories
}

export const updateSongCategory = (props: {
  songCategories: SongCategoryType[]
  songCategoryId: number
  name: string
  name2: string
}) => {
  const { songCategories, songCategoryId, name, name2 } = props

  const updatedCategories = (songCategories ? [...songCategories] : []).map(
    (category) => {
      if (category.id === songCategoryId) {
        return {
          ...category,
          name,
          name2,
        }
      }
      return category
    }
  )

  return updatedCategories
}
