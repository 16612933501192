import {
  Header,
  HeaderLeft,
  Paper,
  SongAuthor,
  SongName,
  Title,
} from '../SongPaperA4'
/* eslint-disable react-hooks/exhaustive-deps */
import {
  InstrumentChordType,
  InstrumentSectionType,
  SongChordType,
  SongGeneralInfoType,
  SongType,
  StringChordSchemaType,
} from 'types'
import {
  getInstrumentSectionName,
  isKeyboardInstrument,
  isStringInstrument,
  transposeChord,
} from 'utils'

import { SONG_ZOOM_MODAL } from 'modals/SongZoomModal'
import StringChord from 'components/Instruments/StringChord'
import SvgIcon from 'components/SvgIcon'
import styled from 'styled-components'

export const PAPER_WIDTH = 2480
export const PAPER_HEIGHT = 3508

const PageIcon = styled.div`
  width: 200px;
  min-width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: 100px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`

const Content = styled.div`
  flex: 1;
  margin: 100px;
  margin-top: 0;

  font-size: 36px;
  color: ${(props) => props.theme.dark};
  //outline: 4px solid ${(props) => props.theme.highlight}44;

  height: 1500px;
  width: 46%;
  transform: scale(2);
  transform-origin: 0 0;
`

const InstrumentHeading = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 60px;
  margin-bottom: 10px;

  border-top: 2px solid ${(props) => props.theme.black}44;
  padding: 5px 0;
  font-size: 25px;
  font-weight: 900;

  & > *:last-child {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

const Tuning = styled.div`
  font-size: 20px;
`

const Strumming = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const ChordItems = styled.div<{ fretCount?: number }>`
  flex: 1;
  display: grid;
  gap: 30px;
  row-gap: 15px;

  grid-template-columns: repeat(
    auto-fit,
    minmax(
      min(calc(120px + 14px * ${(props) => props.fretCount || 0}), 100%),
      ${(props) => Math.min(1, 0.2 + 0.1 * (props.fretCount || 0))}fr
    )
  );
`

const ChordItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  min-height: 100px;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const InstrumentsPaperA4 = (props: {
  id?: string
  generalInfo: SongGeneralInfoType
  chords: SongChordType[]
  instrumentSections: InstrumentSectionType[]
  readOnly?: boolean
  zoomSongBody?: SongType
  onZoomClick?: () => void
}) => {
  //
  // STRING CHORD
  //
  const renderStringChord = ({ chordName, chordSchema, fretCount }) => {
    return (
      <StringChord
        autoFillSchema
        chordSchema={chordSchema as StringChordSchemaType}
        chordName={chordName}
        fretCount={fretCount}
      />
    )
  }

  //
  // RENDER ANY CHORD
  //
  const renderChord = (
    instrumentSection: InstrumentSectionType,
    instrumentChord: InstrumentChordType
  ) => {
    const chordNameRaw = instrumentChord.isExtraChord
      ? instrumentChord.extraChordName
      : props.chords.find((c) => c.id === instrumentChord.songChordId)?.name

    const chordName =
      !instrumentChord.isExtraChord && instrumentSection.instrument?.transpose
        ? transposeChord(chordNameRaw, instrumentSection.instrument?.transpose)
        : chordNameRaw

    const chordSchema = instrumentChord.chordSchema

    if (isStringInstrument(instrumentSection.instrument.code)) {
      return renderStringChord({
        chordName,
        chordSchema,
        fretCount: instrumentSection.instrument.renderFretCount,
      })
    }
    if (isKeyboardInstrument(instrumentSection.instrument.code)) {
      return <div>Piano {chordName}</div>
    }
    return <div>{chordName}</div>
  }

  //
  // RENDER
  //
  return (
    <Paper
      id={props.id}
      onClick={() => {
        if (props.onZoomClick) {
          return props.onZoomClick()
        }
        if (props.zoomSongBody) {
          SONG_ZOOM_MODAL({
            ...SONG_ZOOM_MODAL(),
            isOpen: true,
            viewType: 'instruments',
            songs: [
              {
                songBody: props.zoomSongBody,
              },
            ],
          })
        }
      }}
      isClickable={props.zoomSongBody || props.onZoomClick ? true : false}
    >
      <Header>
        <HeaderLeft>
          <PageIcon>
            <SvgIcon code="icon-chords" />
          </PageIcon>

          <Title>
            <SongName>{props.generalInfo.name}</SongName>
            {props.generalInfo.author && (
              <SongAuthor>{props.generalInfo.author}</SongAuthor>
            )}
          </Title>
        </HeaderLeft>
      </Header>

      <Content>
        {props.instrumentSections.map((instrumentSection, i) => (
          <div key={i}>
            <InstrumentHeading>
              <span>
                {getInstrumentSectionName(
                  instrumentSection,
                  props.generalInfo?.languageCode
                )}
              </span>
              <div>
                <Tuning>
                  {instrumentSection.instrument?.tuning.name} (
                  {[...instrumentSection.instrument?.tuning.notes]
                    .reverse()
                    .join('')}
                  )
                </Tuning>
                {instrumentSection.instrument?.strumming && (
                  <Strumming>
                    {instrumentSection.instrument?.strumming}
                  </Strumming>
                )}
              </div>
            </InstrumentHeading>

            <ChordItems
              fretCount={instrumentSection.instrument.renderFretCount}
            >
              {instrumentSection.instrument.chords.map((chord, i) => (
                <ChordItem key={i}>
                  {renderChord(instrumentSection, chord)}
                </ChordItem>
              ))}
            </ChordItems>
          </div>
        ))}
      </Content>
    </Paper>
  )
}

export default InstrumentsPaperA4
