import { UserGroupMemberType, UserGroupRoleType, UserGroupType } from 'types'
import { useQuery, useReactiveVar } from '@apollo/client'

import Droplist from 'components/Inputs/Droplist'
import { GET_USER_GROUP_ROLES } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import SvgIcon from 'components/SvgIcon'
import { USER_GROUP_ROLES } from 'const'
import UserCard from 'components/User/UserCard'
import { hasGroupPermission } from 'utils'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{
  isLoggedUser?: boolean
  isUserGroupAdmin?: boolean
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  row-gap: 0px;

  min-height: 40px;
  padding: 4px;
  padding-left: 6px;

  background: ${(props) =>
    props.isLoggedUser
      ? `${props.theme.light}`
      : props.isUserGroupAdmin
      ? `${props.theme.light}`
      : `${props.theme.light}`};

  border-radius: 4px;
  box-shadow: 5px 5px 10px 0px ${(props) => props.theme.black}08;

  select {
    background: ${(props) => props.theme.background};
    border-radius: 6px;
  }
`

const DeleteButton = styled.div<{ isLoggedUser?: boolean }>`
  ${resetButtonCss}
  padding: 10px;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(1.2);
  }
  svg {
    width: 24px;
    color: ${(props) => props.theme.black};
  }
`

const ColumnUser = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`

const ColumnOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 220px;
`

/**
 *
 * COMPONENT
 *
 */
const UserGroupMember = (props: {
  member: UserGroupMemberType
  userGroup: UserGroupType
  deleteMember: (member: UserGroupMemberType) => void
  updateMemberRole: (member: UserGroupMemberType, roleCode: string) => void
}) => {
  const { t } = useTranslation()
  const { data: userGroupRolesData } = useQuery(GET_USER_GROUP_ROLES)

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)
  const isLoggedUser = loggedUser?.id === props.member?.user.id

  const isGroupAdmin = props.member?.groupRole?.code === USER_GROUP_ROLES.admin

  //
  // PERMISSIONS
  //
  const getAllowUpdateMembers = () =>
    hasGroupPermission(props.userGroup, 'GR_updateMembers')

  //
  // RENDER
  //
  return (
    <Wrapper isLoggedUser={isLoggedUser} isGroupAdmin={isGroupAdmin}>
      <ColumnUser>
        <UserCard user={props.member?.user} noBackground showName />
      </ColumnUser>

      <ColumnOptions>
        <Droplist
          options={(
            (userGroupRolesData?.getUserGroupRoles as UserGroupRoleType[]) || []
          ).map((role) => {
            return {
              id: role.code,
              name: t('user group.roles.' + role.code),
            }
          })}
          value={props.member.groupRole?.code}
          onChange={(roleCode) => {
            props.updateMemberRole(props.member, roleCode as string)
          }}
          disabled={!getAllowUpdateMembers()}
        />
        {getAllowUpdateMembers() && (
          <DeleteButton
            onClick={() => props.deleteMember(props.member)}
            isLoggedUser={isLoggedUser}
          >
            <SvgIcon code="icon-delete" />
          </DeleteButton>
        )}
      </ColumnOptions>
    </Wrapper>
  )
}

export default UserGroupMember
