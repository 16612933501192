import FloatingButton, { FloatingButtonType } from './FloatingButton'
/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import React from 'react'

export const FLOATING_MENU_BREAKPOINT = 'XL'

const Wrapper = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

const Viewport = styled.div`
  flex: 1;
  position: relative;
`

const ButtonArray = styled.div<{
  isOpposite?: boolean
  isFooterOpen?: boolean
}>`
  position: absolute;
  z-index: 10;
  right: 10%;
  pointer-events: none;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${(props) =>
    props.isOpposite
      ? css`
          justify-content: flex-end;
          bottom: 140px;
        `
      : css`
          top: 20vh;
        `}

  ${(props) => props.theme.breakpoint.XL} {
    top: auto;
    bottom: ${(props) => (props.isFooterOpen ? 10 : 50)}px;
    right: 4%;
    left: 4%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    ${(props) =>
      props.isOpposite &&
      css`
        right: auto;
        left: 4%;
      `}
  }

  ${(props) => props.theme.breakpoint.L} {
    gap: 10px;
  }

  ${(props) => props.theme.breakpoint.S} {
    bottom: 16px;
  }

  ${(props) => props.theme.breakpoint.XXS} {
    left: 1%;
    right: 1%;
    gap: 1%;
  }

  ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
    flex: 1;
  }
`

const FooterMenu = styled.div`
  z-index: 10;
  background: ${(props) => props.theme.primary};

  display: flex;
  flex-direction: column;
  align-items: stretch;

  pointer-events: auto !important;
`

const FooterMenuContent = styled.div`
  padding: 10px 4%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  & > * {
    max-width: 300px;

    ${(props) => props.theme.breakpoint.XL} {
      max-width: 100vw;
    }
  }
`

const Fade = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: -60px;
  top: 80vh;
  pointer-events: none;
  background: linear-gradient(
    ${(props) => props.theme.background}00,
    ${(props) => props.theme.background}AA,
    ${(props) => props.theme.background}
  );

  display: none;

  ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
    display: block;
  }
`

const InfoText = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  right: 12px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.primary_light};
`

const FloatingMenu = (props: {
  buttons: FloatingButtonType[]
  footerMenu?: {
    isOpen: boolean
    render: () => void
  }
  infoText?: string
  isOpposite?: boolean
  noFade?: boolean
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <Viewport>
        <ButtonArray
          isOpposite={props.isOpposite}
          isFooterOpen={props.footerMenu?.isOpen}
        >
          {props.buttons?.map((button, i) =>
            button.component ? (
              (typeof button.isVisible !== 'boolean' || button.isVisible) && (
                <React.Fragment key={i}>{button.component}</React.Fragment>
              )
            ) : (
              <FloatingButton key={i} button={button} />
            )
          )}
          {props.infoText && <InfoText>{props.infoText}</InfoText>}
        </ButtonArray>
      </Viewport>

      <FooterMenu>
        <AnimateHeight height={props.footerMenu?.isOpen ? 'auto' : 0.01}>
          <FooterMenuContent>{props.footerMenu?.render()}</FooterMenuContent>
        </AnimateHeight>
      </FooterMenu>

      {!props.noFade && <Fade />}
    </Wrapper>
  )
}

export default FloatingMenu
