import {
  LyricChordType,
  LyricDesignType,
  LyricSectionType,
  SongChordType,
} from 'types'
import styled, { css } from 'styled-components'

import Droplist from 'components/Inputs/Droplist'
import EditableItem from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText/EditableItem'
import StringInput from 'components/Inputs/StringInput'
import { calculateFontCharSizeMonospace } from 'utils'
import { getChordOptions } from 'const'
import { useTranslation } from 'react-i18next'

export const boxCSS = css`
  border: 1px solid ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.lightest};
`

export const editableCSS = css`
  border-radius: 10px;
  border: 1px solid #0000;
  transition: background 0.2s ease;
`

const Chord = styled.div<{
  fontSize: number
  width?: number
  allowEdit?: boolean
}>`
  ${editableCSS}
  ${(props) =>
    props.allowEdit &&
    css`
      &:hover {
        ${boxCSS}
      }
    `}

  select {
    opacity: 1; // fight the 'disabled' property
    color: ${(props) => props.theme.chord};
    font-size: ${(props) => props.fontSize}px;
    font-family: 'Inconsolata', monospace;
    font-weight: 800;

    padding: 0;
    min-height: 20px;
    border: none;
    width: ${(props) => props.width + 12}px !important;

    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    ${(props) =>
      props.allowEdit
        ? css`
            &:hover {
              color: ${(props) => props.theme.black};
            }
          `
        : css`
            cursor: default;
          `}

    transition: all 0.1s ease;
    transition-property: font-size, width, color;
  }
`

const Duration = styled.div<{
  fontSize: number
  width?: number
  allowEdit?: boolean
}>`
  ${editableCSS}
  ${(props) =>
    props.allowEdit &&
    css`
      &:hover {
        ${boxCSS}
      }
    `}

  margin-bottom: -5px;
  margin-top: -5px;

  input {
    color: ${(props) => props.theme.chord};
    font-size: ${(props) => props.fontSize}px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    text-align: center;

    padding: 0 2px;
    border-radius: 10px;
    border: none;
    min-height: 20px;
    width: ${(props) => props.width + 8}px;

    ${(props) =>
      props.allowEdit &&
      css`
        &:hover {
          color: ${(props) => props.theme.black};
        }
      `}

    transition: all 0.1s ease;
    transition-property: font-size, width, color;
  }
`

const Wrapper = styled.div<{
  isEmpty?: number
  allowEdit?: boolean
}>`
  ${(props) =>
    props.isEmpty &&
    css`
      ${Duration} {
        ${boxCSS}
        opacity: 0;
        margin-bottom: -20px;
      }

      ${(props) =>
        props.allowEdit &&
        css`
          &:hover {
            margin-top: -20px;
            ${Duration} {
              opacity: 1;
              margin-bottom: 0px;
            }
          }
        `}
    `}
`

const LyricChord = (props: {
  lyricChord: LyricChordType
  songChords: SongChordType[]
  design: LyricDesignType
  updateLyricChord?: (updatedLyricChord: LyricChordType) => void
  deleteLyricChord?: (deleteId: number) => void
  lyricSection?: LyricSectionType
  isHeightRef?: boolean
}) => {
  const { t } = useTranslation()

  const allowEdit = props.design?.ALLOW_EDIT || !props.design

  return (
    <Wrapper
      isEmpty={(props.lyricChord.duration?.length || 0) === 0}
      allowEdit={allowEdit}
    >
      <EditableItem
        onMoveLeft={() =>
          props.updateLyricChord({
            ...props.lyricChord,
            position: Math.max(0, props.lyricChord.position - 1),
          })
        }
        onMoveRight={() =>
          props.updateLyricChord({
            ...props.lyricChord,
            position: Math.min(
              props.lyricSection?.lyrics?.length,
              props.lyricChord.position + 1
            ),
          })
        }
        onDelete={() => props.deleteLyricChord(props.lyricChord.itemId)}
        allowEdit={allowEdit}
      >
        <Duration
          fontSize={props.design.chordDurationSize}
          width={
            calculateFontCharSizeMonospace(props.design.chordDurationSize) *
            Math.max(4, props.lyricChord.duration?.length || 0)
          }
          allowEdit={allowEdit}
          title={t('song.lyric text options.chord duration')}
        >
          <StringInput
            placeholder="1/1"
            value={props.lyricChord.duration || ''}
            setValue={(value) =>
              props.updateLyricChord({
                ...props.lyricChord,
                duration: value,
              })
            }
            disabled={!allowEdit}
            liveUpdate /* REMOVE THIS IF PERFORMANCE IS AN ISSUE */
          />
        </Duration>

        <Chord
          fontSize={props.design.chordNameSize}
          width={
            calculateFontCharSizeMonospace(props.design.chordNameSize) *
            Math.max(
              4,
              getChordOptions(props.songChords).find(
                (c) => c.id === props.lyricChord.chordId
              )?.name?.length || 0
            )
          }
          allowEdit={allowEdit}
          title={t('song.lyric text options.chord selection')}
        >
          <Droplist
            options={getChordOptions(props.songChords).map((chord) => {
              return { id: chord.id, name: chord.name || '?' }
            })}
            value={props.lyricChord.chordId}
            onChange={(value) => {
              props.updateLyricChord({
                ...props.lyricChord,
                chordId: parseInt(value as string),
              })
            }}
            disabled={!allowEdit}
          />
        </Chord>
      </EditableItem>
    </Wrapper>
  )
}

export default LyricChord
