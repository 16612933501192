import CheckboxSongInput from '../CheckboxSongInput'
import { useTranslation } from 'react-i18next'

const SimplifyInput = (props: {
  value: boolean
  setValue?: (value: boolean) => void
  roundCorners?: boolean
  noBackground?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <CheckboxSongInput
      title={t('song.simplify chords')}
      value={props.value}
      setValue={props.setValue}
      roundCorners={props.roundCorners}
      noBackground={props.noBackground}
    />
  )
}

export default SimplifyInput
