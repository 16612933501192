import {
  Separator,
  renderButtonControl,
  renderCounterControl,
  renderHamburgerMenu,
  renderToggleControl,
} from '..'
import styled, { css } from 'styled-components'
import { useEffect, useRef, useState } from 'react'

import { ActionButton } from 'styles/GlobalStyle/Buttons'
import { LYRICS_DEFAULT_DESIGN } from 'const'
import { LyricDesignType } from 'types'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import { useClickOutside } from 'hooks'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const RadioArray = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  & > *:last-child {
    margin-left: 20px;
  }
`

const Radio = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  position: relative;

  & > button {
    width: 34px;
    height: 34px;
    margin: 0;
    color: ${(props) => props.theme.black}44;
    border: 1px solid ${(props) => props.theme.black}22;

    svg {
      width: 16px;
    }

    ${(props) =>
      props.isActive &&
      css`
        color: ${(props) => props.theme.white};
        border: 1px solid ${(props) => props.theme.primary_light};
        background: ${(props) => props.theme.primary_light} !important;
        &:hover {
          background: ${(props) => props.theme.primary_light} !important;
          filter: brightness(1.2);
        }
      `};
  }
`

const LyricDesignController = (props: {
  lyricsDesign: LyricDesignType
  setLyricsDesign: (lyricsDesign: LyricDesignType) => void
  lyricsDesignDefault?: LyricDesignType
  onClear?: () => void
  onToggle?: (value: boolean) => void
  compactMode?: boolean
}) => {
  const { t } = useTranslation()

  //
  // MENU OVERLAY
  //
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const menuRef = useRef()

  useClickOutside(menuRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (props.onToggle) {
      props.onToggle(isOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  //
  // CONTROLS
  //
  const renderModeControl = () => {
    const isChordsMode = props.lyricsDesign.editMode === 'chords'
    const isNotesMode = props.lyricsDesign.editMode === 'notes'

    const onChordsModeClick = () =>
      props.setLyricsDesign({
        ...props.lyricsDesign,
        editMode: isChordsMode ? null : 'chords',
      })

    const onNotesModeClick = () =>
      props.setLyricsDesign({
        ...props.lyricsDesign,
        editMode: isNotesMode ? null : 'notes',
      })

    const onClearClick = () => {
      confirmedAction({
        title: t('song.lyric editor options.clear all confirm title'),
        description: t(
          'song.lyric editor options.clear all confirm description'
        ),
        okText: t('delete'),
        cancelText: t('cancel'),
        onSubmit: () => {
          props.setLyricsDesign({
            ...props.lyricsDesign,
            editMode: 'chords',
          })
          props.onClear()
        },
      })
    }

    return (
      <RadioArray>
        {props.lyricsDesign.ALLOW_EDIT && (
          <>
            <Radio
              onClick={onChordsModeClick}
              isActive={isChordsMode}
              title={t('song.lyric editor options.chords mode tooltip')}
            >
              <ActionButton>
                <SvgIcon code="icon-paper-design-mode-chords" />
              </ActionButton>
            </Radio>

            <Radio
              onClick={onNotesModeClick}
              isActive={isNotesMode}
              title={t('song.lyric editor options.notes mode tooltip')}
            >
              <ActionButton>
                <SvgIcon code="icon-paper-design-mode-notes" />
              </ActionButton>
            </Radio>

            {props.onClear && (
              <Radio
                onClick={onClearClick}
                title={t('song.lyric editor options.clear all tooltip')}
              >
                <ActionButton>
                  <SvgIcon code="icon-paper-design-clear" />
                </ActionButton>
              </Radio>
            )}
          </>
        )}

        {props.compactMode &&
          renderHamburgerMenu({
            isOpen,
            setIsOpen,
            renderMenu,
            menuRef: menuRef,
            t,
          })}
      </RadioArray>
    )
  }

  //
  // RENDER
  //
  const renderMenu = () => {
    return (
      <>
        {renderCounterControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: <SvgIcon code="icon-paper-design-font-size" />,
          prop: 'fontSize',
          options: { valueMin: 1, valueMax: 50 },
          t,
        })}

        {renderCounterControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: <SvgIcon code="icon-paper-design-chord-name-size" />,
          prop: 'chordNameSize',
          options: { valueMin: 1, valueMax: 50 },
          t,
        })}

        {renderCounterControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: <SvgIcon code="icon-paper-design-chord-duration-size" />,
          prop: 'chordDurationSize',
          options: { valueMin: 1, valueMax: 50 },
          t,
        })}

        {renderCounterControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: <SvgIcon code="icon-paper-design-note-size" />,
          prop: 'noteSize',
          options: { valueMin: 1, valueMax: 50 },
          t,
        })}

        {renderCounterControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: <SvgIcon code="icon-paper-design-line-gap" />,
          prop: 'lineGap',
          options: { valueMin: 0, valueMax: 100 },
          t,
        })}

        <Separator>
          <SvgIcon code="icon-paper-design-separator" />
        </Separator>

        {renderToggleControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: 'chords',
          prop: 'showLyricChords',
          t,
        })}

        {renderToggleControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: 'notes',
          prop: 'showLyricNotes',
          t,
        })}

        {renderToggleControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: 'lines',
          prop: 'showPaperLine',
          t,
        })}

        {renderToggleControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: 'spaces',
          prop: 'showSpaces',
          t,
        })}

        <Separator>
          <SvgIcon code="icon-paper-design-separator" />
        </Separator>

        {renderButtonControl({
          design: props.lyricsDesign,
          setDesign: props.setLyricsDesign,
          label: 'reset',
          icon: <SvgIcon code="icon-reset" />,
          onClick: () => {
            props.setLyricsDesign(
              props.lyricsDesignDefault
                ? {
                    ...LYRICS_DEFAULT_DESIGN,
                    ...props.lyricsDesignDefault,
                  }
                : {
                    ...LYRICS_DEFAULT_DESIGN,
                  }
            )
          },
          t,
        })}
      </>
    )
  }

  return (
    <Wrapper>
      {renderModeControl()}

      {!props.compactMode && (
        <>
          <Separator>
            <SvgIcon code="icon-paper-design-separator" />
          </Separator>
          {renderMenu()}
        </>
      )}
    </Wrapper>
  )
}

export default LyricDesignController
