import { useEffect, useState } from 'react'

import { SEARCH_USER_GROUPS } from 'api/graphql'
import StringInput from 'components/Inputs/StringInput'
import UserCard from 'components/User/UserCard'
import { UserGroupType } from 'types'
import { hasPermission } from 'utils'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

const Wrapper = styled.div`
  flex: 1;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 6px 10px;
  ${(props) => props.theme.breakpoint.XS} {
    padding: 8px 10px;
  }
  &:hover {
    background: ${(props) => props.theme.primary_light}11;
  }
`

/**
 *
 * COMPONENT
 *
 */
const UserGroupAutocomplete = (props: {
  label?: string
  excludeUserGroupIds?: number[]
  maxCount?: number
  onUserGroupClick: (userGroup: UserGroupType) => void
}) => {
  const allowGetUserGroups = hasPermission('getUserGroups')

  //
  // USER GROUP AUTOCOMPLETE
  //
  const [userGroupSearch, setUserGroupSearch] = useState<string>()
  const [searchResults, setSearchResults] = useState<UserGroupType[]>([])

  const { data: userGroupSearchData } = useQuery(SEARCH_USER_GROUPS, {
    variables: {
      search: userGroupSearch,
      excludeIds: props.excludeUserGroupIds || [],
      maxCount: props.maxCount || 6,
    },
    skip: !userGroupSearch || !allowGetUserGroups,
  })

  useEffect(() => {
    if (userGroupSearch?.length && userGroupSearchData?.getUserGroups) {
      setSearchResults(userGroupSearchData?.getUserGroups)
    } else if (!userGroupSearch?.length) {
      setSearchResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupSearch, userGroupSearchData])

  //
  // RENDER
  //
  return (
    <StringInput
      label={props.label}
      value={userGroupSearch}
      setValue={setUserGroupSearch}
      liveUpdate
      autocompleteItems={searchResults?.map((userGroup: UserGroupType) => {
        return (
          <Wrapper
            key={userGroup.id}
            onClick={() => {
              setUserGroupSearch(null)
              props.onUserGroupClick(userGroup)
            }}
          >
            <UserCard userGroup={userGroup} showName noBackground />
          </Wrapper>
        )
      })}
    />
  )
}

export default UserGroupAutocomplete
