import {
  LyricChordType,
  LyricDesignType,
  LyricSectionType,
  SongChordType,
} from 'types'
import {
  calculateFontCharSizeMonospace,
  convertGlobalCaretIndexToLocal,
} from 'utils'

import LyricChord from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText/LyricChord'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`

const HeightReference = styled.div`
  width: 0px;
  opacity: 0;
  pointer-events: none;
`

const ItemWrapper = styled.div<{
  offset: number
}>`
  //transition: all 0.1s ease;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: calc(1px + ${(props) => props.offset}px);
  width: 1px; // necessary for center alignment
  margin-left: -1px;
`

/**
 *
 *  COMPONENT
 *
 */
const LyricChords = (props: {
  chords: LyricChordType[]
  songChords: SongChordType[]
  lyricSection: LyricSectionType
  setLyricSection: (lyricSection: LyricSectionType) => void
  textLines: string[]
  lineIndex: number
  design?: LyricDesignType
}) => {
  const updateLyricChord = (updatedChord: LyricChordType) => {
    props.setLyricSection({
      ...props.lyricSection,
      lyricChords: props.lyricSection.lyricChords.map((lyricChord) =>
        lyricChord.itemId === updatedChord.itemId ? updatedChord : lyricChord
      ),
    })
  }

  const deleteLyricChord = (deleteId) => {
    props.setLyricSection({
      ...props.lyricSection,
      lyricChords: props.lyricSection.lyricChords.filter(
        (lyricChord) => lyricChord.itemId !== deleteId
      ),
    })
  }

  //
  // RENDER
  //
  return (
    props.chords?.length > 0 && (
      <Wrapper>
        {props.chords.map((lyricChord, itemIndex) => (
          <div key={itemIndex}>
            <ItemWrapper
              offset={
                convertGlobalCaretIndexToLocal(
                  lyricChord.position,
                  props.textLines,
                  props.lineIndex
                ) * calculateFontCharSizeMonospace(props.design.fontSize)
              }
            >
              <LyricChord
                lyricChord={lyricChord}
                design={props.design}
                updateLyricChord={updateLyricChord}
                deleteLyricChord={deleteLyricChord}
                lyricSection={props.lyricSection}
                songChords={props.songChords}
              />
            </ItemWrapper>

            <HeightReference>
              <LyricChord
                isHeightRef
                lyricChord={lyricChord}
                design={props.design}
                songChords={props.songChords}
              />
            </HeightReference>
          </div>
        ))}
      </Wrapper>
    )
  )
}

export default LyricChords
