import {
  InstrumentChordType,
  InstrumentSectionType,
  SongChordType,
  SongInstrumentType,
  StringChordSchemaType,
} from 'types'

import { SONG_INSTRUMENTS } from 'const'
import confirmedAction from 'modals/actions/confirmedAction'
import { createChordSchema } from 'utils'
import i18n from 'i18n'
import selectedInstrumentAction from 'modals/actions/selectedInstrumentAction'

/**
 * UNIQUE IDS
 */
export const getUniqueInstrumentSectionId = (
  instrumentSections: InstrumentSectionType[]
) => {
  const i =
    (instrumentSections.length
      ? Math.max(...instrumentSections.map((section) => section.id))
      : 0) + 1
  return i
}

export const getUniqueExtraChordId = (
  instrumentSection: InstrumentSectionType
) => {
  const extraChords = instrumentSection.instrument?.chords?.filter(
    (c) => c.isExtraChord
  )
  const i =
    (extraChords.length
      ? Math.max(...extraChords?.map((c) => c.extraChordId))
      : 0) + 1
  return i
}

/**
 * GENERAL
 */
export const getInstrumentSectionName = (
  instrumentSection: InstrumentSectionType,
  songLanguageCode?: string
) => {
  const transpose = instrumentSection?.instrument?.transpose
  return (
    (songLanguageCode
      ? SONG_INSTRUMENTS[instrumentSection.instrument?.code].translation[
          songLanguageCode
        ]
      : i18n.t('instruments.' + instrumentSection.instrument?.code + '.name')) +
    (instrumentSection.name
      ? ` - ${instrumentSection.name}`
      : '' +
        (transpose ? ` (${transpose > 0 ? `+${transpose}` : transpose})` : ''))
  )
}

/**
 * ADD NEW INSTRUMENT SECTION
 */
export const addInstrumentSectionToSongRaw = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  songInstrument: SongInstrumentType
}) => {
  const id = getUniqueInstrumentSectionId(props.instrumentSections)
  const newSection: InstrumentSectionType = {
    id: id,
    name: null,
    instrument: props.songInstrument,
  }

  props.setInstrumentSections([...props.instrumentSections, newSection])

  return newSection
}

/**
 * DELETE INSTRUMENT SECTION
 */
export const addInstrumentSectionToSong = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  t: any
}) => {
  selectedInstrumentAction({
    t: props.t,
    onSubmit: (songInstrument) => {
      addInstrumentSectionToSongRaw({
        instrumentSections: props.instrumentSections,
        setInstrumentSections: props.setInstrumentSections,
        songInstrument: songInstrument,
      })
    },
  })
}

/**
 * MOVE INSTRUMENT SECTION
 */
export const moveInstrumentSectionInSong = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  instrumentSectionId: number
  offset: number
}) => {
  const index = props.instrumentSections.findIndex(
    (s) => s.id === props.instrumentSectionId
  )
  const finalIndex = Math.min(
    props.instrumentSections.length,
    Math.max(0, index + props.offset)
  )
  const updatedInstrumentSections = [...props.instrumentSections]
  var element = updatedInstrumentSections[index]
  updatedInstrumentSections.splice(index, 1)
  updatedInstrumentSections.splice(finalIndex, 0, element)
  props.setInstrumentSections(updatedInstrumentSections)
}

/**
 * DELETE INSTRUMENT SECTION (WITHOUT WARNING)
 */
const deleteInstrumentSectionFromSongRaw = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  instrumentSectionId: number
}) => {
  return props.setInstrumentSections(
    props.instrumentSections.filter((s) => s.id !== props.instrumentSectionId)
  )
}

/**
 * DELETE INSTRUMENT SECTION
 */
export const deleteInstrumentSectionFromSong = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  instrumentSectionId: number
  t: any
}) => {
  confirmedAction({
    title: props.t(
      'song.instrument editor options.delete section confirm title'
    ),
    description: props.t(
      'song.instrument editor options.delete section confirm description'
    ),
    okText: props.t('delete'),
    cancelText: props.t('cancel'),
    onSubmit: () => {
      deleteInstrumentSectionFromSongRaw({
        instrumentSections: props.instrumentSections,
        setInstrumentSections: props.setInstrumentSections,
        instrumentSectionId: props.instrumentSectionId,
      })
    },
  })
}

/**
 * UPDATE INSTRUMENT SECTION
 */
export const updateInstrumentSectionInSong = (props: {
  index: number
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  updatedInstrumentSection: InstrumentSectionType
}) => {
  const updatedInstrumentSections = props.instrumentSections.slice(0)
  updatedInstrumentSections[props.index] = props.updatedInstrumentSection

  props.setInstrumentSections(updatedInstrumentSections)
}

/**
 * ADD CHORD TO (PRE-EXISTING) INSTRUMENTS
 */
export const addChordToInstrumentSections = (props: {
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  songChordId: number
}) => {
  const updatedInstrumentSections: InstrumentSectionType[] = [
    ...props.instrumentSections,
  ].map((instrumentSection) => {
    return {
      ...instrumentSection,
      instrument: {
        ...instrumentSection.instrument,
        chords: [
          ...instrumentSection.instrument.chords,
          {
            songChordId: props.songChordId,
            chordSchema: createChordSchema({
              instrumentCode: instrumentSection.instrument.code,
              notes: instrumentSection.instrument?.tuning?.notes,
            }),
          },
        ],
      },
    }
  })

  props.setInstrumentSections(updatedInstrumentSections)
}

/**
 * CREATE NEW INSTRUMENT CHORDS
 */
export const createNewInstrumentChords = (props: {
  chords: SongChordType[]
  instrumentSection: InstrumentSectionType
}) => {
  const instrumentChords: InstrumentChordType[] = props.chords.map(
    (songChord) => {
      return {
        songChordId: songChord.id,
        chordSchema: createChordSchema({
          instrumentCode: props.instrumentSection.instrument.code,
          notes: props.instrumentSection.instrument?.tuning?.notes,
        }),
      }
    }
  )

  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: instrumentChords,
    },
  }

  return updatedInstrumentSection
}

/**
 * UPDATE INSTRUMENT CHORD
 */
export const updateInstrumentChord = (props: {
  instrumentSection: InstrumentSectionType
  updatedInstrumentChord: InstrumentChordType
}) => {
  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: [...props.instrumentSection.instrument.chords].map((c) =>
        (
          c.isExtraChord
            ? c.extraChordId === props.updatedInstrumentChord.extraChordId
            : c.songChordId === props.updatedInstrumentChord.songChordId
        )
          ? props.updatedInstrumentChord
          : c
      ),
    },
  }
  return updatedInstrumentSection
}

/**
 * ADD EXTRA CHORD TO INSTRUMENT
 */
export const addExtraChordToInstrumentSection = (props: {
  instrumentSection: InstrumentSectionType
}) => {
  const extraChordId = getUniqueExtraChordId(props.instrumentSection)

  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: [
        ...props.instrumentSection.instrument.chords,
        {
          songChordId: null,
          chordSchema: createChordSchema({
            instrumentCode: props.instrumentSection.instrument.code,
            notes: props.instrumentSection.instrument?.tuning?.notes,
          }),
          isExtraChord: true,
          extraChordId: extraChordId,
        },
      ],
    },
  }

  return updatedInstrumentSection
}

/**
 * DELETE EXTRA CHORD FROM INSTRUMENT
 */
export const removeExtraChordFromInstrumentSection = (props: {
  instrumentSection: InstrumentSectionType
  extraChordId: number
}) => {
  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: [...props.instrumentSection.instrument.chords].filter(
        (c) => c.extraChordId !== props.extraChordId
      ),
    },
  }

  return updatedInstrumentSection
}

/**
 * MOVE EXTRA CHORD IN INSTRUMENT (moves required chords to front)
 */
export const moveExtraChordInInstrumentSection = (props: {
  instrumentSection: InstrumentSectionType
  extraChordId: number
  offset: number
}) => {
  const allChords = props.instrumentSection.instrument.chords
  const requiredChords = [...allChords].filter((c) => !c.isExtraChord)
  const extraChords = [...allChords].filter((c) => c.isExtraChord)

  const index = extraChords.findIndex(
    (c) => c.extraChordId === props.extraChordId
  )
  const finalIndex = Math.min(
    extraChords.length,
    Math.max(0, index + props.offset)
  )
  const updatedExtraChords = [...extraChords]
  var element = updatedExtraChords[index]
  updatedExtraChords.splice(index, 1)
  updatedExtraChords.splice(finalIndex, 0, element)

  const allChordsUpdated = [...requiredChords, ...updatedExtraChords]

  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: allChordsUpdated,
    },
  }

  return updatedInstrumentSection
}

/**
 * UPDATE INSTRUMENT CHORDS TUNING
 */
export const updateInstrumentChordsTuning = (props: {
  instrumentSection: InstrumentSectionType
}) => {
  const notes = props.instrumentSection.instrument.tuning.notes

  const updatedChords: InstrumentChordType[] = [
    ...props.instrumentSection.instrument.chords,
  ].map((chord) => {
    const strings = (chord.chordSchema as StringChordSchemaType).strings
    let updatedStrings = []

    notes.forEach((note, i) => {
      updatedStrings.push(
        strings[i] ? { ...strings[i], note: note } : { note: note }
      )
    })

    const updatedChordSchema: StringChordSchemaType = {
      ...chord.chordSchema,
      strings: updatedStrings,
    }

    return {
      ...chord,
      chordSchema: updatedChordSchema,
    } as InstrumentChordType
  })

  const updatedInstrumentSection: InstrumentSectionType = {
    ...props.instrumentSection,
    instrument: {
      ...props.instrumentSection.instrument,
      chords: updatedChords,
    },
  }

  return updatedInstrumentSection
}
