/* eslint-disable react-hooks/exhaustive-deps */
import { InstrumentSectionType, SongChordType } from 'types'
import {
  addExtraChordToInstrumentSection,
  createNewInstrumentChords,
  deleteInstrumentSectionFromSong,
  getInstrumentSectionName,
  isStringInstrument,
  moveInstrumentSectionInSong,
  updateInstrumentSectionInSong,
} from 'utils'

import ContentBox from 'components/Boxes/ContentBox'
import InstrumentChords from './InstrumentChords'
import InstrumentOptions from './InstrumentOptions'
import SvgIcon from 'components/SvgIcon'
import { getInstrumentIcon } from 'const'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 1px dotted ${(props) => props.theme.black}44;
`

const TitleButton = styled.button`
  align-self: flex-start;
  display: flex;
  gap: 5px;

  background: none;
  border: none;
  box-shadow: none;
  padding: 5px;
  padding-left: 0;

  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.black}77;

  svg {
    width: 16px;
    height: 16px;
  }
`

/**
 *
 *  Component
 *
 */
const InstrumentSection = (props: {
  index: number
  chords: SongChordType[]
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  instrumentSection: InstrumentSectionType
  setOpenBox?: (isOpen: boolean) => void
  forcedIsOpen?: boolean
}) => {
  const { t } = useTranslation()

  //
  // UPDATE OUTSIDE DATA (SEPARATE FOR PERFORMANCE REASONS)
  //
  const setInstrumentSection = (instrumentSection: InstrumentSectionType) => {
    updateInstrumentSectionInSong({
      index: props.index,
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
      updatedInstrumentSection: instrumentSection,
    })
  }

  //
  // INSTRUMENT INFO
  //
  const title = getInstrumentSectionName(props.instrumentSection)

  const hasExtraChords = !!props.instrumentSection.instrument?.chords?.find(
    (c) => c.isExtraChord
  )

  //
  // CREATE CHORDS ON INIT
  //
  useEffect(() => {
    if (!props.instrumentSection.instrument.chords?.length) {
      setInstrumentSection(
        createNewInstrumentChords({
          chords: props.chords,
          instrumentSection: props.instrumentSection,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //
  // UTILS
  //
  const deleteSection = () => {
    deleteInstrumentSectionFromSong({
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
      instrumentSectionId: props.instrumentSection.id,
      t,
    })
  }

  const moveSection = (offset) => {
    moveInstrumentSectionInSong({
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
      instrumentSectionId: props.instrumentSection.id,
      offset,
    })
  }

  //
  // SUB-RENDERS
  //
  const renderEmptyTitle = (local: {
    title: string
    tooltip: string
    onClick: () => void
    hasContent?: boolean
  }) => {
    return (
      <TitleButton onClick={local.onClick} title={local.tooltip}>
        {!local.hasContent && <SvgIcon code="icon-add" />}
        <div>{local.title}</div>
      </TitleButton>
    )
  }

  //
  // RENDER
  //
  return (
    <ContentBox
      title={title}
      largeTitle
      icon={getInstrumentIcon(props.instrumentSection?.instrument?.code)}
      onClickUp={() => moveSection(-1)}
      onClickDown={() => moveSection(1)}
      onClickDelete={deleteSection}
      forcedIsOpen={props.forcedIsOpen}
      callback={props.setOpenBox}
    >
      <Wrapper>
        <InstrumentOptions
          instrumentSection={props.instrumentSection}
          setInstrumentSection={setInstrumentSection}
        />

        <Separator />
        <TitleButton>
          <div>{t('song.chords')}</div>
        </TitleButton>

        <InstrumentChords
          chords={props.chords}
          instrumentSection={props.instrumentSection}
          setInstrumentSection={setInstrumentSection}
          displayMode="song-chords"
        />

        <Separator />
        {renderEmptyTitle({
          title: t('song.instrument editor options.extra chords'),
          tooltip: t('song.instrument editor options.add extra chord'),
          hasContent: hasExtraChords,
          onClick: () =>
            setInstrumentSection(
              addExtraChordToInstrumentSection({
                instrumentSection: props.instrumentSection,
              })
            ),
        })}

        {hasExtraChords && (
          <>
            <InstrumentChords
              chords={props.chords}
              instrumentSection={props.instrumentSection}
              setInstrumentSection={setInstrumentSection}
              displayMode="extra-chords"
            />
            <Separator />
          </>
        )}

        {isStringInstrument(props.instrumentSection.instrument.code) && (
          <>
            {renderEmptyTitle({
              title: t('song.instrument editor options.tabs'),
              tooltip: t('song.instrument editor options.add tab'),
              onClick: () => alert('TODO'),
            })}

            {renderEmptyTitle({
              title: t('song.instrument editor options.riffs'),
              tooltip: t('song.instrument editor options.add riff'),
              onClick: () => alert('TODO'),
            })}

            {renderEmptyTitle({
              title: t('song.instrument editor options.multiline notes'),
              tooltip: t('song.instrument editor options.add multiline note'),
              onClick: () => alert('TODO'),
            })}
          </>
        )}
      </Wrapper>
    </ContentBox>
  )
}

export default InstrumentSection
