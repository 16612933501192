import {
  CompositionSectionType,
  LyricChordType,
  LyricNoteType,
  LyricSectionType,
  SongChordType,
} from 'types'

import { LYRICS_ROLES_TRANSLATED } from 'const'
import confirmedAction from 'modals/actions/confirmedAction'
import { getGlobalCaretIndex } from 'utils'
import i18n from 'i18n'
import { toast } from 'react-toastify'

/**
 * LOCAL TRANSLATIONS
 */
export const getLocalTranslatedHeading = (
  lyricSection: LyricSectionType,
  songLanguageCode: string
) => {
  if (!lyricSection) {
    return null
  }
  const localTranslation =
    LYRICS_ROLES_TRANSLATED[songLanguageCode][lyricSection.role]
  return `[${localTranslation}${
    lyricSection.roleIndex ? ` ${lyricSection.roleIndex}` : ''
  }]`
}

/**
 * PARSE LYRIC ITEMS (CHORDS, NOTES) INTO THEIR DESIGNATED LINES
 */
export const parseLyricItemsToLines = (
  lyricSection: LyricSectionType,
  textLines: string[]
) => {
  const parsedLyricChords: LyricChordType[][] = []
  const parsedLyricNotes: LyricNoteType[][] = []

  const getLineStartIndex = (i) =>
    getGlobalCaretIndex && getGlobalCaretIndex(0, textLines, i)

  const isInLine = (item, textLine, i) => {
    const lineStart = getLineStartIndex(i) - 1
    const lineEnd = lineStart + textLine.length

    return item.position >= lineStart && item.position <= lineEnd
  }

  textLines.forEach((textLine, i) => {
    parsedLyricChords.push(
      lyricSection?.lyricChords.filter((chord) => isInLine(chord, textLine, i))
    )
    parsedLyricNotes.push(
      lyricSection?.lyricNotes.filter((note) => isInLine(note, textLine, i))
    )
  })

  return { parsedLyricChords, parsedLyricNotes }
}

/**
 * GET NUMBER OF REFERENCES OF GIVEN CHORD IN LYRICS
 */
export const getNumberOfLyricSectionReferences = (
  compositionSections: CompositionSectionType[],
  lyricSectionId: number
) => {
  let numberOfReferences = 0
  compositionSections.forEach((compSection) => {
    if (compSection.lyricSectionId === lyricSectionId) {
      numberOfReferences += 1
    }
  })

  return numberOfReferences
}

/**
 * UNIQUE IDS
 */
export const getUniqueLyricSectionId = (lyricSections: LyricSectionType[]) => {
  let i =
    (lyricSections.length
      ? Math.max(...lyricSections.map((section) => section.id))
      : 0) + 1
  return i
}

export const getUniqueLyricChordId = (lyricSection: LyricSectionType) => {
  let i =
    (lyricSection.lyricChords.length
      ? Math.max(
          ...lyricSection.lyricChords.map((lyricChord) => lyricChord.itemId)
        )
      : 0) + 1
  return i
}

export const getUniqueLyricNoteId = (lyricSection: LyricSectionType) => {
  let i =
    (lyricSection.lyricNotes.length
      ? Math.max(
          ...lyricSection.lyricNotes.map((lyricNote) => lyricNote.itemId)
        )
      : 0) + 1
  return i
}

/**
 * ADD NEW LYRIC SECTION
 */
export const addLyricSectionToSong = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
}) => {
  const id = getUniqueLyricSectionId(props.lyricSections)
  const newSection: LyricSectionType = {
    id: id,
    role: 'verse',
    roleIndex: null,
    lyrics: '',
    lyricChords: [],
    lyricNotes: [],
  }

  props.setLyricSections([...props.lyricSections, newSection])

  return newSection
}

/**
 * DUPLICATE LYRIC SECTION
 */
export const duplicateLyricSectionInSong = (props: {
  lyricSectionToDuplicate: LyricSectionType
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
}) => {
  const id = getUniqueLyricSectionId(props.lyricSections)
  const newSection: LyricSectionType = {
    ...props.lyricSectionToDuplicate,
    id: id,
  }

  const updatedSections: LyricSectionType[] = []

  props.lyricSections.forEach((s) => {
    updatedSections.push(s)
    if (s.id === props.lyricSectionToDuplicate.id) {
      updatedSections.push(newSection)
    }
  })

  props.setLyricSections(updatedSections)
  return newSection
}

/**
 * MOVE LYRIC SECTION
 */
export const moveLyricSectionInSong = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSectionId: number
  offset: number
}) => {
  const index = props.lyricSections.findIndex(
    (s) => s.id === props.lyricSectionId
  )
  const finalIndex = Math.min(
    props.lyricSections.length,
    Math.max(0, index + props.offset)
  )
  const updatedLyricSections = [...props.lyricSections]
  var element = updatedLyricSections[index]
  updatedLyricSections.splice(index, 1)
  updatedLyricSections.splice(finalIndex, 0, element)
  props.setLyricSections(updatedLyricSections)
}

/**
 * DELETE LYRIC SECTION (WITHOUT WARNING)
 */
const deleteLyricSectionFromSongRaw = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSectionId: number
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
}) => {
  props.setLyricSections(
    props.lyricSections.filter((s) => s.id !== props.lyricSectionId)
  )
  props.setCompositionSections(
    props.compositionSections.filter(
      (s) => s.lyricSectionId !== props.lyricSectionId
    )
  )
}

/**
 * DELETE LYRIC SECTION
 */
export const deleteLyricSectionFromSong = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSectionId: number
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
  t: any
}) => {
  let numberOfReferences = getNumberOfLyricSectionReferences(
    props.compositionSections,
    props.lyricSectionId
  )
  let hasLyrics = props.lyricSections?.find(
    (s) => s.id === props.lyricSectionId
  )?.lyrics?.length

  if (numberOfReferences || hasLyrics) {
    confirmedAction({
      title: props.t('song.lyric editor options.delete section confirm title'),
      description: props
        .t('song.lyric editor options.delete section confirm description')
        .replace('{NUMBER}', `${numberOfReferences}`),
      okText: props.t('delete'),
      cancelText: props.t('cancel'),
      onSubmit: () => {
        deleteLyricSectionFromSongRaw({
          lyricSections: props.lyricSections,
          setLyricSections: props.setLyricSections,
          compositionSections: props.compositionSections,
          setCompositionSections: props.setCompositionSections,
          lyricSectionId: props.lyricSectionId,
        })
      },
    })
  } else {
    deleteLyricSectionFromSongRaw({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      compositionSections: props.compositionSections,
      setCompositionSections: props.setCompositionSections,
      lyricSectionId: props.lyricSectionId,
    })
  }
}

/**
 * UPDATE LYRIC SECTION
 */
export const updateLyricSectionInSong = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  updatedLyricSection: LyricSectionType
}) => {
  const index = props.lyricSections
    .map((s) => s.id)
    .indexOf(props.updatedLyricSection.id)

  const updatedLyricSections = props.lyricSections.slice(0)
  updatedLyricSections[index] = props.updatedLyricSection

  props.setLyricSections(updatedLyricSections)
}

/**
 * ADD LYRIC CHORD
 */
export const addChordToLyricSection = (props: {
  chords: SongChordType[]
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSection: LyricSectionType
  position: number
  chordId: number
}) => {
  if (!props.chords?.length) {
    toast.info(i18n.t('song.add chord no chords'))
    return null
  }
  let selectedChordId = props.chordId
  if (!selectedChordId) {
    selectedChordId = props.chords[0].id
  }
  if (selectedChordId || selectedChordId === 0) {
    updateLyricSectionInSong({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      updatedLyricSection: {
        ...props.lyricSection,
        lyricChords: [
          ...props.lyricSection?.lyricChords,
          {
            itemId: getUniqueLyricChordId(props.lyricSection),
            position: props.position,
            chordId: selectedChordId,
            duration: null,
          },
        ],
      },
    })
  }
  return selectedChordId
}

/**
 * ADD LYRIC NOTE
 */
export const addNoteToLyricSection = (props: {
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSection: LyricSectionType
  position: number
}) => {
  const uniqueLyricNoteId = getUniqueLyricNoteId(props.lyricSection)

  updateLyricSectionInSong({
    lyricSections: props.lyricSections,
    setLyricSections: props.setLyricSections,
    updatedLyricSection: {
      ...props.lyricSection,
      lyricNotes: [
        ...props.lyricSection?.lyricNotes,
        {
          itemId: uniqueLyricNoteId,
          position: props.position,
          text: '',
          isImportant: false,
        },
      ],
    },
  })
  return uniqueLyricNoteId
}

/**
 * NORMALIZE LYRIC CHORDS
 */
export const getNormalizedLyricChord = (chord: LyricChordType) => {
  return [chord.chordId, chord.duration, chord.itemId, chord.position].join(',')
}

export const getNormalizedLyricChords = (chords: LyricChordType[]) => {
  return chords.map((c) => getNormalizedLyricChord(c)).join('|')
}

export const lyricChordsEqual = (a: LyricChordType[], b: LyricChordType[]) => {
  if (a?.length !== b?.length) {
    return false
  }
  return getNormalizedLyricChords(a) === getNormalizedLyricChords(b)
}

/**
 * NORMALIZE LYRIC NOTES
 */
export const getNormalizedLyricNote = (note: LyricNoteType) => {
  return [
    note.isImportant ? 't' : 'f',
    note.text,
    note.itemId,
    note.position,
  ].join(',')
}

export const getNormalizedLyricNotes = (notes: LyricNoteType[]) => {
  return notes.map((c) => getNormalizedLyricNote(c)).join('|')
}

export const lyricNotesEqual = (a: LyricNoteType[], b: LyricNoteType[]) => {
  if (a?.length !== b?.length) {
    return false
  }
  return getNormalizedLyricNotes(a) === getNormalizedLyricNotes(b)
}
