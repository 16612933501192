/* eslint-disable react-hooks/exhaustive-deps */
import { SCLChordType, SCLChordsByRootType, StringChordSchemaType } from 'types'
import { useEffect, useMemo, useState } from 'react'

import ChordLibraryList from '../ChordLibraryList'
import Counter from 'components/Inputs/Counter'
import StringChord from 'components/Instruments/StringChord'
import { createChordSchema } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
`

const InputSection = styled.div`
  position: relative;
`

const ResultSection = styled.div`
  flex: 1;
`

const Large = styled.div`
  ${(props) => props.theme.breakpoint.M} {
    display: none;
  }
`
const Small = styled.div`
  display: none;
  ${(props) => props.theme.breakpoint.M} {
    display: block;
  }
`

const ChordNamer = (props: {
  tuning: string[]
  instrumentCode: string
  chordsByRoot: SCLChordsByRootType
}) => {
  const { t } = useTranslation()

  const chordsFlat: SCLChordType[] = useMemo(
    () => [...Object.values(props.chordsByRoot)].flat(),
    []
  )

  const [fretCount_S, setFretCount_S] = useState<number>(6)
  const [fretCount_L, setFretCount_L] = useState<number>(16)
  const [foundChords, setFoundChords] = useState<SCLChordType[]>()

  useEffect(() => {
    setFretCount_S(6)
    setFretCount_L(16)
    setFoundChords(undefined)
  }, [])

  const [searchChordSchema, setSearchChordSchema] =
    useState<StringChordSchemaType>(
      createChordSchema({
        instrumentCode: props.instrumentCode,
        notes: props.tuning,
      }) as StringChordSchemaType
    )

  const getChordFrets = (chord: SCLChordType) => {
    if (
      chord?.frets?.length === 1 &&
      chord?.frets[0].toString().length / props.tuning.length === 2
    ) {
      // sometimes frets have been scraped into one value -> can be separated if all frets were 10 and higher
      const fretString = chord?.frets[0].toString()
      const frets = []
      for (let i = 0; i < fretString.length; i += 2) {
        const fret = fretString.slice(i, i + 2)
        frets.push(parseInt(fret))
      }
      return frets
    }
    return chord.frets
  }

  useEffect(() => {
    if (searchChordSchema) {
      const frets = [
        ...searchChordSchema.strings.map((s) => (s.mute ? -1 : s.fret || 0)),
      ].reverse()

      setFoundChords(
        chordsFlat.filter(
          (chord) => getChordFrets(chord).join(',') === frets.join(',')
        )
      )
    } else {
      setFoundChords(undefined)
    }
  }, [searchChordSchema])

  return (
    <Wrapper>
      <Small>
        <InputSection>
          <StringChord
            chordName=" "
            chordSchema={searchChordSchema}
            setChordSchema={setSearchChordSchema}
            autoFillSchema
            stringHeight={22}
            fretCount={fretCount_S}
          />
          <Counter
            label={t('instrument.render fret count')}
            value={fretCount_S}
            setValue={setFretCount_S}
            valueMin={3}
            valueMax={25}
          />
        </InputSection>
      </Small>

      <Large>
        <InputSection>
          <StringChord
            chordName=" "
            chordSchema={searchChordSchema}
            setChordSchema={setSearchChordSchema}
            autoFillSchema
            stringHeight={22}
            fretCount={fretCount_L}
          />
          <Counter
            label={t('instrument.render fret count')}
            value={fretCount_L}
            setValue={setFretCount_L}
            valueMin={3}
            valueMax={25}
          />
        </InputSection>
      </Large>

      <ResultSection>
        {foundChords ? (
          <ChordLibraryList
            tuning={props.tuning}
            chords={foundChords}
            filterChords={false}
          />
        ) : (
          <div />
        )}
      </ResultSection>
    </Wrapper>
  )
}

export default ChordNamer
