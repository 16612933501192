import { SCLChordType, SingleStringType } from 'types'

import StringChord from 'components/Instruments/StringChord'
import styled from 'styled-components'
import { useState } from 'react'

const ChordArray = styled.div`
  display: grid;
  justify-content: center;
  gap: 20px;

  grid-template-columns: repeat(auto-fill, 15%);

  ${(props) => props.theme.breakpoint.L} {
    grid-template-columns: repeat(auto-fill, 20%);
  }
  ${(props) => props.theme.breakpoint.M} {
    grid-template-columns: repeat(auto-fill, 30%);
  }
  ${(props) => props.theme.breakpoint.S} {
    grid-template-columns: repeat(auto-fill, 45%);
  }
  ${(props) => props.theme.breakpoint.XXS} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 5%;
  }

  & > * {
    background: ${(props) => props.theme.background};
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;

  button {
    --size: 40px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    border: none;
    background: ${(props) => props.theme.light};
    color: ${(props) => props.theme.neutral};
    border-radius: 50%;
  }
`

const ChordLibraryList = (props: {
  tuning: string[]
  chords: SCLChordType[]
  filterChords?: boolean
  maxCount?: number
}) => {
  const limitStep = props.maxCount || 5
  const [limitCount, setLimitCount] = useState<number>(props.maxCount ? 1 : -1)

  const getChordName = (chord: SCLChordType) => {
    return chord.name
    /* return chord.altVersion && chord.altVersion > 1
      ? `${chord.name} (${chord.altVersion})`
      : chord.name */
  }

  const getChordFrets = (chord: SCLChordType) => {
    if (
      chord?.frets?.length === 1 &&
      chord?.frets[0].toString().length / props.tuning.length === 2
    ) {
      // sometimes frets have been scraped into one value -> can be separated if all frets were 10 and higher
      const fretString = chord?.frets[0].toString()
      const frets = []
      for (let i = 0; i < fretString.length; i += 2) {
        const fret = fretString.slice(i, i + 2)
        frets.push(parseInt(fret))
      }
      return frets
    }
    return chord.frets
  }

  const isChordListed = (chord: SCLChordType, index: number) => {
    if (limitCount && index >= props.maxCount * limitCount) {
      return false
    }
    if (getChordFrets(chord)?.length !== props.tuning.length) {
      return false
    }
    if (!props.filterChords) {
      return true
    }
    const majorFormula = '1 3 5'
    const minorFormula = '1 b3 5'
    if (chord?.formula === majorFormula || chord?.formula === minorFormula) {
      return true
    }
    return false
  }

  const renderShowAllButton = () => {
    if (!props.maxCount) {
      return null
    }

    const diff = props.chords.length - props.maxCount * limitCount

    return (
      <Buttons>
        {limitCount > 1 && (
          <button onClick={() => setLimitCount(limitCount - 1)}>
            {`-${limitStep}`}
          </button>
        )}
        {diff > 0 && (
          <button onClick={() => setLimitCount(limitCount + 1)}>
            {`+${limitStep}`}
          </button>
        )}
      </Buttons>
    )
  }

  return (
    <>
      <ChordArray>
        {props.chords
          ?.sort((a, b) => (getChordName(a) < getChordName(b) ? -1 : 1))
          .map((chord, i) =>
            isChordListed(chord, i) ? (
              <StringChord
                key={i}
                chordName={getChordName(chord)}
                chordSchema={{
                  strings: [...getChordFrets(chord)]
                    .reverse()
                    .map((fret, stringIndex) => {
                      return {
                        note: [...props.tuning][stringIndex],
                        mute: fret < 0,
                        fret: Math.max(fret, 0),
                      } as SingleStringType
                    }),
                }}
                fretCount={4}
                autoFillSchema
              />
            ) : null
          )}
      </ChordArray>

      {renderShowAllButton()}
    </>
  )
}

export default ChordLibraryList
