import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
  TextLyricDesignType,
} from 'types'
import { useEffect, useState } from 'react'

import { BreakpointEnumType } from 'styles/breakpoints'
import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import { SONG_PLACEHOLDER } from './placeholder'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import TextInput from 'components/Inputs/TextInput'
import TextLyricDesignController from 'components/Song/SongDesignControllers/TextLyricDesignController'
import TransposeInput from 'components/Inputs/TransposeInput'
import { convertTextToSong } from 'utils/song/convertTextToSong'
import styled from 'styled-components'

const COMPACT_CONTROLS_BREAKPOINT: BreakpointEnumType = 'M_LenovoTab'

const Wrapper = styled.div`
  align-self: stretch;
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  gap: 2%;

  --overflow: 20vw;
  width: max(1500px, calc(100% + var(--overflow)));
  margin-left: calc(-1 * var(--overflow) / 2);

  // background: yellow;

  @media screen and (max-width: 2100px) {
    --overflow: 10vw;
    width: calc(100% + var(--overflow));
    // background: green;
  }

  @media screen and (max-width: 1800px) {
    --overflow: 10vw;
    width: calc(100% + var(--overflow));
    margin-left: calc(-1 * var(--overflow));
    // background: grey;
  }

  ${(props) => props.theme.breakpoint.XL} {
    --overflow: 10vw;
    margin-left: calc(-1 * var(--overflow) / 2);
    // background: blue;
  }

  @media screen and (max-width: 1400px) {
    --overflow: 0;
    // background: cyan;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    // background: orange;
  }

  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    padding: 0 5%;
    margin-right: 0;
    gap: 60px;
  }

  ${(props) => props.theme.breakpoint.S} {
    padding: 0;
  }
`

const TextWrapper = styled.div`
  flex: 1;
  min-width: 400px;
  max-width: 600px;
  justify-self: stretch;
  align-self: stretch;
  background: ${(props) => props.theme.background};

  & > * {
    // background: red;
    min-height: 100%;

    textarea {
      padding: 5%;
      white-space: pre;
      overflow-wrap: normal;
      overflow-x: auto;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 300px;
  }

  @media screen and (max-width: 1000px) {
    align-self: center;
    width: 78%;
    max-width: 78%;
    min-width: 0;
    & > * {
      min-height: 70vh;
    }
  }

  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    width: 100%;
    max-width: 600px;
  }
`

const PaperWrapper = styled.div`
  align-self: flex-start;
  position: relative;

  width: 50%;
  margin-right: 150px; /* space for absolute controls */

  @media screen and (max-width: 1000px) {
    width: 62%;
    margin-left: 10%;
  }

  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    width: 100%;
    margin: auto;
    max-width: 600px;
  }
`

const ControlsOpen = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 20px);

  background: ${(props) => props.theme.background};

  ${(props) => props.theme.breakpoint[COMPACT_CONTROLS_BREAKPOINT]} {
    display: none;
  }
`

const ControlsCompact = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  right: 8px;

  background: ${(props) => props.theme.background};

  display: none;
  ${(props) => props.theme.breakpoint[COMPACT_CONTROLS_BREAKPOINT]} {
    display: flex;
  }
`
const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
`

/**
 *
 *  COMPONENT
 *
 */
const TextToSong = (props: {
  generalInfo: SongGeneralInfoType
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
  textLyricDesign: TextLyricDesignType
  setTextLyricDesign: (d: TextLyricDesignType) => void
  showLocalSongAdjustments?: boolean
}) => {
  const [songText, setSongText] = useState<string>('')

  useEffect(() => {
    if (songText?.length) {
      const song = convertTextToSong(songText)

      props.setChords(song.chords)
      props.setLyricSections(song.lyricSections)
      props.setCompositionSections(song.compositionSections)
    } else {
      props.setChords([])
      props.setLyricSections([])
      props.setCompositionSections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songText])

  /* THESE ADJUSTMENTS ARE JUST LOCAL (FOR TOOLS PAGE) */
  const [transpose, setTranspose] = useState<number>(0)
  const [capoOffset, setCapoOffset] = useState<number>(0)
  const [simplifyChords, setSimplifyChords] = useState<boolean>(false)

  //
  // RENDER
  //
  return (
    <Wrapper>
      <TextWrapper>
        <TextInput
          placeholder={SONG_PLACEHOLDER.trim()}
          value={songText}
          setValue={setSongText}
          isMultiline
          isSongFont
          liveUpdate
        />
      </TextWrapper>

      <PaperWrapper>
        {props.showLocalSongAdjustments && (
          <Controls>
            <TransposeInput
              value={transpose}
              setValue={setTranspose}
              roundCorners
            />
            <TransposeInput
              value={capoOffset}
              setValue={setCapoOffset}
              roundCorners
              isCapo
            />
            <SimplifyInput
              value={simplifyChords}
              setValue={setSimplifyChords}
              roundCorners
            />
          </Controls>
        )}

        <FillScreenPaperWrapper>
          <SongPaperA4
            generalInfo={props.generalInfo}
            lyricSections={props.lyricSections}
            compositionSections={props.compositionSections}
            chords={props.chords}
            textLyricDesign={props.textLyricDesign}
            setTextLyricDesign={props.setTextLyricDesign}
            transpose={transpose}
            capoOffset={capoOffset}
            simplifyChords={simplifyChords}
          />
        </FillScreenPaperWrapper>

        <ControlsCompact>
          <TextLyricDesignController
            textLyricsDesign={props.textLyricDesign}
            setTextLyricsDesign={props.setTextLyricDesign}
            compactMode={true}
          />
        </ControlsCompact>

        <ControlsOpen>
          <TextLyricDesignController
            textLyricsDesign={props.textLyricDesign}
            setTextLyricsDesign={props.setTextLyricDesign}
            compactMode={false}
          />
        </ControlsOpen>
      </PaperWrapper>
    </Wrapper>
  )
}

export default TextToSong
