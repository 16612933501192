import { useEffect, useState } from 'react'

import { SEARCH_USERS } from 'api/graphql'
import StringInput from 'components/Inputs/StringInput'
import UserCard from 'components/User/UserCard'
import { UserType } from 'types'
import { hasPermission } from 'utils'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

const AutocompleteUser = styled.div`
  flex: 1;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 6px 10px;
  ${(props) => props.theme.breakpoint.XS} {
    padding: 8px 10px;
  }
  &:hover {
    background: ${(props) => props.theme.primary_light}11;
  }
`

/**
 *
 * COMPONENT
 *
 */
const UserAutocomplete = (props: {
  label?: string
  excludeUserIds?: number[]
  maxCount?: number
  onUserClick: (user: UserType) => void
}) => {
  const allowGetUsers = hasPermission('getUsers')

  //
  // USER AUTOCOMPLETE
  //
  const [userSearch, setUserSearch] = useState<string>()
  const [searchResults, setSearchResults] = useState<UserType[]>([])

  const { data: userSearchData } = useQuery(SEARCH_USERS, {
    variables: {
      search: userSearch,
      excludeIds: props.excludeUserIds || [],
      maxCount: props.maxCount || 6,
    },
    skip: !userSearch || !allowGetUsers,
  })

  useEffect(() => {
    if (userSearch?.length && userSearchData?.getUsers) {
      setSearchResults(userSearchData?.getUsers)
    } else if (!userSearch?.length) {
      setSearchResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearch, userSearchData])

  //
  // RENDER
  //
  return (
    <StringInput
      label={props.label}
      value={userSearch}
      setValue={setUserSearch}
      liveUpdate
      autocompleteItems={searchResults?.map((user: UserType) => {
        return (
          <AutocompleteUser
            key={user.id}
            onClick={() => {
              setUserSearch(null)
              props.onUserClick(user)
            }}
          >
            <UserCard user={user} showName noBackground />
          </AutocompleteUser>
        )
      })}
    />
  )
}

export default UserAutocomplete
