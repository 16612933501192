import { ICON_MAP } from 'components/SvgIcon'
import ModuleWrapper from '../Layout/ModuleWrapper'
import { copyToClipboard } from 'utils'
import styled from 'styled-components'
import { toast } from 'react-toastify'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1600px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const IconWrapper = styled.div`
  --cardSize: 120px;
  width: var(--cardSize);
  height: var(--cardSize);
  min-width: var(--cardSize);
  min-height: var(--cardSize);
  max-width: var(--cardSize);
  max-height: var(--cardSize);

  ${(props) => props.theme.breakpoint.M} {
    --cardSize: 100px;
  }
  ${(props) => props.theme.breakpoint.S} {
    --cardSize: 80px;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  cursor: pointer;
  border: 1px solid ${(props) => props.theme.admin};

  div {
    margin-top: auto;
    font-size: 7px;
    font-weight: 600;
    text-align: center;
  }

  svg {
    position: absolute;
    --size: 30px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
  }
`

const DevModuleIcons = (props: { isOpenDefault?: boolean }) => {
  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="Icons"
      subtitle="Icons available via component"
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        {Object.entries(ICON_MAP).map(([code, component]) => (
          <IconWrapper
            key={code}
            onClick={() => {
              const copied = `<SvgIcon code="${code}" />`
              copyToClipboard(copied)
              toast.info(`COPIED TO CLIPBOARD:\n${copied}`)
            }}
          >
            {component}
            <div>{code}</div>
          </IconWrapper>
        ))}
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleIcons
