import { LyricRoleType, LyricSectionType } from 'types'

import Droplist from 'components/Inputs/Droplist'
import { LYRICS_ROLES } from 'const'
import TextInput from 'components/Inputs/TextInput'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const INDEX_COUNT = 10

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  column-gap: 15px;
`

/**
 *
 *  COMPONENT
 *
 */
const Options = (props: {
  lyricSection: LyricSectionType
  setLyricSection: (lyricSection: LyricSectionType) => void
}) => {
  const { t } = useTranslation()

  const lyricRoles: string[] = Object.values(LYRICS_ROLES)

  //
  // UPDATE CHORD ITEMS ON TEXT CHANGES
  //

  const isInRange = (value, start, end) => {
    return value > start && value < end
  }

  const updateLyricItems = ({
    textNew,
    textPrev,
    changeIndexStart,
    changeIndexEnd,
    caretIndex,
    items,
  }) => {
    /* Text deletion - move text items back + delete*/
    if (textNew.length < textPrev.length) {
      changeIndexStart = changeIndexStart - 1
      const lengthDifference = textPrev.length - textNew.length

      if (lengthDifference > 1) {
        items = items.filter(
          (item) => !isInRange(item.position, changeIndexStart, changeIndexEnd)
        )
      } else {
        items = items.filter((item) => item.position !== caretIndex)
      }

      items = items.map((item) => {
        if (item.position > caretIndex) {
          return {
            ...item,
            position: item.position - lengthDifference,
          }
        }
        return item
      })
    }

    /* Text addition - move text items front */
    if (textNew.length > textPrev.length) {
      const lengthDifference = textNew.length - textPrev.length
      items = items.map((item) => {
        if (
          lengthDifference > 1
            ? item.position > changeIndexStart
            : item.position > caretIndex - 2
        ) {
          return {
            ...item,
            position: item.position + lengthDifference,
          }
        }
        return item
      })
    }

    return items
  }

  const updateText = (value, e) => {
    const textPrev = props.lyricSection?.lyrics
    const textNew = value

    let caretIndex = e.target.selectionStart
    let changeIndexStartFound = false
    let changeIndexStart = 0
    let changeIndexEnd = 0

    for (let i = 0; i < textPrev.length; i++) {
      if (textPrev[i] !== textNew[i]) {
        changeIndexStart = i
        changeIndexStartFound = true
        break
      }
    }

    if (!changeIndexStartFound) {
      changeIndexStart = Math.min(textPrev.length, textNew.length)
    }

    if (textNew.length > textPrev.length) {
      changeIndexEnd = changeIndexStart + (textNew.length - textPrev.length)
    } else {
      changeIndexStart = changeIndexStart + 1
      changeIndexEnd = changeIndexStart + (textPrev.length - textNew.length) - 1
    }

    const updatedChords = updateLyricItems({
      textNew,
      textPrev,
      changeIndexStart,
      changeIndexEnd,
      caretIndex,
      items: props.lyricSection?.lyricChords,
    })

    const updatedNotes = updateLyricItems({
      textNew,
      textPrev,
      changeIndexStart,
      changeIndexEnd,
      caretIndex,
      items: props.lyricSection?.lyricNotes,
    })

    props.setLyricSection({
      ...props.lyricSection,
      lyrics: textNew,
      lyricChords: updatedChords,
      lyricNotes: updatedNotes,
    })
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Droplist
        label={t('song.section.lyrics role')}
        options={lyricRoles.map((role) => {
          return { id: role, name: t('song.section.lyrics roles.' + role) }
        })}
        value={props.lyricSection.role}
        onChange={(value) =>
          props.setLyricSection({
            ...props.lyricSection,
            role: value as LyricRoleType,
          })
        }
      />

      <Droplist
        label={t('song.section.index')}
        options={Array.apply(null, Array(INDEX_COUNT)).map(
          (u, i) => `${i + 1}`
        )}
        value={props.lyricSection.roleIndex}
        onChange={(value) =>
          props.setLyricSection({
            ...props.lyricSection,
            roleIndex: value as number,
          })
        }
        placeholder={t('song.section.no index')}
        placeholderSelectable={true}
      />

      <TextInput
        label={t('song.text')}
        placeholder="I believe I can fly..."
        value={props.lyricSection?.lyrics}
        setValue={updateText}
        height="164px"
        isMultiline
        isResizable
      />
    </Wrapper>
  )
}

export default Options
