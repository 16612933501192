import { gql } from '@apollo/client'

export const CREATE_SONG = gql`
  mutation createSong(
    $name: String!
    $author: String
    $languageCode: String
    $body: String
    $members: [SongMemberInput]
    $createdByUserId: ID
  ) {
    createSong(
      name: $name
      author: $author
      languageCode: $languageCode
      body: $body
      members: $members
      createdByUserId: $createdByUserId
    ) {
      success
      message
      id
    }
  }
`

export const UPDATE_SONG = gql`
  mutation updateSong(
    $id: ID!
    $name: String!
    $author: String
    $languageCode: String
    $body: String
    $members: [SongMemberInput]
    $createdByUserId: ID
  ) {
    updateSong(
      id: $id
      name: $name
      author: $author
      languageCode: $languageCode
      body: $body
      members: $members
      createdByUserId: $createdByUserId
    ) {
      success
      message
    }
  }
`

export const DELETE_SONG = gql`
  mutation deleteSong($id: ID!) {
    deleteSong(id: $id) {
      success
      message
    }
  }
`
