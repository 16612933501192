import {
  CREATE_USER,
  GET_LOGGED_USER,
  GET_USER,
  GET_USERS,
  UPDATE_USER,
} from 'api/graphql'
import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'

import DataModal from 'modals/base/DataModal'
import { EMPTY_USER } from 'const'
import { LOGGED_USER_VAR } from 'App'
import UserForm from 'components/User/UserForm'
import { UserType } from 'types'
import { hasPermission } from 'utils'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  & > * {
    background: ${(props) => props.theme.background};
  }
`

/**
 *
 * REACTIVE VARS
 *
 */
const USER_MODAL_DEFAULT = {
  isOpen: false,
  userId: null,
  isEditMode: false,
  callback: undefined,
}

export const USER_MODAL = makeVar<{
  isOpen: boolean
  userId?: number
  isEditMode?: boolean
  callback?: (newUserId: number) => void
}>(USER_MODAL_DEFAULT)

/**
 *
 * COMPONENT
 *
 */
const UserModal = () => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const modalData = useReactiveVar(USER_MODAL)
  const closeModal = () => USER_MODAL(USER_MODAL_DEFAULT)

  const [user, setUser] = useState<UserType>(EMPTY_USER)
  const [initPassword, setInitPassword] = useState<string>(null)
  const [oldPassword, setOldPassword] = useState<string>(null)
  const [newPassword, setNewPassword] = useState<string>(null)

  const allowUpdateAnyUser = hasPermission('updateAnyUser')
  const allowUpdateUserRole = hasPermission('updateUserRole')
  const isLoggedUser = loggedUser?.id === user?.id

  //
  // MUTATIONS/QUERIES
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUsers } =
    useQuery(GET_USERS) /* NECESSARY FOR REFETCH */

  const [createUser, { data: newData }] = useMutation(CREATE_USER, {
    refetchQueries: [GET_USERS],
  })

  const [updateUser, { data: updatedData }] = useMutation(UPDATE_USER, {
    refetchQueries: [
      GET_USERS,
      GET_USER,
      isLoggedUser ? GET_LOGGED_USER : null,
    ].filter((q) => q),
  })

  const { data: userData } = useQuery(GET_USER, {
    variables: { id: modalData.userId },
    skip: !modalData?.isOpen || !modalData?.isEditMode,
  })

  //
  // WATCH FOR EDIT/ADD RESPONSES
  //
  useEffect(() => {
    if (!modalData?.isEditMode && newData?.createUser) {
      if (newData?.createUser?.success) {
        toast.success(t(newData.createUser.message))
        if (modalData.callback) {
          modalData.callback(newData?.createUser?.id)
        }
        closeModal()
      } else {
        toast.error(t(newData.createUser.message))
      }
    } else if (modalData?.isEditMode && updatedData?.updateUser) {
      if (updatedData?.updateUser?.success) {
        toast.success(t(updatedData.updateUser.message))
        closeModal()
      } else {
        toast.error(t(updatedData.updateUser.message))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, updatedData])

  //
  // WATCH FOR 'IS OPEN' CHANGES
  //
  useEffect(() => {
    if (modalData.isOpen) {
      if (modalData.isEditMode && userData) {
        setUser(userData.getUser)
      } else if (!modalData.isEditMode) {
        setUser(EMPTY_USER)
      }
    } else {
      setUser(null)
    }
    setInitPassword('')
    setOldPassword('')
    setNewPassword('')
    setInvalidFields([])
  }, [modalData, userData])

  //
  // INVALID FIELDS
  //
  const [invalidFields, setInvalidFields] = useState<string[]>([])

  const getInvalidFields = () => {
    const requiredValues = [
      { id: 'email', value: user.email },
      { id: 'name', value: user.name },
      !modalData?.isEditMode && { id: 'initPassword', value: initPassword },
      modalData?.isEditMode &&
        oldPassword?.length &&
        !newPassword.length && { id: 'newPassword', value: newPassword },
      modalData?.isEditMode &&
        newPassword?.length &&
        !allowUpdateAnyUser &&
        !oldPassword.length && { id: 'oldPassword', value: oldPassword },
    ]
    const updatedInvalidFields = []
    requiredValues.forEach((rv) => {
      if (!rv.value) {
        updatedInvalidFields.push(rv.id)
      }
    })
    return updatedInvalidFields.filter((f) => f)
  }

  //
  // SUBMIT / CANCEL
  //
  const onSubmit = async () => {
    const updatedInvalidFields = getInvalidFields()

    setInvalidFields(updatedInvalidFields)
    if (updatedInvalidFields.length) {
      return
    }
    if (modalData.isEditMode) {
      updateUser({
        variables: {
          id: user.id,
          roleCode: allowUpdateUserRole ? user.role?.code : undefined,
          email: user.email,
          name: user.name,
          username: user.username,
          languageCode: user.languageCode,
          oldPassword: oldPassword,
          newPassword: newPassword,
          avatar: user.avatar,
        },
      })
    } else {
      createUser({
        variables: {
          roleCode: allowUpdateUserRole ? user.role?.code : undefined,
          email: user.email,
          name: user.name,
          username: user.username,
          languageCode: user.languageCode,
          password: initPassword,
          avatar: user.avatar,
        },
      })
    }
  }

  const onCancel = async () => {
    closeModal()
  }

  //
  // RENDER
  //
  return (
    <DataModal
      isOpen={modalData.isOpen}
      hasData={user ? true : false}
      title={modalData.isEditMode ? t('user.edit') : t('user.new')}
      onSubmitLabel={
        modalData.isEditMode ? t('user.save changes') : t('user.save new')
      }
      onSubmit={onSubmit}
      onCancelLabel={
        modalData.isEditMode ? t('user.dismiss changes') : t('user.dismiss new')
      }
      onCancel={onCancel}
      closeModal={closeModal}
    >
      <Wrapper>
        <UserForm
          user={user}
          setUser={setUser}
          initPassword={initPassword}
          setInitPassword={!modalData.isEditMode && setInitPassword}
          oldPassword={oldPassword}
          setOldPassword={modalData.isEditMode && setOldPassword}
          newPassword={newPassword}
          setNewPassword={modalData.isEditMode && setNewPassword}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      </Wrapper>
    </DataModal>
  )
}

export default UserModal
