import { DBPlaylistType, PlaylistBodyType } from 'types'
import { File, ViewMode } from 'components/Files/FileSystem'
import { getLocalizedDateString, getLocalizedDateTimeString } from './date'
import { isPast, isToday } from 'date-fns'

export const isPlaylistPrivate = (playlist: DBPlaylistType) => {
  if (
    playlist?.playlistMembers?.length === 1 &&
    playlist?.playlistMembers[0]?.user
  ) {
    // if song has only one member and it's a user (could also be a group!), it has to be the logged user who sees the song
    return true
  }
  return false
}

export const fileFromPlaylist = (
  playlist: DBPlaylistType,
  lang: any,
  renderCustomOverlay: (props: {
    isActive: boolean
    viewMode: ViewMode
  }) => void
) => {
  const playlistBody: PlaylistBodyType = playlist.body
    ? JSON.parse(playlist.body)
    : null

  const date = playlistBody.eventDate
  let name2 = undefined

  if (date) {
    name2 =
      date?.length > 10
        ? getLocalizedDateTimeString(date, lang)
        : getLocalizedDateString(date, lang)
  }

  const file: File = {
    id: playlist.id,
    name: playlist.name,
    name2: name2,
    type: 'file',
    icon: 'playlist',
    isPrivate: isPlaylistPrivate(playlist),
    renderCustomOverlay,
  }
  return file
}

const parsePlaylistSongs = (playlist, songId) => {
  const song = playlist?.songs?.find((s) => s.id === songId)
  if (song) {
    return JSON.parse(song.body)
  }
  return undefined
}

export const parsePlaylistBody = (playlist: DBPlaylistType) => {
  let body: PlaylistBodyType = JSON.parse(playlist.body)

  body = {
    ...body,
    playlistSongs: body.playlistSongs?.map((ps) => {
      return {
        ...ps,
        songData: parsePlaylistSongs(playlist, ps.songId),
      }
    }),
  }
  return body
}

export const isPlaylistPast = (date: Date) => {
  return !isToday(date) && isPast(date)
}

export const sortPlaylists = (playlists: DBPlaylistType[]) => {
  const playlistsSorted = [...playlists].sort((a, b) => {
    const body1: PlaylistBodyType = JSON.parse(a.body)
    const body2: PlaylistBodyType = JSON.parse(b.body)

    if (!body2?.eventDate) {
      return 1
    }
    if (!body1?.eventDate) {
      return -1
    }

    const date1 = new Date(body1.eventDate)
    const date2 = new Date(body2.eventDate)

    if (isPlaylistPast(date1) && !isPlaylistPast(date2)) {
      return 1
    }
    if (!isPlaylistPast(date1) && isPlaylistPast(date2)) {
      return -1
    }
    if (isPlaylistPast(date1) && isPlaylistPast(date2)) {
      // dates in the past are in reverse order
      return body1.eventDate < body2.eventDate ? 1 : -1
    }

    return body1.eventDate < body2.eventDate ? -1 : 1
  })

  return playlistsSorted
}
