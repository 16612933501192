import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css, keyframes } from 'styled-components'

import Breadcrumbs from './Breadcrumbs'
import FileItem from './FileItem'
import FileSystemIconButton from './FileSystemIconButton'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import inputAction from 'modals/actions/inputAction'
import { useClickOutside } from 'hooks'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const HeaderAnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
`

const Header = styled.div`
  animation-name: ${HeaderAnimationIn};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  padding: 1vh min(2vw, 30px);
`

const FileCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  font-weight: 800;
  color: ${(props) => props.theme.primary_light};
`

const FileArray = styled.div<{
  viewMode?: ViewMode
  maxHeight?: string
  noBackground?: boolean
}>`
  position: relative;
  background: ${(props) => !props.noBackground && props.theme.background};
  padding: min(5vh, 30px) min(2vw, 20px);
  display: grid;
  gap: 10px;
  /* grid-auto-rows: 1fr; */ /* All rows in a grid to have same height */
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

  ${(props) => props.theme.breakpoint.S} {
    grid-auto-rows: unset;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  ${(props) =>
    (props.viewMode as ViewMode) === 'list' &&
    css`
      display: flex;
      flex-direction: column;
      gap: 5px;
    `}

  & > * {
    display: flex;
  }

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
      overflow-y: scroll;
    `}
`

const SearchBar = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    min-height: 30px;
    min-width: 0px;
    padding: 5px 0px;
    padding-left: 35px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.background};

    &:focus {
      background: ${(props) => props.theme.lightest};
      box-shadow: 2px 2px 10px 0px ${(props) => props.theme.black}08;
    }
  }
`

const SearchIconWrapper = styled.div`
  position: absolute;
  svg {
    color: ${(props) => props.theme.neutral};
    height: 22px;
    margin-left: 8px;
  }
`

export type ViewMode = 'grid' | 'list'

export type FileType = 'folder' | 'file'

export type FileIconType = 'file' | 'folder' | 'playlist' | 'user' | 'userGroup'

export type FileBadgeType = {
  icon: ReactElement
  color?: string
  isFlashing?: boolean
}

export type File = {
  id: number
  parentId?: number
  name: string
  name2?: string
  type: FileType
  icon?: FileIconType
  image?: string
  renderCustomIcon?: (props: { isActive: boolean; viewMode: ViewMode }) => void
  renderCustomOverlay?: (props: {
    isActive: boolean
    viewMode: ViewMode
  }) => void
  customBadges?: FileBadgeType[]
  customBadgesTop?: FileBadgeType[]
  isParent?: boolean
  isPrivate?: boolean
}

const FileSystem = (props: {
  files: File[]
  folderBreadcrumbs?: File[]
  currentFolderId?: number
  currentParentId?: number
  getParentIdOfParentId?: (id: number) => number
  onFileAddClick?: (file: File) => void
  onFileOpenClick?: (file: File) => void
  onFilePreviewClick?: (file: File) => void
  onFileSingleClick?: (file: File) => void
  onFileDoubleClick?: (file: File) => void
  onFileMiddleClick?: (file: File) => void
  onFolderAddClick?: (file: File) => void
  onFolderOpenClick?: (file: File) => void
  onFolderSingleClick?: (file: File) => void
  onFolderDoubleClick?: (file: File) => void
  onFolderMiddleClick?: (file: File) => void
  viewMode?: ViewMode
  setViewMode?: Dispatch<SetStateAction<ViewMode>>
  handleMoveFilesAndFolders?: (options: {
    folderIds: number[]
    filedIds: number[]
    originalFolderId: number
    targetFolderId: number
  }) => void
  handleFolderDelete?: (folder: File) => void
  handleFolderEdit?: (folder: File) => void
  doFileSelect?: boolean
  setDoFileSelect?: (value: boolean) => void
  setIsUnselecting?: (value: boolean) => void
  search?: string
  setSearch?: (val: string) => void
  searchPlaceholder?: string
  maxHeight?: string
  noSelection?: boolean
  noMultiSelection?: boolean
  noBackground?: boolean
  noAlphabeticalSort?: boolean
  noFolders?: boolean
  setNoFolders?: (val: boolean) => void
  showFileCount?: boolean
}) => {
  const { t } = useTranslation()
  const ref = useRef()
  useClickOutside(ref, () => {
    unselectAll()
  })

  //
  // SORT FILES
  //
  const sortFiles = (a: File, b: File) => {
    /* NOTE: DO NOT SEPARATE THIS TO INDIVIDUAL FUNCTIONS, FIREFOX DOESN'T WORK */

    if (a.isParent) {
      // parent link is always first
      return -1
    }
    if (b.isParent) {
      return 1
    }

    if (a.type === b.type) {
      // same file types > alphabetical
      if (props.noAlphabeticalSort) {
        return 0
      }
      return a.name < b.name ? -1 : 1
    }

    if (a.type === 'folder') {
      // otherwise put folders before files
      return -1
    }
    return 1
  }

  //
  // FILES
  //
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    /* Reset files to ensure css animations */
    setFiles([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files])

  useEffect(() => {
    if (!files?.length && props.files) {
      if (
        !props.noFolders &&
        (props.currentParentId || props.currentParentId === 0)
      ) {
        const parentOfParentId =
          props.getParentIdOfParentId &&
          props.getParentIdOfParentId(props.currentParentId)

        const parentFolderLink: File = {
          id: props.currentParentId,
          parentId: parentOfParentId,
          type: 'folder',
          name: t('file_system.parent_folder'),
          isParent: true,
        }

        setFiles([parentFolderLink, ...props.files])
      } else {
        setFiles(props.files)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, props.files])

  //
  // SELECTED FILES
  //
  const [selectedFolderIds, setSelectedFolderIds] = useState<any[]>([])
  const [selectedFileIds, setSelectedFileIds] = useState<any[]>([])

  useEffect(() => {
    if (props.setIsUnselecting) {
      const isUnselecting =
        [...selectedFileIds, ...selectedFolderIds].length > 0
      props.setIsUnselecting(isUnselecting)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderIds, selectedFileIds])

  const getSelectedFileCount = () => {
    return (selectedFolderIds?.length || 0) + (selectedFileIds?.length || 0)
  }

  const isSelected = (file: File) => {
    if (file.type === 'folder') {
      return selectedFolderIds.includes(file.id)
    } else {
      return selectedFileIds.includes(file.id)
    }
  }

  const selectFile = (file: File) => {
    if (props.noSelection) {
      return
    }

    let updatedSelectedFolderIds = props.noMultiSelection
      ? []
      : [...selectedFolderIds]
    let updatedSelectedFileIds = props.noMultiSelection
      ? []
      : [...selectedFileIds]

    if (file.type === 'folder') {
      if (!selectedFolderIds.includes(file.id)) {
        updatedSelectedFolderIds = [...updatedSelectedFolderIds, file.id]
      }
    } else {
      if (!selectedFileIds.includes(file.id)) {
        updatedSelectedFileIds = [...updatedSelectedFileIds, file.id]
      }
    }

    setSelectedFolderIds(updatedSelectedFolderIds)
    setSelectedFileIds(updatedSelectedFileIds)
  }

  const unselectFile = (file: File) => {
    if (props.noSelection) {
      return
    }
    if (file.type === 'folder') {
      setSelectedFolderIds(
        [...selectedFolderIds].filter((id) => id !== file.id)
      )
    } else {
      setSelectedFileIds([...selectedFileIds].filter((id) => id !== file.id))
    }
  }

  const selectAll = () => {
    if (props.noSelection) {
      return
    }
    const folders = props.files.filter((f) => f.type === 'folder')
    const files = props.files.filter((f) => f.type === 'file')
    setSelectedFolderIds([...folders.map((file) => file.id)])
    setSelectedFileIds([...files.map((file) => file.id)])
  }

  const unselectAll = () => {
    if (props.noSelection) {
      return
    }
    setSelectedFileIds([])
    setSelectedFolderIds([])
  }

  const onFileHover = (file: File) => {
    if (isMouseDown && !props.noSelection && !props.noMultiSelection) {
      selectFile(file)
    }
  }

  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)

  const onFileMouseDown = (e) => {
    if (e.button === 0) {
      setIsMouseDown(true)
    }
  }
  const onFileMouseUp = (e) => {
    if (e.button === 0) {
      setIsMouseDown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', onFileMouseDown)
    window.addEventListener('mouseup', onFileMouseUp)
    return () => {
      window.removeEventListener('mousedown', onFileMouseDown)
      window.removeEventListener('mouseup', onFileMouseUp)
    }
  }, [])

  //
  // CLICK HANDLERS
  //
  const onSingleClick = (file: File) => {
    if (file.type === 'file' && props.onFileSingleClick) {
      return props.onFileSingleClick(file)
    }
    if (file.type === 'folder' && props.onFolderSingleClick) {
      return props.onFolderSingleClick(file)
    }
    if (
      (file.type === 'folder' ? selectedFolderIds : selectedFileIds).includes(
        file.id
      )
    ) {
      unselectFile(file)
    } else {
      selectFile(file)
    }
  }

  const onDoubleClick = (file: File) => {
    unselectAll()
    if (file.type === 'file' && props.onFileDoubleClick) {
      return props.onFileDoubleClick(file)
    }
    if (file.type === 'folder' && props.onFolderDoubleClick) {
      return props.onFolderDoubleClick(file)
    }
    if (file.type === 'folder') {
      props.onFolderDoubleClick(file)
    } else {
      props.onFileDoubleClick(file)
    }
  }

  const onFileMiddleClick = (file: File) => {
    props.onFileMiddleClick && props.onFileMiddleClick(file)
  }

  const onFolderMiddleClick = (file: File) => {
    props.onFolderMiddleClick && props.onFolderMiddleClick(file)
  }

  const onMoveFilesClick = (targetFolder: File) => {
    if (props.handleMoveFilesAndFolders && targetFolder.type === 'folder') {
      props.handleMoveFilesAndFolders({
        folderIds: selectedFolderIds,
        filedIds: selectedFileIds,
        originalFolderId: props.currentFolderId,
        targetFolderId: targetFolder.id,
      })
      unselectAll()
    }
  }

  const onFolderDeleteClick = (folder: File) => {
    if (props.handleFolderDelete) {
      props.handleFolderDelete(folder)
      unselectAll()
    }
  }

  const onFolderEditClick = (folder: File) => {
    if (props.handleFolderEdit) {
      inputAction({
        title: t('file_system.edit_folder.title'),
        cancelText: t('file_system.edit_folder.no'),
        okText: t('file_system.edit_folder.yes'),
        inputNames: [
          t('file_system.create_folder.name'),
          t('file_system.create_folder.name2'),
        ],
        defaultValues: [folder.name, folder.name2],
        onSubmit: (values: string[]) => {
          const updatedFolder: File = {
            ...folder,
            name: values[0],
            name2: values[1] && values[1] !== '' ? values[1] : undefined,
          }
          props.handleFolderEdit(updatedFolder)
        },
      })
    }
  }

  //
  // INVOKE FUNCTIONS (to be accessible from elsewhere in the app)
  //
  useEffect(() => {
    if (props.doFileSelect) {
      const isUnselecting =
        [...selectedFileIds, ...selectedFolderIds].length > 0
      if (isUnselecting) {
        unselectAll()
      } else {
        selectAll()
      }
      props.setDoFileSelect(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.doFileSelect])

  //
  // RENDER
  //
  return (
    <Wrapper ref={ref}>
      <Header>
        <FileSystemIconButton
          onClick={() => props.setViewMode('grid')}
          isActive={props.viewMode === 'grid'}
          hint={t('file_system.grid_view')}
          icon={<SvgIcon code="icon-file-system-grid" />}
        />

        <FileSystemIconButton
          onClick={() => props.setViewMode('list')}
          isActive={props.viewMode === 'list'}
          hint={t('file_system.list_view')}
          icon={<SvgIcon code="icon-file-system-list" />}
        />

        {props.setNoFolders && (
          <FileSystemIconButton
            onClick={() => props.setNoFolders(!props.noFolders)}
            isActive={props.noFolders}
            hint={t('file_system.no_folders_view')}
            icon={<SvgIcon code="icon-file-system-no-folders" />}
          />
        )}

        {props.setSearch && (
          <SearchBar>
            <StringInput
              placeholder={props.searchPlaceholder || ''}
              value={props.search}
              setValue={props.setSearch}
              liveUpdate
            />
            {props.search?.length > 0 ? (
              <SearchIconWrapper
                style={{ cursor: 'pointer' }}
                onClick={() => props.setSearch('')}
              >
                <SvgIcon code="icon-close" />
              </SearchIconWrapper>
            ) : (
              <SearchIconWrapper>
                <SvgIcon code="icon-search" />
              </SearchIconWrapper>
            )}
          </SearchBar>
        )}
      </Header>

      <Breadcrumbs
        folders={props.folderBreadcrumbs}
        handleGoToFolder={(folder) =>
          props.onFolderDoubleClick && props.onFolderDoubleClick(folder)
        }
        onMoveFilesClick={onMoveFilesClick}
        onFolderMiddleClick={onFolderMiddleClick}
        selectedFileCount={getSelectedFileCount()}
        noFolders={props.noFolders}
      />

      <FileArray
        viewMode={props.viewMode}
        maxHeight={props.maxHeight}
        noBackground={props.noBackground}
      >
        {props.showFileCount && (
          <FileCount>
            {`${selectedFileIds?.length ? `${selectedFileIds.length}/` : ''}${
              files.filter((f) => f.type === 'file').length
            }`}
          </FileCount>
        )}
        {[...files].sort(sortFiles).map((file, i) => (
          <FileItem
            key={`${file.type}_${file.id}`}
            index={i}
            viewMode={props.viewMode}
            id={file.id}
            type={file.type}
            name={file.name}
            name2={file.name2}
            icon={file.icon}
            image={file.image}
            renderCustomIcon={file.renderCustomIcon}
            renderCustomOverlay={file.renderCustomOverlay}
            customBadges={file.customBadges}
            customBadgesTop={file.customBadgesTop}
            onClick={() => onSingleClick(file)}
            onDoubleClick={() => onDoubleClick(file)}
            onMoveFilesClick={() => onMoveFilesClick(file)}
            onFileAddClick={
              props.onFileAddClick
                ? () => props.onFileAddClick(file)
                : undefined
            }
            onFileOpenClick={
              props.onFileOpenClick
                ? () => props.onFileOpenClick(file)
                : undefined
            }
            onFilePreviewClick={
              props.onFilePreviewClick
                ? () => props.onFilePreviewClick(file)
                : undefined
            }
            onFileMiddleClick={() => onFileMiddleClick(file)}
            onFolderAddClick={
              props.onFolderAddClick
                ? () => props.onFolderAddClick(file)
                : undefined
            }
            onFolderOpenClick={
              props.onFolderOpenClick
                ? () => props.onFolderOpenClick(file)
                : undefined
            }
            onFolderDeleteClick={
              props.handleFolderDelete
                ? () => onFolderDeleteClick(file)
                : undefined
            }
            onFolderEditClick={
              props.handleFolderEdit ? () => onFolderEditClick(file) : undefined
            }
            onFolderMiddleClick={() => onFolderMiddleClick(file)}
            onHover={() => onFileHover(file)}
            selectedFileCount={getSelectedFileCount()}
            isSelected={isSelected(file)}
            isParent={file.isParent}
            isPrivate={file.isPrivate}
          />
        ))}
      </FileArray>
    </Wrapper>
  )
}

export default FileSystem
