import { DBSongType, SongCategoryType } from 'types'
import { File, ViewMode } from 'components/Files/FileSystem'
import FileSystemSongs, {
  ROOT_CATEGORY_ID,
} from 'components/Files/FileSystemSongs'

import { ROUTES } from 'const'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const FileSystemSongsWithNavigation = (props: {
  onFilePreviewClick?: (file: File) => void
  onFileSingleClick?: (file: File) => void
  onFileDoubleClick?: (file: File) => void
  onFileAddClick?: (file: File) => void
  onFolderAddClick?: (file: File) => void
  maxHeight?: string
  defaultViewMode?: ViewMode
  preloadedSongs?: DBSongType[]
  preloadedSongCategories?: SongCategoryType[]
  noSearch?: boolean
  noFolders?: boolean
  noSelection?: boolean
  noMultiSelection?: boolean
  noBackground?: boolean
  noAlphabeticalSort?: boolean
  noFileOpenOnClickIcon?: boolean
  showFileCount?: boolean
  doNotStoreAnyData?: boolean
}) => {
  const history = useHistory()

  //
  // STATE
  //
  const [songCategories, setSongCategories] = useState<SongCategoryType[]>()
  const [viewMode, setViewMode] = useState<ViewMode>(
    props.defaultViewMode || 'grid'
  )
  const [noFolders, setNoFolders] = useState<boolean>(props.noFolders || false)

  //
  // DELEGATED FUNCTIONS
  //
  const [doCreateFolder, setDoCreateFolder] =
    useState<{ folder: File; parentId?: number }>(undefined)

  //
  // NAVIGATION
  //
  const [parentIdParam, setParentIdParam] = useState('')
  const [folderIdParam, setFolderIdParam] = useState(`${ROOT_CATEGORY_ID}`)

  const onFolderDoubleClick = (folder: File) => {
    setParentIdParam(folder.parentId as any)
    setFolderIdParam(folder.id as any)
  }

  const onFileOpenClick = (file: File) => {
    history.push(
      `${ROUTES.songs.slug}?${new URLSearchParams({
        song: file.id as any,
      }).toString()}`,
      { onBackOnClose: true }
    )
  }

  //
  // RENDER
  //
  return (
    <FileSystemSongs
      songCategories={songCategories}
      setSongCategories={setSongCategories}
      doCreateFolder={doCreateFolder}
      setDoCreateFolder={setDoCreateFolder}
      folderId={folderIdParam}
      parentId={parentIdParam}
      onFileAddClick={props.onFileAddClick}
      onFileOpenClick={!props.noFileOpenOnClickIcon && onFileOpenClick}
      onFilePreviewClick={props.onFilePreviewClick}
      onFileSingleClick={props.onFileSingleClick}
      onFileDoubleClick={props.onFileDoubleClick}
      onFolderAddClick={props.onFolderAddClick}
      onFolderDoubleClick={onFolderDoubleClick}
      maxHeight={props.maxHeight}
      viewMode={viewMode}
      setViewMode={setViewMode}
      preloadedSongs={props.preloadedSongs}
      preloadedSongCategories={props.preloadedSongCategories}
      noSearch={props.noSearch}
      noFolders={noFolders}
      setNoFolders={props.noFolders ? undefined : setNoFolders}
      noSelection={props.noSelection}
      noMultiSelection={props.noMultiSelection}
      noBackground={props.noBackground}
      noAlphabeticalSort={props.noAlphabeticalSort}
      showFileCount={props.showFileCount}
      doNotStoreAnyData={props.doNotStoreAnyData}
    />
  )
}

export default FileSystemSongsWithNavigation
