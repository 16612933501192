import {
  DBSongType,
  PlaylistMemberType,
  PlaylistSongType,
  SongType,
} from 'types'
import { forwardRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import { File } from 'components/Files/FileSystem'
import FileSystemSongsWithNavigation from 'components/Files/FileSystemSongsWithNavigation'
import FlipMove from 'react-flip-move'
import { GET_SONG } from 'api/graphql'
import PlaylistSong from '../PlaylistSong'
import SvgIcon from 'components/SvgIcon'
import { getPlaylistSongUserSettings } from 'utils'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Sections = styled.div`
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`

/* const animLoading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`*/

/* const LoadingSong = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 42px;
  font-size: 14px;
  padding-left: 10px;

  svg {
    height: 30px;
    animation: ${animLoading} 1s infinite linear;
    color: ${(props) => props.theme.primary_light};
  }
` */

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const AddSongWrapper = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.2s;
  animation-fill-mode: backwards;

  align-self: stretch;
  flex: 1;
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      playlistSong
      playlistSongs
      setPlaylistSongs
      playlistMembers
      setOpenBox
      openBoxMap
      forcedClosed
      onDeleteCallback
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <PlaylistSong
          playlistSong={props.playlistSong}
          playlistSongs={props.playlistSongs}
          setPlaylistSongs={props.setPlaylistSongs}
          playlistMembers={props.playlistMembers}
          setOpenBox={(isOpen) =>
            props.setOpenBox(props.playlistSong.songId, isOpen)
          }
          forcedIsOpen={
            typeof props.forcedClosed === 'boolean'
              ? props.forcedClosed
              : props.openBoxMap.find((s) => s.id === props.playlistSong.songId)
                  ?.isOpen
          }
          onDeleteCallback={props.onDeleteCallback}
        />
      </div>
    )
  }
)

/**
 *
 *  UTILS, TYPES
 *
 */
const PlaylistSongs = (props: {
  playlistSongs: PlaylistSongType[]
  setPlaylistSongs: (playlistSongs: PlaylistSongType[]) => void
  playlistMembers: PlaylistMemberType[]
  isLoadingNewSong?: boolean
  setIsLoadingNewSong?: (val: boolean) => void
}) => {
  const { t } = useTranslation()

  const [isAddFocused, setIsAddFocused] = useState<boolean>(false)

  //
  // CONTENT BOX OPEN MAP (animations destroy the original functionality)
  //
  const [openBoxMap, setOpenBoxMap] = useState<
    { id: number; isOpen: boolean }[]
  >(
    props.playlistSongs.map((s) => {
      return { id: s.songId, isOpen: false }
    })
  )

  const setOpenBox = (sectionId, isOpen) => {
    const updatedMap = [...openBoxMap]
    const i = updatedMap.map((s) => s.id).indexOf(sectionId)
    if (i >= 0) {
      updatedMap[i].isOpen = isOpen
    } else {
      // for newly added sections
      updatedMap.push({ id: sectionId, isOpen: false })
    }
    setOpenBoxMap(updatedMap)
  }

  //
  // FETCH SELECTED SONG
  //
  const [fetchSongId, setFetchSongId] = useState<number>()

  const {
    data: fetchedSongData,
    refetch: refetchSongData,
    loading: loadingNewSong,
  } = useQuery(GET_SONG, {
    variables: { id: fetchSongId },
    skip: !fetchSongId,
  })

  useEffect(() => {
    props.setIsLoadingNewSong && props.setIsLoadingNewSong(loadingNewSong)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingNewSong])

  useEffect(() => {
    if (fetchedSongData) {
      refetchSongData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSongId])

  useEffect(() => {
    const song = fetchedSongData?.getSong
    if (song && props.playlistSongs.map((ps) => ps.songId).includes(song.id)) {
      toast.info(t('playlist.song already in playlist'))
      return
    }
    if (song) {
      addSongToPlaylist(song)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSongData])

  //
  // ADD SONG TO PLAYLIST
  //
  const addSongToPlaylist = (song: DBSongType) => {
    const songData: SongType = song?.body ? JSON.parse(song?.body) : null
    const userSettings = getPlaylistSongUserSettings()
    props.setPlaylistSongs([
      ...(props.playlistSongs || []),
      {
        songId: song.id,
        songData: songData,
        capoOffset: 0,
        userSettings: [userSettings],
      },
    ])
    toast.success(t('playlist.song added'))
  }

  const onSongDoubleClick = (file: File) => {
    if (file.id === fetchSongId) {
      toast.info(t('playlist.song already in playlist'))
      return
    }
    setFetchSongId(file.id)
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Sections>
        <FlipMove
          easing="ease"
          duration={300} // milliseconds
          staggerDurationBy={150} // milliseconds
          staggerDelayBy={60} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: { transform: 'scale(0)' },
            to: { transform: 'none' },
          }}
          enterAnimation={{
            from: { transform: 'scaleY(0)', transformOrigin: '50% 0' },
            to: { transform: 'none' },
          }}
          leaveAnimation={{
            from: { transform: 'none', transformOrigin: '50% 0' },
            to: { transform: 'scaleY(0)' },
          }}
        >
          {props.playlistSongs?.map((playlistSong, i) => (
            <AnimatedItem
              key={playlistSong.songId}
              playlistSong={playlistSong}
              playlistSongs={props.playlistSongs}
              setPlaylistSongs={props.setPlaylistSongs}
              playlistMembers={props.playlistMembers}
              setOpenBox={setOpenBox}
              openBoxMap={openBoxMap}
              /* close newly added song: */
              forcedClosed={props.playlistSongs?.length === i}
              onDeleteCallback={() => setFetchSongId(undefined)}
            />
          ))}

          {/* {props.isLoadingNewSong && (
            <LoadingSong>
              <Loader4 />
              <span>{t('playlist.loading new song')}</span>
            </LoadingSong>
          )} */}
        </FlipMove>
      </Sections>

      {props.playlistSongs.length === 0 && !props.isLoadingNewSong && (
        <h3>{t('playlist.no songs')}</h3>
      )}

      <ButtonWrapper>
        {isAddFocused ? (
          <>
            <AddSongWrapper>
              <FileSystemSongsWithNavigation
                onFileDoubleClick={onSongDoubleClick}
                onFileAddClick={onSongDoubleClick}
                maxHeight="40vh"
                defaultViewMode="grid"
                noFileOpenOnClickIcon
                noMultiSelection
              />
            </AddSongWrapper>
            <button
              onClick={() => setIsAddFocused(false)}
              title={t('playlist.close add song')}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-close" />
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsAddFocused(true)}
            title={t('playlist.add song')}
            className="btn-action-circular"
          >
            <SvgIcon code="icon-add" />
          </button>
        )}
      </ButtonWrapper>
    </Wrapper>
  )
}

export default PlaylistSongs
