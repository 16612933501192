/* eslint-disable react-hooks/exhaustive-deps */
import { DBSongType, PlaylistBodyType, UserGroupType } from 'types'
import {
  DELETE_USER_GROUP,
  GET_PLAYLISTS,
  GET_SONGS,
  GET_USERS,
  GET_USER_GROUPS,
  GET_USER_GROUP_DELETE_CHECK,
} from 'api/graphql'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import FloatingButton from 'components/App/FloatingMenu/FloatingButton'
import { ROUTES } from 'const'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import { hasGroupPermission } from 'utils'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ButtonDelete = styled.button`
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  padding-right: 25px;
  align-self: center;
  background: ${(props) => props.theme.warning} !important;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const UserGroupDeleteButton = (props: {
  userGroup: UserGroupType
  isFloatingButton?: boolean
  isVisible?: boolean
  isIndented?: boolean
  noShadow?: boolean
  tooltipShortIsVisible?: boolean
  index?: number
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const getAllowDeleteGroup = () =>
    hasGroupPermission(props.userGroup, 'GR_deleteGroup')

  //
  // CHECK BEFORE DELETE
  //
  const [checkBeforeDelete, setCheckBeforeDelete] = useState<boolean>()
  const [deleteData, setDeleteData] = useState<{
    songsToDelete: DBSongType[]
    playlistsToDelete: PlaylistBodyType[]
  }>()

  const { refetch: refetchDeleteCheck, data: deleteCheck } = useQuery(
    GET_USER_GROUP_DELETE_CHECK,
    {
      variables: {
        userGroupId: props.userGroup?.id,
      },
      skip: !checkBeforeDelete || !props.userGroup,
    }
  )

  useEffect(() => {
    if (checkBeforeDelete) {
      refetchDeleteCheck()
    }
  }, [checkBeforeDelete])

  useEffect(() => {
    if (deleteCheck?.getUserGroupDeleteCheck) {
      setDeleteData({
        songsToDelete: deleteCheck?.getUserGroupDeleteCheck?.songsToDelete,
        playlistsToDelete:
          deleteCheck?.getUserGroupDeleteCheck?.playlistsToDelete,
      })
    }
  }, [deleteCheck])

  useEffect(() => {
    if (checkBeforeDelete && deleteCheck) {
      safelyDeleteUserGroup(props.userGroup, deleteData)
    }
  }, [deleteData])

  //
  // DELETE USER GROUP
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUsers } =
    useQuery(GET_USERS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedSongs } =
    useQuery(GET_SONGS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedPlaylists, refetch: refetchPlaylists } =
    useQuery(GET_PLAYLISTS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUserGroups } =
    useQuery(GET_USER_GROUPS) /* NECESSARY FOR REFETCH */

  const [deleteUserGroup, { data: delData }] = useMutation(DELETE_USER_GROUP, {
    refetchQueries: [GET_USER_GROUPS, GET_SONGS],
  })

  useEffect(() => {
    if (delData?.deleteUserGroup) {
      if (delData?.deleteUserGroup?.success) {
        refetchPlaylists()
        toast.success(t(delData?.deleteUserGroup?.message))
        history.push(ROUTES.users.slug)
      } else {
        toast.error(t(delData?.deleteUserGroup?.message))
      }
    }
  }, [delData])

  //
  // SAFELY DELETE USER GROUP
  //
  const safelyDeleteUserGroup = (
    userGroup: UserGroupType,
    deleteData: {
      songsToDelete: DBSongType[]
      playlistsToDelete: PlaylistBodyType[]
    }
  ) => {
    const songNames = deleteData.songsToDelete
      .map((s) => `'${s.name}'`)
      .join('\n')

    const playlistNames = deleteData.playlistsToDelete
      .map((s) => `'${s.name}'`)
      .join('\n')

    confirmedAction({
      title: t('user group.delete.title'),
      description: t('user group.delete.description').replace(
        '{GROUP}',
        userGroup.name
      ),
      descriptionWarning: [
        deleteData.songsToDelete.length &&
          t('user group.delete.description songs').replace(
            '{SONGS}',
            songNames
          ),

        deleteData.playlistsToDelete.length &&
          t('user group.delete.description playlists').replace(
            '{PLAYLISTS}',
            playlistNames
          ),
      ],

      okText: deleteData.songsToDelete.length
        ? t('user group.delete.yes with songs')
        : t('user group.delete.yes'),
      cancelText: t('user group.delete.no'),
      onSubmit: () => {
        deleteUserGroup({ variables: { id: userGroup.id } })
      },
    })

    setCheckBeforeDelete(undefined)
    setDeleteData(undefined)
  }

  //
  // BUTTON CLICK
  //
  const onDeleteClick = () => {
    setCheckBeforeDelete(true)
  }

  //
  // RENDER
  //
  const renderButton = () => {
    if (props.isFloatingButton) {
      return (
        <FloatingButton
          button={{
            onClick: onDeleteClick,
            tooltip: t('user group.delete.label'),
            tooltipShort: t('user group.delete.label short'),
            tooltipShortIsVisible: props.tooltipShortIsVisible,
            icon: <SvgIcon code="icon-delete" />,
            color: 'warning',
            isVisible: props.isVisible,
            isIndented: props.isIndented,
          }}
          noShadow={props.noShadow}
          index={props.index}
        />
      )
    }
    return (
      <ButtonDelete className="btn-primary" onClick={onDeleteClick}>
        <SvgIcon code="icon-delete" /> {t('user group.delete.label')}
      </ButtonDelete>
    )
  }

  return getAllowDeleteGroup() ? renderButton() : null
}

export default UserGroupDeleteButton
