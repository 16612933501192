import ChordLibrarySection from '../ChordLibrarySection'
import { SCLChordsByRootType } from 'types'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ChordLibrary = (props: {
  tuning: string[]
  chordsByRoot: SCLChordsByRootType
}) => {
  return (
    <Wrapper>
      {Object.entries(props.chordsByRoot).map(([root, chords]) => (
        <ChordLibrarySection
          key={root}
          tuning={props.tuning}
          root={root}
          chords={chords}
        />
      ))}
    </Wrapper>
  )
}

export default ChordLibrary
