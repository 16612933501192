import { UserGroupMemberType, UserGroupType } from 'types'

import FlipMove from 'react-flip-move'
import { LOGGED_USER_VAR } from 'App'
import { USER_GROUP_ROLES } from 'const'
import UserGroupMember from '../UserGroupMember'
import confirmedAction from 'modals/actions/confirmedAction'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  & > * {
    background: ${(props) => props.theme.background};
  }
`

const Members = styled.div`
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 15px;
    margin-top: 20px;
  }
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      member
      userGroup
      deleteMember
      updateMemberRole
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <UserGroupMember
          member={props.member}
          userGroup={props.userGroup}
          deleteMember={props.deleteMember}
          updateMemberRole={props.updateMemberRole}
        />
      </div>
    )
  }
)
/**
 *
 * COMPONENT
 *
 */
const UserGroupMembersList = (props: {
  userGroup: UserGroupType
  setUserGroup?: (userGroup: UserGroupType) => void
  onDeleteCallback?: () => void
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  //
  // MEMBERS
  //
  const getRemainingAdmins = (excludeMember: UserGroupMemberType) => {
    const remainingAdmins = props.userGroup.userGroupMembers.filter(
      (m) =>
        m.user.id !== excludeMember?.user?.id &&
        m.groupRole?.code === USER_GROUP_ROLES.admin
    )
    return remainingAdmins
  }

  const updateMember = (updatedMember: UserGroupMemberType) => {
    props.setUserGroup({
      ...props.userGroup,
      userGroupMembers: [...props.userGroup.userGroupMembers].map((m) =>
        m.user.id === updatedMember.user.id ? updatedMember : m
      ),
    })
  }

  const updateMemberRole = (member: UserGroupMemberType, roleCode: string) => {
    updateMember({
      ...member,
      groupRole: { code: roleCode },
    })
  }

  const safelyUpdateMemberRole = (
    member: UserGroupMemberType,
    roleCode: string
  ) => {
    const remainingAdmins = getRemainingAdmins(member)
    if (!remainingAdmins.length && roleCode !== USER_GROUP_ROLES.admin) {
      confirmedAction({
        title: t('user group.giving up last admin.title'),
        description: t('user group.giving up last admin.description'),
        okText: t('user group.giving up last admin.ok'),
      })
    } else if (
      member.user.id === loggedUser.id &&
      member.groupRole.code === USER_GROUP_ROLES.admin &&
      roleCode !== USER_GROUP_ROLES.admin
    ) {
      confirmedAction({
        title: t('user group.giving up admin.title'),
        description: t('user group.giving up admin.description'),
        okText: t('user group.giving up admin.yes'),
        cancelText: t('user group.giving up admin.no'),
        onSubmit: () => updateMemberRole(member, roleCode),
      })
    } else {
      updateMemberRole(member, roleCode)
    }
  }

  const deleteMember = (memberToDelete: UserGroupMemberType) => {
    props.setUserGroup({
      ...props.userGroup,
      userGroupMembers: [...props.userGroup.userGroupMembers].filter(
        (m) => m.user.id !== memberToDelete.user.id
      ),
    })
    props.onDeleteCallback && props.onDeleteCallback()
  }

  const safelyDeleteMember = (memberToDelete: UserGroupMemberType) => {
    const isDeletingSelf = loggedUser?.id === memberToDelete?.user?.id
    const remainingAdmins = getRemainingAdmins(memberToDelete)

    if (isDeletingSelf && !remainingAdmins.length) {
      confirmedAction({
        title: t('user group.leaving last admin.title'),
        description: t('user group.leaving last admin.description'),
        okText: t('user group.leaving last admin.ok'),
      })
    } else if (isDeletingSelf) {
      confirmedAction({
        title: t('user group.leaving.title'),
        description: t('user group.leaving.description'),
        okText: t('user group.leaving.yes'),
        cancelText: t('user group.leaving.no'),
        onSubmit: () => deleteMember(memberToDelete),
      })
    } else if (!remainingAdmins.length) {
      confirmedAction({
        title: t('user group.removing last admin.title'),
        description: t('user group.removing last admin.description').replace(
          '{USER}',
          memberToDelete?.user?.name
        ),
        okText: t('user group.removing last admin.ok'),
      })
    } else {
      confirmedAction({
        title: t('user group.removing member.title'),
        description: t('user group.removing member.description').replace(
          '{USER}',
          memberToDelete?.user?.name
        ),
        okText: t('user group.removing member.yes'),
        cancelText: t('user group.removing member.no'),
        onSubmit: () => deleteMember(memberToDelete),
      })
    }
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Members>
        <FlipMove
          easing="ease"
          duration={200} // milliseconds
          staggerDurationBy={80} // milliseconds
          staggerDelayBy={100} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: {
              opacity: '0',
              transform: 'scale(0.4)',
              transformOrigin: '50% 50%',
            },
            to: { opacity: '1', transform: 'none' },
          }}
        >
          {props.userGroup?.userGroupMembers?.map(
            (member: UserGroupMemberType) => (
              <AnimatedItem
                key={member.user?.id || `gr_${member?.id}`}
                member={member}
                userGroup={props.userGroup}
                updateMemberRole={safelyUpdateMemberRole}
                deleteMember={safelyDeleteMember}
              />
            )
          )}
        </FlipMove>
      </Members>
    </Wrapper>
  )
}

export default UserGroupMembersList
