import styled from 'styled-components'

const PADDING = '3%'
const MIN_PADDING = '20px'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Name = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.black}88;
  background: ${props => props.theme.black}11;
  height: 28px;
  border-radius: 2px;

  svg {
    color: ${props => props.theme.black}33;
    height: 14px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 10;

  flex: 1;
  flex-direction: column;
  gap: 20px;

  & > * {
    flex: 1;
    --max-container-width: calc(100% - 2 * calc(max(${PADDING}, ${MIN_PADDING})))
    min-width: calc(min(max(50% - 20px, 200px), var(--max-container-width)));
    max-width: var(--max-container-width);
  }

  select {
    padding-left: 6px;
  }
  textarea {
    font-family: inherit;
    padding: 7px 10px;
    height: 20px;
    min-height: 20px;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const SectionBox = (props: { name?: string; icon?: any; children?: any }) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      {(props.name || props.icon) && (
        <Name>
          {props.icon && props.icon}
          {props.name && `${props.name}:`}
        </Name>
      )}
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

export default SectionBox
