import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'

import InputLabel from 'components/Inputs/_InputLabel'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 100%;
  }
`

const TextArea = styled.textarea<{
  isSongFont?: boolean
  isResizable?: boolean
  placeholder?: string
  height?: string
}>`
  ${defaultInputCss}
  padding: 10px;
  flex-grow: 1;
  resize: none;
  overflow-x: hidden;
  white-space: pre-wrap;

  cursor: auto;
  color: ${(props) => props.theme.black};

  text-align: left;
  font-family: ${(props) => (props.isSongFont ? 'Roboto Mono' : 'Inconsolata')},
    monospace;

  &:focus {
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.black}0A;
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `};

  ${({ isResizable }) =>
    isResizable &&
    css`
      resize: vertical;
    `};

  // PLACEHOLDER
  &::placeholder {
    ${({ placeholder }) =>
      placeholder &&
      css`
        content: '${placeholder}';
      `};
  }
`

const TextInput = (props: {
  label?: string
  placeholder?: string
  value: string | null
  setValue: (value: any, event?: any) => void
  height?: string
  isResizable?: boolean
  isMultiline?: boolean
  isSongFont?: boolean
  disabled?: boolean
  liveUpdate?: boolean
}) => {
  //
  // LOCAL VALUE (FOR onBlur UPDATE)
  //
  const [isInit, setIsInit] = useState<boolean>(true)
  const [localValue, setLocalValue] = useState<string>('')

  useEffect(() => {
    if (!props.liveUpdate && isInit) {
      setLocalValue(props.value)
      setIsInit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}

      {!props.liveUpdate ? (
        <TextArea
          value={(localValue?.length && localValue) || ''}
          onChange={(event) => {
            setLocalValue(event.target.value)
          }}
          onBlur={(event) => {
            props.setValue(localValue, event)
          }}
          placeholder={props.placeholder}
          isResizable={props.isResizable}
          isSongFont={props.isSongFont}
          height={props.height}
          onKeyDown={
            props.isMultiline
              ? null
              : (event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault()
                  }
                }
          }
          disabled={props.disabled}
        />
      ) : (
        <TextArea
          value={props.value || ''}
          onChange={(event) => props.setValue(event.target.value, event)}
          placeholder={props.placeholder}
          isResizable={props.isResizable}
          isSongFont={props.isSongFont}
          height={props.height}
          onKeyDown={
            props.isMultiline
              ? null
              : (event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault()
                  }
                }
          }
          disabled={props.disabled}
        />
      )}
    </Wrapper>
  )
}

export default TextInput
