import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
} from 'types'
import { forwardRef, useState } from 'react'

import FlipMove from 'react-flip-move'
import LyricSection from 'components/Song/SongEdit/SongEditor/Lyrics/LyricSection'
import SvgIcon from 'components/SvgIcon'
import { addLyricSectionToSong } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Sections = styled.div`
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const LyricSectionWrapper = styled.div<{ isSubMenuOpen }>`
  z-index: ${(props) => (props.isSubMenuOpen ? 2 : 1)};
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      generalInfo
      chords
      lyricSections
      setLyricSections
      lyricSection
      compositionSections
      setCompositionSections
      setOpenBox
      openBoxMap
    },
    ref
  ) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false)

    return (
      <LyricSectionWrapper
        ref={ref as any}
        style={{ overflowAnchor: 'none' }}
        isSubMenuOpen={isSubMenuOpen}
      >
        <LyricSection
          generalInfo={props.generalInfo}
          chords={props.chords}
          lyricSections={props.lyricSections}
          setLyricSections={props.setLyricSections}
          lyricSection={props.lyricSection}
          compositionSections={props.compositionSections}
          setCompositionSections={props.setCompositionSections}
          setOpenBox={(isOpen) =>
            props.setOpenBox(props.lyricSection.id, isOpen)
          }
          forcedIsOpen={
            props.openBoxMap.find((s) => s.id === props.lyricSection.id)?.isOpen
          }
          onSubMenuToggle={(value) => setIsSubMenuOpen(value)}
        />
      </LyricSectionWrapper>
    )
  }
)

/**
 *
 *  UTILS, TYPES
 *
 */
const LyricSections = (props: {
  generalInfo: SongGeneralInfoType
  chords: SongChordType[]
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
}) => {
  const { t } = useTranslation()

  //
  // CONTENT BOX OPEN MAP (animations destroy the original functionality)
  //
  const [openBoxMap, setOpenBoxMap] = useState<
    { id: number; isOpen: boolean }[]
  >(
    props.lyricSections.map((s) => {
      return { id: s.id, isOpen: false }
    })
  )

  const setOpenBox = (lyricSectionId, isOpen) => {
    const updatedMap = [...openBoxMap]
    const i = updatedMap.map((s) => s.id).indexOf(lyricSectionId)
    if (i >= 0) {
      updatedMap[i].isOpen = isOpen
    } else {
      // for newly added sections
      updatedMap.push({ id: lyricSectionId, isOpen })
    }
    setOpenBoxMap(updatedMap)
  }

  //
  // UTILS
  //
  const addSection = () => {
    addLyricSectionToSong({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
    })
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Sections>
        <FlipMove
          easing="ease"
          duration={300} // milliseconds
          staggerDurationBy={150} // milliseconds
          staggerDelayBy={60} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: { transform: 'scale(0)' },
            to: { transform: 'none' },
          }}
          enterAnimation={{
            from: { transform: 'scaleY(0)', transformOrigin: '50% 0' },
            to: { transform: 'none' },
          }}
          leaveAnimation={{
            from: { transform: 'none', transformOrigin: '50% 0' },
            to: { transform: 'scaleY(0)' },
          }}
        >
          {props.lyricSections?.map((lyricSection, i) => (
            <AnimatedItem
              key={lyricSection.id}
              generalInfo={props.generalInfo}
              chords={props.chords}
              lyricSections={props.lyricSections}
              setLyricSections={props.setLyricSections}
              lyricSection={lyricSection}
              compositionSections={props.compositionSections}
              setCompositionSections={props.setCompositionSections}
              setOpenBox={setOpenBox}
              openBoxMap={openBoxMap}
            />
          ))}
        </FlipMove>
      </Sections>

      {!props.lyricSections.length && (
        <h3>{t('song.sections editor empty')}</h3>
      )}

      <ButtonWrapper>
        <button
          onClick={addSection}
          title={t('song.lyric editor options.add lyric section')}
          className="btn-action-circular"
        >
          <SvgIcon code="icon-add" />
        </button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default LyricSections
