import {
  Button,
  Buttons,
  Description,
  Fullscreen,
  Modal,
  ModalContent,
  Separator,
  Title,
} from '../confirmedAction'

import InputLabel from 'components/Inputs/_InputLabel'
import React from 'react'
import StringInput from 'components/Inputs/StringInput'
import { confirmAlert } from 'react-confirm-alert'
import styled from 'styled-components'

const InputWrapper = styled.div`
  margin: auto;
  margin-top: 20px;
  max-width: 400px;
`

export const INPUT_ACTION_FOCUS_ID = 'INPUT_ACTION_FOCUS_ID'

const inputAction = (props: {
  title: string
  description?: string
  okText: string
  cancelText?: string
  defaultValues?: string[]
  inputNames: string[]
  onSubmit?: (values: string[]) => void
}) => {
  confirmAlert({
    closeOnEscape: true,
    //closeOnClickOutside: true,
    overlayClassName: 'confirm-alert-overlay',

    customUI: ({ onClose }) => {
      let values = props.inputNames.map(
        (v, i) => (props.defaultValues && props.defaultValues[i]) || ''
      )

      return (
        <Fullscreen>
          <Modal>
            <ModalContent>
              <Title>{props.title}</Title>
              <Separator />

              {props.description && (
                <Description>{props.description}</Description>
              )}

              <InputWrapper>
                {props.inputNames.map((valueName, i) => (
                  <React.Fragment key={i}>
                    <InputLabel label={valueName} />
                    <StringInput
                      id={i === 0 && INPUT_ACTION_FOCUS_ID}
                      value={values[i]}
                      setValue={(val) => {
                        values[i] = val
                      }}
                    />
                  </React.Fragment>
                ))}
              </InputWrapper>
              <Buttons center={!props.cancelText}>
                {props.cancelText && (
                  <Button onClick={onClose}>{props.cancelText}</Button>
                )}
                <Button
                  onClick={() => {
                    props.onSubmit && props.onSubmit(values)
                    onClose()
                  }}
                >
                  {props.okText}
                </Button>
              </Buttons>
            </ModalContent>
          </Modal>
        </Fullscreen>
      )
    },
  })

  document.getElementById(INPUT_ACTION_FOCUS_ID)?.focus()
}

export default inputAction
