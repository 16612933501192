export const TEST_SONG = `
[Verse 1]
B                    G#m
 The night is not so scary
Esus4                 B      A
 When you know how to fly
B                        G#m
 But when your wings are broken                 
    Dsus4
E                          B
 You start praying for the light
 
E                          B
 
A   A   A   A   A   A   A   A   A   A
aaa aaa aaa aaa aaa aaa aaa aaa aaa


[Sloka]
cesky nazev

[Sloha]
slovensky nazev

[Interlude]
B  B
 
 
[Interlude]
B  B 
 
 
[Interlude]
B  B
 
 
[Interlude]
    B  B
 
[Chorus]

E                   F#
Please keep waiting for the morning
B           F#     G#m7


 Don't give up too soon
E               F#
Jesus cares for every sparrow
B          F#        G#m7
 I know He cares for you
B      E         F#
Oh, oh-oh, pleasе  don't give up too soon
 
 
[Interlude]
B  B
 
 
[Verse 2]
B                            G#m
 When you're left out in the open
E                       B
 You feel every drop of rain
B                      G#m
 From every storm that passes
E                  B
 Every hour of the day
 
 
[Interlude]
B  B
 
 
[Chorus]
E                   F#
Please keep waiting for the morning
B           F#     G#m7
 Don't give up too soon
E               F#
Jesus cares for every sparrow
B          F#        G#m7
 I know He cares for you
B      E         F#
Oh, oh-oh, pleasе  don't give up too soon
 
 
[Interlude]
C#m7  F#  G#m7  E
C#m7  F#  G#m7  E
 
 
[Bridge]
B                            G#m
 When it feels like you havе fallen
E                   B
 To the bottom of a well
B                     G#m
 Oh, there is nothing braver
E                     B
 Than calling out for help
 
 
[Chorus]
E                    F#
Please keep waiting, hope is coming
B           F#    G#m
 Don't give up to soon
E               F#
Jesus cares for every sparrow
B          F#        G#m
 I know He cares for you
B      E         F#                  G#m  F#  E
Oh, oh-oh, please  don't give up too soon
   B          E
He waits with you
F#
 Don't give up too soon
 
 
[Outro]
B  G#m  E  F#
B

`
