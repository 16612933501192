import { ReactElement } from 'react'
import SvgIcon from 'components/SvgIcon'
import TextToSongConvertor from './ToolPages/TextToSongConvertor'
import ToolPageGuitar from './ToolPages/Guitar'
import ToolPageGuitarByTuning from './ToolPages/GuitarByTuning'
import ToolPageUkulele from './ToolPages/Ukulele'
import ToolPageUkuleleBaritone from './ToolPages/UkuleleBaritone'
import ToolPageUkuleleDTuning from './ToolPages/UkuleleDTuning'
import ToolPageUkuleleSlackKey from './ToolPages/UkuleleSlackKey'

export type ToolType = {
  id: string
  toolName?: string
  icon: ReactElement
  component: ReactElement
}

export const TOOLS: ToolType[] = [
  /* Song */
  {
    id: 'text-to-song',
    toolName: 'tools.text-to-song.name',
    icon: <SvgIcon code="icon-tool-text-to-song" />,
    component: <TextToSongConvertor />,
  },

  /* Guitar Standard */
  {
    id: 'guitar-standard',
    toolName: 'tools.guitar-standard',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitar />,
  },

  /* Guitar Drop-D */
  {
    id: 'guitar-drop-d',
    toolName: 'tools.guitar-drop-d',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="drop-d" />,
  },

  /* Guitar Open-A */
  {
    id: 'guitar-open-a',
    toolName: 'tools.guitar-open-a',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-a" />,
  },

  /* Guitar Open-B */
  {
    id: 'guitar-open-b',
    toolName: 'tools.guitar-open-b',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-b" />,
  },

  /* Guitar Open-C */
  {
    id: 'guitar-open-c',
    toolName: 'tools.guitar-open-c',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-c" />,
  },

  /* Guitar Open-D */
  {
    id: 'guitar-open-d',
    toolName: 'tools.guitar-open-d',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-d" />,
  },

  /* Guitar Open-E */
  {
    id: 'guitar-open-e',
    toolName: 'tools.guitar-open-e',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-e" />,
  },

  /* Guitar Open-F */
  {
    id: 'guitar-open-f',
    toolName: 'tools.guitar-open-f',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-f" />,
  },

  /* Guitar Open-G */
  {
    id: 'guitar-open-g',
    toolName: 'tools.guitar-open-g',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="open-g" />,
  },

  /* Guitar DAEAAE */
  {
    id: 'guitar-daeaae',
    toolName: 'tools.guitar-daeaae',
    icon: <SvgIcon code="icon-instrument-guitar" />,
    component: <ToolPageGuitarByTuning tuning="daeaae" />,
  },

  /* Ukulele Soprano */
  {
    id: 'ukulele',
    toolName: 'tools.ukulele',
    icon: <SvgIcon code="icon-instrument-ukulele" />,
    component: <ToolPageUkulele />,
  },

  /* Ukulele Baritone */
  {
    id: 'ukulele-baritone',
    toolName: 'tools.ukulele-baritone',
    icon: <SvgIcon code="icon-instrument-baritone-ukulele" />,
    component: <ToolPageUkuleleBaritone />,
  },

  /* Ukulele D-Tuning */
  {
    id: 'ukulele-d-tuning',
    toolName: 'tools.ukulele-d-tuning',
    icon: <SvgIcon code="icon-instrument-ukulele" />,
    component: <ToolPageUkuleleDTuning />,
  },

  /* Ukulele Slack-Key */
  {
    id: 'ukulele-slack-key',
    toolName: 'tools.ukulele-slack-key',
    icon: <SvgIcon code="icon-instrument-ukulele" />,
    component: <ToolPageUkuleleSlackKey />,
  },
]
