// NOTE: STANDARD GUITAR USES DIFFERENT JSON BECAUSE IT HAS BETTER DATA (B5, power chords etc.)
import GuitarJSON from 'assets/chords/guitar2.json'
///////////////////////////////////////////////////////////////////////////////////////////////
// import GuitarJSON from 'assets/chords/guitar.json'
import { INSTRUMENT_CODES } from 'const'
import StringInstrument from 'components/Tools/Sections/StringInstrument'
import ToolLayout from 'layouts/ToolLayout'

const ToolPageGuitar = () => {
  return (
    <ToolLayout>
      <StringInstrument
        tuning="standard"
        instrumentCode={INSTRUMENT_CODES.guitar}
        stringChordLibraryJSON={GuitarJSON as any}
      />
    </ToolLayout>
  )
}

export default ToolPageGuitar
