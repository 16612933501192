import { LOGGED_USER_VAR } from 'App'
import { USER_ROLES } from 'const'

export const getAuthToken = () => {
  return JSON.parse(localStorage.getItem('mnote_token') || '-1')
}

export const storeAuthToken = (token) => {
  let parsedTokenObject
  if (token && token.data) {
    parsedTokenObject = token.data
  } else {
    parsedTokenObject = token || null
  }
  localStorage.removeItem('mnote_token')
  localStorage.setItem('mnote_token', JSON.stringify(parsedTokenObject))
}

export const removeAuthToken = () => {
  localStorage.removeItem('mnote_token')
}

export const isLoggedAppAdmin = () => {
  return LOGGED_USER_VAR()?.role?.code === USER_ROLES.admin
}
