import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
} from 'types'

import CompSection from 'components/Song/SongEdit/SongEditor/Composition/CompSection'
import FlipMove from 'react-flip-move'
import SvgIcon from 'components/SvgIcon'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 40px 0px;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const SectionsWrapper = styled.div`
  position: relative;
`

const EmptyLabel = styled.div<{ locked?: boolean }>`
  opacity: ${(props) => (props.locked ? 0.6 : 1)};
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      songLanguageCode
      chords
      lyricSections
      setLyricSections
      compositionSections
      compSection
      updateSection
      index
      deleteSection
      moveSection
      addSectionToIndex
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <CompSection
          key={props.compSection.id}
          songLanguageCode={props.songLanguageCode}
          chords={props.chords}
          lyricSections={props.lyricSections}
          setLyricSections={props.setLyricSections}
          compositionSections={props.compositionSections}
          compSection={props.compSection}
          setCompSection={props.updateSection}
          deleteSection={() => props.deleteSection(props.compSection.id)}
          moveSection={(offset) => props.moveSection(props.index, offset)}
          addSectionToStart={() => props.addSectionToIndex(-1)}
          addSection={() => props.addSectionToIndex(props.index)}
          i={props.index}
        />
      </div>
    )
  }
)

const CompSections = (props: {
  generalInfo: SongGeneralInfoType
  chords: SongChordType[]
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
}) => {
  const { t } = useTranslation()

  //
  // UTILS
  //
  const updateSection = (updatedSection) => {
    props.setCompositionSections(
      [...props.compositionSections].map((s) =>
        s.id === updatedSection.id ? updatedSection : s
      )
    )
  }

  const deleteSection = (sectionId) => {
    props.setCompositionSections(
      props.compositionSections.filter((s) => s.id !== sectionId)
    )
  }

  const moveSection = (index, offset) => {
    const finalIndex = Math.min(
      props.compositionSections.length,
      Math.max(0, index + offset)
    )
    const updatedSections = [...props.compositionSections]
    var element = updatedSections[index]
    updatedSections.splice(index, 1)
    updatedSections.splice(finalIndex, 0, element)
    props.setCompositionSections(updatedSections)
  }

  const getUniqueSectionId = (sections) => {
    let i =
      (sections.length
        ? Math.max(...sections.map((section) => section.id))
        : 0) + 1
    return i
  }

  const createEmptySection = () => {
    const id = getUniqueSectionId(props.compositionSections)
    const newSection: CompositionSectionType = {
      id: id,
      lyricSectionId: props.lyricSections[0].id,
      note: null,
      note2: null,
    }
    return newSection
  }

  const addSection = () => {
    if (props.lyricSections.length) {
      props.setCompositionSections([
        ...props.compositionSections,
        createEmptySection(),
      ])
    }
  }

  const addSectionToIndex = (index) => {
    if (index < 0) {
      props.setCompositionSections([
        createEmptySection(),
        ...props.compositionSections,
      ])
      return
    }

    const updatedSections = []
    let pushed = false
    props.compositionSections.forEach((s, i) => {
      updatedSections.push(s)
      if (i === index) {
        updatedSections.push(createEmptySection())
        pushed = true
      }
    })

    if (!pushed) {
      updatedSections.push(createEmptySection())
    }
    props.setCompositionSections(updatedSections)
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <SectionsWrapper>
        <FlipMove
          easing="ease"
          duration={300} // milliseconds
          staggerDurationBy={150} // milliseconds
          staggerDelayBy={60} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: { transform: 'scale(0)' },
            to: { transform: 'none' },
          }}
          enterAnimation={{
            from: { transform: 'scaleY(0)', transformOrigin: '50% 0' },
            to: { transform: 'none' },
          }}
          leaveAnimation={{
            from: { transform: 'none', transformOrigin: '50% 0' },
            to: { transform: 'scaleY(0)' },
          }}
        >
          {props.compositionSections?.map((compSection, i) => (
            <AnimatedItem
              key={compSection.id}
              songLanguageCode={props.generalInfo.languageCode}
              chords={props.chords}
              lyricSections={props.lyricSections}
              setLyricSections={props.setLyricSections}
              compositionSections={props.compositionSections}
              compSection={compSection}
              index={i}
              updateSection={updateSection}
              deleteSection={deleteSection}
              moveSection={moveSection}
              addSectionToIndex={addSectionToIndex}
            />
          ))}
        </FlipMove>
      </SectionsWrapper>

      {!props.compositionSections.length && (
        <>
          <EmptyLabel locked={!props.lyricSections?.length}>
            <h3>{t('song.composition editor empty')}</h3>
          </EmptyLabel>

          <ButtonWrapper>
            <button
              onClick={addSection}
              title={t('song.lyric editor options.add lyric section')}
              className={`btn-action-circular ${
                !props.lyricSections.length ? 'locked' : ''
              }`}
              disabled={!props.lyricSections.length}
            >
              <SvgIcon code="icon-add" />
            </button>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default CompSections
