import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'

import { getAuthToken } from 'utils/auth'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

//
// EXPORTS
//
export * from './SongRoles/Queries'

export * from './Songs/Mutations'
export * from './Songs/Queries'

export * from './PlaylistRoles/Queries'

export * from './Playlists/Mutations'
export * from './Playlists/Queries'

export * from './UserGroupRoles/Queries'

export * from './UserGroups/Mutations'
export * from './UserGroups/Queries'

export * from './UserRoles/Queries'

export * from './Users/Queries'
export * from './Users/Mutations'

//
// SETUP
//
const { REACT_APP_API_GRAPHQL_URL } = process.env

const httpLink = createHttpLink({
  uri: REACT_APP_API_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.error('GraphQL Error', { message, path })
    })
  }
})

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
})

const GraphQLApollo = (props: { children: any }) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default GraphQLApollo
