const colors = {
  green: '#00e789',
  yellow: '#ffc400',
  orange: '#e75500',
  red: '#ff0000',
}

const theme = {
  ...colors,

  white: '#ffffff',
  black: '#000000',

  primary: '#0a466e',
  primary_light: '#3384b9',
  primary_lightest: '#e9f0f5',

  background: '#F6F6F6',

  admin: '#ff9100',

  success: colors.green,
  warning: colors.orange,
  error: colors.red,
  highlight: colors.yellow,

  lightest: '#F6F6F6',
  lighter: '#F0F0F0',
  light: '#e0e0e0',
  neutral: '#7e7e7e',
  dark: '#525252',
  darker: '#2e2e2e',

  chord: '#3384b9',

  instrument: {
    fretboard: '#ffffff',
    fretboardShaded: '#e2e2e2',
    fret: '#888',
    stringMuted: '#444444',
    stringMutedWarning: '#ff7b00',
    stringPlayed: '#000',
    stringPressed: '#000',
  },

  paper: {
    background: '#fcfcfc',
    line: '#e0e0e0',
  },
}

export default theme
