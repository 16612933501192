import { PLAYLIST_ROLES, SONG_ROLES } from 'const'
import {
  PlaylistMemberType,
  SongMemberType,
  UserGroupType,
  UserType,
} from 'types'

import AvatarPlaceholder from 'assets/avatar.jpg'
import { LOGGED_USER_VAR } from 'App'

//
// INFO
//
export const getUserAvatar = (user: UserType) => {
  return user?.avatar || AvatarPlaceholder
}

export const getUserDisplayName = (user: UserType) => {
  return user?.username || user?.name
}

//
// PERMISSIONS
//
export const hasPermission = (permissionCode) => {
  return LOGGED_USER_VAR()
    ?.role?.permissions?.map((p) => p.code)
    .includes(permissionCode)
}

export const hasGroupPermission = (
  group: UserGroupType,
  groupPermissionCode: string
) => {
  if (!group) {
    return false
  }
  const hasMasterPermission = hasPermission('updateAnyGroup')
  if (hasMasterPermission) {
    return true
  }
  const member = group.userGroupMembers.filter(
    (m) => m.user?.id === LOGGED_USER_VAR()?.id
  )[0]
  if (member) {
    return member?.groupRole?.userGroupPermissions
      ?.map((p) => p.code)
      .includes(groupPermissionCode)
  }
  return false
}

//
// USER as a SONG MEMBER
//
export const getSongMemberWithHighestAuthorityByUser = (
  user: UserType,
  songMembers: SongMemberType[]
) => {
  /* Find user member === admin */
  const userMember = songMembers?.filter((m) => m.user?.id === user.id)[0]
  if (userMember && userMember.songRole.code === SONG_ROLES.admin) {
    return userMember
  }

  /* Find userGroup member */
  let userGroupMember = null
  for (const m of songMembers) {
    if (
      m.userGroup?.userGroupMembers?.map((gm) => gm.user?.id).includes(user.id)
    ) {
      userGroupMember = m
      if (m && m.songRole.code === SONG_ROLES.admin) {
        break
      }
    }
  }
  return userGroupMember || userMember
}

export const hasSongPermission = (
  songMembers: SongMemberType[],
  songPermissionCode: string
) => {
  if (!songMembers) {
    return false
  }

  const hasMasterPermission = hasPermission('updateAnySong')
  if (hasMasterPermission) {
    return true
  }
  const member = getSongMemberWithHighestAuthorityByUser(
    LOGGED_USER_VAR(),
    songMembers
  )

  if (member) {
    return member.songRole.songPermissions
      ?.map((p) => p.code)
      .includes(songPermissionCode)
  }
  return false
}

//
// USER as a PLAYLIST MEMBER
//
export const getPlaylistMemberWithHighestAuthorityByUser = (
  user: UserType,
  playlistMembers: PlaylistMemberType[]
) => {
  /* Find user member === admin */
  const userMember = playlistMembers?.filter((m) => m.user?.id === user.id)[0]
  if (userMember && userMember.playlistRole.code === PLAYLIST_ROLES.admin) {
    return userMember
  }

  /* Find userGroup member */
  let userGroupMember = null
  for (const m of playlistMembers) {
    if (
      m.userGroup?.userGroupMembers?.map((gm) => gm.user?.id).includes(user.id)
    ) {
      userGroupMember = m
      if (m && m.playlistRole.code === PLAYLIST_ROLES.admin) {
        break
      }
    }
  }
  return userGroupMember || userMember
}

export const hasPlaylistPermission = (
  playlistMembers: PlaylistMemberType[],
  playlistPermissionCode: string
) => {
  if (!playlistMembers) {
    return false
  }
  const hasMasterPermission = hasPermission('updateAnyPlaylist')
  if (hasMasterPermission) {
    return true
  }
  const member = getPlaylistMemberWithHighestAuthorityByUser(
    LOGGED_USER_VAR(),
    playlistMembers
  )

  if (member) {
    return member.playlistRole.playlistPermissions
      ?.map((p) => p.code)
      .includes(playlistPermissionCode)
  }
  return false
}

export const trimUserForComparison = (user: UserType) => {
  if (!user) {
    return undefined
  }
  const { id, name, email } = user
  return {
    id,
    name,
    email,
  } as UserType
}
