import { NAVIGATION, ROUTES } from 'const'

import { Link } from 'react-router-dom'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import { isLoggedAppAdmin } from 'utils/auth'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ITEM_WIDTH_L = '130px'
const ITEM_WIDTH_M = '110px'
const ITEM_WIDTH_S = '90px'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 15vh;
  gap: 10vh;

  ${(props) => props.theme.breakpoint.S} {
    padding-top: 10vh;
    gap: 10vh;
  }
`

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;
    font-size: calc(16px + 0.4vw);
    font-weight: 600;
    background: ${(props) => props.theme.background};
    padding: 20px;
  }

  p {
    margin: 0;
    padding: 0 10%;
    padding-top: 2vh;
    text-align: center;
    line-height: 24px;
    max-width: 660px;
    background: ${(props) => props.theme.background};

    font-size: 15px;
    ${(props) => props.theme.breakpoint.S} {
      padding-top: 1vh;
      font-size: 13px;
      line-height: 20px;
    }
  }
`

const List = styled.div`
  width: 80%;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, ${ITEM_WIDTH_L});
  gap: 3vw;

  ${(props) => props.theme.breakpoint.M} {
    grid-template-columns: repeat(auto-fit, ${ITEM_WIDTH_M});
    gap: 8vw;
  }

  ${(props) => props.theme.breakpoint.S} {
    grid-template-columns: repeat(auto-fit, ${ITEM_WIDTH_S});
    gap: 8vw;
  }
`

const Item = styled.div<{ adminOnly?: boolean }>`
  & > * {
    align-self: center;
    justify-self: center;

    cursor: pointer;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 6px;
    color: ${(props) =>
      props.adminOnly ? props.theme.admin : props.theme.primary_light};
    background: ${(props) => props.theme.background};

    box-shadow: 0 4px 6px 0 ${(props) => props.theme.black}22;
    &:hover {
      transform: translateY(-8px);
      box-shadow: 0px 14px 10px 0 ${(props) => props.theme.black}1A;
    }

    transition: all 0.2s ease;

    width: ${ITEM_WIDTH_L};
    height: ${ITEM_WIDTH_L};
    font-size: 14px;
    font-weight: 600;
    svg {
      width: 40px;
      height: 40px;
    }

    ${(props) => props.theme.breakpoint.M} {
      width: ${ITEM_WIDTH_M};
      height: ${ITEM_WIDTH_M};
      font-size: 13px;
      svg {
        width: 40px;
        height: 40px;
      }
    }

    ${(props) => props.theme.breakpoint.S} {
      width: ${ITEM_WIDTH_S};
      height: ${ITEM_WIDTH_S};
      font-size: 12px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <LoaderPageLayout isReady>
      <Wrapper>
        <Welcome>
          <div>{t('welcome')}</div>
          <p>{t('welcome text')}</p>
        </Welcome>

        <List>
          {NAVIGATION.map((page, i) =>
            page.slug !== ROUTES.home.slug &&
            (!page.adminOnly || isLoggedAppAdmin()) ? (
              <Item key={i} adminOnly={page.adminOnly}>
                <Link to={page.slug}>
                  {page.icon ? page.icon : null}
                  {t(page.name)}
                </Link>
              </Item>
            ) : null
          )}
        </List>
      </Wrapper>
    </LoaderPageLayout>
  )
}

export default HomePage
