import styled from 'styled-components'

const Line = styled.div`
  flex: 1;
  align-self: stretch;
  max-width: 1px;
  background: ${(props) => props.theme.admin}66;
`

const ModuleSectionVerticalSeparator = () => {
  return <Line />
}

export default ModuleSectionVerticalSeparator
