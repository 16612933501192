import ChordLibraryList from '../ChordLibraryList'
import ContentBox from 'components/Boxes/ContentBox'
import { SCLChordType } from 'types'
import SvgIcon from 'components/SvgIcon'

const ChordLibrarySection = (props: {
  tuning: string[]
  root: string
  chords: SCLChordType[]
}) => {
  return (
    <ContentBox
      icon={<SvgIcon code="icon-instrument-ukulele" />}
      title={props.root}
      largeTitle
      closed
      noBackground
      disabled={props.chords.length === 0}
    >
      <ChordLibraryList
        tuning={props.tuning}
        chords={props.chords}
        filterChords
      />
    </ContentBox>
  )
}

export default ChordLibrarySection
