import {
  isKeyboardInstrument,
  isStringInstrument,
  updateInstrumentChordsTuning,
} from 'utils'

import Counter from 'components/Inputs/Counter'
import { InstrumentSectionType } from 'types'
import StringInput from 'components/Inputs/StringInput'
import StringTuning from 'components/Instruments/StringTuning'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5%;
`

const Items = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;

  gap: 20px;
  row-gap: 0px;
  margin-bottom: 10px;

  & > * {
    flex: 1;
    min-width: calc(max(20%, 200px));
    ${(props) => props.theme.breakpoint.S} {
      min-width: 100%;
    }
  }
`

const FullwidthItem = styled.div`
  min-width: 100%;
`

const TuningWrapper = styled.div`
  flex: 1;
  margin-bottom: 20px;
  max-width: 400px;
`

/**
 *
 *  Component
 *
 */
const InstrumentOptions = (props: {
  instrumentSection: InstrumentSectionType
  setInstrumentSection: (v: InstrumentSectionType) => void
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (
      isStringInstrument(props.instrumentSection.instrument.code) &&
      props.instrumentSection?.instrument?.tuning?.notes
    ) {
      props.setInstrumentSection(
        updateInstrumentChordsTuning({
          instrumentSection: props.instrumentSection,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.instrumentSection?.instrument?.tuning?.notes])

  //
  // SHARED OPTIONS
  //
  const optionSectionName = () => {
    return (
      <StringInput
        label={t('song.instrument editor options.instrument name')}
        value={props.instrumentSection.name}
        setValue={(value) =>
          props.setInstrumentSection({
            ...props.instrumentSection,
            name: value,
          })
        }
      />
    )
  }

  //
  // INSTRUMENT TYPES
  //
  const renderStringInstrumentOptions = () => {
    return (
      <>
        <Items>
          {optionSectionName()}

          <FullwidthItem>
            <StringInput
              label={t('song.strumming')}
              placeholder="D_d_D_du (2x)"
              value={props.instrumentSection.instrument.strumming}
              setValue={(value) =>
                props.setInstrumentSection({
                  ...props.instrumentSection,
                  instrument: {
                    ...props.instrumentSection.instrument,
                    strumming: value,
                  },
                })
              }
              monospace
            />
          </FullwidthItem>

          <Counter
            label={t('instrument.render fret count')}
            value={props.instrumentSection.instrument.renderFretCount || 3}
            setValue={(value) => {
              props.setInstrumentSection({
                ...props.instrumentSection,
                instrument: {
                  ...props.instrumentSection.instrument,
                  renderFretCount: value,
                },
              })
            }}
            valueMin={3}
            valueMax={25}
          />

          <Counter
            label={t('instrument.transpose')}
            value={props.instrumentSection.instrument.transpose || 0}
            setValue={(value) => {
              props.setInstrumentSection({
                ...props.instrumentSection,
                instrument: {
                  ...props.instrumentSection.instrument,
                  transpose: value,
                },
              })
            }}
            valueMin={-10}
            valueMax={24}
          />
        </Items>

        <TuningWrapper>
          <StringTuning
            tuningName={props.instrumentSection.instrument.tuning.name}
            setTuningName={(name) =>
              props.setInstrumentSection({
                ...props.instrumentSection,
                instrument: {
                  ...props.instrumentSection.instrument,
                  tuning: {
                    ...props.instrumentSection.instrument.tuning,
                    name: name,
                  },
                },
              })
            }
            notes={props.instrumentSection.instrument.tuning.notes}
            setNotes={(notes) =>
              props.setInstrumentSection({
                ...props.instrumentSection,
                instrument: {
                  ...props.instrumentSection.instrument,
                  tuning: {
                    ...props.instrumentSection.instrument.tuning,
                    notes: notes,
                  },
                },
              })
            }
            fretHeight={28}
            stretch
          />
        </TuningWrapper>
      </>
    )
  }

  //
  // RENDER
  //
  const renderInstrumentOptions = () => {
    if (isStringInstrument(props.instrumentSection.instrument.code)) {
      return renderStringInstrumentOptions()
    }
    if (isKeyboardInstrument(props.instrumentSection.instrument.code)) {
      return <div>Piano options</div>
    }
    return <div>?</div>
  }

  return <Wrapper>{renderInstrumentOptions()}</Wrapper>
}

export default InstrumentOptions
