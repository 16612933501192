import styled from 'styled-components'

const Line = styled.div<{
  minHeight: string
  lineGap: number
  showPaperLine: boolean
}>`
  flex: 1;
  display: flex;
  min-height: ${props => props.minHeight};
  padding: 5px;
  border-bottom: 1px solid
    ${props => (props.showPaperLine ? props.theme.paper.line : '#0000')};

  transition: padding 0.1s ease;
  padding-top: ${props =>
    props.lineGap < 0 ? 0 : props.lineGap > 0 ? props.lineGap : 0}px;
  padding-bottom: ${props =>
    props.lineGap < 0 ? 0 : props.lineGap > 0 ? props.lineGap : 0}px;
`

/**
 *
 *  COMPONENT
 *
 */
const PaperLine = (props: {
  children: any
  minHeight?: string
  lineGap?: number
  showPaperLine?: boolean
}) => {
  return (
    <Line
      minHeight={props.minHeight || '30px'}
      lineGap={props.lineGap}
      showPaperLine={props.showPaperLine}
    >
      {props.children}
    </Line>
  )
}

export default PaperLine
