import AppHeader from 'components/App/AppHeader'
import { HEADER_HEIGHT } from 'const'
import SvgIcon from 'components/SvgIcon'
import { gridBackground } from 'styles/GlobalStyle/Backgrounds'
import modalRenderFunctions from 'modals'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  flex: 1;
  height: 100vh;
  display: flex;

  ${(props) => props.theme.breakpoint.L} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ContentWindow = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  flex: 1;

  /* GRID BACKGROUND */
  background: ${(props) => props.theme.background};
  ${gridBackground}

  padding-top: ${HEADER_HEIGHT};
  padding-bottom: 0;

  ${(props) => props.theme.breakpoint.S} {
    padding-left: 2%;
    padding-right: 2%;
  }
`

const Content = styled.div`
  margin: auto;
  flex: 1;
  padding-bottom: 15vh;
  min-height: calc(100% - 35vh);
`

const Footer = styled.div`
  padding-bottom: calc(max(70px, 15vh));

  a {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    max-width: 200px;
    padding: 10px;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.black}55;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none;
    svg {
      --size: 14px;
      width: var(--size);
      height: var(--size);
      margin-bottom: 1px;
    }
  }
`

const AppLayout = (props: { children: any }) => {
  return (
    <Wrapper>
      <AppHeader />

      <ContentWindow>
        <ContentWrapper>
          <Content>{props.children}</Content>
          <Footer>
            <a href="https://jan-hamernik.com" target="_blank" rel="noreferrer">
              <SvgIcon code="icon-copyright" />
              jan.hamernik.com
            </a>
          </Footer>
        </ContentWrapper>
      </ContentWindow>

      {modalRenderFunctions.map((f, i) => (
        <div key={i}>{f()}</div>
      ))}
    </Wrapper>
  )
}

export default AppLayout
