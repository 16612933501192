import { DBPlaylistType, PlaylistBodyType } from 'types'

import FileSystemPlaylists from 'components/Files/FileSystemPlaylists'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import { addDays } from 'date-fns'
import styled from 'styled-components'
import { useMemo } from 'react'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;

  & > *:last-child {
    max-width: 30%;
    min-width: 350px;
  }

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    & > *:last-child {
      max-width: 100%;
    }
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const DevModuleFileSystemPlaylists = (props: { isOpenDefault?: boolean }) => {
  const maxHeight = '50vh'

  const preloadedPlaylists: DBPlaylistType[] = useMemo(
    () =>
      Array.apply(null, Array(20)).map((u, i) => {
        const index = i === 8 ? 0 : -8 + i + (i >= 8 ? i - 9 : 0)
        const name = `Playlist ${i + 1}`
        const body: PlaylistBodyType = {
          name,
          playlistSongs: [],
          eventDate:
            i <= 1 ? undefined : addDays(new Date(), index).toISOString(),
        }

        return {
          id: i,
          name,
          body: JSON.stringify(body),
          playlistMembers: [],
          songs: [],
        }
      }),
    []
  )

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="File System - Playlists"
      subtitle="File system for organizing playlists"
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        <Column>
          <ModuleSectionTitle
            title="Playlist File System"
            subtitle="Gird View"
          />

          <FileSystemPlaylists
            preloadedPlaylists={preloadedPlaylists}
            defaultViewMode="grid"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileOpenClick={() => alert('onFileOpenClick')}
            maxHeight={maxHeight}
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          <ModuleSectionTitle
            title="Playlist File System"
            subtitle="Gird View"
          />

          <FileSystemPlaylists
            preloadedPlaylists={preloadedPlaylists}
            defaultViewMode="list"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileOpenClick={() => alert('onFileOpenClick')}
            maxHeight={maxHeight}
          />
        </Column>
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleFileSystemPlaylists
