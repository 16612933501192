import { createGlobalStyle } from 'styled-components'
import { defaultButtons } from './Buttons'
import { defaultHeadings } from './Headings'
import { defaultInputs } from './Inputs'

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    font-family: 'Manrope', sans-serif;
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.white};

    & * {
      -webkit-tap-highlight-color: transparent;
    }

    & *.admin-color {
      color: ${(props) => props.theme.admin}
    }
  }


  /* SCROLLBAR */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    ${(props) => props.theme.breakpoint.M} {
        width: 0px;
    }
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.lighter};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.light};
    &:hover {
    background: ${(props) => props.theme.primary_light};
    }
  }




  ${defaultHeadings}
  ${defaultButtons}
  ${defaultInputs}
`

export default GlobalStyle
