import { INSTRUMENT_CODES } from 'const'
import StringInstrument from 'components/Tools/Sections/StringInstrument'
import ToolLayout from 'layouts/ToolLayout'
import UkuleleJSON from 'assets/chords/ukulele.json'

const ToolPageUkulele = () => {
  return (
    <ToolLayout>
      <StringInstrument
        tuning="soprano"
        instrumentCode={INSTRUMENT_CODES.ukulele}
        stringChordLibraryJSON={UkuleleJSON}
      />
    </ToolLayout>
  )
}

export default ToolPageUkulele
