import {
  FileBadgeType,
  FileIconType,
  FileType,
  ViewMode,
} from 'components/Files/FileSystem'
import styled, { css, keyframes } from 'styled-components'

import FileIconButton from '../FileIconButton'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import theme from 'styles/theme'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Name = styled.div``

const SubName = styled.div``

const NameContainer = styled.div<{ useVerticalOffset?: boolean }>`
  transition: all 0.2s ease-out;

  ${(props) =>
    props.useVerticalOffset &&
    css`
      //transform: translateY(-14px);
    `}
`

const Image = styled.div``

const Icon = styled.div<{ useVerticalOffset?: boolean; type?: FileType }>`
  position: relative;
  transition: all 0.2s ease-out;

  display: flex;
  align-items: center;
  justify-content: center;

  --size: 36px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);

  svg {
    width: 100%;
    height: 100%;
  }

  ${Image} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    outline: 2px solid
      ${(props) =>
        props.type === 'folder'
          ? props.theme.primary
          : props.theme.primary_light};
  }

  ${(props) =>
    props.useVerticalOffset &&
    css`
      //transform: translateY(-10px);
    `}
`

const IconOverlay = styled.div`
  pointer-events: none !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BadgeArrayBottom = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const BadgeArrayTop = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const FlashAnimation = keyframes`
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const Badge = styled.div<{ color?: string; isFlashing?: boolean }>`
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.color || theme.primary_light};
    width: 80%;
    height: 90%;

    ${(props) =>
      props.isFlashing &&
      css`
        animation: ${FlashAnimation} 1s infinite ease;
      `}
  }
`

const Dot = styled.div<{
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
}>`
  position: absolute;
  --size: 2px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: ${(props) => props.theme.primary_light}66;
  top: ${(props) => props.top && 0};
  bottom: ${(props) => props.bottom && 0};
  left: ${(props) => props.left && 0};
  right: ${(props) => props.right && 0};
`

const FileMenu = styled.div<{ direction: 'left' | 'right' }>`
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  pointer-events: none;

  ${(props) =>
    props.direction === 'right'
      ? css`
          justify-content: flex-end;
        `
      : css``}
`

const FileItemAnimationIn_GridView = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
  }
`

const FileItemAnimationIn_ListView = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
  }
`

const ITEM_APPEAR_DURATION = 0.2
const ITEM_DELAY_DURATION = 0.03

const Wrapper = styled.div<{
  index: number
  viewMode: ViewMode
  type: FileType
  isSelected: boolean
}>`
  animation-name: ${(props) =>
    props.viewMode === 'list'
      ? FileItemAnimationIn_ListView
      : FileItemAnimationIn_GridView};
  animation-timing-function: ease-out;
  animation-duration: ${ITEM_APPEAR_DURATION}s;
  animation-fill-mode: backwards;
  animation-delay: ${(props) => props.index * ITEM_DELAY_DURATION}s;

  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 4px;

  user-select: none;
  transition: all 0.2s ease;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  color: ${(props) => props.theme.primary_light};

  ${(props) =>
    props.isSelected &&
    css`
      background: ${(props) => props.theme.primary_light}11;
      outline: 1px solid ${(props) => props.theme.primary_light}44;
    `}

  ${(props) =>
    props.type === 'folder' &&
    css`
      color: ${(props) => props.theme.primary};
    `}

  ${(props) =>
    (props.viewMode as ViewMode) === 'list'
      ? /* LIST VIEW */
        css`
          flex-direction: row;
          gap: 10px;
          padding-left: min(3%, 12px);

          ${Icon} {
            --size: 24px;
          }

          ${BadgeArrayBottom} {
            left: 70%;
            bottom: -3px;
          }

          ${BadgeArrayTop} {
            left: 70%;
            top: -3px;
          }

          ${Badge} {
            --size: 12px;
          }

          ${FileMenu} {
            bottom: auto;
            top: auto;
            gap: min(3%, 5px);
          }

          ${NameContainer} {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
          }

          ${Name} {
            font-size: 15px;
            font-weight: 600;
          }

          ${SubName} {
            margin-top: 2px;
            font-size: 13px;
            font-weight: 600;
            opacity: 0.6;
          }
        `
      : /* GRID VIEW */
        css`
          text-align: center;
          min-height: 100px;

          ${(props) => props.theme.breakpoint.S} {
            min-height: 80px;
          }

          ${Icon} {
            --size: 40px;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          ${BadgeArrayBottom} {
            left: 70%;
            bottom: -2px;
          }

          ${BadgeArrayTop} {
            left: 70%;
            top: -2px;
          }

          ${Badge} {
            --size: 14px;
          }

          ${NameContainer} {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }

          ${Name} {
            font-size: 12px;
            font-weight: ${(props) => (props.type === 'folder' ? 600 : 600)};
          }

          ${SubName} {
            font-size: 11px;
            font-weight: 600;
            opacity: 0.6;
          }
        `};
`

const FileItem = (props: {
  index: number
  id: number
  name: string
  name2: string
  type: FileType
  icon: FileIconType
  image?: string
  renderCustomIcon?: (props: { isActive: boolean; viewMode: ViewMode }) => void
  renderCustomOverlay?: (props: {
    isActive: boolean
    viewMode: ViewMode
  }) => void
  customBadges?: FileBadgeType[]
  customBadgesTop?: FileBadgeType[]
  onClick: () => void
  onDoubleClick: () => void
  onMoveFilesClick: () => void
  onFileAddClick?: () => void
  onFileOpenClick?: () => void
  onFilePreviewClick?: () => void
  onFileMiddleClick?: () => void
  onFolderAddClick?: () => void
  onFolderOpenClick?: () => void
  onFolderDeleteClick?: () => void
  onFolderEditClick?: () => void
  onFolderMiddleClick?: () => void
  onHover: () => void
  viewMode: ViewMode
  selectedFileCount: number
  isSelected: boolean
  isParent: boolean
  isPrivate: boolean
}) => {
  const { t } = useTranslation()

  const iconFolder = <SvgIcon code="icon-file-system-folder" />
  const iconFile = <SvgIcon code="icon-file-system-file-text" />

  const viewMode: ViewMode = props.viewMode || 'grid'

  const showFileMenu: boolean = props.type === 'file' && props.isSelected

  const showFolderMenu: boolean = props.type === 'folder' && props.isSelected
  const showFolderMove: boolean = props.selectedFileCount > 1

  const [clickTimeOut, setClickTimeout] = useState(null)

  const onClick = (e) => {
    if (clickTimeOut !== null) {
      props.onDoubleClick()
      clearTimeout(clickTimeOut)
      setClickTimeout(null)
    } else {
      props.onClick()
      setClickTimeout(
        setTimeout(() => {
          clearTimeout(clickTimeOut)
          setClickTimeout(null)
        }, 400)
      )
    }
  }

  const onMouseDown = (e) => {
    if (e.button === 1) {
      // middle mouse button click
      if (props.type === 'file' && props.onFileMiddleClick) {
        props.onFileMiddleClick()
      }
      if (props.type === 'folder' && props.onFolderMiddleClick) {
        props.onFolderMiddleClick()
      }
    }
  }

  const onFolderDeleteClick = () => {
    confirmedAction({
      title: t('file_system.delete_folder.title'),
      description: t('file_system.delete_folder.description').replace(
        '{NAME}',
        `${props.name}${props.name2 ? ` (${props.name2})` : ''}`
      ),
      cancelText: t('file_system.delete_folder.no'),
      okText: t('file_system.delete_folder.yes'),
      onSubmit: () => {
        props.onFolderDeleteClick()
      },
    })
  }

  //
  // RENDER PARTS
  //
  const renderTypeIcon = () => {
    switch (props.type) {
      case 'folder':
        return props.isParent ? (
          <SvgIcon code="icon-file-system-folder-parent" />
        ) : (
          iconFolder
        )
      case 'file':
        return iconFile
      default:
        return <SvgIcon code="icon-file-system-file-none" />
    }
  }

  const renderImg = (src: string) => {
    return <Image src={src} alt={props.name} />
  }

  const renderIcon = () => {
    if (props.image) {
      return renderImg(props.image)
    }

    switch (props.icon) {
      case 'folder':
        return iconFolder
      case 'file':
        return iconFile
      case 'playlist':
        return <SvgIcon code="icon-file-system-file-playlist" />
      case 'user':
        return <SvgIcon code="icon-file-system-file-user" />
      case 'userGroup':
        return <SvgIcon code="icon-file-system-file-user-group" />
      default:
        return renderTypeIcon()
    }
  }

  const renderFileMenu = () => {
    return (
      <>
        {props.onFilePreviewClick && (
          <FileIconButton
            id={`${props.id}_preview`}
            onClick={props.onFilePreviewClick}
            hint={t('file_system.open_preview')}
          >
            <SvgIcon code="icon-zoom-in" />
          </FileIconButton>
        )}

        {props.onFileOpenClick && (
          <FileIconButton
            id={`${props.id}_open`}
            onClick={props.onFileOpenClick}
            hint={t('file_system.open_file')}
          >
            <SvgIcon code="icon-file-system-open-file" />
          </FileIconButton>
        )}

        {props.onFileAddClick && (
          <FileIconButton
            id={`${props.id}_add`}
            onClick={props.onFileAddClick}
            hint={t('file_system.add_file')}
          >
            <SvgIcon code="icon-add" />
          </FileIconButton>
        )}
      </>
    )
  }

  const renderFolderMenu = () => {
    return (
      <>
        {!props.isParent && props.onFolderDeleteClick && (
          <FileIconButton
            id={`${props.id}_delete`}
            onClick={onFolderDeleteClick}
            hint={t('file_system.delete_folder.label')}
            isCompact={showFolderMove}
          >
            <SvgIcon code="icon-delete" />
          </FileIconButton>
        )}

        {!props.isParent && props.onFolderEditClick && (
          <FileIconButton
            id={`${props.id}_edit`}
            onClick={props.onFolderEditClick}
            hint={t('file_system.edit_folder.label')}
            isCompact={showFolderMove}
          >
            <SvgIcon code="icon-edit" />
          </FileIconButton>
        )}
      </>
    )
  }

  const renderFolderMenuRight = () => {
    return (
      <>
        {props.onFolderOpenClick && (
          <FileIconButton
            id={`${props.id}_open`}
            onClick={props.onFolderOpenClick}
            hint={t('file_system.open_file')}
          >
            <SvgIcon code="icon-file-system-open-file" />
          </FileIconButton>
        )}

        {showFolderMove && (
          <FileIconButton
            id={`${props.id}_move`}
            onClick={props.onMoveFilesClick}
            hint={t('file_system.move_to_folder')}
          >
            <SvgIcon code="icon-file-system-move-files" />
          </FileIconButton>
        )}

        {!props.isParent && props.onFolderAddClick && (
          <FileIconButton
            id={`${props.id}_add`}
            onClick={props.onFolderAddClick}
            hint={t('file_system.add_file')}
          >
            <SvgIcon code="icon-add" />
          </FileIconButton>
        )}
      </>
    )
  }

  //
  // RENDER
  //
  return (
    <Wrapper
      index={props.index}
      onClick={onClick}
      onMouseDown={onMouseDown}
      type={props.type}
      viewMode={viewMode}
      isSelected={props.isSelected}
      onMouseOver={props.onHover}
    >
      {viewMode === 'grid' && (
        <>
          <Dot top left />
          <Dot top right />
          <Dot bottom left />
          <Dot bottom right />
        </>
      )}
      <Icon
        useVerticalOffset={viewMode === 'grid' && showFolderMenu}
        type={props.type}
      >
        {props.renderCustomIcon ? (
          <IconOverlay>
            {props.renderCustomIcon({
              isActive: props.isSelected,
              viewMode: props.viewMode,
            })}
          </IconOverlay>
        ) : (
          renderIcon()
        )}

        <BadgeArrayTop>
          {props.customBadgesTop?.map((customBadge, i) => (
            <Badge
              key={i}
              color={customBadge.color}
              isFlashing={customBadge.isFlashing}
            >
              {customBadge?.icon}
            </Badge>
          ))}
        </BadgeArrayTop>

        <BadgeArrayBottom>
          {props.type !== 'folder' && props.isPrivate && (
            <Badge color={theme.warning}>
              <SvgIcon code="icon-private" />
            </Badge>
          )}
          {props.customBadges?.map((customBadge, i) => (
            <Badge
              key={i}
              color={customBadge.color}
              isFlashing={customBadge.isFlashing}
            >
              {customBadge?.icon}
            </Badge>
          ))}
        </BadgeArrayBottom>

        {props.renderCustomOverlay && (
          <IconOverlay>
            {props.renderCustomOverlay({
              isActive: props.isSelected,
              viewMode: props.viewMode,
            })}
          </IconOverlay>
        )}
      </Icon>

      <NameContainer useVerticalOffset={viewMode === 'grid' && showFolderMenu}>
        <Name>{props.name.trim()}</Name>
        {props.name2 && <SubName>{props.name2.trim()}</SubName>}
      </NameContainer>

      {viewMode === 'grid' && (
        <>
          {showFolderMenu && <FileMenu>{renderFolderMenu()}</FileMenu>}{' '}
          {showFolderMenu && (
            <FileMenu direction={'right'}>{renderFolderMenuRight()}</FileMenu>
          )}
          {showFileMenu && (
            <FileMenu direction={'right'}>{renderFileMenu()}</FileMenu>
          )}
        </>
      )}

      {viewMode === 'list' && (
        <FileMenu direction={'right'}>
          {showFolderMenu && renderFolderMenu()}
          {showFolderMenu && renderFolderMenuRight()}
          {showFileMenu && renderFileMenu()}
        </FileMenu>
      )}
    </Wrapper>
  )
}

export default FileItem
