import { InstrumentSectionType, LyricSectionType, SongChordType } from 'types'

import confirmedAction from 'modals/actions/confirmedAction'

/**
 * GET NUMBER OF REFERENCES OF GIVEN CHORD IN LYRICS
 */
export const getNumberOfChordReferences = (
  lyricSections: LyricSectionType[],
  chordId: number
) => {
  let numberOfReferences = 0
  lyricSections.forEach((lyricSection) =>
    lyricSection.lyricChords.forEach((lyricChord) => {
      if (lyricChord.chordId === chordId) {
        numberOfReferences += 1
      }
    })
  )

  return numberOfReferences
}

/**
 * GET UNIQUE ID FOR NEW CHORD
 */
export const getUniqueChordId = (chords: SongChordType[]) => {
  let i =
    (chords?.length ? Math.max(...chords.map((chord) => chord.id)) : 0) + 1
  return i
}

/**
 * ADD NEW CHORD TO SONG CHORD SELECTION
 */
export const addChordToChordSelection = (props: {
  chords: SongChordType[]
  setChords?: (data: SongChordType[]) => void
}) => {
  const id = getUniqueChordId(props.chords)
  const newChord: SongChordType = {
    id: id,
    name: '',
  }

  if (props.chords.length) {
    props.setChords([...props.chords, newChord])
  } else {
    props.setChords([newChord])
  }
  return newChord
}

/**
 * MOVE CHORD IN SONG CHORD SELECTION
 */
export const moveChordInChordSelection = (props: {
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  chordId: number
  offset: number
}) => {
  const index = props.chords.findIndex((c) => c.id === props.chordId)
  const finalIndex = Math.min(
    props.chords.length,
    Math.max(0, index + props.offset)
  )
  const updatedArray = [...props.chords]
  var element = updatedArray[index]
  updatedArray.splice(index, 1)
  updatedArray.splice(finalIndex, 0, element)
  props.setChords(updatedArray)
}

/**
 * DELETE CHORD FROM CHORD SELECTION (WITHOUT ANY WARNING)
 */
const deleteChordRaw = (props: {
  chordId: number
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
}) => {
  props.setChords(props.chords.filter((chord) => chord.id !== props.chordId))

  props.setLyricSections(
    [...props.lyricSections].map((lyricSection) => {
      return {
        ...lyricSection,
        lyricChords: [...lyricSection.lyricChords].filter(
          (lyricChord) => lyricChord.chordId !== props.chordId
        ),
      }
    })
  )

  props.setInstrumentSections(
    [...props.instrumentSections].map((instrumentSection) => {
      return {
        ...instrumentSection,
        instrument: {
          ...instrumentSection.instrument,
          chords: [...instrumentSection.instrument.chords].filter(
            (instrumentChord) => instrumentChord.songChordId !== props.chordId
          ),
        },
      }
    })
  )
}

/**
 * DELETE CHORD FROM CHORD SELECTION WITH CONFIRMATION POP-UP
 */
export const deleteChordFromChordSelection = (props: {
  chordId: number
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
  t: any
}) => {
  let numberOfReferences = getNumberOfChordReferences(
    props.lyricSections,
    props.chordId
  )

  if (numberOfReferences) {
    confirmedAction({
      title: props.t('song.chord editor options.delete chord confirm title'),
      description: props
        .t('song.chord editor options.delete chord confirm description')
        .replace('{NUMBER}', `${numberOfReferences}`),
      okText: props.t('delete'),
      cancelText: props.t('cancel'),
      onSubmit: () => {
        deleteChordRaw({
          chordId: props.chordId,
          chords: props.chords,
          setChords: props.setChords,
          lyricSections: props.lyricSections,
          setLyricSections: props.setLyricSections,
          instrumentSections: props.instrumentSections,
          setInstrumentSections: props.setInstrumentSections,
        })
      },
    })
  } else {
    deleteChordRaw({
      chordId: props.chordId,
      chords: props.chords,
      setChords: props.setChords,
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
    })
  }
}

/**
 * UPDATE CHORD IN CHORD SELECTION
 */
export const updateChordInChordSelection = (props: {
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  updatedChord: SongChordType
}) => {
  props.setChords(
    [...props.chords].map((c) =>
      c.id === props.updatedChord.id ? props.updatedChord : c
    )
  )
}
