import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SongGeneralInfoType } from 'types'
import { LYRICS_ROLES_TRANSLATED } from 'const'

import StringInput from 'components/Inputs/StringInput'
import Droplist from 'components/Inputs/Droplist'
import Counter from 'components/Inputs/Counter'
import TextInput from 'components/Inputs/TextInput'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 20px;

  padding: 30px;
  background: ${props => props.theme.background};

  ${props => props.theme.breakpoint.S} {
    padding: 5%;
    gap: 10px;
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: 20px;

  ${props => props.theme.breakpoint.S} {
    gap: 10px;
  }
`

const RowSeparator = styled.div`
  flex: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.black}22;
`

const Column = styled.div<{ wide?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  min-width: 250px;

  ${props =>
    props.wide &&
    css`
      flex: 2;
      min-width: 400px;

      ${props => props.theme.breakpoint.S} {
        min-width: 250px;
      }
    `}
`

const SongName = styled.div`
  input {
    font-size: 16px;
    font-weight: 600;
  }
`

const SongAuthor = styled.div`
  input {
    font-size: 16px;
    font-weight: 600;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const SongInformation = (props: {
  generalInfo: SongGeneralInfoType
  setGeneralInfo: (data: SongGeneralInfoType) => void
}) => {
  const { t } = useTranslation()

  const languages = Object.keys(LYRICS_ROLES_TRANSLATED)

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Column>
        <Row>
          <Column>
            <SongName>
              <StringInput
                label={t('song.name')}
                value={props.generalInfo.name}
                setValue={value =>
                  props.setGeneralInfo({
                    ...props.generalInfo,
                    name: value,
                  })
                }
              />
            </SongName>
          </Column>

          <Column>
            <SongAuthor>
              <StringInput
                label={t('song.author')}
                value={props.generalInfo.author}
                setValue={value =>
                  props.setGeneralInfo({
                    ...props.generalInfo,
                    author: value,
                  })
                }
              />
            </SongAuthor>
          </Column>

          <Column>
            <Droplist
              label={t('song.language')}
              options={languages.map(lang => {
                return { id: lang, name: t('languages.' + lang) }
              })}
              value={props.generalInfo.languageCode}
              onChange={value =>
                props.setGeneralInfo({
                  ...props.generalInfo,
                  languageCode: value as string,
                })
              }
            />
          </Column>
        </Row>

        <RowSeparator />

        <Row>
          <Column>
            <Counter
              label={t('song.capo')}
              value={props.generalInfo.capo}
              setValue={value =>
                props.setGeneralInfo({ ...props.generalInfo, capo: value })
              }
              valueMin={-10}
              valueMax={30}
            />

            <StringInput
              label={t('song.key')}
              value={props.generalInfo.key}
              setValue={value =>
                props.setGeneralInfo({ ...props.generalInfo, key: value })
              }
            />
          </Column>

          <Column>
            <StringInput
              label={t('song.bpm')}
              value={props.generalInfo.bpm?.toString()}
              setValue={value =>
                props.setGeneralInfo({
                  ...props.generalInfo,
                  bpm: parseInt(value),
                })
              }
            />

            <StringInput
              label={t('song.time signature')}
              placeholder="4/4"
              value={props.generalInfo.timeSignature}
              setValue={value =>
                props.setGeneralInfo({
                  ...props.generalInfo,
                  timeSignature: value,
                })
              }
            />
          </Column>

          <Column wide>
            <TextInput
              label={t('song.note')}
              value={props.generalInfo.note}
              setValue={value =>
                props.setGeneralInfo({ ...props.generalInfo, note: value })
              }
              isMultiline
              isResizable
              height="90px"
            />
          </Column>
        </Row>
      </Column>
    </Wrapper>
  )
}

export default SongInformation
