/* eslint-disable react-hooks/exhaustive-deps */
import { DBSongType, SongType } from 'types'
import IconMenu, {
  A4_COMPONENT_EXPORT_ID,
  A4_COMPONENT_EXPORT_INSTRUMENTS_ID,
} from 'components/Song/SongDetail/IconMenu'
import { ROUTES, TEXT_LYRICS_DEFAULT_DESIGN } from 'const'
import { getTabName, isSongPrivate } from 'utils'
import { useEffect, useState } from 'react'

import FillScreenPaperWrapper from '../Paper/FillScreenPaperWrapper'
import { GET_SONG } from 'api/graphql'
import InstrumentsPaperA4 from '../Paper/InstrumentsPaperA4'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import Members from 'components/Members'
import { Redirect } from 'react-router-dom'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import UserCard from 'components/User/UserCard'
import { isLoggedAppAdmin } from 'utils/auth'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const PreviewWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const Footer = styled.div`
  flex: 1;
  margin-top: 7vh;
  flex: 1;
  display: flex;
  gap: 20px;

  flex-direction: column;
  align-items: stretch;
`

const Large = styled.div`
  ${(props) => props.theme.breakpoint.M} {
    display: none;
  }
`
const Small = styled.div`
  display: none;
  ${(props) => props.theme.breakpoint.M} {
    display: block;
  }
`

const VerticalSpacer = styled.div`
  height: 3vh;
`

const CreatedByWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
`

const CreatedByTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const SongDetail = (props: {
  songId: string
  onDelete: () => void
  onDuplicate: (songBody: SongType) => void
  setOutsideSongData?: (song: DBSongType) => void
}) => {
  const { t } = useTranslation()

  const [song, setSong] = useState<DBSongType>()
  const [songBody, setSongBody] = useState<SongType>()
  const [transpose, setTranspose] = useState<number>(0)
  const [simplifyChords, setSimplifyChords] = useState<boolean>(false)
  const [capoOffset, setCapoOffset] = useState<number>(0)

  useEffect(() => {
    document.title = getTabName(song?.name)
  }, [song?.name])

  //
  // QUERY SONG DATA
  //
  const { data: fetchedSongData, loading } = useQuery(GET_SONG, {
    variables: { id: props.songId },
  })

  useEffect(() => {
    if (fetchedSongData?.getSong) {
      setSong(fetchedSongData?.getSong)
      setSongBody(JSON.parse(fetchedSongData?.getSong.body))
    }
    if (fetchedSongData?.getSong === null) {
      toast.warning(t('song.no_access_redirect'))
    }
  }, [fetchedSongData])

  useEffect(() => {
    if (props.setOutsideSongData) {
      props.setOutsideSongData(song)
    }
  }, [song])

  //
  // SUB-RENDERS
  //
  const renderMenu = () => {
    return (
      <IconMenu
        song={song}
        songBody={songBody}
        transpose={transpose}
        setTranspose={setTranspose}
        simplifyChords={simplifyChords}
        setSimplifyChords={setSimplifyChords}
        capoOffset={capoOffset}
        setCapoOffset={setCapoOffset}
      />
    )
  }

  const renderSongPaper = (options?: { allowZoom?: boolean }) => {
    return (
      <FillScreenPaperWrapper>
        <SongPaperA4
          id={A4_COMPONENT_EXPORT_ID}
          generalInfo={songBody}
          chords={songBody.chords}
          lyricSections={songBody.lyricSections}
          compositionSections={songBody.compositionSections}
          textLyricDesign={
            songBody.textLyricDesign || {
              ...TEXT_LYRICS_DEFAULT_DESIGN,
            }
          }
          readOnly
          transpose={transpose}
          simplifyChords={simplifyChords}
          capoOffset={capoOffset}
          zoomSongBody={options?.allowZoom ? songBody : undefined}
        />
      </FillScreenPaperWrapper>
    )
  }

  const renderInstrumentsPaper = (options?: { allowZoom?: boolean }) => {
    return songBody.instrumentSections.length > 0 ? (
      <FillScreenPaperWrapper>
        <InstrumentsPaperA4
          id={A4_COMPONENT_EXPORT_INSTRUMENTS_ID}
          generalInfo={songBody}
          chords={songBody.chords}
          instrumentSections={songBody.instrumentSections}
          zoomSongBody={options?.allowZoom ? songBody : undefined}
        />
      </FillScreenPaperWrapper>
    ) : null
  }

  //
  // RENDER
  //
  if (!loading && !fetchedSongData?.getSong) {
    return <Redirect to={ROUTES.songs.slug} />
  }

  return (
    <LoaderPageLayout
      title={songBody?.name}
      subtitle={songBody?.author}
      isPrivate={isSongPrivate(song)}
      loadingText={t('song.loading song data')}
      isLoading={loading}
      isReady={!!songBody}
      isSlimPage
    >
      {songBody && (
        <>
          <PreviewWrapper>
            <Large>{renderMenu()}</Large>

            <Large>{renderSongPaper({ allowZoom: false })}</Large>
            <Small>{renderSongPaper({ allowZoom: true })}</Small>

            <Small>
              <VerticalSpacer />
              {renderMenu()}
            </Small>

            <Large>{renderInstrumentsPaper({ allowZoom: false })}</Large>
            <Small>{renderInstrumentsPaper({ allowZoom: true })}</Small>
          </PreviewWrapper>
          <Footer>
            <Members type="song" members={song.songMembers} useLink={true} />

            {isLoggedAppAdmin() && (
              <CreatedByWrapper>
                <CreatedByTitle className="admin-color">
                  {t('song.created by user')}
                </CreatedByTitle>
                <UserCard
                  user={song.createdByUser}
                  showName
                  forceLoggedUserName
                />
              </CreatedByWrapper>
            )}
          </Footer>
        </>
      )}
    </LoaderPageLayout>
  )
}

export default SongDetail
