import styled from 'styled-components'
import { DBSongType } from 'types'
import { SEARCH_SONGS } from 'api/graphql'
import { useQuery } from '@apollo/client'

import StringInput from 'components/Inputs/StringInput'

import { getSongName } from 'utils'
import { useEffect, useState } from 'react'

const AutocompleteSong = styled.div`
  flex: 1;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 6px 10px;
  ${props => props.theme.breakpoint.XS} {
    padding: 8px 10px;
  }
  &:hover {
    background: ${props => props.theme.primary_light}11;
  }
`

/**
 *
 * COMPONENT
 *
 */
const SongAutocomplete = (props: {
  label?: string
  excludeSongIds?: number[]
  maxCount?: number
  onSongClick: (song: DBSongType) => void
  placeholder?: string
}) => {
  //
  // SONG AUTOCOMPLETE
  //
  const [songSearch, setSongSearch] = useState<string>()
  const [searchResults, setSearchResults] = useState<DBSongType[]>([])

  const { data: songSearchData } = useQuery(SEARCH_SONGS, {
    variables: {
      search: songSearch,
      excludeIds: props.excludeSongIds || [],
      maxCount: props.maxCount || 6,
    },
    skip: !songSearch,
  })

  useEffect(() => {
    if (songSearch?.length && songSearchData?.getSongs) {
      setSearchResults(songSearchData?.getSongs)
    } else if (!songSearch?.length) {
      setSearchResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songSearch, songSearchData])

  //
  // RENDER
  //
  return (
    <StringInput
      label={props.label}
      value={songSearch}
      setValue={setSongSearch}
      liveUpdate
      placeholder={props.placeholder}
      autocompleteItems={searchResults?.map((song: DBSongType) => {
        return (
          <AutocompleteSong
            key={song.id}
            onClick={() => {
              setSongSearch(null)
              props.onSongClick(song)
            }}
          >
            {getSongName(song)}
          </AutocompleteSong>
        )
      })}
    />
  )
}

export default SongAutocomplete
