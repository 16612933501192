import { InstrumentSectionType, LyricSectionType, SongChordType } from 'types'
import {
  addChordToChordSelection,
  addChordToInstrumentSections,
  getSelectedChordId,
  transposeSongChords,
} from 'utils'
import { forwardRef, useEffect, useState } from 'react'

import FlipMove from 'react-flip-move'
import SongChord from './SongChord'
import SvgIcon from 'components/SvgIcon'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Items = styled.div`
  & > * {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
`

const ButtonsTransposeWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 20px;
  gap: 4px;

  button {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    padding: 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.primary_light}88;

    svg {
      color: ${(props) => props.theme.primary_light};
    }

    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      chords
      setChords
      lyricSections
      setLyricSections
      instrumentSections
      setInstrumentSections
      chord
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <SongChord
          chords={props.chords}
          setChords={props.setChords}
          lyricSections={props.lyricSections}
          setLyricSections={props.setLyricSections}
          instrumentSections={props.instrumentSections}
          setInstrumentSections={props.setInstrumentSections}
          chord={props.chord}
        />
      </div>
    )
  }
)

/**
 *
 *  Component
 *
 */
const ChordSelection = (props: {
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
}) => {
  const { t } = useTranslation()

  const isEmpty = !props.chords?.length

  const [newChordId, setNewChordId] = useState<number>(null)

  const addChord = () => {
    const newChord: SongChordType = addChordToChordSelection({
      chords: props.chords,
      setChords: props.setChords,
    })
    setNewChordId(newChord.id)
  }

  useEffect(() => {
    if (newChordId || newChordId === 0) {
      const htmlId = getSelectedChordId({
        chordId: newChordId,
      })

      document.getElementById(htmlId)?.focus()

      if (props.instrumentSections?.length) {
        /* Add new chord to instrument sections (chord schemas) */
        addChordToInstrumentSections({
          instrumentSections: props.instrumentSections,
          setInstrumentSections: props.setInstrumentSections,
          songChordId: newChordId,
        })
      }

      setNewChordId(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChordId])

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Items>
        <FlipMove
          easing="ease"
          duration={160} // milliseconds
          staggerDurationBy={20} // milliseconds
          staggerDelayBy={20} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: { transform: 'scale(0)' },
            to: { transform: 'none' },
          }}
          enterAnimation={{
            from: { transform: 'scale(0)', transformOrigin: '50% 50%' },
            to: { transform: 'none' },
          }}
          leaveAnimation={{
            from: { transform: 'none', transformOrigin: '50% 50%' },
            to: { transform: 'scale(0)' },
          }}
        >
          {props.chords?.map((chord, i) => (
            <AnimatedItem
              key={chord.id}
              chords={props.chords}
              setChords={props.setChords}
              lyricSections={props.lyricSections}
              setLyricSections={props.setLyricSections}
              instrumentSections={props.instrumentSections}
              setInstrumentSections={props.setInstrumentSections}
              chord={chord}
            />
          ))}
        </FlipMove>
      </Items>

      {isEmpty && <h3>{t('song.chord editor empty')}</h3>}

      {!isEmpty && (
        <ButtonsTransposeWrapper>
          <button
            onClick={() =>
              props.setChords(transposeSongChords(1, props.chords))
            }
            title={t('song.chord editor options.transpose chords up')}
          >
            <SvgIcon code="icon-arrow-up" />
          </button>

          <button
            onClick={() =>
              props.setChords(transposeSongChords(-1, props.chords))
            }
            title={t('song.chord editor options.transpose chords down')}
          >
            <SvgIcon code="icon-arrow-down" />
          </button>
        </ButtonsTransposeWrapper>
      )}

      <ButtonWrapper>
        <button
          onClick={addChord}
          title={t('song.chord editor options.add chord')}
          className="btn-action-circular"
        >
          <SvgIcon code="icon-add" />
        </button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ChordSelection
