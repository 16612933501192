import { GET_LOGGED_USER, LOGIN } from 'api/graphql'
import styled, { keyframes } from 'styled-components'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import AnimatedPulse from '../../components/App/AnimatedPulse'
import AppName from 'components/App/AppName'
import { storeAuthToken } from 'utils/auth'
import { useTranslation } from 'react-i18next'

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.primary};
`

const AppNameWrapper = styled.div`
  ${(props) => props.theme.breakpoint.S} {
    & * {
      font-size: 40px !important;
    }
  }
`

const SeparatorTop = styled.div`
  height: 80px;
`

const Box = styled.div`
  position: relative;
  flex: 1;
  max-width: 360px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 80px;
`

const Input = styled.input`
  flex: 1;
  align-self: stretch;
  min-height: 34px;
  padding: 1px 20px;
  color: ${(props) => props.theme.black};
  background: ${(props) => props.theme.white};
  border: none;
  margin-bottom: 30px;
  box-shadow: 5px 5px 10px 0px ${(props) => props.theme.black}22;

  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
`

const Button = styled.button`
  padding: 5px 20px;
  width: 100%;
  max-width: 200px;
  min-height: 38px;
  background: none;
  border: none;
  text-transform: lowercase;
  color: ${(props) => props.theme.white};
  font-size: 24px;

  &:hover {
    background: ${(props) => props.theme.white}11;
  }

  ${(props) => props.theme.breakpoint.S} {
    font-size: 20px;
  }

  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
`

const Message = styled.div<{ success?: boolean }>`
  position: absolute;
  top: 110%;
  padding: 4px 14px;
  border-radius: 2px;
  color: ${(props) => props.theme.black};
  background: ${(props) =>
    props.success ? props.theme.success : props.theme.warning};
  box-shadow: 5px 5px 10px 0px ${(props) => props.theme.black}22;
  font-size: 14px;
  font-weight: 600;

  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
`

/**
 *
 * COMPONENT
 *
 */
const LoginPage = () => {
  const { t } = useTranslation()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [login, { loading, data }] = useMutation(LOGIN)
  const { refetch: refetchLoggedUser } = useQuery(GET_LOGGED_USER)

  const onSubmit = async () => {
    if (!loading) {
      await login({
        variables: { email: email.trim(), password },
      })
    }
  }

  useEffect(() => {
    if (data?.login?.token?.length) {
      storeAuthToken(data.login.token)
      refetchLoggedUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Wrapper>
      <Box>
        <AnimatedPulse animationDelay="0.3s" />

        <AppNameWrapper>
          <AppName />
        </AppNameWrapper>
        <SeparatorTop />

        <Input
          value={email || ''}
          onChange={(event) => setEmail(event.target.value)}
          placeholder={t('login.email')}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              onSubmit()
            }
          }}
          style={{ animationDelay: '0.2s' }}
        />

        <Input
          value={password || ''}
          onChange={(event) => setPassword(event.target.value)}
          placeholder={t('login.password')}
          type="password"
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              onSubmit()
            }
          }}
          style={{ animationDelay: '0.4s' }}
        />

        <Button onClick={onSubmit} style={{ animationDelay: '0.6s' }}>
          {t('login.submit')}
        </Button>

        {data?.login && (
          <Message success={data?.login?.success}>
            {data?.login && data.login.success
              ? t(data.login.message)
              : `${t('error')}: ${t(data.login.message)}`}
          </Message>
        )}
      </Box>
    </Wrapper>
  )
}

export default LoginPage
