import { A, Box, Email, Item, renderEmail } from 'react-html-email'

import { ComponentType } from 'react'

const { REACT_APP_URL } = process.env

//
// STYLES
//
const emailHeadCSS = `
  body {
    background-color: #F6F6F6;
  }
`.trim()

const WrapperCSS = {
  width: '100%',
  padding: '5vh 5vw',
  paddingBottom: '15vh',
  backgroundColor: '#F6F6F6',
  backgroundSize: '30px 30px',
  backgroundImage: 'radial-gradient(circle,#3384b966 1px, #0000 1px)',
}

const ContentCSS = {
  width: '100%',
  backgroundColor: '#F6F6F6',
  borderRadius: '4px',
}

const ContentGridCSS = {
  padding: '5vh 4vw',
  backgroundColor: '#F6F6F6',
}

const titleCSS = {
  fontSize: 'calc(20px + 0.5vw)',
  fontWeight: '600',
  color: '#000',
  background: '#F6F6F6',
  padding: '5px 0',
}

const title2CSS = {
  fontSize: 'calc(20px + 0.5vw)',
  fontWeight: '800',
  color: '#3384b9',
  background: '#F6F6F6',
  padding: '5px 0',
}

const paragraphCSS = {
  fontSize: '14px',
  fontWeight: '400',
  color: '#000000',
  background: '#F6F6F6',
  padding: '10px',
  paddingBottom: '5px',
  textAlign: 'justify',
}

const buttonCSS = {
  backgroundColor: '#3384b9',
  background: '#3384b9',
  borderRadius: 4,
  cursor: 'pointer',
  height: 48,
  textAlign: 'center',
  textDecoration: 'none',
}

const linkStyle = {
  color: 'white',
  display: 'block',
  paddingBottom: '13px',
  paddingTop: '13px',
  textDecoration: 'none',
  width: '100%',
  fontSize: '14px',
  fontWeight: '600',
}

const footerCSS = {
  backgroundColor: '#F6F6F6',
}

const footerTextCSS = {
  color: '#0007',
  fontSize: '13px',
  fontWeight: '500',
  textAlign: 'center',
}

const footerButtonCSS = {
  paddingTop: '14px',
  cursor: 'pointer',
  textAlign: 'center',
  textDecoration: 'none',
}

const footerLogoCSS = {
  color: '#3384b9',
  fontSize: '14px',
  fontWeight: '800',
  textAlign: 'center',
  textDecoration: 'none',
}

export type TranslatedString = {
  en: string
  cz: string
  sk: string
}

export const EMAIL_LANGUAGE_OPTIONS: EmailLanguageType[] = ['en', 'cz', 'sk']

export type EmailLanguageType = 'en' | 'cz' | 'sk'

export type EmailProps = {
  lang: EmailLanguageType
  subject: TranslatedString
  title: TranslatedString
  title2?: TranslatedString
  paragraphs: TranslatedString[]
  linkName: TranslatedString
  href: string
  footer: TranslatedString
}

//
// EMAIL TEMPLATE
//
const EmailTemplate = (props: EmailProps) => {
  return (
    <Box align="center" style={WrapperCSS}>
      <Email
        align="center"
        headCSS={emailHeadCSS}
        title={props.subject[props.lang]}
      >
        <Box align="center" style={ContentCSS}>
          {/* TITLE */}
          <Item align="center" style={ContentGridCSS}>
            <Item align="center" style={titleCSS}>
              {props.title[props.lang]}
            </Item>
            {props.title2 && (
              <Item align="center" style={title2CSS}>
                {props.title2[props.lang]}
              </Item>
            )}

            <Item style={{ height: '6vh' }} />

            {/* PARAGRAPHS */}
            {props.paragraphs.map((p, i) => (
              <>
                <Item style={paragraphCSS}>{p[props.lang]}</Item>
                {i < props.paragraphs.length ? (
                  <Item style={{ height: 10 }} />
                ) : null}
              </>
            ))}

            <Item style={{ height: '6vh' }} />

            {/* LINK */}
            <Item className="button" style={buttonCSS}>
              <A href={props.href} style={linkStyle}>
                {props.linkName[props.lang]}
              </A>
            </Item>
            <Item style={{ height: 35 }} />
          </Item>
        </Box>

        <Box align="center" style={footerCSS}>
          <Item style={footerTextCSS}>{props.footer[props.lang]}</Item>

          <Item className="button" style={footerButtonCSS}>
            <A align="center" style={footerLogoCSS} href={REACT_APP_URL}>
              {'m-note.art'}
            </A>
          </Item>
        </Box>
      </Email>
    </Box>
  )
}

export default EmailTemplate

//
// SETTINGS AND UTILS
//

/* ReactEmail.configStyleValidator({
  platforms: [
    'gmail',
    'gmail-android',
    'apple-mail',
    'apple-ios',
    'yahoo-mail',
    'outlook',
    'outlook-legacy',
    'outlook-web',
  ],
}) */

export const renderReactEmail = (
  Component: ComponentType<EmailProps>,
  data: EmailProps
) => {
  return renderEmail(<Component {...data} />)
}

export const getEmailHtml = (props: EmailProps) => {
  return renderReactEmail(EmailTemplate, props)
}
