import styled, { css, keyframes } from 'styled-components'

import { DownloadDataType } from '..'
import SvgIcon from 'components/SvgIcon'

export const A4_COMPONENT_EXPORT_ID = 'A4_COMPONENT_EXPORT_ID'
export const A4_COMPONENT_EXPORT_INSTRUMENTS_ID =
  'A4_COMPONENT_EXPORT_INSTRUMENTS_ID'

const Button = styled.div`
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;
  color: ${(props) => props.theme.neutral}AA;
  filter: drop-shadow(0px 5px 3px ${(props) => props.theme.white});

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${(props) => props.theme.black};
    transform: translateY(-3px);
  }

  --size: 30px;
  ${(props) => props.theme.breakpoint.M} {
    --size: 40px;
  }
`

const Icon = styled.div<{ isLoading?: boolean }>`
  transition: all 0.2s ease;
  ${(props) =>
    props.isLoading &&
    css`
      transform: scale(0.5);
    `}

  svg {
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`

const LoadingAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`

const IconDownloading = styled.div`
  position: absolute;

  animation: ${LoadingAnimation} 1s infinite linear;
  color: ${(props) => props.theme.primary_light} !important;

  svg {
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`

const TextDownloading = styled.div`
  position: absolute;
  bottom: 100%;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary_light} !important;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const IconButton = (props: {
  tooltip: string
  icon: any
  onClick: () => void
  downloadData?: DownloadDataType
}) => {
  return (
    <Button title={props.tooltip} onClick={props.onClick}>
      <Icon isLoading={props.downloadData?.isDownloading}>{props.icon}</Icon>
      {props.downloadData?.isDownloading && (
        <>
          <IconDownloading>
            <SvgIcon code="icon-loader" />
          </IconDownloading>

          {props.downloadData?.countToDownload > 1 ? (
            <TextDownloading>
              {props.downloadData.filenamesDownloaded.length}/
              {props.downloadData.countToDownload}
            </TextDownloading>
          ) : null}
        </>
      )}
    </Button>
  )
}

export default IconButton
