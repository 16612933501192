import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { DBPlaylistType } from 'types'
import { File } from 'components/Files/FileSystem'
import FileSystemPlaylists from 'components/Files/FileSystemPlaylists'
import { FloatingButtonType } from 'components/App/FloatingMenu/FloatingButton'
import FloatingButtonWithMenu from 'components/App/FloatingMenu/FloatingButtonWithMenu'
import FloatingMenu from 'components/App/FloatingMenu'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import PlaylistButtonDelete from 'components/Playlist/PlaylistButtonDelete'
import PlaylistDetail from 'components/Playlist/PlaylistDetail'
import PlaylistEditPage from 'components/Playlist/PlaylistEdit'
import SvgIcon from 'components/SvgIcon'
import { hasPlaylistPermission } from 'utils'
import { useTranslation } from 'react-i18next'

const PlaylistsPlage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  //
  // STATE
  //
  const [playlistData, setPlaylistData] = useState<DBPlaylistType>()

  const redirectAfterSubmitRef = useRef(false)

  //
  // DELEGATED FUNCTIONS
  //
  const [doPlaylistSubmit, setDoPlaylistSubmit] = useState<boolean>(false)
  const [doPlaylistCancel, setDoPlaylistCancel] = useState<boolean>(false)

  //
  // NAVIGATION
  //
  const onFileOpenClick = (file: File) => {
    setPlaylistData(undefined)
    setEditParam('')
    updateParams({ playlist: file.id })
  }

  const onPlaylistEditClick = () => {
    setEditParam('')
    updateParams({ edit: playlistParam })
  }

  const onPlaylistAddClick = () => {
    setEditParam('')
    updateParams({ edit: 'new' })
  }

  const onClose = () => {
    if (location.state?.onBackOnClose) {
      // handle closing song opened from different location (e.g. playlist)
      return history.goBack()
    }
    if (editParam && editParam !== 'new' && editParam !== 'import') {
      setEditParam('')
      updateParams({ playlist: editParam })
    } else {
      setEditParam('')
      updateParams({})
    }
  }

  //
  // FUNCTIONS
  //
  const onPlaylistSubmit = (newPlaylistId?: string) => {
    if (newPlaylistId) {
      if (redirectAfterSubmitRef.current) {
        setEditParam('')
        updateParams({ playlist: newPlaylistId })
      }
    } else if (redirectAfterSubmitRef.current) {
      onClose()
    }
  }

  const onPlaylistCancel = () => {
    if (editParam) {
      setDoPlaylistCancel(true)
    }
  }

  //
  // URL PARAMS
  //
  const [playlistParam, setPlaylistParam] = useState('')
  const [editParam, setEditParam] = useState('')

  const createPlaylistUrlParams = (options: {
    playlist?: any
    edit?: any
    parentId?: any
    folderId?: any
  }) => {
    const { playlist, edit } = options
    let searchParams: {
      playlist?: any
      edit?: any
    } = {}
    if (playlist || playlist === 0) {
      searchParams.playlist = playlist
    }
    if (edit || edit === 0) {
      searchParams.edit = edit
      delete searchParams.playlist
    }
    return searchParams
  }

  const updateParams = (options: { playlist?: any; edit?: any }) => {
    history.push({
      pathname: location.pathname,
      search: new URLSearchParams(createPlaylistUrlParams(options)).toString(),
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const playlistId = searchParams.get('playlist')
    const editId = searchParams.get('edit')

    setPlaylistParam(playlistId || '')
    setEditParam(editId || '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //
  // FLOATING MENU
  //
  const renderFloatingMenu = () => {
    const buttons: FloatingButtonType[] = [
      {
        onClick: onPlaylistAddClick,
        tooltip: t('playlist.add new playlist'),
        icon: <SvgIcon code="icon-add" />,
        isVisible: !editParam,
      },

      {
        component: (
          <FloatingButtonWithMenu
            icon={<SvgIcon code="icon-edit" />}
            buttons={[
              {
                tooltipShortIsVisible: true,
                component: (
                  <PlaylistButtonDelete
                    isFloatingButton
                    playlist={playlistData}
                    tooltipShortIsVisible={true}
                    isVisible={hasPlaylistPermission(
                      playlistData?.playlistMembers,
                      'PLAYLIST_deletePlaylist'
                    )}
                    onDelete={onClose}
                  />
                ),
              },

              {
                onClick: onPlaylistEditClick,
                tooltip: t('playlist.edit'),
                tooltipShort: t('playlist.edit short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-edit" />,
                isVisible: hasPlaylistPermission(
                  playlistData?.playlistMembers,
                  'PLAYLIST_updatePlaylist'
                ),
              },
            ]}
          />
        ),
        isVisible: !!(playlistParam && playlistData),
      },

      {
        onClick: () => {
          redirectAfterSubmitRef.current = true
          setDoPlaylistSubmit(true)
        },
        tooltip:
          editParam === 'new'
            ? t('playlist.save new playlist')
            : t('playlist.save edited playlist'),
        icon: <SvgIcon code="icon-save" />,
        isVisible:
          editParam &&
          (editParam === 'new' ||
            (playlistData &&
              hasPlaylistPermission(
                playlistData.playlistMembers,
                'PLAYLIST_updatePlaylist'
              ))),
      },

      {
        onClick: () => {
          redirectAfterSubmitRef.current = false
          setDoPlaylistSubmit(true)
        },
        tooltip:
          editParam === 'new'
            ? t('playlist.save new playlist without leaving')
            : t('playlist.save edited playlist without leaving'),
        icon: <SvgIcon code="icon-save-without-leaving" />,
        isVisible:
          editParam &&
          (editParam === 'new' ||
            (playlistData &&
              hasPlaylistPermission(
                playlistData.playlistMembers,
                'PLAYLIST_updatePlaylist'
              ))),
      },

      {
        onClick: editParam ? onPlaylistCancel : onClose,
        tooltip: t('close'),
        icon: <SvgIcon code="icon-close" />,
        isSubdued: true,
        isVisible: playlistParam || editParam ? true : false,
      },
    ]

    return <FloatingMenu buttons={buttons} />
  }

  //
  // RENDER
  //
  const doRenderDetail = () => playlistParam?.length > 0
  const doRenderEdit = () => editParam?.length > 0

  return (
    <>
      {/*
       ** VIEW - FILES
       */}
      {!doRenderDetail() && !doRenderEdit() && (
        <LoaderPageLayout
          title={t('navigation.playlists')}
          isLoading={false}
          isReady={true}
        >
          <FileSystemPlaylists
            defaultViewMode="grid"
            onFileDoubleClick={onFileOpenClick}
            onFileOpenClick={onFileOpenClick}
          />
        </LoaderPageLayout>
      )}

      {/*
       ** VIEW - SONG DETAIL
       */}
      {doRenderDetail() && (
        <PlaylistDetail
          playlistId={playlistParam}
          onDelete={onClose}
          setOutsidePlaylistData={setPlaylistData}
        />
      )}

      {/*
       ** VIEW - SONG EDIT
       */}
      {doRenderEdit() && (
        <PlaylistEditPage
          playlistId={editParam}
          doSubmit={doPlaylistSubmit}
          setDoSubmit={setDoPlaylistSubmit}
          doCancel={doPlaylistCancel}
          setDoCancel={setDoPlaylistCancel}
          onSubmit={onPlaylistSubmit}
          onCancel={onClose}
          setOutsidePlaylistData={setPlaylistData}
        />
      )}

      {renderFloatingMenu()}
    </>
  )
}

export default PlaylistsPlage
