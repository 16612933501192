/* eslint-disable react-hooks/exhaustive-deps */
import { DBPlaylistType, PlaylistBodyType, PlaylistMemberType } from 'types'
import { EMPTY_PLAYLIST, PLAYLIST_ROLES, ROUTES } from 'const'
import {
  hasPlaylistPermission,
  isPlaylistPrivate,
  parsePlaylistBody,
} from 'utils'
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { GET_PLAYLIST } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import PlaylistEditor from './PlaylistEditor'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const PlaylistEditPage = (props: {
  playlistId: string
  doSubmit: boolean
  setDoSubmit: (value: boolean) => void
  doCancel: boolean
  setDoCancel: (value: boolean) => void
  onSubmit: (newPlaylistId?: string) => void
  onCancel: () => void
  setOutsidePlaylistData?: (playlist: DBPlaylistType) => void
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const isAddNew = props.playlistId === 'new'
  const [notAllowed, setNotAllowed] = useState<boolean>(false)

  //
  // DATA
  //
  const [playlist, setPlaylist] = useState<DBPlaylistType>()

  const [playlistBody, setPlaylistBody] = useState<PlaylistBodyType>(
    isAddNew ? EMPTY_PLAYLIST : undefined
  )

  const [playlistMembers, setPlaylistMembers] = useState<PlaylistMemberType[]>(
    isAddNew
      ? [
          {
            user: loggedUser,
            playlistRole: { code: PLAYLIST_ROLES.admin },
          },
        ]
      : undefined
  )

  const onSubmit = (newPlaylistId?: string) => {
    props.onSubmit(newPlaylistId)
  }

  const onCancel = () => {
    props.onCancel()
  }

  //
  // QUERY PLAYLIST DATA
  //
  const { data: fetchedPlaylistData, loading } = useQuery(GET_PLAYLIST, {
    variables: { id: props.playlistId },
    skip: isAddNew,
  })

  useEffect(() => {
    if (fetchedPlaylistData?.getPlaylist) {
      const playlist: DBPlaylistType = fetchedPlaylistData?.getPlaylist

      if (
        hasPlaylistPermission(
          playlist?.playlistMembers,
          'PLAYLIST_updatePlaylist'
        )
      ) {
        setPlaylist(playlist)
        setPlaylistBody(parsePlaylistBody(playlist))
        setPlaylistMembers(playlist?.playlistMembers)

        if (props.setOutsidePlaylistData) {
          props.setOutsidePlaylistData(playlist)
        }
      } else {
        setNotAllowed(true)
        toast.warning(t('playlist.no_edit_access_redirect'))
      }
    }
  }, [fetchedPlaylistData])

  //
  // RENDER
  //
  if (!isAddNew && !loading && !fetchedPlaylistData) {
    return <Redirect to={ROUTES.playlists.slug} />
  }

  if (notAllowed) {
    return (
      <Redirect to={`${ROUTES.playlists.slug}?playlist=${props.playlistId}`} />
    )
  }

  return (
    <LoaderPageLayout
      title={isAddNew ? t('playlist.new') : t('playlist.edit')}
      isPrivate={isPlaylistPrivate(playlist)}
      loadingText={t('playlist.loading playlist data')}
      isLoading={loading}
      isReady={!!playlistBody}
      isWidePage
    >
      {playlistBody && (
        <>
          <PlaylistEditor
            playlist={playlist}
            playlistBody={playlistBody}
            playlistMembers={playlistMembers}
            isEditMode={!isAddNew}
            doSubmit={props.doSubmit}
            setDoSubmit={props.setDoSubmit}
            doCancel={props.doCancel}
            setDoCancel={props.setDoCancel}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </LoaderPageLayout>
  )
}

export default PlaylistEditPage
