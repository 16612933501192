import { STRING_INSTRUMENT_TUNINGS } from 'const'
import { ChordSchemaType, StringChordSchemaType } from 'types'
import { isStringInstrument } from 'utils'

export const createStringChordSchema = (notes: string[]) => {
  const chordSchema: StringChordSchemaType = {
    strings: notes.map(note => {
      return {
        note: note,
        mute: false,
        fret: 0,
      }
    }),
  }
  return chordSchema
}

export const createChordSchema = (props: {
  instrumentCode: string
  notes?: string[]
}) => {
  if (isStringInstrument(props.instrumentCode)) {
    const defaultTuning =
      STRING_INSTRUMENT_TUNINGS[props.instrumentCode] &&
      STRING_INSTRUMENT_TUNINGS[props.instrumentCode][0]

    return createStringChordSchema(
      props.notes || defaultTuning?.notes
    ) as ChordSchemaType
  }

  return null
}
