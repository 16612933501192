import {
  DBSongType,
  PlaylistSongType,
  SongGeneralInfoType,
  SongMemberType,
  SongType,
  TextLyricDesignType,
} from 'types'

import { EmailLanguageType } from 'components/EmailTemplate'
import { SONG_ROLES } from 'const'
import { getPlaylistSongUserSettings } from 'utils'

export const getLyricNoteId = (props: { lyricNoteId: number }) => {
  return `lyricNote(${props.lyricNoteId})`
}

export const getSelectedChordId = (props: { chordId: number }) => {
  return `selectedChord(${props.chordId})`
}

export const getSongName = (
  song: DBSongType | SongType,
  options?: { authorInParentheses?: boolean }
) => {
  if (!song) {
    return '?'
  }
  const songName = song.name.trim()

  if (song.author) {
    const songAuthor = song.author.trim()

    return options?.authorInParentheses
      ? `${songName} (${songAuthor})`
      : `${songAuthor} - ${songName}`
  }
  return songName
}

export const getGeneralInfoString = (generalInfo: SongGeneralInfoType) => {
  const key = generalInfo.key
  const bpm = generalInfo.bpm
  const timeSignature = generalInfo.timeSignature

  let str = ''
  if (key) {
    str = `Key: ${key}`
  }
  if (bpm) {
    const bpmStr = `${bpm} BPM`
    str = str.length ? `${str}, ${bpmStr}` : `${bpmStr}`
  }
  if (timeSignature) {
    str = str.length ? `${str}, ${timeSignature}` : `${timeSignature}`
  }

  return str
}

export const getSongFromPlaylistSong = (playlistSong: PlaylistSongType) => {
  let song = playlistSong?.songData

  const userSettings = getPlaylistSongUserSettings(playlistSong)

  if (song) {
    song = {
      ...song,
      note: userSettings?.note
        ? song.note
          ? `${song.note}\n${userSettings.note}`
          : userSettings.note
        : song.note,
    }
  }
  return song
}

export type UserFromSongMembers = {
  userId: number
  email: string
  lang: EmailLanguageType
  canEdit: boolean
}

export const getUsersFromSongMembers = (songMembers: SongMemberType[]) => {
  const users: UserFromSongMembers[] = [...songMembers]
    .map((member) => {
      if (member.userGroup) {
        return member.userGroup.userGroupMembers?.map((groupMember) => {
          return {
            userId: groupMember.user?.id,
            email: groupMember.user?.email,
            lang: groupMember.user?.languageCode as EmailLanguageType,
            canEdit: member.songRole.code === SONG_ROLES.admin,
          }
        })
      }
      return {
        userId: member.user?.id,
        email: member.user?.email,
        lang: member.user?.languageCode as EmailLanguageType,
        canEdit: member.songRole.code === SONG_ROLES.admin,
      }
    })
    .flat()

  const uniqueUsers: UserFromSongMembers[] = []

  users.forEach((user) => {
    if (!uniqueUsers.map((u) => u.userId).includes(user.userId)) {
      uniqueUsers.push(user)
    }
  })

  return uniqueUsers
}

export const getScaledTextLyricDesign = (
  textLyricDesign: TextLyricDesignType,
  scale: number
) => {
  const { fontSize, letterSpacing, lineGapY } = textLyricDesign

  return {
    ...textLyricDesign,
    contentPaddingTop: 0,
    contentPaddingLeft: 0,
    contentPaddingRight: 0,
    fontSize: fontSize * scale,
    letterSpacing: letterSpacing * scale,
    lineGapY: lineGapY * scale,
  } as TextLyricDesignType
}
