import styled, { css } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import Checkbox from 'components/Inputs/Checkbox'
import { useState } from 'react'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  background: ${(props) => props.theme.background};
  outline: 1px solid ${(props) => props.theme.admin}99;
  border-radius: 10px;
`

const Header = styled.div<{ isOpen?: boolean }>`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;

  transition: all 0.15s ease;
  cursor: pointer;

  background: ${(props) =>
    props.isOpen ? `${props.theme.admin}11` : props.theme.background};

  & > *:first-child {
    flex: 0;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.admin};
`

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.black}99;
`

const Content = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  background: ${(props) => props.theme.background};

  transition: all 0.15s ease;

  ${(props) =>
    !props.isOpen
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}

  & > * > * {
    /* AnimateHeight Component */
    width: calc(100% - 2 * 10px);
    padding: 10px;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ModuleWrapper = (props: {
  title?: string
  subtitle?: string
  isOpen?: boolean
  children: any
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen)

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Header isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Checkbox value={isOpen} />
        <div>
          {props.title && <Title>{props.title}</Title>}
          {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
        </div>
      </Header>

      <Content isOpen={isOpen}>
        <AnimateHeight
          height={isOpen ? 'auto' : 0}
          animateOpacity
          style={{ opacity: isOpen ? 1 : 0 }}
        >
          {isOpen && props.children}
        </AnimateHeight>
      </Content>
    </Wrapper>
  )
}

export default ModuleWrapper
