import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'
import styled, { css, keyframes } from 'styled-components'

import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import { useRef } from 'react'

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: rotateZ(360deg) translateY(60vh);
  }
`

const Wrapper = styled.div<{ index: number; isHidden?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transform: rotateZ(360deg);
  transition: 0.2s ease all;

  user-select: none !important;

  ${(props) =>
    props.index === 0
      ? css`
          z-index: 10;
        `
      : css`
          pointer-events: none !important;
          opacity: 0;
          z-index: 0;
          transform: rotateZ(360deg) translateX(${props.index * 90}%);
        `}

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0;
      transform: rotateZ(360deg) translateY(-60vh);
    `}

  animation: ${AnimationIn} 0.15s backwards
        cubic-bezier(0.215, 0.61, 0.355, 1);
`

const Viewport = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  uses-select: none !important;
  cursor: move;

  display: flex;
  align-items: center;
  justify-content: center;
`

const PaperPosition = styled.div`
  position: relative;
  flex: 1;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
`

/**
 *
 * COMPONENT
 *
 */
const PaperViewport = (props: {
  index: number
  activeIndex: number
  isHidden?: boolean
  skipRenderIfHidden?: boolean
  children: any
}) => {
  const viewportRef = useRef()

  //
  // VIEWPORT ACTIONS
  //
  const onViewportInteraction = ({ x, y, scale }) => {
    const elem: HTMLElement = viewportRef?.current
    if (elem) {
      elem.style.setProperty('transform', make3dTransformValue({ x, y, scale }))
    }
  }

  //
  // RENDER
  //
  return props.isHidden && props.skipRenderIfHidden ? null : (
    <Wrapper index={props.index - props.activeIndex} isHidden={props.isHidden}>
      <QuickPinchZoom
        onUpdate={onViewportInteraction}
        inertia={false}
        maxZoom={250}
        minZoom={0.5}
        tapZoomFactor={0.2}
        zoomOutFactor={0.5}
        lockDragAxis={false}
        verticalPadding={1000}
        horizontalPadding={1000}
      >
        <Viewport ref={viewportRef}>
          <PaperPosition>
            <FillScreenPaperWrapper>{props.children}</FillScreenPaperWrapper>
          </PaperPosition>
        </Viewport>
      </QuickPinchZoom>
    </Wrapper>
  )
}

export default PaperViewport
