import fileDownload from 'js-file-download'
import i18n from 'i18n'
import { toast } from 'react-toastify'

export const notifyDownloadFinished = (fileName: string) => {
  toast.success(
    i18n.t(`download.file downloaded`).replace('FILE_NAME', fileName)
  )
}

export const downloadAsFile = (options: { data: string; fileName: string }) => {
  fileDownload(options.data, options.fileName)
  notifyDownloadFinished(options.fileName)
  return true
}

export const idAsNumber = (id) => {
  return parseInt(`${id}`)
}

export const uniqueArray = (arr: any[]) => {
  return [...(new Set(arr) as any)] as any[]
}
