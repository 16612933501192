import styled, { css } from 'styled-components'

import StringInput from 'components/Inputs/StringInput'

const Wrapper = styled.div<{ isEditable?: boolean }>`
  flex: 1;

  input {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    display: flex;

    height: 20px;
    min-height: 16px;

    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.primary_light};
    margin-bottom: 4px;

    &:focus {
      background: ${(props) => props.theme.black}11;
    }

    ${(props) =>
      props.isEditable &&
      css`
        background: ${(props) => props.theme.primary_light}15;
      `}
  }
`

const ChordName = (props: {
  name: string
  setName?: (name: string) => void
  liveUpdate?: boolean
}) => {
  return (
    <Wrapper isEditable={!!props.setName}>
      <StringInput
        value={props.name || ''}
        setValue={
          props.setName
            ? (value) => {
                props.setName(value)
              }
            : undefined
        }
        disabled={!props.setName}
        placeholder="?"
        liveUpdate={
          props.liveUpdate === undefined ? true : props.liveUpdate
        } /* WON'T UPDATE INSTRUMENT CHORD NAME WHEN CHANGING IN SONG WITHOUT LIVEUPDATE */
      />
    </Wrapper>
  )
}

export default ChordName
