import styled, { css } from 'styled-components'

//
// Default Styles
//
export const resetButtonCss = css`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  gap: 0px;
  cursor: pointer;
  backdrop-filter: none;
  box-shadow: none;
  &:hover {
    background: none;
  }
`

export const defaultButtonCss = css`
  ${resetButtonCss}
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 400;
  text-align: center;

  color: ${(props) => props.theme.black};
  border: 1px solid ${(props) => props.theme.black}44;
  border-radius: 1px;
  box-shadow: 0 0 10px 0px ${(props) => props.theme.black}0A;

  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => props.theme.black}0A;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

export const defaultButtons = css`
  button {
    ${defaultButtonCss}

    &.locked {
      pointer-events: none;
      opacity: 0.7 !important;
    }

    &.btn-icon-only {
      ${resetButtonCss}
      padding: 10px;
      border-radius: 4px;
      svg {
        width: 20px;
        height: 20px;
      }
      color: ${(props) => props.theme.black}AA;
      &:hover {
        color: ${(props) => props.theme.black};
      }
    }

    &.btn-action-circular {
      --size: 50px;
      width: var(--size);
      height: var(--size);
      min-width: var(--size);
      min-height: var(--size);

      padding: 10px;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      background: ${(props) => props.theme.background};

      svg {
        color: ${(props) => props.theme.black}99;
        width: min(24px, 100%);
        height: min(24px, 100%);
      }

      &:hover {
        background: ${(props) => props.theme.lightest};
        box-shadow: 2px 2px 10px 0px ${(props) => props.theme.black}08;

        transform: scale(1.1);
        ${(props) => props.theme.breakpoint.M} {
          transform: none;
        }
      }

      &--primary {
        border: none;
        color: ${(props) => props.theme.white};
        background: ${(props) => props.theme.primary_light};
        box-shadow: 0px 4px 10px 0px ${(props) => props.theme.black}08;
        svg {
          color: ${(props) => props.theme.white};
        }
        &:hover {
          filter: brightness(1.02);
          background: ${(props) => props.theme.primary_light};
          box-shadow: 0px 8px 14px 0px ${(props) => props.theme.black}08;
        }
      }

      &--primary--neutral {
        background: ${(props) => props.theme.neutral};
        &:hover {
          background: ${(props) => props.theme.neutral};
        }
      }
    }

    &.btn-primary {
      flex: 1;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 10px;

      min-height: 50px;
      border-radius: 5px;
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.primary_light};
      box-shadow: 0px 4px 10px 0px ${(props) => props.theme.black}22;

      font-size: 14px;
      font-weight: 500;

      svg {
        --size: 20px;
        width: var(--size);
        height: var(--size);
      }

      &:hover {
        filter: brightness(1.1);
        transform: translateY(-2px);
        box-shadow: 0px 8px 14px 0px ${(props) => props.theme.black}22;
      }

      &--neutral {
        color: ${(props) => props.theme.black};
        background: ${(props) => props.theme.light};
        box-shadow: 0px 4px 10px 0px ${(props) => props.theme.black}08;
        &:hover {
          filter: brightness(1.02);
          transform: translateY(-2px);
          box-shadow: 0px 8px 14px 0px ${(props) => props.theme.black}08;
        }
      }
    }
  }
`

export const ActionButton = styled.button`
  ${resetButtonCss}
  color: ${(props) => props.theme.black};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 2px;
  svg {
    --size: 16px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`
