export const SONG_PLACEHOLDER = `
[Verse]
C                                  Fm
I used to think that I could not go on
C                                  Fm
And life was nothing but an awful song
C                                  Fm
But now I know the meaning of true love
C                                 Fm
I'm kneeling on the everlasting lines
 
 
[Pre-Chorus]
         Am
If I can see it
           Fm
Then I can do it
            C
If I just believe it
                 Gsus4
There's nothing to it
 
 
[Chorus]
                C
I believe I can fly
                 Am7
I believe I can touch the sky
                        Dm
I think about it every night and day
                    Gsus4
Spread my wings and fly away
                  Am
I believe I can soar
                             Fm
I see me running through that open door
               C
I believe I can fly
                Fm
I believe I can fly
                 Am
I believe I can fly 
 
Oh...
 
 
[Verse]
      C                        Fm
See I was on the verge of breaking out
       C                      Fm
Sometimes silence can seem so loud
       C                        Fm
There are miracles in life I must achieve
      C                       Fm
But first I know it starts inside of me oh oh
 
 
[Pre-chorus]
         Am
If I can see it
           Fm
Then I can do it
            C
If I just believe it
                 Gsus4
There's nothing to it
 
 
[Chorus]
                C
I believe I can fly
                 Am7
I believe I can touch the sky
                        Dm
I think about it every night and day
                    Gsus4
Spread my wings and fly away
                  Am
I believe I can soar
                             Fm
I see me running through that open door
               C
I believe I can fly
                Fm
I believe I can fly
                 Am
I believe I can fly
Fm
Oh...
                   C   Gsus4
Cause I believe in you
 
 
[Pre-Chorus]
         Am
If I can see it
           Fm
Then I can do it
            C
If I just believe it
                 Gsus4
There's nothing to it
 
 
[Chorus]
                C
I believe I can fly
                 Am7
I believe I can touch the sky
                        Dm
I think about it every night and day
                    Gsus4
Spread my wings and fly away
                  Am
I believe I can soar
                             Fm
I see me running through that open door
               C
I believe I can fly
                Fm
I believe I can fly
                 Am
I believe I can fly 
X
`
