import styled, { css, keyframes } from 'styled-components'

import SvgIcon from 'components/SvgIcon'

const ViewButton = styled.button<{ onClick?: any }>`
  position: relative;
  padding: 6px !important;
  --size: 40px !important;
  ${(props) =>
    !props.onClick &&
    css`
      cursor: wait !important;
    `}
`

const AnimationIn = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`
const ViewButtonLoader = styled.div`
  position: absolute;
  overflow: hidden;
  --size: 50px;
  svg {
    animation: ${AnimationIn} 1s infinite linear;
    color: ${(props) => props.theme.primary_light} !important;
    min-width: var(--size);
    min-height: var(--size);
  }
`

export type FileSystemIconButtonType = {
  onClick: () => void
  isActive?: boolean
  hint?: string
  icon: any
  isLoading?: boolean
}

const FileSystemIconButton = (props: FileSystemIconButtonType) => {
  return (
    <ViewButton
      onClick={!props.isLoading && props.onClick}
      className={`btn-action-circular ${
        props.isActive
          ? 'btn-action-circular--primary btn-action-circular--primary--neutral'
          : ''
      }`}
      title={props.hint}
    >
      {props.isLoading && (
        <ViewButtonLoader>
          <SvgIcon code="icon-loader" />
        </ViewButtonLoader>
      )}
      {props.icon}
    </ViewButton>
  )
}

export default FileSystemIconButton
