import styled, { css } from 'styled-components'
import { useEffect, useRef, useState } from 'react'

import InputLabel from 'components/Inputs/_InputLabel'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import { useClickOutside } from 'hooks'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Input = styled.input<{ monospace?: boolean; isInvalid?: boolean }>`
  color: ${(props) => props.theme.black};

  &:focus {
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.black}0A;
  }

  ${(props) =>
    props.monospace &&
    css`
      font-family: monospace;
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      background: ${(props) => props.theme.warning}11;
      border-color: ${(props) => props.theme.warning};
    `}
`

const AutocompleteWrapper = styled.div`
  ${defaultInputCss}
  padding: 0;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${(props) => props.theme.background}CC;
  backdrop-filter: blur(10px);
`

const AutocompleteRow = styled.div``

const StringInput = (props: {
  label?: string
  placeholder?: string
  value: string | number | null
  setValue: (value: any, event?: any) => void
  onBlur?: () => void
  monospace?: boolean
  id?: string
  disabled?: boolean
  isInvalid?: boolean
  liveUpdate?: boolean
  isSecret?: boolean
  autocompleteItems?: any[]
  type?: string
}) => {
  //
  // LOCAL VALUE (FOR onBlur UPDATE)
  //
  const [isInit, setIsInit] = useState<boolean>(true)
  const [localValue, setLocalValue] = useState<string>('')
  const [hideAutocomplete, setHideAutocomplete] = useState<boolean>(false)

  const updateLocalValue = () => {
    setLocalValue(
      typeof props.value === 'number' ? props.value.toString() : props.value
    )
  }

  useEffect(() => {
    if (!props.liveUpdate && isInit) {
      updateLocalValue()
      setIsInit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateLocalValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      /* BLUR ON 'ENTER' KEY */
      event.target.blur()
    }
  }

  const ref = useRef()
  useClickOutside(ref, () => {
    setHideAutocomplete(true)
  })

  return (
    <Wrapper ref={ref}>
      {props.label && (
        <InputLabel label={props.label} isInvalid={props.isInvalid} />
      )}

      {!props.liveUpdate ? (
        <Input
          type={props.type ? props.type : props.isSecret ? 'password' : 'text'}
          value={(localValue?.length && localValue) || ''}
          onChange={(event) => {
            setLocalValue(event.target?.value)
          }}
          placeholder={props.placeholder}
          monospace={props.monospace}
          disabled={props.disabled}
          id={props.id ? props.id : undefined}
          onKeyDown={onKeyDown}
          isInvalid={props.isInvalid}
          onBlur={(event) => {
            props.setValue(localValue, event)
          }}
          onFocus={() => setHideAutocomplete(false)}
          autoComplete="off"
          tabIndex={props.disabled || !props.setValue ? '-1' : undefined}
        />
      ) : (
        <Input
          type={props.type ? props.type : props.isSecret ? 'password' : 'text'}
          value={props.value || ''}
          onChange={(event) => props.setValue(event.target.value, event)}
          placeholder={props.placeholder}
          monospace={props.monospace}
          disabled={props.disabled}
          id={props.id ? props.id : undefined}
          onKeyDown={onKeyDown}
          isInvalid={props.isInvalid}
          onFocus={() => setHideAutocomplete(false)}
          autoComplete="off"
          tabIndex={props.disabled || !props.setValue ? '-1' : undefined}
        />
      )}
      {!hideAutocomplete && props.autocompleteItems?.length > 0 && (
        <AutocompleteWrapper>
          {props.autocompleteItems.map((item, i) => (
            <AutocompleteRow key={i}>{item}</AutocompleteRow>
          ))}
        </AutocompleteWrapper>
      )}
    </Wrapper>
  )
}

export default StringInput
