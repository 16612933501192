/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import styled, { keyframes } from 'styled-components'

import CheckboxSongInput from 'components/Inputs/CheckboxSongInput'
import { FloatingButtonType } from 'components/App/FloatingMenu/FloatingButton'
import FloatingMenu from 'components/App/FloatingMenu'
import InstrumentsPaperA4 from 'components/Song/Paper/InstrumentsPaperA4'
import PaperViewport from './PaperViewport'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import { SongType } from 'types'
import SvgIcon from 'components/SvgIcon'
import { TEXT_LYRICS_DEFAULT_DESIGN } from 'const'
import TransposeInput from 'components/Inputs/TransposeInput'
import { getPlaylistSongAdditionalText } from 'utils'
import { gridBackground } from 'styles/GlobalStyle/Backgrounds'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: relative;
`

const ModalAnimationIn = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
`
const ModalBox = styled.div`
  animation-name: ${ModalAnimationIn};
  animation-timing-function: ease-out;
  animation-duration: 0.15s;
  animation-fill-mode: backwards;

  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-anchor: 'none';
  overscroll-behavior: contain;

  //backdrop-filter: blur(10px);
  background: ${(props) => props.theme.background};

  ${gridBackground}

  transition: all 0.1s ease;

  display: flex;
  align-items: center;
`

const FloatingMenuWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`

const PopCapoWrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none !important;
`

const AnimationInBg = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`
const PopCapoBg = styled.div`
  animation-name: ${AnimationInBg};
  animation-timing-function: cubic-bezier(0.12, 0.63, 0.38, 0.9);
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
  opacity: 0;

  background: ${(props) => props.theme.primary_light}88;

  --size: max(140vw, 140vh);
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);

  border-radius: 50%;
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const PopCapo = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
  opacity: 0;

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.white};

  font-size: 100px;
  font-weight: 600;
`

const PopCapoText = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
  opacity: 0;

  margin-top: 140px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.white};

  font-size: 30px;
  font-weight: 600;
`

/**
 *
 * REACTIVE VARS
 *
 */

export type SongOptionsType = {
  transpose: number
  setTranspose: (number) => void
  simplifyChords: boolean
  setSimplifyChords: (boolean) => void
  capoOffset: number
  setCapoOffset: (number) => void
}

export type SongModalType = {
  songBody: SongType
  transpose?: number
  setTranspose?: (number) => void
  simplifyChords?: boolean
  setSimplifyChords?: (boolean) => void
  capoOffset?: number
  setCapoOffset?: (number) => void
  userStart?: string
  userEnd?: string
}

export type ViewType = 'song' | 'instruments'

export type ModalDataType = {
  isOpen: boolean
  viewType?: ViewType
  onClose?: () => void
  onNext?: () => void
  onPrev?: () => void
  songs?: SongModalType[]
  activeSongIndex?: number
}

const SONG_ZOOM_MODAL_DEFAULT: ModalDataType = {
  isOpen: false,
}

export const SONG_ZOOM_MODAL = makeVar<ModalDataType>(SONG_ZOOM_MODAL_DEFAULT)

/**
 *
 * COMPONENT
 *
 */
const SongZoomModal = () => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const [notifyCapo, setNotifyCapo] = useState<boolean>(true)

  const [songs, setSongs] = useState<SongModalType[]>([])
  const [songsOptions, setSongsOptions] = useState<SongOptionsType[]>([])

  const [songViewType, setSongViewType] = useState<ViewType>('song')
  const songViewTypeRef = useRef(songViewType)

  useEffect(() => {
    songViewTypeRef.current = songViewType
  }, [songViewType])

  //
  // LOCAL MODAL DATA
  //
  const modalData = useReactiveVar(SONG_ZOOM_MODAL)
  const [modalDataLocal, setModalDataLocal] = useState<ModalDataType>()
  const modalDataLocalRef = useRef(modalDataLocal)

  useEffect(() => {
    if (modalData) {
      setModalDataLocal({
        ...modalData,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData])

  useEffect(() => {
    modalDataLocalRef.current = modalDataLocal
  }, [modalDataLocal])

  //
  // INIT SONGS AND SONG OPTIONS
  //
  useEffect(() => {
    if (modalData?.songs) {
      const receivedSongs = modalData?.songs || []
      setSongs(receivedSongs)

      setSongsOptions(
        receivedSongs?.map((ms) => {
          return {
            transpose: ms.transpose || 0,
            setTranspose: ms.setTranspose,
            capoOffset: ms.capoOffset || 0,
            setCapoOffset: ms.setCapoOffset,
            simplifyChords: !!ms.simplifyChords,
            setSimplifyChords: ms.setSimplifyChords,
          }
        })
      )
    }
  }, [modalData])

  useEffect(() => {
    setSongViewType(modalData.viewType || 'song')
  }, [modalData?.isOpen])

  //
  // UPDATE SONG OPTIONS
  //
  const updateActiveSongOptions = (updatedOptions: {
    transpose?: number
    capoOffset?: number
    simplifyChords?: boolean
  }) => {
    setSongsOptions(
      [...songsOptions].map((options, i) =>
        i === (modalDataLocal.activeSongIndex || 0)
          ? { ...options, ...updatedOptions }
          : options
      )
    )
  }

  const setTranspose = (val) => {
    const activeSongOptions = songsOptions[modalDataLocal.activeSongIndex || 0]
    if (activeSongOptions?.setTranspose) {
      activeSongOptions?.setTranspose(val)
    }
    updateActiveSongOptions({ transpose: val })
  }

  const setSimplifyChords = (val) => {
    const activeSongOptions = songsOptions[modalDataLocal.activeSongIndex || 0]
    if (activeSongOptions?.setSimplifyChords) {
      activeSongOptions?.setSimplifyChords(val)
    }
    updateActiveSongOptions({ simplifyChords: val })
  }

  const setCapoOffset = (val) => {
    const activeSongOptions = songsOptions[modalDataLocal.activeSongIndex || 0]
    if (activeSongOptions?.setCapoOffset) {
      activeSongOptions?.setCapoOffset(val)
    }
    updateActiveSongOptions({ capoOffset: val })
  }

  const resetViewTypeBeforeNavigation = useRef(
    (options: {
      navFunc: () => void
      setSongViewType: (val: ViewType) => void
    }) => {
      if (songViewTypeRef.current !== 'song') {
        setSongViewType('song')
        setTimeout(options.navFunc, 200)
      } else {
        options.navFunc()
      }
    }
  )

  //
  // CLOSE
  //
  const closeModal = () => {
    if (modalDataLocalRef?.current?.onClose) {
      modalDataLocalRef.current.onClose()
    }
    SONG_ZOOM_MODAL(SONG_ZOOM_MODAL_DEFAULT)
  }

  //
  // KEY LISTENER
  //
  useEffect(() => {
    const handleKeydown = (event) => {
      // ESC
      if (event.keyCode === 27) {
        closeModal()
        return
      }

      // ARROW RIGHT
      if (event.keyCode === 39) {
        if (modalDataLocalRef?.current?.onNext) {
          resetViewTypeBeforeNavigation.current({
            navFunc: modalDataLocalRef.current.onNext,
            setSongViewType: setSongViewType,
          })
        }
        return
      }

      // ARROW LEFT
      if (event.keyCode === 37) {
        if (modalDataLocalRef?.current?.onPrev) {
          resetViewTypeBeforeNavigation.current({
            navFunc: modalDataLocalRef.current.onPrev,
            setSongViewType: setSongViewType,
          })
        }
        return
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //
  // FOOTER MENU
  //
  const renderFooterMenu = () => {
    const activeSongOptions = songsOptions[modalDataLocal.activeSongIndex || 0]

    return (
      <>
        <TransposeInput
          value={activeSongOptions?.transpose}
          setValue={setTranspose}
          roundCorners
        />

        <TransposeInput
          value={activeSongOptions?.capoOffset}
          setValue={setCapoOffset}
          roundCorners
          isCapo
        />

        <SimplifyInput
          value={activeSongOptions?.simplifyChords}
          setValue={setSimplifyChords}
          roundCorners
        />

        <CheckboxSongInput
          title={t('playlist.song.capo reminder')}
          value={notifyCapo}
          setValue={setNotifyCapo}
          roundCorners
        />
      </>
    )
  }

  //
  // FLOATING MENU
  //
  const renderFloatingMenu = () => {
    const buttons: FloatingButtonType[] = [
      {
        onClick: () => setIsMenuOpen(!isMenuOpen),
        tooltip: t('song modal.open menu'),
        icon: isMenuOpen ? (
          <SvgIcon code="icon-song-settings-close" />
        ) : (
          <SvgIcon code="icon-song-settings" />
        ),
        isVisible: true,
        isIndented:
          songs[modalDataLocal.activeSongIndex || 0]?.songBody
            ?.instrumentSections?.length > 0
            ? false
            : true,
      },

      {
        onClick: () =>
          setSongViewType(songViewType !== 'song' ? 'song' : 'instruments'),
        tooltip: t('song modal.toggle instruments view'),
        icon: <SvgIcon code="icon-chords" />,
        isSubdued: songViewType === 'song',
        isVisible:
          songs[modalDataLocal.activeSongIndex || 0]?.songBody
            ?.instrumentSections?.length > 0
            ? true
            : false,
        isIndented: true,
      },

      {
        onClick: modalDataLocal.onPrev
          ? () =>
              resetViewTypeBeforeNavigation.current({
                navFunc: modalDataLocalRef.current.onPrev,
                setSongViewType: setSongViewType,
              })
          : undefined,
        tooltip: t('playlist.song.prev'),
        icon: <SvgIcon code="icon-song-prev" />,
        isVisible: !!(modalDataLocal.onPrev || modalDataLocal.onNext),
        isDisabled: !modalDataLocal.onPrev,
      },

      {
        onClick: modalDataLocal.onNext
          ? () =>
              resetViewTypeBeforeNavigation.current({
                navFunc: modalDataLocalRef.current.onNext,
                setSongViewType: setSongViewType,
              })
          : undefined,
        tooltip: t('playlist.song.next'),
        icon: <SvgIcon code="icon-song-next" />,
        isVisible: !!(modalDataLocal.onPrev || modalDataLocal.onNext),
        isDisabled: !modalDataLocal.onNext,
      },

      {
        onClick: closeModal,
        tooltip: t('close'),
        icon: <SvgIcon code="icon-close" />,
        isVisible: true,
        isSubdued: true,
      },
    ]
    return (
      <FloatingMenuWrapper>
        <FloatingMenu
          buttons={buttons}
          footerMenu={{ isOpen: isMenuOpen, render: renderFooterMenu }}
          noFade
          infoText={
            modalDataLocal.songs?.length > 1
              ? `${modalDataLocal.activeSongIndex + 1}/${
                  modalDataLocal.songs?.length
                }`
              : undefined
          }
        />
      </FloatingMenuWrapper>
    )
  }

  //
  // POP-CAPO
  //
  const renderPopCapo = (song: SongModalType, index: number) => {
    if (songs.length > 1) {
      const capo =
        (song.songBody?.capo || 0) +
        (song.capoOffset || 0) -
        (song.transpose || 0)
      return notifyCapo && modalDataLocal.activeSongIndex === index ? (
        <PopCapoWrapper>
          <PopCapoBg />
          <PopCapo>{capo}</PopCapo>
          <PopCapoText>{t('song.capo')}</PopCapoText>
        </PopCapoWrapper>
      ) : null
    }
  }

  //
  // RENDER
  //
  return modalDataLocal?.isOpen ? (
    <Wrapper>
      <ModalBox>
        {songs?.map((ps, i) => (
          <React.Fragment key={i}>
            <PaperViewport
              index={i}
              activeIndex={modalDataLocal.activeSongIndex || 0}
              isHidden={songViewType !== 'song'}
            >
              <SongPaperA4
                generalInfo={ps.songBody}
                chords={ps.songBody.chords}
                lyricSections={ps.songBody.lyricSections}
                compositionSections={ps.songBody.compositionSections}
                textLyricDesign={
                  ps.songBody.textLyricDesign || {
                    ...TEXT_LYRICS_DEFAULT_DESIGN,
                  }
                }
                readOnly
                transpose={songsOptions[i].transpose}
                simplifyChords={songsOptions[i].simplifyChords}
                capoOffset={songsOptions[i].capoOffset}
                topHeaderText={
                  songs?.length > 1
                    ? getPlaylistSongAdditionalText({
                        index: i + 1,
                        songCount: songs?.length,
                      })
                    : undefined
                }
                userStart={ps.userStart}
                userEnd={ps.userEnd}
              />
            </PaperViewport>

            {songs[modalDataLocal.activeSongIndex || 0]?.songBody
              ?.instrumentSections?.length > 0 ? (
              <PaperViewport
                index={i}
                activeIndex={modalDataLocal.activeSongIndex || 0}
                isHidden={songViewType !== 'instruments'}
                skipRenderIfHidden
              >
                <InstrumentsPaperA4
                  generalInfo={ps.songBody}
                  chords={ps.songBody.chords}
                  instrumentSections={ps.songBody.instrumentSections}
                />
              </PaperViewport>
            ) : null}

            {renderPopCapo(ps, i)}
          </React.Fragment>
        ))}

        {renderFloatingMenu()}
      </ModalBox>
    </Wrapper>
  ) : null
}

export default SongZoomModal
