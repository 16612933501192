import GuitarJSON from 'assets/chords/guitar.json'
import { INSTRUMENT_CODES } from 'const'
import StringInstrument from 'components/Tools/Sections/StringInstrument'
import ToolLayout from 'layouts/ToolLayout'

const ToolPageGuitarByTuning = (props: { tuning: string }) => {
  return (
    <ToolLayout>
      <StringInstrument
        tuning={props.tuning}
        instrumentCode={INSTRUMENT_CODES.guitar}
        stringChordLibraryJSON={GuitarJSON as any}
      />
    </ToolLayout>
  )
}

export default ToolPageGuitarByTuning
