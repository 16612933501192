import { PlaylistBodyType } from 'types'

export const EMPTY_PLAYLIST: PlaylistBodyType = {
  name: '',
  description: '',
  playlistSongs: [],
}

export const PLAYLIST_ROLES: {
  admin: string
  member: string
} = {
  admin: 'PLAYLIST_admin',
  member: 'PLAYLIST_member',
}
