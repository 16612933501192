import InputLabel from 'components/Inputs/_InputLabel'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import styled from 'styled-components'

const Wrapper = styled.div<{ disabled?: boolean; height?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  select {
    ${defaultInputCss}
    min-height: ${(props) => props.height || '36px'};
    width: 100%;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`

export type DisplayOptionsType = { id: string | number; name: string }[]

const Droplist = (props: {
  label?: string
  placeholder?: string
  placeholderSelectable?: boolean
  onChange: (value: string | number) => void
  options: DisplayOptionsType | string[] | number[]
  value?: string | number
  disabled?: boolean
  height?: string
}) => {
  const displayOptions =
    props.options.length && typeof props.options[0] === 'string'
      ? props.options.map((option) => {
          return { id: `${option}`, name: `${option}` }
        })
      : props.options

  const renderOptions = () => {
    return (
      <>
        {props.placeholder && (
          <option disabled={!props.placeholderSelectable} value="">
            {props.placeholder}
          </option>
        )}
        {(displayOptions as DisplayOptionsType).map((type) => (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        ))}
      </>
    )
  }

  return (
    <Wrapper disabled={props.disabled} height={props.height}>
      {props.label && <InputLabel label={props.label} />}

      {props.value ? (
        <select
          disabled={props.disabled}
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
        >
          {renderOptions()}
        </select>
      ) : (
        <select
          disabled={props.disabled}
          onChange={(event) => props.onChange(event.target.value)}
          defaultValue=""
        >
          {renderOptions()}
        </select>
      )}
    </Wrapper>
  )
}

export default Droplist
