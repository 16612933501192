import styled, { css, keyframes } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import BreadcrumbsFolder from './BreadcrumbsFolder'
import { File } from 'components/Files/FileSystem'
import SvgIcon from 'components/SvgIcon'

const Wrapper = styled.div<{ inactive?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 6%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  transition: all 0.2s ease;

  ${(props) => props.theme.breakpoint.XS} {
    padding: 3vh 2%;
    padding-bottom: 4vh;
  }

  ${(props) =>
    props.inactive &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const FolderAnimationIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
  }
`

const FolderWrapper = styled.div`
  animation-name: ${FolderAnimationIn};
  animation-timing-function: cubic-bezier(0.09, 0.85, 0.34, 1);
  animation-duration: 0.3s;
  animation-fill-mode: backwards;

  display: flex;
  align-items: center;
  gap: 5px;
`

const BreadcrumbSeparator = styled.div`
  color: ${(props) => props.theme.neutral};
  svg {
    width: 20px;
  }
`

const Breadcrumbs = (props: {
  folders?: File[]
  handleGoToFolder: (folder: File) => void
  onMoveFilesClick: (file: File) => void
  onFolderMiddleClick?: (file: File) => void
  selectedFileCount: number
  noFolders?: boolean
}) => {
  return (
    <AnimateHeight height={props.folders?.length ? 'auto' : 0.0}>
      <Wrapper inactive={props.noFolders}>
        {props.folders?.map((folder, i) => (
          <FolderWrapper key={folder.id}>
            {i > 0 && (
              <BreadcrumbSeparator>
                <SvgIcon code="icon-file-system-breadcrumbs-separator" />
              </BreadcrumbSeparator>
            )}
            <BreadcrumbsFolder
              folder={folder}
              isCurrent={i === props.folders.length - 1}
              handleGoToFolder={props.handleGoToFolder}
              onMoveFilesClick={props.onMoveFilesClick}
              selectedFileCount={props.selectedFileCount}
              onFolderMiddleClick={props.onFolderMiddleClick}
            />
          </FolderWrapper>
        ))}
      </Wrapper>
    </AnimateHeight>
  )
}

export default Breadcrumbs
