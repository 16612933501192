/* eslint-disable react-hooks/exhaustive-deps */
import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  TextLyricDesignType,
} from 'types'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ParsedSongSectionType, parseSongLyrics } from 'utils'
import styled, { css } from 'styled-components'

import SvgIcon from 'components/SvgIcon'
import { useTranslation } from 'react-i18next'

const ROLE_ITEM = 'item'
const ROLE_HEADING = 'heading'
const ROLE_LINE = 'line'

//
// STYLES
//
const Wrapper = styled.div<{
  fontSize?: number
  letterSpacing?: number
  paddingTop?: number
  paddingLeft?: number
  paddingRight?: number
}>`
  flex: 1;
  white-space: pre;

  font-family: 'Roboto Mono', monospace;
  font-size: ${(props) => props.fontSize || 18}px;
  letter-spacing: ${(props) => props.letterSpacing || 0}px;

  padding-top: ${(props) =>
    props.paddingTop ? `${props.paddingTop}px` : '0px'};

  padding-left: ${(props) =>
    props.paddingLeft ? `${props.paddingLeft}px` : '0px'};

  padding-right: ${(props) =>
    props.paddingRight ? `${props.paddingRight}px` : '0px'};
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;
`

const Heading = styled.div`
  font-weight: 900;
`

const Note1 = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.primary_light};
`

const Note2 = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.dark};
`

const Line = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1px;
`

const LineNotes = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.black}88;
`

const LineChordDurations = styled.div`
  color: ${(props) => props.theme.primary_light};
`

const LineChords = styled.div`
  font-weight: 900;
  color: ${(props) => props.theme.primary_light};
`

const LyricLine = styled.div``

const Outline = styled.div<{
  readOnly?: boolean
}>`
  ${(props) =>
    !props.readOnly &&
    css`
      outline: 8px solid ${(props) => props.theme.highlight}44;
    `}
`

const Columns = styled.div<{
  height?: number
  scaleX?: number
  lineGapY?: number
}>`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;

  row-gap: ${(props) => (props.lineGapY ? `${props.lineGapY}px` : '0px')};

  ${(props) =>
    props.height &&
    css`
      max-height: ${props.height}px;
    `}

  transform: scaleX(${(props) => props.scaleX / 100 || 0.95});
  transform-origin: 0 0;
`

const Section = styled.div<{ marginTop?: number; marginRight?: number }>`
  display: flex;
  flex-direction: column;

  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '0px')};
  margin-right: ${(props) =>
    props.marginRight ? `${props.marginRight}px` : '0px'};
`

const WrapSection = styled.div<{
  readOnly?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;

  border: 2px solid ${(props) => props.theme.highlight}00;

  ${(props) =>
    !props.readOnly &&
    css`
      cursor: pointer;

      &:before {
        transition: all 0.2s ease;
        position: absolute;
        bottom: 100%;
        content: '';
        width: 0;
        opacity: 0;
        border-bottom: 8px solid ${(props) => props.theme.highlight};
      }

      &:hover {
        border-color: ${(props) => props.theme.highlight}66;
        background: linear-gradient(
          ${(props) => props.theme.highlight}11,
          ${(props) => props.theme.highlight}00
        );

        &:before {
          opacity: 1;
          width: 100%;
        }
      }
    `}
`

const Wrap = styled.div`
  height: 100000px;
  width: 0px;
`

const ColumnBreak = styled.div`
  position: relative;
  & > * {
    position: absolute;
    flex: 1;
    height: 20px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 15px 0;
    padding: 25px;
    padding-right: 40px;

    font-size: 32px;
    font-weight: 900;
    color: ${(props) => props.theme.dark};
    background: ${(props) => props.theme.highlight}33;
    border: 4px solid ${(props) => props.theme.highlight}55;
    text-transform: uppercase;

    transition: all 0.2s ease;

    svg {
      width: 50px;
      height: 50px;
    }

    &:hover {
      background: ${(props) => props.theme.highlight}44;
    }
  }
`

const SongUser = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.highlight};
  font-weight: 800;
  svg {
    margin-top: 4px;
    width: 80px;
  }
`

const SongUserStart = styled.div`
  position: absolute;
  top: -75px;
  left: -55px;
`

const SongUserEnd = styled.div`
  position: absolute;
  bottom: -85px;
  left: -15px;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const SongLyricsAsText = (props: {
  lyricSections: LyricSectionType[]
  compositionSections: CompositionSectionType[]
  chords: SongChordType[]
  languageCode: string
  textLyricDesign: TextLyricDesignType
  setTextLyricDesign?: (d: TextLyricDesignType) => void
  readOnly?: boolean
  userStart?: string
  userEnd?: string
}) => {
  const { t } = useTranslation()

  //
  // FUNCTIONS
  //
  const renderLine = (line) => {
    return (
      <Line>
        {props.textLyricDesign.showLyricNotes && line.notes?.length && (
          <LineNotes>{line.notes}</LineNotes>
        )}
        {props.textLyricDesign.showLyricChords &&
          line.chordDurations?.length && (
            <LineChordDurations>{line.chordDurations}</LineChordDurations>
          )}
        {props.textLyricDesign.showLyricChords && line.chords?.length && (
          <LineChords>{line.chords}</LineChords>
        )}
        <LyricLine>{line.lyricLine}</LyricLine>
      </Line>
    )
  }

  const renderSongUserStart = () => {
    return props.userStart ? (
      <SongUserStart>
        <SongUser>
          <SvgIcon code="icon-arrow-right" />
          {props.userStart}
        </SongUser>
      </SongUserStart>
    ) : null
  }

  const renderSongUserEnd = () => {
    return props.userEnd ? (
      <SongUserEnd>
        <SongUser>
          <SvgIcon code="icon-arrow-right" />
          {props.userEnd}
        </SongUser>
      </SongUserEnd>
    ) : null
  }

  const getRenderFuncs = () => {
    const parsedCompSections: ParsedSongSectionType[] = parseSongLyrics({
      lyricSections: props.lyricSections,
      compositionSections: props.compositionSections,
      songChords: props.chords,
      songLanguageCode: props.languageCode,
      t,
    })

    const renderFuncs = []

    parsedCompSections.forEach((cs, csIndex) => {
      renderFuncs.push({
        class: `${ROLE_ITEM} ${ROLE_HEADING}`,
        role: ROLE_HEADING,
        func: () => (
          <>
            {csIndex === 0 ? renderSongUserStart() : null}

            <HeadingWrapper>
              <Heading>{cs.name}</Heading>
              {props.textLyricDesign.showSectionNotes && cs.note && (
                <Note1>{cs.note}</Note1>
              )}
              {props.textLyricDesign.showSectionNotes && cs.note2 && (
                <Note2>{cs.note2}</Note2>
              )}
            </HeadingWrapper>

            {cs.lines?.length > 0 && (
              <>
                {renderLine(cs.lines[0])}
                {csIndex === parsedCompSections.length - 1 &&
                cs.lines.length === 1
                  ? renderSongUserEnd()
                  : null}
              </>
            )}
          </>
        ),
      })

      cs.lines.forEach((line, i) => {
        if (i > 0) {
          renderFuncs.push({
            class: `${ROLE_ITEM} ${ROLE_LINE}`,
            role: ROLE_LINE,
            func: () => (
              <>
                {renderLine(line)}
                {csIndex === parsedCompSections.length - 1 &&
                i === cs.lines?.length - 1
                  ? renderSongUserEnd()
                  : null}
              </>
            ),
          })
        }
      })
    })

    return renderFuncs
  }

  //
  // DATA
  //
  const itemsRef = useRef()
  const [renderFuncs, setRenderFuncs] = useState(getRenderFuncs())

  useEffect(() => {
    setRenderFuncs(getRenderFuncs())
  }, [
    props.lyricSections,
    props.compositionSections,
    props.chords,
    props.languageCode,
    props.textLyricDesign,
  ])

  //
  // ACTIONS
  //
  const onSectionClick = (index) => {
    const wrapIndex = index
    if (wrapIndex > 0) {
      if (
        !props.textLyricDesign.wrapIndexesSorted.includes(wrapIndex) &&
        wrapIndex > 0
      ) {
        props.setTextLyricDesign({
          ...props.textLyricDesign,
          wrapIndexesSorted: [
            ...props.textLyricDesign.wrapIndexesSorted,
            wrapIndex,
          ].sort((a, b) => (a < b ? -1 : 1)),
        })
      }
    }
  }

  const onColumnEndClick = (index) => {
    const updatedWrapIndexes = [
      ...props.textLyricDesign.wrapIndexesSorted,
    ].filter((i) => i !== index)
    props.setTextLyricDesign({
      ...props.textLyricDesign,
      wrapIndexesSorted: updatedWrapIndexes,
    })
  }

  //
  // JS
  //
  const updateElementSpacing = () => {
    if (itemsRef?.current) {
      const root = itemsRef.current as HTMLElement
      let lastElemY = 0

      Array.from(
        root.getElementsByClassName(ROLE_ITEM) as HTMLCollectionOf<HTMLElement>
      ).forEach((elem) => {
        const elemY = elem.getBoundingClientRect()?.top
        if (elemY <= lastElemY || lastElemY === 0) {
          elem.style.marginTop = '0px'
        } else {
          const isHeading = elem.classList.contains(ROLE_HEADING)
          if (isHeading) {
            elem.style.marginTop = `${props.textLyricDesign.sectionGapY}px`
          } else {
            elem.style.marginTop = '0px'
          }
        }
        lastElemY = elemY
      })
    }
  }

  updateElementSpacing()
  useEffect(() => {
    updateElementSpacing()
  }, [itemsRef, renderFuncs])

  //
  // RENDER
  //
  return (
    <Wrapper
      fontSize={props.textLyricDesign.fontSize}
      letterSpacing={props.textLyricDesign.letterSpacing}
      paddingTop={props.textLyricDesign.contentPaddingTop}
      paddingLeft={props.textLyricDesign.contentPaddingLeft}
      paddingRight={props.textLyricDesign.contentPaddingRight}
    >
      <Outline readOnly={props.readOnly}>
        <Columns
          ref={itemsRef}
          height={props.textLyricDesign.contentHeight}
          scaleX={props.textLyricDesign.scaleX}
          lineGapY={props.textLyricDesign.lineGapY}
        >
          {renderFuncs?.map((funcObj, i) => (
            <Fragment key={i}>
              {props.textLyricDesign.wrapIndexesSorted?.includes(i) && (
                <Wrap key={'wrap_' + i} />
              )}

              <Section
                className={funcObj.class}
                marginTop={
                  funcObj.role === ROLE_HEADING &&
                  props.textLyricDesign.sectionGapY
                }
                marginRight={props.textLyricDesign.columnGap}
              >
                <WrapSection
                  onClick={() => !props.readOnly && onSectionClick(i)}
                  title={
                    !props.readOnly
                      ? t('song.layout editor options.add column break')
                      : undefined
                  }
                  readOnly={props.readOnly}
                >
                  {funcObj.func()}
                </WrapSection>

                {!props.readOnly &&
                  props.textLyricDesign.wrapIndexesSorted?.includes(i + 1) && (
                    <ColumnBreak onClick={() => onColumnEndClick(i + 1)}>
                      <div>
                        <SvgIcon code="icon-close" />
                        {t('song.layout editor options.remove column break')}
                      </div>
                    </ColumnBreak>
                  )}
              </Section>
            </Fragment>
          ))}
        </Columns>
      </Outline>
    </Wrapper>
  )
}

export default SongLyricsAsText
