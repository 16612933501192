import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const ToolLayout = (props: { children: any }) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ToolLayout
