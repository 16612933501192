import { PLAYLIST_ROLES, SONG_ROLES } from 'const'
import { PlaylistMemberType, SongMemberType } from 'types'
import UserCard, { UserBadgeType } from 'components/User/UserCard'

import styled from 'styled-components'

const MemberList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 0 10px;
`

/**
 *
 *  COMPONENT
 *
 */
const Members = (props: {
  type: 'song' | 'playlist'
  members: (SongMemberType | PlaylistMemberType)[]
  useLink?: boolean
}) => {
  const renderMembers = (
    members: (SongMemberType | PlaylistMemberType)[],
    options?: { badge?: UserBadgeType }
  ) => {
    return (
      <>
        {members?.map((m, i) => (
          <div key={i}>
            <UserCard
              user={m.user}
              userGroup={m.userGroup}
              useLink={props.useLink}
              badge={options?.badge}
              noBackground
            />
          </div>
        ))}
      </>
    )
  }

  const isAdmin = (member: SongMemberType | PlaylistMemberType) => {
    if (props.type === 'playlist') {
      return (
        (member as PlaylistMemberType).playlistRole?.code ===
        PLAYLIST_ROLES.admin
      )
    }
    return (member as SongMemberType).songRole?.code === SONG_ROLES.admin
  }

  //
  // RENDER
  //
  return props.members?.length > 0 ? (
    <MemberList>
      {renderMembers(
        props.members?.filter((m) => isAdmin(m)),
        { badge: 'edit' }
      )}
      {renderMembers(props.members?.filter((m) => !isAdmin(m)))}
    </MemberList>
  ) : null
}

export default Members
