import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'

import InputLabel from 'components/Inputs/_InputLabel'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'

const HEIGHT = '30px'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  button {
    box-shadow: none;
  }
`

const CounterWrapper = styled.section<{
  height: string
  withoutDefaultCss: boolean
  noReadout?: boolean
}>`
  ${(props) =>
    !props.withoutDefaultCss &&
    css`
      ${defaultInputCss}
      padding: 0;
    `}

  height: ${(props) => props.height || HEIGHT};
  max-height: ${(props) => props.height || 'none'};
  min-height: ${(props) => props.height || 'none'};
  flex: 1;
  min-width: ${(props) => (props.noReadout ? '0px' : '60px')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    height: 90%;
    min-height: ${(props) => props.height || 'none'};
    max-height: ${(props) => props.height || 'none'};
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 4px;
    outline: none;
    background: none;
    text-align: center;
    backdrop-filter: none;

    &:focus {
      background: ${(props) => props.theme.white};
      font-weight: 700;
    }
  }
`

const ActionButton = styled.button<{ disabled?: boolean }>`
  ${resetButtonCss}
  color: ${(props) => props.theme.black}88;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${(props) => props.height || HEIGHT};
  height: ${(props) => props.height || HEIGHT};
  svg {
    width: 16px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}
`

const Counter = (props: {
  label?: string
  value: number
  setValue?: (value: number) => void
  valueMin?: number
  valueMax?: number
  step?: number
  height?: string
  withoutDefaultCss?: boolean
  arrowIcons?: boolean
  noReadout?: boolean
  showResetButton?: boolean
}) => {
  const [displayedInput, setDisplayedInput] = useState<number>(props.value)

  useEffect(() => {
    if (displayedInput !== props.value) {
      setDisplayedInput(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedInput, props.value])

  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}

      <CounterWrapper
        height={props.height}
        withoutDefaultCss={props.withoutDefaultCss}
        noReadout={props.noReadout}
      >
        <ActionButton
          onClick={() => {
            const value =
              props.value - (props.step !== undefined ? props.step : 1)
            props.setValue &&
              props.setValue(
                props.valueMin || props.valueMin === 0
                  ? Math.max(props.valueMin, value)
                  : value
              )
          }}
          height={props.height}
          disabled={
            props.valueMin !== undefined && props.value <= props.valueMin
          }
        >
          {props.arrowIcons ? (
            <SvgIcon code="icon-arrow-left" />
          ) : (
            <SvgIcon code="icon-minus" />
          )}
        </ActionButton>

        {!props.noReadout && (
          <StringInput
            value={displayedInput}
            setValue={(value) => {
              let valueLimited = parseInt(value) || 0
              if (props.valueMin) {
                valueLimited = Math.max(props.valueMin, valueLimited)
              }
              if (props.valueMax) {
                valueLimited = Math.min(props.valueMax, valueLimited)
              }
              props.setValue && props.setValue(valueLimited)
              setDisplayedInput(parseInt(value))
            }}
          />
        )}

        <ActionButton
          onClick={() => {
            const value =
              props.value + (props.step !== undefined ? props.step : 1)
            props.setValue &&
              props.setValue(
                props.valueMax || props.valueMax === 0
                  ? Math.min(props.valueMax, value)
                  : value
              )
          }}
          height={props.height}
          disabled={
            props.valueMax !== undefined && props.value >= props.valueMax
          }
        >
          {props.arrowIcons ? (
            <SvgIcon code="icon-arrow-right" />
          ) : (
            <SvgIcon code="icon-plus" />
          )}
        </ActionButton>

        {props.showResetButton && (
          <ActionButton onClick={() => props.setValue(0)} height={props.height}>
            <SvgIcon code="icon-close" />
          </ActionButton>
        )}
      </CounterWrapper>
    </Wrapper>
  )
}

export default Counter
