import styled, { css } from 'styled-components'

import { ActionButton } from 'styles/GlobalStyle/Buttons'
import SvgIcon from 'components/SvgIcon'
import { useTranslation } from 'react-i18next'

const HOVER_PADDING_X = '30px'
const HOVER_PADDING_Y = '30px'
const EDITOR_SIZE = '26px'

const EditButton = styled.div<{ position: 'left' | 'right' | 'top' }>`
  position: absolute;
  z-index: 10;
  ${(props) =>
    props.position === 'left'
      ? css`
          right: calc(100% - ${EDITOR_SIZE});
        `
      : props.position === 'right'
      ? css`
          left: calc(100% - ${EDITOR_SIZE});
        `
      : props.position === 'top'
      ? css`
          margin-left: 4px;
          bottom: calc(100% - ${EDITOR_SIZE});
        `
      : css``}

  button {
    border: 1px solid ${(props) => props.theme.black}11;
    background: ${(props) => props.theme.lightest};
    box-shadow: 0 0 0px 8px ${(props) => props.theme.white}AA;
    width: ${EDITOR_SIZE};
    height: ${EDITOR_SIZE};

    &:hover {
      background: ${(props) => props.theme.lighter};
    }
  }
`

const Content = styled.div<{ allowEdit?: boolean }>`
  z-index: 11;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;

  ${(props) =>
    props.allowEdit &&
    css`
      cursor: pointer;
    `}
`

const Wrapper = styled.div<{ alignLeft: boolean; allowEdit?: boolean }>`
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;
  ${EditButton} {
    transition: all 0.2s ease;
    display: none;
    opacity: 0;
  }

  ${(props) =>
    props.allowEdit &&
    css`
      &:hover {
        z-index: 10;
        border-radius: ${HOVER_PADDING_X} ${HOVER_PADDING_X} 0 0;
        padding: ${HOVER_PADDING_Y} ${HOVER_PADDING_X};
        margin-top: -${HOVER_PADDING_Y};
        margin-bottom: -${HOVER_PADDING_Y};
        padding-bottom: 0;
        margin-bottom: 0;

        ${(props) =>
          props.alignLeft &&
          css`
            margin-left: -${HOVER_PADDING_Y};
          `}

        background: radial-gradient(${(props) => props.theme.white}, #0000);

        ${EditButton} {
          display: flex;
          justify-content: center;
          opacity: 1;
        }
      }
    `}
`

const EditableItem = (props: {
  children?: any
  alignLeft?: boolean
  onMoveLeft?: () => any
  onMoveRight?: () => any
  onDelete?: () => any
  allowEdit?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper alignLeft={props.alignLeft} allowEdit={props.allowEdit}>
      <Content>{props.children}</Content>

      {props.allowEdit && (
        <>
          <EditButton
            position="top"
            title={t('song.lyric text options.delete item')}
          >
            {props.onDelete && (
              <ActionButton onClick={props.onDelete}>
                {<SvgIcon code="icon-close" />}
              </ActionButton>
            )}
          </EditButton>

          <EditButton
            position="left"
            title={t('song.lyric text options.move item left')}
          >
            {props.onMoveLeft && (
              <ActionButton onClick={props.onMoveLeft}>
                <SvgIcon code="icon-chevron-left" />
              </ActionButton>
            )}
          </EditButton>

          <EditButton
            position="right"
            title={t('song.lyric text options.move item right')}
          >
            {props.onMoveRight && (
              <ActionButton onClick={props.onMoveRight}>
                <SvgIcon code="icon-chevron-right" />
              </ActionButton>
            )}
          </EditButton>
        </>
      )}
    </Wrapper>
  )
}

export default EditableItem
