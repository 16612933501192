import Counter from 'components/Inputs/Counter'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import { STRING_INSTRUMENT_TUNINGS } from 'const'
import StringChord from 'components/Instruments/StringChord'
import { StringChordSchemaType } from 'types'
import StringTuning from 'components/Instruments/StringTuning'
import styled from 'styled-components'
import { useState } from 'react'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  column-gap: 60px;
  row-gap: 40px;

  & > *:first-child {
    max-width: 400px;
  }

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    & > *:first-child {
      max-width: 100%;
    }
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  column-gap: 60px;
  flex-wrap: wrap;
`

const DevModuleStringChords = (props: { isOpenDefault?: boolean }) => {
  const [tuningName, setTuningName] = useState<string>('Standard')
  const [notes, setNotes] = useState<string[]>(
    STRING_INSTRUMENT_TUNINGS.guitar[0].notes
  )

  const [fretCount, setFretCount] = useState<number>(3)
  const [chordName, setChordName] = useState<string>('C#m')
  const [chordSchema, setChordSchema] = useState<StringChordSchemaType>({
    renderFretOffset: 0,
    strings: [
      { note: 'e', mute: false, fret: 0 },
      { note: 'A', mute: false, fret: 5 },
      { note: 'D', mute: false, fret: 6 },
      { note: 'G', mute: false, fret: 6 },
      { note: 'B', mute: false, fret: 4 },
      { note: 'E', mute: true, fret: null },
    ],
  })

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="String Chords"
      subtitle="string chord schema, edit/readonly mode"
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        <Column>
          <ModuleSectionTitle
            title="String Tuning"
            subtitle="component for changing string instrument tuning"
          />

          <StringTuning
            tuningName={tuningName}
            setTuningName={setTuningName}
            notes={notes}
            setNotes={setNotes}
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          <ModuleSectionTitle
            title="String Chord Schema"
            subtitle="component for displaying and editing string chord schema"
          />

          <Counter
            label="Fret Count"
            value={fretCount}
            setValue={setFretCount}
            valueMin={3}
            valueMax={25}
          />

          <Row>
            <Column>
              <ModuleSectionTitle
                title="Editable Version"
                subtitle="autoFillSchema"
                paddingTop="20px"
              />
              <StringChord
                chordName={chordName}
                setChordName={setChordName}
                chordSchema={chordSchema}
                setChordSchema={setChordSchema}
                fretCount={fretCount}
                autoFillSchema
                stretch
              />

              <ModuleSectionTitle
                title="Read-Only Version"
                subtitle="autoFillSchema"
                paddingTop="20px"
              />
              <StringChord
                chordName={chordName}
                chordSchema={chordSchema}
                fretCount={fretCount}
                autoFillSchema
                stretch
              />
            </Column>

            <Column>
              <ModuleSectionTitle
                title="Editable Version"
                subtitle="autoFitSchema"
                paddingTop="20px"
              />
              <StringChord
                chordName={chordName}
                setChordName={setChordName}
                chordSchema={chordSchema}
                setChordSchema={setChordSchema}
                fretCount={fretCount}
                autoFitSchema
                stretch
              />

              <ModuleSectionTitle
                title="Read-Only Version"
                subtitle="autoFitSchema"
                paddingTop="20px"
              />
              <StringChord
                chordName={chordName}
                chordSchema={chordSchema}
                fretCount={fretCount}
                autoFitSchema
                stretch
              />
            </Column>
          </Row>
        </Column>
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleStringChords
