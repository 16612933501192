import { DBPlaylistType, DBSongType, PlaylistBodyType, SongType } from 'types'
import { SONG_ZOOM_MODAL, SongModalType } from 'modals/SongZoomModal'
import {
  allowDownloads,
  copyToClipboard,
  downloadAsJSON,
  downloadAsPNG,
  downloadAsTXT,
  getPlaylistSongUserSettings,
  getPlaylistSongUserStartEndNames,
  getSongFromPlaylistSong,
} from 'utils'
import { makeVar, useReactiveVar } from '@apollo/client'
import styled, { css, keyframes } from 'styled-components'
import { useEffect, useState } from 'react'

import IconButton from './IconButton'
import { ROUTES } from 'const'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SvgIcon from 'components/SvgIcon'
import TransposeInput from 'components/Inputs/TransposeInput'
import { useTranslation } from 'react-i18next'

export const A4_COMPONENT_EXPORT_ID = 'A4_COMPONENT_EXPORT_ID'
export const A4_COMPONENT_EXPORT_INSTRUMENTS_ID =
  'A4_COMPONENT_EXPORT_INSTRUMENTS_ID'

const AnimationMenuIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
`

const MenuWrapper = styled.div<{ isLocked?: boolean }>`
  animation-name: ${AnimationMenuIn};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: 0.3s;

  ${(props) =>
    props.isLocked &&
    css`
      pointer-events: none !important;
    `}
`

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 5px;

  & > *:last-child {
    align-self: flex-end;

    ${(props) => props.theme.breakpoint.M} {
      align-self: center;
    }
  }

  ${(props) => props.theme.breakpoint.M} {
    align-items: center;
    gap: 10px;
  }
`

const FuncRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${(props) => props.theme.breakpoint.M} {
    flex: 1;
    gap: 4vw;
  }
`

const Spacer = styled.div`
  flex: 1;
`

/**
 *
 *
 * GLOBAL STATE
 *
 *
 */
export type DownloadDataType = {
  countToDownload: number
  filenamesDownloaded: string[]
  isDownloading: boolean
}

const DOWNLOAD_VAR = makeVar<DownloadDataType>({
  countToDownload: 0,
  filenamesDownloaded: [],
  isDownloading: false,
})

/**
 *
 *
 * COMPONENT
 *
 *
 */
const IconMenu = (props: {
  playlist?: DBPlaylistType
  playlistBody?: PlaylistBodyType
  setPlaylistBody?: (PlaylistBodyType) => void
  openedPlaylistSongId?: number
  setOpenedPlaylistSongId?: (number) => void
  song?: DBSongType
  songBody?: SongType
  transpose?: number
  setTranspose?: (semitones: number) => void
  simplifyChords?: boolean
  setSimplifyChords?: (val: boolean) => void
  capoOffset?: number
  setCapoOffset?: (val: number) => void
}) => {
  const { t } = useTranslation()
  const downloadData = useReactiveVar(DOWNLOAD_VAR)

  const includeChordsInExport = !props.transpose && !props.simplifyChords

  //
  // FULLSCREEN - SONG
  //
  const onSongFullscreen = () => {
    SONG_ZOOM_MODAL({
      ...SONG_ZOOM_MODAL(),
      isOpen: true,
      songs: [
        {
          songBody: props.songBody,
          transpose: props.transpose,
          setTranspose: props.setTranspose,
          simplifyChords: props.simplifyChords,
          setSimplifyChords: props.setSimplifyChords,
          capoOffset: props.capoOffset,
          setCapoOffset: props.setCapoOffset,
        },
      ],
    })
  }

  //
  // FULLSCREEN - PLAYLIST
  //
  const [openedPlaylistSongId, setOpenedPlaylistSongId] = useState<number>()

  useEffect(() => {
    setOpenedPlaylistSongId(props.openedPlaylistSongId)
  }, [props.openedPlaylistSongId])

  const onPlaylistFullscreen = () => {
    if (props.playlistBody?.playlistSongs?.length) {
      const songId = props.playlistBody.playlistSongs[0]?.songId
      if (songId !== undefined) {
        setOpenedPlaylistSongId(songId)
      }
    }
  }

  const getNeighborPlaylistSongId = (songId: number, offset: number) => {
    const songIds = props.playlistBody?.playlistSongs?.map((ps) => ps.songId)
    const index = songIds.findIndex((id) => id === songId)
    const newIndex = index + offset
    if (newIndex >= 0 && newIndex < songIds.length) {
      return songIds[newIndex]
    }
    return undefined
  }

  const getNextSongId = (songId: number) => {
    return getNeighborPlaylistSongId(songId, 1)
  }
  const getPrevSongId = (songId: number) => {
    return getNeighborPlaylistSongId(songId, -1)
  }
  const onPlaylistFullscreenNext = (songId: number) => {
    const newSongId = getNextSongId(songId)
    setOpenedPlaylistSongId(newSongId)
  }
  const onPlaylistFullscreenPrev = (songId: number) => {
    const newSongId = getPrevSongId(songId)
    setOpenedPlaylistSongId(newSongId)
  }

  /* const updatePlaylistSong = (playlistSong: PlaylistSongType) => {
    props.setPlaylistBody({
      ...props.playlistBody,
      playlistSongs: [...props.playlistBody.playlistSongs].map(ps =>
        ps.songId === playlistSong.songId ? playlistSong : ps
      ),
    })
  } */

  useEffect(() => {
    if (openedPlaylistSongId !== undefined) {
      const activeSongIndex = props.playlistBody?.playlistSongs
        ?.map((ps) => ps.songId)
        .indexOf(openedPlaylistSongId)

      const songs: SongModalType[] = props.playlistBody?.playlistSongs?.map(
        (ps, i) => {
          const { userStart, userEnd } = getPlaylistSongUserStartEndNames({
            playSettings: ps.playSettings,
            playlistMembers: props.playlist?.playlistMembers,
          })

          return {
            songBody: getSongFromPlaylistSong(ps),

            transpose: getPlaylistSongUserSettings(
              props.playlistBody?.playlistSongs[i]
            ).transpose,
            setTranspose: undefined,

            simplifyChords: getPlaylistSongUserSettings(
              props.playlistBody?.playlistSongs[i]
            ).simplifyChords,
            setSimplifyChords: undefined,

            capoOffset: ps.capoOffset,
            setCapoOffset: undefined,

            userStart,
            userEnd,
          }
        }
      )

      SONG_ZOOM_MODAL({
        ...SONG_ZOOM_MODAL(),
        isOpen: true,
        songs: songs,
        activeSongIndex: activeSongIndex,

        onNext:
          getNextSongId(openedPlaylistSongId) !== undefined
            ? () => onPlaylistFullscreenNext(openedPlaylistSongId)
            : undefined,
        onPrev:
          getPrevSongId(openedPlaylistSongId) !== undefined
            ? () => onPlaylistFullscreenPrev(openedPlaylistSongId)
            : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedPlaylistSongId])

  //
  // DOWNLOADS - SONG
  //
  const triggerDownloadSongAsPNG = () => {
    DOWNLOAD_VAR({
      countToDownload: 1,
      filenamesDownloaded: [],
      isDownloading: true,
    })
    downloadAsPNG(
      {
        songBody: props.songBody,
        playlistBody: props.playlistBody,
        transpose: props.transpose,
        simplifyChords: props.simplifyChords,
        includeChordsInExport: includeChordsInExport,
        callback: (filename) =>
          DOWNLOAD_VAR({
            ...DOWNLOAD_VAR(),
            filenamesDownloaded: [filename],
            isDownloading: false,
          }),
      },
      t
    )
  }

  const triggerDownloadSongAsTXT = () => {
    downloadAsTXT(
      {
        songBody: props.songBody,
        transpose: props.transpose,
        simplifyChords: props.simplifyChords,
        includeChordsInExport: includeChordsInExport,
      },
      t
    )
  }

  const triggerDownloadSongAsJSON = () => {
    downloadAsJSON(
      {
        songBody: props.songBody,
        transpose: props.transpose,
        simplifyChords: props.simplifyChords,
      },
      t
    )
  }

  //
  // DOWNLOADS - PLAYLIST SONGS
  //
  const triggerDownloadPlaylistSongsAsPNG = () => {
    DOWNLOAD_VAR({
      countToDownload: props.playlistBody.playlistSongs.length,
      filenamesDownloaded: [],
      isDownloading: true,
    })
    downloadAsPNG(
      {
        songBody: props.songBody,
        playlistBody: props.playlistBody,
        transpose: props.transpose,
        simplifyChords: props.simplifyChords,
        includeChordsInExport: includeChordsInExport,
        callback: (filename) =>
          DOWNLOAD_VAR({
            ...DOWNLOAD_VAR(),
            filenamesDownloaded: [
              ...DOWNLOAD_VAR().filenamesDownloaded,
              filename,
            ],
            isDownloading:
              DOWNLOAD_VAR().filenamesDownloaded.length + 1 <
              DOWNLOAD_VAR().countToDownload,
          }),
      },
      t
    )
  }

  //
  // DOWNLOAD STATE
  //
  useEffect(() => {
    document.body.style.cursor = downloadData.isDownloading ? 'wait' : 'unset'
  }, [downloadData])

  //
  // RENDER
  //
  return (
    <MenuWrapper isLocked={downloadData.isDownloading}>
      <Wrapper>
        {props.song && (
          <>
            <FuncRow>
              <TransposeInput
                value={props.transpose}
                setValue={props.setTranspose}
                roundCorners
              />

              <TransposeInput
                value={props.capoOffset}
                setValue={props.setCapoOffset}
                roundCorners
                isCapo
              />

              <SimplifyInput
                value={props.simplifyChords}
                setValue={props.setSimplifyChords}
                roundCorners
              />
            </FuncRow>
          </>
        )}

        <Spacer />

        <Row>
          {allowDownloads({
            song: props.song,
            playlist: props.playlist,
          }) && (
            <>
              <IconButton
                tooltip={t('download.png')}
                icon={<SvgIcon code="icon-export-png" />}
                onClick={
                  props.song
                    ? triggerDownloadSongAsPNG
                    : triggerDownloadPlaylistSongsAsPNG
                }
                downloadData={downloadData}
              />

              {props.song && (
                <IconButton
                  tooltip={t('download.txt')}
                  icon={<SvgIcon code="icon-export-txt" />}
                  onClick={triggerDownloadSongAsTXT}
                />
              )}

              {props.song && (
                <IconButton
                  tooltip={t('download.json')}
                  icon={<SvgIcon code="icon-export-json" />}
                  onClick={triggerDownloadSongAsJSON}
                />
              )}
            </>
          )}

          {props.song && (
            <>
              <IconButton
                tooltip={t('song.share')}
                icon={<SvgIcon code="icon-share" />}
                onClick={() =>
                  copyToClipboard(
                    `${window.location.origin}${ROUTES.songs.slug}?song=${props.song.id}`,
                    { success: t(`song.share_notification`) }
                  )
                }
              />

              <IconButton
                tooltip={t('song.fullscreen')}
                icon={<SvgIcon code="icon-zoom-in" />}
                onClick={onSongFullscreen}
              />
            </>
          )}

          {props.playlist && (
            <>
              <IconButton
                tooltip={t('playlist.share')}
                icon={<SvgIcon code="icon-share" />}
                onClick={() =>
                  copyToClipboard(
                    `${window.location.origin}${ROUTES.playlists.slug}?playlist=${props.playlist.id}`,
                    { success: t(`playlist.share_notification`) }
                  )
                }
              />

              <IconButton
                tooltip={t('song.fullscreen')}
                icon={<SvgIcon code="icon-zoom-in" />}
                onClick={onPlaylistFullscreen}
              />
            </>
          )}
        </Row>
      </Wrapper>

      {/* DEV: */}
      {/* <div style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
        {convertSongToText(props.songBody, t)}
      </div> */}
    </MenuWrapper>
  )
}

export default IconMenu
