import {
  EmailLanguageType,
  EmailProps,
  getEmailHtml,
} from 'components/EmailTemplate'

import { LOGGED_USER_VAR } from 'App'
import { ROUTES } from 'const'
import { sendEmail } from 'api/email'
import { toast } from 'react-toastify'

const { REACT_APP_URL } = process.env

export const TEST_EMAIL_DATA: EmailProps = {
  lang: 'en',
  subject: {
    en: `New Song: 'Song Name (Song Author)'`,
    cz: `Nová skladba: 'Song Name (Song Author)'`,
    sk: `Nová skladba: 'Song Name (Song Author)'`,
  },
  title: {
    en: 'New Song',
    cz: 'Nová skladba',
    sk: 'Nová skladba',
  },
  title2: {
    en: 'Song Name (Song Author)',
    cz: 'Song Name (Song Author)',
    sk: 'Song Name (Song Author)',
  },
  paragraphs: [
    {
      en: `User 'Logged User' allowed you to have access to the song 'Song Name (Song Author)'.`,
      cz: `Uživatel 'Logged User' vám umožnil přístup do skladby 'Song Name (Song Author)'.`,
      sk: `Používateľ 'Logged User' vám umožnil prístup do skladby 'Song Name (Song Author)'.`,
    },
    {
      en: 'You have the right to read and edit the song. It can be found in the list of your songs in the m-note app, or you can open it by clicking on the button below.',
      cz: 'Skladbu máte právo číst a upravovat. Naleznete ji mezi vašimi skladbami v aplikaci m-note, nebo ji otevřete kliknutím na tlačítko níže.',
      sk: 'Skladbu máte právo čítať a upravovať. Nájdete ju medzi vašimi skladbami v aplikácii m-note, alebo ju otvoríte kliknutím na tlačidlo nižšie.',
    },
  ],
  linkName: {
    en: 'Open song',
    cz: 'Otevřít skladbu',
    sk: 'Otvoriť skladbu',
  },
  footer: {
    en: 'This is an auto generated message, please do not reply.',
    cz: 'Tento email je odesílán automaticky, neodpovídejte na něj.',
    sk: 'Tento email je odosielaný automaticky, nezodpovedajte naň.',
  },
  href: REACT_APP_URL,
}

//
// FUNCTIONS
//
export const sendTestEmail = async (props?: {
  emailTo?: string
  subject?: string
  lang?: EmailLanguageType
}) => {
  const emailData: EmailProps = props.lang
    ? { ...TEST_EMAIL_DATA, lang: props.lang }
    : TEST_EMAIL_DATA

  const response = await sendEmail({
    emailTo: props?.emailTo || 'jan.hamernik@gmail.com',
    subject: props?.subject || 'Hello There',
    message: 'Hi dude,\nthis is just a freaking test, no worries.\n\nHonzik',
    html: getEmailHtml(emailData),
  })

  if (response.status === 200) {
    toast.success(
      `${response.status}, ${response.statusText}\n${response.data}`
    )
  } else {
    toast.error(`${response.status}, ${response.statusText}\n${response.data}`)
  }
  console.info(response)
}

export const sendEmailNewSongMember = async (props: {
  songId: number
  songName: string
  songAuthor?: string
  userEmail: string
  userLang: EmailLanguageType
  userCanEdit?: boolean
}) => {
  const { songId, songName, songAuthor, userEmail, userLang, userCanEdit } =
    props

  const songNameWithAuthor = `${songName}${
    songAuthor?.length ? ` (${songAuthor})` : ''
  }`

  const loggedUser = LOGGED_USER_VAR()
  const loggedUserName = `${loggedUser.name}${
    loggedUser.username?.length ? ` (${loggedUser.username})` : ''
  }`

  const emailData: EmailProps = {
    lang: userLang,
    subject: {
      en: `New Song: '${songNameWithAuthor}'`,
      cz: `Nová skladba: '${songNameWithAuthor}'`,
      sk: `Nová skladba: '${songNameWithAuthor}'`,
    },
    title: {
      en: 'New Song',
      cz: 'Nová skladba',
      sk: 'Nová skladba',
    },
    title2: {
      en: songName,
      cz: songName,
      sk: songName,
    },
    paragraphs: [
      {
        en: `User '${loggedUserName}' allowed you to have access to the song '${songNameWithAuthor}'.`,
        cz: `Uživatel '${loggedUserName}' vám umožnil přístup do skladby '${songNameWithAuthor}'.`,
        sk: `Používateľ '${loggedUserName}' vám umožnil prístup do skladby '${songNameWithAuthor}'.`,
      },
      userCanEdit
        ? {
            en: 'You have the right to read and edit the song. It can be found in the list of your songs in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Skladbu máte právo číst a upravovat. Naleznete ji mezi vašimi skladbami v aplikaci m-note, nebo ji otevřete kliknutím na tlačítko níže.',
            sk: 'Skladbu máte právo čítať a upravovať. Nájdete ju medzi vašimi skladbami v aplikácii m-note, alebo ju otvoríte kliknutím na tlačidlo nižšie.',
          }
        : {
            en: 'You have the right to read the song, you cannot edit it. The song can be found in the list of your songs in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Skladbu máte právo pouze číst, nelze ji upravovat. Naleznete ji mezi vašimi skladbami v aplikaci m-note, nebo ji otevřete kliknutím na tlačítko níže.',
            sk: 'Skladbu máte právo iba čítať, nemožno ju upravovať. Nájdete ju medzi vašimi skladbami v aplikácii m-note, alebo ju otvoríte kliknutím na tlačidlo nižšie.',
          },
    ],
    linkName: {
      en: 'Open song',
      cz: 'Otevřít skladbu',
      sk: 'Otvoriť skladbu',
    },
    footer: {
      en: 'This is an auto generated message, please do not reply.',
      cz: 'Tento email je odesílán automaticky, neodpovídejte na něj.',
      sk: 'Tento email je odosielaný automaticky, nezodpovedajte naň.',
    },
    href: `${REACT_APP_URL}${ROUTES.songs.slug}?song=${songId}`,
  }

  const message = emailData.paragraphs.map((p) => p[userLang]).join('\n\n')
  const html = getEmailHtml(emailData)

  await sendEmail({
    emailTo: userEmail,
    subject: emailData.subject[userLang],
    message,
    html,
  })
}

export const sendEmailNewPlaylistMember = async (props: {
  playlistId: number
  playlistName: string
  userEmail: string
  userLang: EmailLanguageType
  userCanEdit?: boolean
}) => {
  const { playlistId, playlistName, userEmail, userLang, userCanEdit } = props

  const loggedUser = LOGGED_USER_VAR()
  const loggedUserName = `${loggedUser.name}${
    loggedUser.username?.length ? ` (${loggedUser.username})` : ''
  }`

  const emailData: EmailProps = {
    lang: userLang,
    subject: {
      en: `New Playlist: '${playlistName}'`,
      cz: `Nový playlist: '${playlistName}'`,
      sk: `Nový playlist: '${playlistName}'`,
    },
    title: {
      en: 'New Playlist',
      cz: 'Nový Playlist',
      sk: 'Nový Playlist',
    },
    title2: {
      en: playlistName,
      cz: playlistName,
      sk: playlistName,
    },
    paragraphs: [
      {
        en: `User '${loggedUserName}' allowed you to have access to the playlist '${playlistName}'.`,
        cz: `Uživatel '${loggedUserName}' vám umožnil přístup do playlistu '${playlistName}'.`,
        sk: `Používateľ '${loggedUserName}' vám umožnil prístup do playlistu '${playlistName}'.`,
      },
      userCanEdit
        ? {
            en: 'You have the right to read and edit the playlist. It can be found in the list of your playlists in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Playlist máte právo číst a upravovat. Naleznete ho mezi vašimi playlisty v aplikaci m-note, nebo ho otevřete kliknutím na tlačítko níže.',
            sk: 'Playlist máte právo čítať a upravovať. Nájdete ho medzi vašimi playlistami v aplikácii m-note, alebo ho otvoríte kliknutím na tlačidlo nižšie.',
          }
        : {
            en: 'You have the right to read the playlist, you cannot edit it. The playlist can be found in the list of your playlists in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Playlist máte právo pouze číst, nelze ho upravovat. Naleznete ho mezi vašimi playlisty v aplikaci m-note, nebo ho otevřete kliknutím na tlačítko níže.',
            sk: 'Playlist máte právo iba čítať, nemožno ho upravovať. Nájdete ho medzi vašimi playlistami v aplikácii m-note, alebo ho otvoríte kliknutím na tlačidlo nižšie.',
          },
    ],
    linkName: {
      en: 'Open Playlist',
      cz: 'Otevřít playlist',
      sk: 'Otvoriť playlist',
    },
    footer: {
      en: 'This is an auto generated message, please do not reply.',
      cz: 'Tento email je odesílán automaticky, neodpovídejte na něj.',
      sk: 'Tento email je odosielaný automaticky, nezodpovedajte naň.',
    },
    href: `${REACT_APP_URL}${ROUTES.playlists.slug}?playlist=${playlistId}`,
  }

  const message = emailData.paragraphs.map((p) => p[userLang]).join('\n\n')
  const html = getEmailHtml(emailData)

  await sendEmail({
    emailTo: userEmail,
    subject: emailData.subject[userLang],
    message,
    html,
  })
}

export const sendEmailNewUserGroupMember = async (props: {
  userGroupId: number
  userGroupName: string
  userEmail: string
  userLang: EmailLanguageType
  userCanEdit?: boolean
}) => {
  const { userGroupId, userGroupName, userEmail, userLang, userCanEdit } = props

  const loggedUser = LOGGED_USER_VAR()
  const loggedUserName = `${loggedUser.name}${
    loggedUser.username?.length ? ` (${loggedUser.username})` : ''
  }`

  const emailData: EmailProps = {
    lang: userLang,
    subject: {
      en: `New User Group: '${userGroupName}'`,
      cz: `Nová skupina: '${userGroupName}'`,
      sk: `Nová skupina: '${userGroupName}'`,
    },
    title: {
      en: 'New User Group',
      cz: 'Nová skupina',
      sk: 'Nová skupina',
    },
    title2: {
      en: userGroupName,
      cz: userGroupName,
      sk: userGroupName,
    },
    paragraphs: [
      {
        en: `User '${loggedUserName}' allowed you to have access to the user group '${userGroupName}'.`,
        cz: `Uživatel '${loggedUserName}' vám umožnil přístup do skupiny '${userGroupName}'.`,
        sk: `Používateľ '${loggedUserName}' vám umožnil prístup do skupiny '${userGroupName}'.`,
      },
      userCanEdit
        ? {
            en: 'You have the right to read and edit the user group. It can be found in the list of your user groups in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Skupinu máte právo číst a upravovat. Naleznete ji mezi vašimi skupinami v aplikaci m-note, nebo ji otevřete kliknutím na tlačítko níže.',
            sk: 'Skupinu máte právo čítať a upravovať. Nájdete ju medzi vašimi skupinami v aplikácii m-note, alebo ju otvoríte kliknutím na tlačidlo nižšie.',
          }
        : {
            en: 'You have the right to read the user group, you cannot edit it. The user group can be found in the list of your user groups in the m-note app, or you can open it by clicking on the button below.',
            cz: 'Skupinu máte právo pouze číst, nelze ji upravovat. Naleznete ji mezi vašimi skupinami v aplikaci m-note, nebo ji otevřete kliknutím na tlačítko níže.',
            sk: 'Skupinu máte právo iba čítať, nemožno ju upravovať. Nájdete ju medzi vašimi skupinami v aplikácii m-note, alebo ju otvoríte kliknutím na tlačidlo nižšie.',
          },
    ],
    linkName: {
      en: 'Open User Group',
      cz: 'Otevřít skupinu',
      sk: 'Otvoriť skupinu',
    },
    footer: {
      en: 'This is an auto generated message, please do not reply.',
      cz: 'Tento email je odesílán automaticky, neodpovídejte na něj.',
      sk: 'Tento email je odosielaný automaticky, nezodpovedajte naň.',
    },
    href: `${REACT_APP_URL}${ROUTES.users.slug}?userGroup=${userGroupId}`,
  }

  const message = emailData.paragraphs.map((p) => p[userLang]).join('\n\n')
  const html = getEmailHtml(emailData)

  await sendEmail({
    emailTo: userEmail,
    subject: emailData.subject[userLang],
    message,
    html,
  })
}
