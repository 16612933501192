import styled, { css } from 'styled-components'

const PAPER_HOLE_GAP = 80

const Wrapper = styled.div<{
  noTopBorder: boolean
  noBottomBorder: boolean
}>`
  flex: 1;
  display: flex;
  background: ${props => props.theme.paper.background};
  border: 1px solid ${props => props.theme.black}18;
  border-radius: 2px;
  overflow-x: auto;
  box-shadow: 5px 5px 10px 0px ${props => props.theme.black}08;

  ${props =>
    props.noTopBorder &&
    css`
      border-top: none;
    `}

  ${props =>
    props.noBottomBorder &&
    css`
      border-bottom: none;
    `}

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  ${props => props.theme.breakpoint.S} {
    border-radius: 6px;
  }
`

const PaperHoles = styled.div`
  width: 10%;
  max-width: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-end;

  ${props => props.theme.breakpoint.S} {
    width: 0;
  }
`

const PaperHolesAbsolute = styled.div`
  top: 40px;
  right: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${PAPER_HOLE_GAP}px;
`

const PaperContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PaperHole = styled.div`
  --size: 14px;
  width: var(--size);
  height: var(--size);
  background: ${props => props.theme.background};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.black}33;

  box-shadow: 5px 5px 10px 0px ${props => props.theme.black}08 inset;
`

/**
 *
 *  COMPONENT
 *
 */
const PaperSheet = (props: {
  children: any
  minWidth?: number
  noTopBorder?: boolean
  noBottomBorder?: boolean
}) => {
  const paperHoleCount = 30

  return (
    <Wrapper
      style={{ minWidth: `${props.minWidth || 0}px` }}
      noTopBorder={props.noTopBorder}
      noBottomBorder={props.noBottomBorder}
    >
      <PaperHoles>
        <PaperHolesAbsolute>
          {Array.apply(null, Array(paperHoleCount)).map((u, i) => (
            <PaperHole key={i} />
          ))}
        </PaperHolesAbsolute>
      </PaperHoles>
      <PaperContent>{props.children}</PaperContent>
    </Wrapper>
  )
}

export default PaperSheet
