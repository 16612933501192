import { forwardRef, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import FlipMove from 'react-flip-move'
import Loader from 'components/App/Loader'
import SvgIcon from 'components/SvgIcon'
import { gridBackground } from 'styles/GlobalStyle/Backgrounds'
import theme from 'styles/theme'
import { useHistory } from 'react-router-dom'

const FORCED_FULLSCREEN_BREAKPOINT = 'L'
const OVERLAY_COLOR = theme.primary

const Modal = styled.div<{ isFullscreen?: boolean; addZIndex?: number }>`
  overscroll-behavior: contain;
  position: fixed;
  z-index: ${(props) => (props.addZIndex ? 10000 + props.addZIndex : 10000)};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;

  padding: ${(props) => (props.isFullscreen ? '0' : '5%')};
  padding-top: ${(props) => (props.isFullscreen ? '0' : '5vh')};
  padding-bottom: ${(props) => (props.isFullscreen ? '0' : '20vh')};

  ${(props) => props.theme.breakpoint[FORCED_FULLSCREEN_BREAKPOINT]} {
    padding: 0;
  }

  & > * {
    /* FlipMove */
    margin: auto;
  }

  backdrop-filter: blur(2px);
  background: ${OVERLAY_COLOR}88;
  transition: all 0.2s ease;
`

const LoaderWrapper = styled.div`
  margin: 15vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const ModalBox = styled.div<{
  isFullscreen?: boolean
  maxWidth?: boolean
  noBorderColor?: boolean
}>`
  --fullHeight: calc(100vh - 35px - 20px);

  position: relative;
  margin: auto;
  flex: 1;
  max-width: ${(props) =>
    props.isFullscreen ? '100%' : props.maxWidth ? props.maxWidth : '1800px'};
  min-height: ${(props) =>
    props.isFullscreen ? css`var(--fullHeight)` : 'none'};

  box-shadow: 0 0 50px 10px ${(props) => props.theme.black}08;
  background: ${(props) => props.theme.background};

  border: 1px solid #000;
  border-top: 60px solid #000;
  border-bottom: 6px solid #000;
  border-color: ${(props) =>
    props.noBorderColor ? props.theme.neutral : props.theme.primary_light};
  border-radius: 5px;

  transition: all 0.1s ease;

  ${(props) => props.theme.breakpoint[FORCED_FULLSCREEN_BREAKPOINT]} {
    min-height: var(--fullHeight);
    max-width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
`

const Content = styled.div<{
  noBackgroundDots?: boolean
  noLargePadding?: boolean
  noBorderColor?: boolean
  smallTitle?: boolean
}>`
  transition: all 0.2s ease;
  margin: 2%;
  padding: 6vh 10%;
  padding-bottom: 15vh;

  ${(props) =>
    props.noLargePadding &&
    css`
      padding: 5vh 10%;
    `};

  ${(props) => props.theme.breakpoint[FORCED_FULLSCREEN_BREAKPOINT]} {
    margin: 0;
  }
  ${(props) => props.theme.breakpoint.XL} {
    padding-left: 0;
    padding-right: 0;
  }
  ${(props) => !props.noBackgroundDots && gridBackground}

  h1 {
    margin-bottom: 5vh;

    ${(props) =>
      props.noBorderColor &&
      css`
        &::after {
          background: ${(props) => props.theme.neutral};
        }
      `};

    ${(props) =>
      props.smallTitle &&
      css`
        font-size: 26px;
      `};
  }
`

const ContentChildren = styled.div<{ noBackgroundDotsUnderContent?: boolean }>`
  flex: 1;
  padding: 4%;

  ${(props) =>
    props.noBackgroundDotsUnderContent &&
    css`
      background: ${(props) => props.theme.background};
    `};
`

const Header = styled.div`
  position: absolute;
  z-index: 10;

  height: 60px;
  top: -60px;
  left: 5px;
  right: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderTitle = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
`

const Icon = styled.div<{ hideOnMobile?: boolean }>`
  transition: all 0.1s ease;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
  padding: 5px;
  svg {
    color: ${(props) => props.theme.white};
    height: 30px;
  }
  ${(props) =>
    props.hideOnMobile &&
    css`
      ${(props) => props.theme.breakpoint.L} {
        display: none;
      }
    `}
`

const Spacer = styled.div`
  flex: 1;
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      hasData
      title
      isFullscreen
      setIsFullscreen
      closeModal
      maxWidth
      noBorderColor
      noBackgroundDots
      noBackgroundDotsUnderContent
      noLargePadding
      smallTitle
      loadingText
      forceFullscreen
      noTitle
      children
    },
    ref
  ) => {
    return (
      <ModalBox
        ref={ref as any}
        style={{ overflowAnchor: 'none' }}
        isFullscreen={props.isFullscreen}
        maxWidth={props.maxWidth}
        noBorderColor={props.noBorderColor}
      >
        <Header>
          <HeaderTitle>{props.title}</HeaderTitle>
          <Spacer />
          {!props.forceFullscreen && (
            <Icon
              onClick={() => props.setIsFullscreen(!props.isFullscreen)}
              hideOnMobile
            >
              {props.isFullscreen ? (
                <SvgIcon code="icon-fullscreen-exit" />
              ) : (
                <SvgIcon code="icon-fullscreen" />
              )}
            </Icon>
          )}
          <Icon onClick={props.closeModal}>
            <SvgIcon code="icon-close" />
          </Icon>
        </Header>

        <Content
          noBackgroundDots={props.noBackgroundDots}
          noLargePadding={props.noLargePadding}
          noBorderColor={props.noBorderColor}
          smallTitle={props.smallTitle}
        >
          {!props.noTitle && <h1>{props.title}</h1>}
          {props.hasData ? (
            <ContentChildren
              noBackgroundDotsUnderContent={props.noBackgroundDotsUnderContent}
            >
              {props.children}
            </ContentChildren>
          ) : (
            <LoaderWrapper>
              <Loader />
              {props.loadingText && <div>{props.loadingText}</div>}
            </LoaderWrapper>
          )}
        </Content>
      </ModalBox>
    )
  }
)

/**
 *
 *  Component
 *
 */
const RootModal = (props: {
  title: string
  children: any
  isOpen: boolean
  hasData: boolean
  closeModal: () => void
  closeOnESC?: boolean
  addZIndex?: number
  maxWidth?: string
  noBorderColor?: boolean
  noBackgroundDots?: boolean
  noBackgroundDotsUnderContent?: boolean
  noLargePadding?: boolean
  smallTitle?: boolean
  loadingText?: string
  forceFullscreen?: boolean
  noTitle?: boolean
}) => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(
    props.forceFullscreen ? true : false
  )

  //
  // KEY LISTENER
  //
  useEffect(() => {
    const handleEsc = (event) => {
      if (
        (typeof props.closeOnESC === 'undefined' || props.closeOnESC) &&
        event.keyCode === 27
      ) {
        props.closeModal()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //
  // HISTORY LISTENER
  //
  const [locationKeys, setLocationKeys] = useState([])
  const history = useHistory()

  const isOpenRef = useRef(props.isOpen)
  useEffect(() => {
    isOpenRef.current = props.isOpen
  }, [props.isOpen])

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          // Handle back event

          if (isOpenRef.current) {
            props.closeModal()
            history.goForward() // return to underlying page on going back
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys])

  //
  // RENDER
  //
  return props.isOpen ? (
    <Modal isFullscreen={isFullscreen} addZIndex={props.addZIndex}>
      <FlipMove
        easing="ease"
        duration={160} // milliseconds
        staggerDurationBy={20} // milliseconds
        staggerDelayBy={20} // milliseconds
        maintainContainerHeight={false}
        appearAnimation={{
          /* first item appearance */
          from: {
            opacity: '0',
            transform: 'scale(0.9)',
            transformOrigin: '50% 50vh',
          },
          to: { opacity: '1', transform: 'none' },
        }}
      >
        <AnimatedItem
          key={0}
          hasData={props.hasData}
          title={props.title}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          closeModal={props.closeModal}
          maxWidth={props.maxWidth}
          noBorderColor={props.noBorderColor}
          noBackgroundDots={props.noBackgroundDots}
          noBackgroundDotsUnderContent={props.noBackgroundDotsUnderContent}
          noLargePadding={props.noLargePadding}
          smallTitle={props.smallTitle}
          children={props.children}
          loadingText={props.loadingText}
          forceFullscreen={props.forceFullscreen}
          noTitle={props.noTitle}
        />
      </FlipMove>
    </Modal>
  ) : null
}

export default RootModal
