import {
  Add,
  AutoFitHeight,
  AutoFitWidth,
  GroupList,
  PaddingLeft,
  PaddingTop,
  Save,
  TextFootnote,
} from '@styled-icons/fluentui-system-filled/'
import {
  AddAPhoto,
  Fullscreen,
  FullscreenExit,
} from '@styled-icons/material-outlined/'
import {
  ArrowDownShort,
  ArrowLeftShort,
  ArrowRightShort,
  ArrowUpShort,
  Circle,
  CircleFill,
  EraserFill,
  EyeFill,
  EyeSlashFill,
  FileCodeFill,
  FileLock2Fill,
  FilePersonFill,
  FilePlayFill,
  FileTextFill,
  FileXFill,
  Files,
  List,
  MusicNoteBeamed,
} from '@styled-icons/bootstrap/'
import { ArrowGoBack, CheckboxCircle, Share } from '@styled-icons/remix-fill/'
import { ArrowIosForward, Text } from '@styled-icons/evaicons-solid/'
import {
  ArrowToLeft,
  ArrowToRight,
  Copyright,
  File,
  FileJson,
  FilePng,
  FileTxt,
  FolderPlus,
  Grid,
  LockAlt,
  SelectMultiple,
  User,
  ZoomIn,
} from '@styled-icons/boxicons-solid/'
import {
  Text as BoxiconsRegularText,
  SelectMultiple as DeselectMultiple,
  DotsHorizontalRounded,
  Font,
  InfoCircle,
  LoaderAlt,
  LogOutCircle,
  Reset,
  Upload,
} from '@styled-icons/boxicons-regular/'
import {
  CheckboxBlankCircle,
  FolderForbid,
  Settings as RemixLineSettings,
  Settings6,
} from '@styled-icons/remix-line/'
import {
  ChevronLeft,
  ChevronRight,
  Folder,
  SkipNext,
  SkipPrevious,
} from '@styled-icons/material-sharp'
import { Cross, TextWidth } from '@styled-icons/icomoon/'
import {
  Dashboard,
  Edit,
  GroupAdd,
  HourglassEmpty,
  Add as MaterialAdd,
  Remove as MaterialRemove,
  Menu,
  PersonAdd,
  Warning,
} from '@styled-icons/material'
import { Guitar, Users } from '@styled-icons/fa-solid/'
import { Key, Search, ViewList } from '@styled-icons/heroicons-solid/'
import { People, Settings } from '@styled-icons/ionicons-sharp/'

import { ReactComponent as BassGuitarIcon } from 'assets/instrumentIcons/icon_bass-guitar.svg'
import { CheckmarkOutline } from '@styled-icons/evaicons-outline/'
import { ReactComponent as ChordFinderIcon } from 'assets/toolIcons/icon_tool_chord-finder-guitar.svg'
import { ReactComponent as ChordNamerIcon } from 'assets/toolIcons/icon_tool_chord-namer-guitar.svg'
import { Close } from '@styled-icons/ionicons-outline/'
import { DeleteOutline } from '@styled-icons/material-twotone/'
import { DocumentDuplicate } from '@styled-icons/heroicons-outline/'
import { DocumentSave } from '@styled-icons/fluentui-system-regular/'
import { ReactComponent as ElectricGuitarIcon } from 'assets/instrumentIcons/icon_electric-guitar_les-paul.svg'
import { ReactComponent as GuitarIcon } from 'assets/instrumentIcons/icon_guitar_grand-auditorium-cutaway.svg'
import { ReactComponent as PianoIcon } from 'assets/instrumentIcons/icon_piano.svg'
import { ReactElement } from 'react'
import { TextDirectionL } from '@styled-icons/remix-editor/'
import { ReactComponent as TransposeLyricsIcon } from 'assets/toolIcons/icon_tool_transpose-lyrics.svg'
import { ReactComponent as UkuleleBaritoneIcon } from 'assets/instrumentIcons/icon_ukulele-baritone.svg'
import { ReactComponent as UkuleleIcon } from 'assets/instrumentIcons/icon_ukulele.svg'

export type IconType =
  | 'icon-nav-dashboard'
  | 'icon-nav-songs'
  | 'icon-nav-playlists'
  | 'icon-nav-users'
  | 'icon-nav-tools'
  | 'icon-nav-dev'
  | 'icon-fullscreen'
  | 'icon-fullscreen-exit'
  | 'icon-song-next'
  | 'icon-song-prev'
  | 'icon-song-settings'
  | 'icon-song-settings-close'
  | 'icon-warning'
  | 'icon-copyright'
  | 'icon-app-loader'
  | 'icon-loader'
  | 'icon-settings'
  | 'icon-admin-badge'
  | 'icon-logout'
  | 'icon-user'
  | 'icon-users'
  | 'icon-reset'
  | 'icon-close'
  | 'icon-edit'
  | 'icon-delete'
  | 'icon-duplicate'
  | 'icon-add'
  | 'icon-add-user'
  | 'icon-add-user-group'
  | 'icon-import-json'
  | 'icon-export-json'
  | 'icon-export-png'
  | 'icon-export-txt'
  | 'icon-text'
  | 'icon-photo'
  | 'icon-menu'
  | 'icon-menu-close'
  | 'icon-submenu-close'
  | 'icon-submenu-indicator'
  | 'icon-arrow-up'
  | 'icon-arrow-down'
  | 'icon-arrow-left'
  | 'icon-arrow-right'
  | 'icon-chevron-left'
  | 'icon-chevron-right'
  | 'icon-fretboard-string-played'
  | 'icon-fretboard-string-open'
  | 'icon-fretboard-string-mute'
  | 'icon-offset-left'
  | 'icon-offset-right'
  | 'icon-upload'
  | 'icon-search'
  | 'icon-private'
  | 'icon-eye-on'
  | 'icon-eye-off'
  | 'icon-circle-on'
  | 'icon-circle-off'
  | 'icon-checkmark'
  | 'icon-plus'
  | 'icon-minus'
  | 'icon-info'
  | 'icon-save'
  | 'icon-save-without-leaving'
  | 'icon-chords'
  | 'icon-zoom-in'
  | 'icon-share'
  | 'icon-paper-design-controls-menu'
  | 'icon-paper-design-mode-chords'
  | 'icon-paper-design-mode-notes'
  | 'icon-paper-design-clear'
  | 'icon-paper-design-line-gap'
  | 'icon-paper-design-chord-name-size'
  | 'icon-paper-design-font-size'
  | 'icon-paper-design-note-size'
  | 'icon-paper-design-chord-duration-size'
  | 'icon-paper-design-padding-top'
  | 'icon-paper-design-padding-left'
  | 'icon-paper-design-height'
  | 'icon-paper-design-column-gap'
  | 'icon-paper-design-line-gap-y'
  | 'icon-paper-design-section-gap-y'
  | 'icon-paper-design-letter-spacing'
  | 'icon-paper-design-scale-x'
  | 'icon-paper-design-separator'
  | 'icon-file-system-grid'
  | 'icon-file-system-list'
  | 'icon-file-system-no-folders'
  | 'icon-file-system-create-folder'
  | 'icon-file-system-select-files'
  | 'icon-file-system-deselect-files'
  | 'icon-file-system-move-files'
  | 'icon-file-system-breadcrumbs-separator'
  | 'icon-file-system-open-file'
  | 'icon-file-system-file-text'
  | 'icon-file-system-file-none'
  | 'icon-file-system-file-playlist'
  | 'icon-file-system-file-user'
  | 'icon-file-system-file-user-group'
  | 'icon-file-system-folder-parent'
  | 'icon-file-system-folder'
  | 'icon-instrument-guitar'
  | 'icon-instrument-electric-guitar'
  | 'icon-instrument-bass-guitar'
  | 'icon-instrument-ukulele'
  | 'icon-instrument-baritone-ukulele'
  | 'icon-instrument-piano'
  | 'icon-tool-chord-namer-guitar'
  | 'icon-tool-chord-finder-guitar'
  | 'icon-tool-text-to-song'

type keymapItem = {
  [code in IconType]: ReactElement
}

export const ICON_MAP: keymapItem = {
  'icon-nav-dashboard': <Dashboard />,
  'icon-nav-songs': <FileTextFill />,
  'icon-nav-playlists': <FilePlayFill />,
  'icon-nav-users': <FilePersonFill />,
  'icon-nav-tools': <FileCodeFill />,
  'icon-nav-dev': <FileLock2Fill />,

  'icon-fullscreen': <Fullscreen />,
  'icon-fullscreen-exit': <FullscreenExit />,
  'icon-song-next': <SkipNext />,
  'icon-song-prev': <SkipPrevious />,
  'icon-song-settings': <RemixLineSettings />,
  'icon-song-settings-close': <Settings6 />,

  'icon-warning': <Warning />,
  'icon-copyright': <Copyright />,
  'icon-app-loader': <MusicNoteBeamed />,
  'icon-loader': <LoaderAlt />,
  'icon-settings': <Settings />,
  'icon-admin-badge': <Key />,
  'icon-logout': <LogOutCircle />,
  'icon-user': <User />,
  'icon-users': <Users />,
  'icon-reset': <Reset />,
  'icon-close': <Close />,
  'icon-edit': <Edit />,
  'icon-delete': <DeleteOutline />,
  'icon-duplicate': <DocumentDuplicate />,
  'icon-add': <Add />,
  'icon-add-user': <PersonAdd />,
  'icon-add-user-group': <GroupAdd />,
  'icon-import-json': <FileJson />,
  'icon-export-json': <FileJson />,
  'icon-export-png': <FilePng />,
  'icon-export-txt': <FileTxt />,
  'icon-text': <Text />,
  'icon-photo': <AddAPhoto />,
  'icon-menu': <Menu />,
  'icon-menu-close': <Close />,
  'icon-submenu-close': <Close />,
  'icon-submenu-indicator': <DotsHorizontalRounded />,
  'icon-arrow-up': <ArrowUpShort />,
  'icon-arrow-down': <ArrowDownShort />,
  'icon-arrow-left': <ArrowLeftShort />,
  'icon-arrow-right': <ArrowRightShort />,
  'icon-chevron-left': <ChevronLeft />,
  'icon-chevron-right': <ChevronRight />,
  'icon-fretboard-string-played': <Circle />,
  'icon-fretboard-string-open': <CircleFill />,
  'icon-fretboard-string-mute': <Cross />,
  'icon-offset-left': <ArrowToLeft />,
  'icon-offset-right': <ArrowToRight />,
  'icon-upload': <Upload />,
  'icon-search': <Search />,
  'icon-private': <LockAlt />,
  'icon-eye-on': <EyeSlashFill />,
  'icon-eye-off': <EyeFill />,
  'icon-circle-on': <CheckboxBlankCircle />,
  'icon-circle-off': <CheckboxCircle />,
  'icon-checkmark': <CheckmarkOutline />,
  'icon-plus': <MaterialAdd />,
  'icon-minus': <MaterialRemove />,
  'icon-info': <InfoCircle />,
  'icon-save': <Save />,
  'icon-save-without-leaving': <DocumentSave />,
  'icon-chords': <Guitar />,
  'icon-zoom-in': <ZoomIn />,
  'icon-share': <Share />,

  'icon-paper-design-controls-menu': <Menu />,
  'icon-paper-design-mode-chords': <TextFootnote />,
  'icon-paper-design-mode-notes': <BoxiconsRegularText />,
  'icon-paper-design-clear': <EraserFill />,
  'icon-paper-design-line-gap': <AutoFitHeight />,
  'icon-paper-design-chord-name-size': <TextFootnote />,
  'icon-paper-design-font-size': <Font />,
  'icon-paper-design-note-size': <BoxiconsRegularText />,
  'icon-paper-design-chord-duration-size': <HourglassEmpty />,
  'icon-paper-design-padding-top': <PaddingTop />,
  'icon-paper-design-padding-left': <PaddingLeft />,
  'icon-paper-design-height': <AutoFitHeight />,
  'icon-paper-design-column-gap': <AutoFitWidth />,
  'icon-paper-design-line-gap-y': <List />,
  'icon-paper-design-section-gap-y': <GroupList />,
  'icon-paper-design-letter-spacing': <TextDirectionL />,
  'icon-paper-design-scale-x': <TextWidth />,
  'icon-paper-design-separator': <DotsHorizontalRounded />,

  'icon-file-system-grid': <Grid />,
  'icon-file-system-list': <ViewList />,
  'icon-file-system-no-folders': <FolderForbid />,
  'icon-file-system-create-folder': <FolderPlus />,
  'icon-file-system-select-files': <SelectMultiple />,
  'icon-file-system-deselect-files': <DeselectMultiple />,
  'icon-file-system-move-files': <Files />,
  'icon-file-system-breadcrumbs-separator': <ArrowIosForward />,
  'icon-file-system-open-file': <File />,
  'icon-file-system-file-text': <FileTextFill />,
  'icon-file-system-file-none': <FileXFill />,
  'icon-file-system-file-playlist': <FilePlayFill />,
  'icon-file-system-file-user': <FilePersonFill />,
  'icon-file-system-file-user-group': <People />,
  'icon-file-system-folder-parent': <ArrowGoBack />,
  'icon-file-system-folder': <Folder />,

  'icon-instrument-guitar': <GuitarIcon />,
  'icon-instrument-electric-guitar': <ElectricGuitarIcon />,
  'icon-instrument-bass-guitar': <BassGuitarIcon />,
  'icon-instrument-ukulele': <UkuleleIcon />,
  'icon-instrument-baritone-ukulele': <UkuleleBaritoneIcon />,
  'icon-instrument-piano': <PianoIcon />,

  'icon-tool-chord-namer-guitar': <ChordNamerIcon />,
  'icon-tool-chord-finder-guitar': <ChordFinderIcon />,
  'icon-tool-text-to-song': <TransposeLyricsIcon />,
}

const SvgIcon = (props: { code: IconType; style?: object }) => {
  const icon = ICON_MAP[props.code || 'icon-close']

  return <icon.type {...icon.props} style={props.style} /> || null
}

export default SvgIcon
