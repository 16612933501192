import styled, { css, keyframes } from 'styled-components'

import { ActionButton } from 'styles/GlobalStyle/Buttons'
import Counter from 'components/Inputs/Counter'
import SvgIcon from 'components/SvgIcon'

export const Separator = styled.div`
  margin: auto;
  svg {
    color: ${(props) => props.theme.black}22;
    width: 20px;
  }
`

export const ColumnLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Item = styled.div<{ isActive: boolean; onClick?: any }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.black}0A;
  opacity: 0.6;
  min-height: 30px;
  transition: all 0.2s ease;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background: ${(props) => props.theme.black}11 !important;
      }
    `};

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      background: ${(props) => props.theme.black}22;
      &:hover {
        background: ${(props) => props.theme.black}28 !important;
      }
    `};

  span {
    color: ${(props) => props.theme.black};
    font-size: 12px;
    padding-left: 8px;
    svg {
      color: ${(props) => props.theme.black}88;
      width: 20px;
    }
  }

  button {
    &:hover {
      background: ${(props) => props.theme.black}11 !important;
    }
  }

  input {
    font-size: 12px;
    font-weight: 600;
  }
`

const ActionButtonLocal = styled(ActionButton)<{ isActive: boolean }>`
  svg {
    color: ${(props) => props.theme.black}88;
  }
  ${(props) =>
    props.isActive &&
    css`
      svg {
        color: ${(props) => props.theme.primary_light};
      }
    `};
`

const Control = styled.div`
  width: 100px;
  min-width: 100px;
  padding: 2px;

  & > * {
    background: none !important;
    border: none !important;
  }
  section {
    background: none !important;
    border: none !important;

    input {
      max-width: 48px;
    }

    button {
      width: 26px;
      min-width: 26px;
    }
  }
`

const Hamburger = styled.div<{ isActive: boolean }>`
  position: relative;
  margin-left: 10px;

  & > button {
    width: 34px;
    height: 34px;
    color: ${(props) => props.theme.black}AA;
    border: none;
    padding: 0;

    svg {
      width: 28px;
      height: 28px;
    }

    ${(props) =>
      props.isActive &&
      css`
        color: ${(props) => props.theme.black}88;
        border: 1px solid ${(props) => props.theme.black}44;
        background: ${(props) => props.theme.black}11 !important;
        &:hover {
          background: ${(props) => props.theme.black}28 !important;
        }
      `};
  }
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`
const MenuOverlay = styled.div`
  position: absolute;
  z-index: 20;
  top: 100%;
  right: 0px;
  width: 135px;
  min-width: 135px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.black}22;
  background: ${(props) => props.theme.white};
  box-shadow: 0 0 10px 2px ${(props) => props.theme.black}11;

  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.2s;
  animation-fill-mode: backwards;
`

//
// FUNCTIONS
//
const translateLabel = (label: any, t: any) => {
  if (typeof label === 'string') {
    return t('song.design options.' + label)
  }
  return label
}

const translateTooltip = (prop: any, t: any) => {
  return t('song.design options.' + prop + ' tooltip')
}

//
// RENDERS
//
export const renderHamburgerMenu = (props: {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  renderMenu: () => void
  menuRef: any
  t: any
}) => {
  return (
    <Hamburger
      title={props.t('song.lyric editor options.open menu tooltip')}
      isActive={props.isOpen}
    >
      <ActionButton
        onClick={() => !props.isOpen && props.setIsOpen(!props.isOpen)}
      >
        <SvgIcon
          code={props.isOpen ? 'icon-close' : 'icon-paper-design-controls-menu'}
        />
      </ActionButton>
      {props.isOpen && (
        <MenuOverlay ref={props.menuRef}>
          <ColumnLayout>{props.renderMenu()}</ColumnLayout>
        </MenuOverlay>
      )}
    </Hamburger>
  )
}

export const renderCounterControl = (props: {
  design: Object
  setDesign: (d: Object) => void
  label: any
  prop: string
  options?: {
    isActive?: boolean
    valueMin?: number
    valueMax?: number
    step?: number
  }
  t: any
}) => {
  return (
    <Item
      isActive={props.options?.isActive}
      title={translateTooltip(props.prop, props.t)}
    >
      <span>{translateLabel(props.label, props.t)}</span>
      <Control>
        <Counter
          withoutDefaultCss
          value={props.design[props.prop]}
          setValue={(value) => {
            const updatedDesign = { ...props.design }
            updatedDesign[props.prop] = value
            props.setDesign(updatedDesign)
          }}
          valueMin={
            props.options?.valueMin || props.options?.valueMin === 0
              ? props.options?.valueMin
              : 0
          }
          valueMax={
            props.options?.valueMax || props.options?.valueMax === 0
              ? props.options?.valueMax
              : 60
          }
          step={props.options?.step}
          height={'26px'}
        />
      </Control>
    </Item>
  )
}

export const renderToggleControl = (props: {
  design: Object
  setDesign: (d: Object) => void
  label: any
  prop: string
  options?: { isActive?: boolean; useCheckMark?: boolean }
  t: any
}) => {
  const value = props.design[props.prop]
  const iconON = props.options?.useCheckMark ? (
    <SvgIcon code="icon-circle-on" />
  ) : (
    <SvgIcon code="icon-eye-on" />
  )
  const iconOFF = props.options?.useCheckMark ? (
    <SvgIcon code="icon-circle-off" />
  ) : (
    <SvgIcon code="icon-eye-off" />
  )

  return (
    <Item
      isActive={props.options?.isActive}
      title={translateTooltip(props.prop, props.t)}
      onClick={() => {
        const updatedDesign = { ...props.design }
        updatedDesign[props.prop] = !value
        props.setDesign(updatedDesign)
      }}
    >
      <span>{translateLabel(props.label, props.t)}</span>
      <ActionButtonLocal isActive={value}>
        {value ? iconOFF : iconON}
      </ActionButtonLocal>
    </Item>
  )
}

export const renderButtonControl = (props: {
  design: Object
  setDesign: (d: Object) => void
  label: any
  icon: any
  onClick: () => void
  options?: { isActive?: boolean }
  t: any
}) => {
  return (
    <Item
      isActive={props.options?.isActive}
      onClick={props.onClick}
      title={translateTooltip(props.label, props.t)}
    >
      <span>{translateLabel(props.label, props.t)}</span>
      <ActionButton>{props.icon}</ActionButton>
    </Item>
  )
}
