import { gql } from '@apollo/client'

export const CREATE_PLAYLIST = gql`
  mutation createPlaylist(
    $name: String!
    $body: String
    $members: [PlaylistMemberInput]
    $songIds: [ID]
  ) {
    createPlaylist(
      name: $name
      body: $body
      members: $members
      songIds: $songIds
    ) {
      success
      message
      id
    }
  }
`

export const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist(
    $id: ID!
    $name: String!
    $body: String
    $members: [PlaylistMemberInput]
    $songIds: [ID]
  ) {
    updatePlaylist(
      id: $id
      name: $name
      body: $body
      members: $members
      songIds: $songIds
    ) {
      success
      message
    }
  }
`

export const DELETE_PLAYLIST = gql`
  mutation deletePlaylist($id: ID!) {
    deletePlaylist(id: $id) {
      success
      message
    }
  }
`
