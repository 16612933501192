import AvatarUpload from 'components/Inputs/AvatarUpload'
import SectionBox from 'components/Boxes/SectionBox'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import TextInput from 'components/Inputs/TextInput'
import UserGroupMembers from '../UserGroupMembers'
import { UserGroupType } from 'types'
import { hasGroupPermission } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;

  & > * {
    background: ${(props) => props.theme.background};
  }

  textArea {
    min-height: 60px;
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;

  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column-reverse;
  }
`

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-child {
    min-width: 168px;
    max-width: 168px;

    ${(props) => props.theme.breakpoint.S} {
      align-self: center;
    }

    ${(props) => props.theme.breakpoint.XS} {
      min-width: 100%;
      max-width: 100%;
    }
  }
`

/**
 *
 * COMPONENT
 *
 */
const UserGroupForm = (props: {
  userGroup: UserGroupType
  setUserGroup: (userGroup: UserGroupType) => void
  invalidFields?: string[]
  setInvalidFields?: (fields: string[]) => void
  isEditMode?: boolean
}) => {
  const { t } = useTranslation()

  const checkInvalidValue = (value, id) => {
    if (props.setInvalidFields && props.invalidFields) {
      if (props.invalidFields.includes(id) && value.length) {
        props.setInvalidFields([...props.invalidFields].filter((f) => f !== id))
      }
    }
  }

  //
  // PERMISSIONS
  //
  const getAllowUpdateGroup = () =>
    !props.isEditMode || hasGroupPermission(props.userGroup, 'GR_updateGroup')

  //
  // RENDER
  //
  return (
    <Wrapper>
      <SectionBox
        name={t('user group.general info')}
        icon={<SvgIcon code="icon-info" />}
      >
        <Row>
          <Col>
            <StringInput
              label={t('user group.name')}
              value={props.userGroup.name}
              setValue={(value) => {
                props.setUserGroup({ ...props.userGroup, name: value })
                checkInvalidValue(value, 'name')
              }}
              isInvalid={props.invalidFields?.includes('name')}
              disabled={!getAllowUpdateGroup()}
            />

            <TextInput
              label={t('user group.description')}
              value={props.userGroup.description}
              setValue={(value) => {
                props.setUserGroup({ ...props.userGroup, description: value })
                checkInvalidValue(value, 'description')
              }}
              isMultiline
              isResizable
              disabled={!getAllowUpdateGroup()}
            />
          </Col>
          <Col>
            <AvatarUpload
              label={t('user group.avatar')}
              value={props.userGroup.avatar}
              setValue={(value) => {
                props.setUserGroup({ ...props.userGroup, avatar: value })
              }}
              isUserGroup
            />
          </Col>
        </Row>
      </SectionBox>

      <SectionBox
        name={t('user group.members')}
        icon={<SvgIcon code="icon-users" />}
      >
        <UserGroupMembers
          userGroup={props.userGroup}
          setUserGroup={props.setUserGroup}
          isEditMode={props.isEditMode}
        />
      </SectionBox>
    </Wrapper>
  )
}

export default UserGroupForm
