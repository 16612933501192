import { DBPlaylistType, PlaylistBodyType } from 'types'
import FileSystem, { File, ViewMode } from 'components/Files/FileSystem'
import {
  compareSearchStrings,
  fileFromPlaylist,
  getLocalizedTimeUntilDate,
  isPlaylistPast,
  openInNewTab,
  sortPlaylists,
} from 'utils'
import { differenceInDays, isToday, isTomorrow } from 'date-fns'
import styled, { css, keyframes } from 'styled-components'
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { FileSystemIconButtonType } from '../FileSystem/FileSystemIconButton'
import { GET_PLAYLISTS } from 'api/graphql'
import Loader from 'components/App/Loader'
import { ROUTES } from 'const'
import { USER_PLAYLIST_LIST_VAR } from 'App'
import theme from 'styles/theme'
import { useTranslation } from 'react-i18next'

const LoaderWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FlashAnimation = keyframes`
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const PlaylistOverlayWrapperPast = styled.div`
  position: absolute;
  border-radius: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.background}AA;
`

const PlaylistOverlayWrapper = styled.div<{
  viewMode: ViewMode
  fontSize: number
  color: string
  background: string
  isFlashing?: boolean
}>`
  position: absolute;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 4px;

  font-size: ${(props) => props.fontSize + 2}px;
  font-weight: 600;

  ${(props) => props.theme.breakpoint.S} {
    font-size: ${(props) => props.fontSize}px;
  }

  color: ${(props) => props.color || props.theme.primary_light};
  background: ${(props) => props.background || props.theme.background};

  ${(props) =>
    props.isFlashing &&
    css`
      animation: ${FlashAnimation} 0.6s infinite ease;
    `}

  ${(props) =>
    props.viewMode === 'grid'
      ? css`
          bottom: 100%;
          margin-bottom: 2px;
        `
      : css`
          top: 100%;
          left: 100%;
          margin-top: -2px;
          margin-left: 6px;
        `}
`

const FileSystemPlaylists = (props: {
  preloadedPlaylists?: DBPlaylistType[]
  onFileAddClick?: (file: File) => void
  onFileOpenClick?: (file: File) => void
  onFilePreviewClick?: (file: File) => void
  onFileSingleClick?: (file: File) => void
  onFileDoubleClick: (file: File) => void
  onFolderAddClick?: (file: File) => void
  onFolderSingleClick?: (file: File) => void
  onFolderDoubleClick?: (file: File) => void
  defaultViewMode?: ViewMode
  maxHeight?: string
  noSearch?: boolean
  noSelection?: boolean
  noBackground?: boolean
  customButtons?: FileSystemIconButtonType[]
}) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const userPlaylists = useReactiveVar(USER_PLAYLIST_LIST_VAR)

  const playlists = props.preloadedPlaylists || userPlaylists

  const [viewMode, setViewMode] = useState<ViewMode>(
    props.defaultViewMode || 'grid'
  )

  //
  // QUERY ALL PLAYLISTS
  //
  const { data: dataPlaylists, loading: playlistsLoading } = useQuery(
    GET_PLAYLISTS,
    {
      skip: props.preloadedPlaylists ? true : false,
    }
  )

  useEffect(() => {
    if (dataPlaylists?.getPlaylists) {
      USER_PLAYLIST_LIST_VAR(dataPlaylists.getPlaylists)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPlaylists])

  //
  // CLICK HANDLERS - FILE
  //
  const onFileMiddleClick = (file: File) => {
    let params: any = { playlist: file.id }
    openInNewTab(
      `${ROUTES.playlists.slug}?${new URLSearchParams(params).toString()}`
    )
  }

  //
  // FILE SYSTEM MANAGEMENT
  //
  const [files, setFiles] = useState<File[]>([])
  const [search, setSearch] = useState<string>('')

  const populateFiles = () => {
    if (playlists) {
      const files: File[] = sortPlaylists(playlists).map((p) =>
        fileFromPlaylist(p, lang, ({ isActive, viewMode }) =>
          renderCustomOverlay({ playlist: p, isActive, viewMode })
        )
      )
      setFiles(files)
    }
  }

  const searchPlaylists = () => {
    return sortPlaylists(
      playlists?.filter((p) => compareSearchStrings(p.name, search)) || []
    ).map((p) =>
      fileFromPlaylist(p, lang, ({ isActive, viewMode }) =>
        renderCustomOverlay({ playlist: p, isActive, viewMode })
      )
    )
  }

  useEffect(() => {
    if (playlists) {
      if (search) {
        setFiles(searchPlaylists())
      } else {
        populateFiles()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlists, search])

  //
  // FILE RENDER OVERLAY
  //
  const renderCustomOverlay = (options: {
    playlist: DBPlaylistType
    isActive: boolean
    viewMode: ViewMode
  }) => {
    const playlistBody: PlaylistBodyType = JSON.parse(options.playlist.body)

    const date = playlistBody.eventDate

    if (!date) {
      return null
    }

    const today = new Date()
    const playlistDate = new Date(date)

    let fontSize = 8
    let background = undefined
    let color = `${theme.black}33`

    if (isPlaylistPast(playlistDate)) {
      color = `${theme.black}33`
    } else if (isToday(playlistDate)) {
      fontSize = 10
      color = theme.white
      background = theme.warning
    } else if (isTomorrow(playlistDate)) {
      fontSize = 10
      color = theme.warning
    } else if (differenceInDays(playlistDate, today) <= 7) {
      color = theme.warning
    }

    if (options.isActive) {
      if (isToday(playlistDate)) {
        background = theme.primary_light
      } else {
        color = theme.primary_light
      }
    }

    return (
      <>
        <PlaylistOverlayWrapper
          viewMode={options.viewMode}
          fontSize={fontSize}
          color={color}
          background={background}
          isFlashing={isToday(playlistDate)}
        >
          {getLocalizedTimeUntilDate(playlistBody?.eventDate)}
        </PlaylistOverlayWrapper>

        {isPlaylistPast(playlistDate) && (
          <PlaylistOverlayWrapperPast viewMode={options.viewMode} />
        )}
      </>
    )
  }

  //
  // RENDER
  //
  return playlistsLoading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <FileSystem
      files={files}
      onFileAddClick={props.onFileAddClick}
      onFileOpenClick={props.onFileOpenClick}
      onFilePreviewClick={props.onFilePreviewClick}
      onFileSingleClick={props.onFileSingleClick}
      onFileDoubleClick={props.onFileDoubleClick}
      onFileMiddleClick={onFileMiddleClick}
      onFolderAddClick={props.onFolderAddClick}
      onFolderSingleClick={props.onFolderSingleClick}
      viewMode={viewMode}
      setViewMode={setViewMode}
      search={props.noSearch ? undefined : search}
      setSearch={props.noSearch ? undefined : setSearch}
      searchPlaceholder={t('playlist.search placeholder')}
      noFolders={true}
      maxHeight={props.maxHeight}
      noSelection={props.noSelection}
      noMultiSelection={true}
      noBackground={props.noBackground}
      noAlphabeticalSort={true}
    />
  )
}

export default FileSystemPlaylists
