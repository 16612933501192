/* eslint-disable react-hooks/exhaustive-deps */
import { GET_LOGGED_USER, GET_USER, GET_USER_GROUPS } from 'api/graphql'
import { LOGGED_USER_VAR, USER_GROUP_LIST_VAR } from 'App'
import { getTabName, getUserAvatar } from 'utils'
import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'

import AvatarExplodedView from '../../Boxes/AvatarExplodedView'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import { ROUTES } from 'const'
import { Redirect } from 'react-router-dom'
import SvgIcon from 'components/SvgIcon'
import UserCard from 'components/User/UserCard'
import { UserType } from 'types'
import { removeAuthToken } from 'utils/auth'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ButtonLogout = styled.button`
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  padding-right: 25px;
  align-self: center;
  margin-bottom: 10px;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const UserDetailPage = (props: {
  userId: string
  setOutsideUserData?: (playlist: UserType) => void
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const client = useApolloClient()

  const [user, setUser] = useState<UserType>()

  const userGroups = useReactiveVar(USER_GROUP_LIST_VAR)?.filter((g) =>
    g.userGroupMembers.map((m) => m.user?.id).includes(user?.id)
  )

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)
  const isLoggedUser = user?.id === loggedUser.id

  useEffect(() => {
    document.title = getTabName(user?.name)
  }, [user?.name])

  const handleLogout = () => {
    history.push(ROUTES.home.slug)

    removeAuthToken()

    sessionStorage.clear()
    client.clearStore().then(() => {
      client.resetStore()
    })

    refetchLoggedUser()
    LOGGED_USER_VAR(null)
  }

  //
  // QUERY USER DATA
  //
  const { data: fetchedUserData, loading } = useQuery(GET_USER, {
    variables: { id: props.userId },
  })

  useEffect(() => {
    if (fetchedUserData?.getUser) {
      setUser(fetchedUserData?.getUser)
    }
    if (fetchedUserData?.getUser === null) {
      toast.warning(t('user.no_access_redirect'))
    }
  }, [fetchedUserData])

  useEffect(() => {
    if (props.setOutsideUserData) {
      props.setOutsideUserData(user)
    }
  }, [user])

  //
  // QUERY LIST USER GROUPS
  //
  const { data: dataUserGroups } = useQuery(GET_USER_GROUPS)

  useEffect(() => {
    if (!USER_GROUP_LIST_VAR() && dataUserGroups?.getUserGroups) {
      USER_GROUP_LIST_VAR(dataUserGroups.getUserGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserGroups])

  const { refetch: refetchLoggedUser } = useQuery(GET_LOGGED_USER)

  //
  // RENDER
  //
  if (!loading && !fetchedUserData?.getUser) {
    return <Redirect to={ROUTES.users.slug} />
  }

  return (
    <LoaderPageLayout
      title={user?.name}
      subtitle={user?.username}
      isPrivate={false}
      loadingText={t('user.loading user data')}
      isLoading={loading}
      isReady={!!user}
    >
      {user && (
        <>
          <AvatarExplodedView
            avatar={getUserAvatar(user)}
            user={user}
            items1={[
              { component: t('user.roles.' + user.role.code), isText: true },
              null,
              { component: t('languages.' + user.languageCode), isText: true },
            ]}
            items2={userGroups?.map((userGroup) => {
              return {
                component: (
                  <UserCard
                    userGroup={userGroup}
                    noBackground
                    useLink
                    noStretch
                  />
                ),
              }
            })}
          />

          {isLoggedUser && (
            <ButtonLogout className="btn-primary" onClick={handleLogout}>
              <SvgIcon code="icon-logout" /> {t('logout')}
            </ButtonLogout>
          )}
        </>
      )}
    </LoaderPageLayout>
  )
}

export default UserDetailPage
