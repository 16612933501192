import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { PlaylistBodyType } from 'types'

import StringInput from 'components/Inputs/StringInput'
import TextInput from 'components/Inputs/TextInput'
import DateInput from 'components/Inputs/DateInput'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 20px;

  padding: 30px;
  background: ${props => props.theme.background};

  ${props => props.theme.breakpoint.S} {
    padding: 5%;
    gap: 10px;
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: 20px;

  ${props => props.theme.breakpoint.S} {
    gap: 10px;
  }
`

const Column = styled.div<{ wide?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  min-width: 250px;

  ${props =>
    props.wide &&
    css`
      flex: 2;
      min-width: 400px;

      ${props => props.theme.breakpoint.S} {
        min-width: 250px;
      }
    `}
`

const PlaylistName = styled.div`
  input {
    font-size: 16px;
    font-weight: 600;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const PlaylistInformation = (props: {
  generalInfo: PlaylistBodyType
  setGeneralInfo: (data: PlaylistBodyType) => void
}) => {
  const { t } = useTranslation()

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Row>
        <Column>
          <PlaylistName>
            <StringInput
              label={t('playlist.name')}
              value={props.generalInfo.name}
              setValue={value =>
                props.setGeneralInfo({
                  ...props.generalInfo,
                  name: value,
                })
              }
            />
          </PlaylistName>

          <DateInput
            label={t('playlist.event date')}
            value={props.generalInfo.eventDate}
            setValue={value =>
              props.setGeneralInfo({
                ...props.generalInfo,
                eventDate: value,
              })
            }
          />
        </Column>

        <Column>
          <TextInput
            label={t('playlist.description')}
            value={props.generalInfo.description}
            setValue={value =>
              props.setGeneralInfo({
                ...props.generalInfo,
                description: value,
              })
            }
            isMultiline
            isResizable
            height="84px"
          />
        </Column>
      </Row>
    </Wrapper>
  )
}

export default PlaylistInformation
