import { DBSongType, SongCategoryType } from 'types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import FileSystem, { File, ViewMode } from 'components/Files/FileSystem'
import {
  GET_LOGGED_USER,
  GET_SONGS,
  UPDATE_USER_SONG_CATEGORIES,
} from 'api/graphql'
import { LOGGED_USER_VAR, USER_SONG_LIST_VAR } from 'App'
import {
  compareSearchStrings,
  createFilesFromSongCategories,
  deleteSongCategory,
  fileFromSong,
  folderFromSongCategory,
  getSongCategoryParentChain,
  getSongCategoryParentIdOfParentId,
  moveSongsAndCategories,
  openInNewTab,
  parseFetchedSongCategories,
  uniqueSongCategoryId,
  updateSongCategory,
} from 'utils'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import Loader from 'components/App/Loader'
import { ROUTES } from 'const'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const LoaderWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ROOT_CATEGORY_ID = 0

const FileSystemSongs = (props: {
  songCategories: SongCategoryType[]
  setSongCategories: (songCategories: SongCategoryType[]) => void
  doCreateFolder?: { folder: File; parentId?: number }
  setDoCreateFolder?: (val: any) => void
  parentId: string
  folderId: string
  onFileAddClick?: (file: File) => void
  onFileOpenClick?: (file: File) => void
  onFilePreviewClick?: (file: File) => void
  onFileSingleClick?: (file: File) => void
  onFileDoubleClick: (file: File) => void
  onFolderAddClick?: (file: File) => void
  onFolderSingleClick?: (file: File) => void
  onFolderDoubleClick: (file: File) => void
  setIsUnselecting?: (val: boolean) => void
  doFileSelect?: boolean
  setDoFileSelect?: (val: boolean) => void
  maxHeight?: string
  viewMode?: ViewMode
  setViewMode?: Dispatch<SetStateAction<ViewMode>>
  preloadedSongs?: DBSongType[]
  preloadedSongsLoading?: boolean
  preloadedSongCategories?: SongCategoryType[]
  noSearch?: boolean
  noFolders?: boolean
  setNoFolders?: (val: boolean) => void
  noSelection?: boolean
  noMultiSelection?: boolean
  noBackground?: boolean
  noAlphabeticalSort?: boolean
  isAdminCreateUserFolders?: boolean
  showFileCount?: boolean
  doNotStoreAnyData?: boolean // for _dev module preview (do not ruin folders for logged user)
}) => {
  const { t } = useTranslation()

  const user = useReactiveVar(LOGGED_USER_VAR)
  const userSongs = useReactiveVar(USER_SONG_LIST_VAR)

  const songs = props.preloadedSongs || userSongs

  const rootCategory: SongCategoryType = {
    id: ROOT_CATEGORY_ID,
    name: t('song categories.root_folder'),
    songIds: [],
  }

  //
  // QUERY ALL SONGS
  //
  const { data: dataSongs, loading: songsLoading } = useQuery(GET_SONGS, {
    skip: props.preloadedSongs || props.preloadedSongsLoading ? true : false,
  })

  useEffect(() => {
    if (dataSongs?.getSongs) {
      USER_SONG_LIST_VAR(dataSongs.getSongs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSongs])

  //
  // CLICK HANDLERS - FILE
  //
  const onFileMiddleClick = (file: File) => {
    let params: any = { song: file.id }
    if (props.folderId) {
      params = { parent: props.parentId, folder: props.folderId, ...params }
    }
    openInNewTab(
      `${ROUTES.songs.slug}?${new URLSearchParams(params).toString()}`
    )
  }

  //
  // CLICK HANDLERS - FOLDER
  //
  const onFolderDoubleClick = (folder: File) => {
    setSearch('')
    props.onFolderDoubleClick(folder)
  }

  const onFolderMiddleClick = (folder: File) => {
    openInNewTab(
      `${ROUTES.songs.slug}?${new URLSearchParams({
        parent: folder.parentId as any,
        folder: folder.id as any,
      }).toString()}`
    )
  }

  //
  // FETCH / STORE SONG CATEGORIES
  //
  const [updateUserSongCategories, { data: updatedSongCategoriesData }] =
    useMutation(UPDATE_USER_SONG_CATEGORIES, {
      refetchQueries: [GET_LOGGED_USER],
    })

  useEffect(() => {
    if (updatedSongCategoriesData?.updateUserSongCategories) {
      if (updatedSongCategoriesData?.updateUserSongCategories?.success) {
        toast.success(
          t(updatedSongCategoriesData?.updateUserSongCategories.message)
        )
      } else {
        toast.error(
          t(updatedSongCategoriesData?.updateUserSongCategories.message)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSongCategoriesData])

  const storeSongCategories = (categories: SongCategoryType[]) => {
    if (!props.doNotStoreAnyData) {
      updateUserSongCategories({
        variables: {
          id: Number(user?.id),
          songCategories: JSON.stringify(
            categories.filter(
              (c) =>
                c.id !== ROOT_CATEGORY_ID &&
                c.id >= 0 /* negative ids are used for temp folders in admin */
            )
          ),
        },
      })
    }
  }

  //
  // FILE SYSTEM MANAGEMENT
  //
  const [files, setFiles] = useState<File[]>([])
  const [search, setSearch] = useState<string>('')

  const getCurrentFolderId = () => {
    if (typeof props.folderId === 'number') {
      return props.folderId
    }
    return props.folderId?.length ? parseInt(props.folderId) : rootCategory.id
  }

  const getCurrentParentId = () => {
    if (typeof props.parentId === 'number') {
      return props.parentId
    }
    return props.parentId?.length ? parseInt(props.parentId) : undefined
  }

  const getParentIdOfParentId = (id: number) => {
    return getSongCategoryParentIdOfParentId({
      songCategories: props.songCategories,
      id,
    })
  }

  const getFolderBreadcrumbs = () => {
    if (getCurrentFolderId() === rootCategory.id) {
      return []
    }
    return [
      ...getSongCategoryParentChain({
        songCategories: props.songCategories,
        id: getCurrentFolderId(),
      }),
      rootCategory,
    ]
      .reverse()
      .map((category) => {
        return folderFromSongCategory(category)
      })
  }

  const handleFolderCreate = (options: { folder: File; parentId?: number }) => {
    const newCategory: SongCategoryType = {
      id: uniqueSongCategoryId(props.songCategories),
      parentCategoryId: options.parentId,
      name: options.folder.name,
      name2: options.folder.name2,
      songIds: [],
    }
    const updatedCategories = [...props.songCategories, newCategory]
    storeSongCategories(updatedCategories)
  }

  useEffect(() => {
    if (props.doCreateFolder) {
      handleFolderCreate(props.doCreateFolder)
      props.setDoCreateFolder(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.doCreateFolder])

  const handleMoveFilesAndFolders = (options: {
    folderIds: number[]
    filedIds: number[]
    originalFolderId: number
    targetFolderId: number
  }) => {
    const updatedCategories: SongCategoryType[] = moveSongsAndCategories({
      songIds: options.filedIds,
      categoryIds: options.folderIds,
      originalCategoryId: options.originalFolderId,
      targetCategoryId: options.targetFolderId,
      songCategories: props.songCategories,
    })
    storeSongCategories(updatedCategories)
  }

  const handleFolderDelete = (folder: File) => {
    const updatedCategories: SongCategoryType[] = deleteSongCategory({
      songCategories: props.songCategories,
      deleteCategoryId: folder.id,
    })
    storeSongCategories(updatedCategories)
  }

  const handleFolderEdit = (folder: File) => {
    const updatedCategories: SongCategoryType[] = updateSongCategory({
      songCategories: props.songCategories,
      songCategoryId: folder.id,
      name: folder.name,
      name2: folder.name2,
    })
    storeSongCategories(updatedCategories)
  }

  const populateFiles = () => {
    const currentFolderId = getCurrentFolderId()
    if (props.songCategories && (currentFolderId || currentFolderId === 0)) {
      const files: File[] = createFilesFromSongCategories({
        currentFolderId,
        rootCategory,
        songCategories: props.songCategories,
        allSongs: songs,
      })
      setFiles(files)
    }
  }

  const getAllFiles = () => {
    return songs.map((s) => {
      return fileFromSong(s)
    })
  }

  const searchSongs = () => {
    return (
      songs?.filter(
        (s) =>
          compareSearchStrings(s.name, search) ||
          compareSearchStrings(s.author, search)
      ) || []
    )
  }

  const searchSongCategories = () => {
    return (
      props.songCategories?.filter(
        (c) =>
          compareSearchStrings(c.name, search) ||
          compareSearchStrings(c.name2, search)
      ) || []
    )
  }

  const getSearchFiles = () => {
    return [
      ...searchSongs().map((s) => {
        return fileFromSong(s)
      }),
      ...searchSongCategories().map((c) => {
        return folderFromSongCategory(c)
      }),
    ]
  }

  useEffect(() => {
    if (songs) {
      if (search) {
        setFiles(getSearchFiles())
      } else if (props.noFolders) {
        setFiles(getAllFiles())
      } else {
        populateFiles()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songs, props.songCategories, props.folderId, search, props.noFolders])

  useEffect(() => {
    // Parse stored song categories, find song objects
    if (props.preloadedSongCategories && !props.songCategories) {
      props.setSongCategories(props.preloadedSongCategories)
    } else if (user && songs) {
      const songCategories: SongCategoryType[] = parseFetchedSongCategories({
        user,
        rootCategory,
        allSongs: songs,
        isAdminCreateUserFolders: props.isAdminCreateUserFolders,
      })
      props.setSongCategories(songCategories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, songs])

  //
  // RENDER
  //
  return songsLoading || props.preloadedSongsLoading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <FileSystem
      files={files}
      folderBreadcrumbs={getFolderBreadcrumbs()}
      currentFolderId={getCurrentFolderId()}
      currentParentId={getCurrentParentId()}
      getParentIdOfParentId={getParentIdOfParentId}
      onFileAddClick={props.onFileAddClick}
      onFileOpenClick={props.onFileOpenClick}
      onFilePreviewClick={props.onFilePreviewClick}
      onFileSingleClick={props.onFileSingleClick}
      onFileDoubleClick={props.onFileDoubleClick}
      onFileMiddleClick={onFileMiddleClick}
      onFolderAddClick={props.onFolderAddClick}
      onFolderSingleClick={props.onFolderSingleClick}
      onFolderDoubleClick={onFolderDoubleClick}
      onFolderMiddleClick={onFolderMiddleClick}
      handleFolderDelete={handleFolderDelete}
      handleFolderEdit={handleFolderEdit}
      handleMoveFilesAndFolders={handleMoveFilesAndFolders}
      viewMode={props.viewMode}
      setViewMode={props.setViewMode}
      doFileSelect={props.doFileSelect}
      setDoFileSelect={props.setDoFileSelect}
      setIsUnselecting={props.setIsUnselecting}
      search={props.noSearch ? undefined : search}
      setSearch={props.noSearch ? undefined : setSearch}
      searchPlaceholder={t('song categories.search')}
      noFolders={props.noFolders}
      setNoFolders={props.setNoFolders}
      maxHeight={props.maxHeight}
      noSelection={props.noSelection}
      noMultiSelection={props.noMultiSelection}
      noBackground={props.noBackground}
      noAlphabeticalSort={props.noAlphabeticalSort}
      showFileCount={props.showFileCount}
    />
  )
}

export default FileSystemSongs
