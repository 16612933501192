import styled, { keyframes } from 'styled-components'

import SvgIcon from 'components/SvgIcon'

const SIZE = '200px'
const SIZE_SMALL = '100px'

const Wrapper = styled.div<{ small?: boolean }>`
  position: relative;
  width: ${(props) => (props.small ? SIZE_SMALL : SIZE)};
  height: ${(props) => (props.small ? SIZE_SMALL : SIZE)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.small ? SIZE_SMALL : SIZE)};
  height: ${(props) => (props.small ? SIZE_SMALL : SIZE)};
`

const animIcon = keyframes`
  0% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(1.1);
  }
  45% {
    transform: scale(0.9);
  }
  55% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
`

const Icon = styled.div<{ inverted?: boolean }>`
  width: 15%;
  margin: auto;
  animation: ${animIcon} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  svg {
    width: 100%;
    color: ${(props) =>
      props.inverted ? props.theme.white : props.theme.black};
    opacity: ${(props) => (props.inverted ? 0.4 : 0.4)};
  }
`

const animCircle = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`
const Circle = styled.div<{
  animOffset?: string
  inverted?: boolean
  small?: boolean
}>`
  --borderWidth: 1px;
  --size: calc(
    ${(props) => (props.small ? SIZE_SMALL : SIZE)} - 2 * var(--borderWidth)
  );

  width: var(--size);
  height: var(--size);

  animation: ${animCircle} 1.8s infinite ease-out;
  animation-delay: ${(props) => props.animOffset};

  border-radius: 50%;
  border: var(--borderWidth) solid
    ${(props) =>
      props.inverted ? `${props.theme.white}44` : `${props.theme.black}22`};
`

const Loader = (props: { inverted?: boolean; small?: boolean }) => {
  return (
    <Wrapper small={props.small}>
      <Icon inverted={props.inverted}>
        <SvgIcon code="icon-app-loader" />
      </Icon>
      <Absolute>
        <Circle inverted={props.inverted} small={props.small} />
      </Absolute>
      <Absolute>
        <Circle
          inverted={props.inverted}
          small={props.small}
          animOffset={'-0.6s'}
        />
      </Absolute>
      <Absolute>
        <Circle
          inverted={props.inverted}
          small={props.small}
          animOffset={'-1.2s'}
        />
      </Absolute>
    </Wrapper>
  )
}

export default Loader
