import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
  TextLyricDesignType,
} from 'types'
import { EMPTY_SONG, TEXT_LYRICS_DEFAULT_DESIGN } from 'const'

import TextToSong from 'components/Song/SongEdit/SongEditor/TextToSong'
import ToolLayout from 'layouts/ToolLayout'
import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SongPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  @media screen and (max-width: 1800px) {
    margin-left: 10%;
  }
  ${(props) => props.theme.breakpoint.XL} {
    margin-left: 5%;
    margin-right: 6%;
  }
  @media screen and (max-width: 1400px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const TextToSongConvertor = () => {
  const { t } = useTranslation()

  const generalInfo: SongGeneralInfoType = {
    ...EMPTY_SONG,
    name: t('song.name'),
  }

  const [chords, setChords] = useState<SongChordType[]>([])
  const [lyricSections, setLyricSections] = useState<LyricSectionType[]>([])
  const [compositionSections, setCompositionSections] = useState<
    CompositionSectionType[]
  >([])
  const [textLyricDesign, setTextLyricDesign] = useState<TextLyricDesignType>({
    ...TEXT_LYRICS_DEFAULT_DESIGN,
  })

  //
  // RENDER
  //
  return (
    <ToolLayout>
      <SongPreview>
        <TextToSong
          generalInfo={generalInfo}
          chords={chords}
          setChords={setChords}
          lyricSections={lyricSections}
          setLyricSections={setLyricSections}
          compositionSections={compositionSections}
          setCompositionSections={setCompositionSections}
          textLyricDesign={textLyricDesign}
          setTextLyricDesign={setTextLyricDesign}
          showLocalSongAdjustments
        />
      </SongPreview>
    </ToolLayout>
  )
}

export default TextToSongConvertor
