/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES, USER_GROUP_ROLES, USER_ROLES } from 'const'
import { UserGroupMemberType, UserGroupType, UserType } from 'types'
import { getUserAvatar, getUserGroupAvatar } from 'utils'
import styled, { css, keyframes } from 'styled-components'
import { useEffect, useState } from 'react'

import { LOGGED_USER_VAR } from 'App'
import { Link } from 'react-router-dom'
import SvgIcon from 'components/SvgIcon'
import { ViewMode } from 'components/Files/FileSystem'
import theme from 'styles/theme'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const FILE_GROUP_MEM_ROT_INIT = 140
const FILE_GROUP_MEM_ROT_STEP = 40

const UserLink = styled(Link)``
const UserWithoutLink = styled.div``

const Wrapper = styled.div<{
  isAdmin?: boolean
  isLoggedUser?: boolean
  useLink?: boolean
  noBackground?: boolean
  noStretch?: boolean
  isChild?: boolean
  userColor: string
}>`
  pointer-events: ${(props) => (props.isChild ? 'none' : 'auto')} !important;
  flex: ${(props) => (props.noStretch ? 0 : 1)};
  width: ${(props) => (props.noStretch ? '55px' : 'auto')};

  ${UserLink} {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 10px;
    text-decoration: none;

    transition: all 0.2s ease;

    color: ${(props) =>
      props.noBackground
        ? props.isLoggedUser || props.isAdmin
          ? props.userColor
          : props.theme.black
        : props.theme.white};

    background: ${(props) => (props.noBackground ? 'none' : props.userColor)};

    ${(props) =>
      props.useLink
        ? css`
            &:hover {
              filter: brightness(1.2);
            }
          `
        : css`
            cursor: default;
            pointer-events: none;
          `}

    border-radius: 30px;
    box-shadow: ${(props) =>
      !props.noBackground && `0px 2px 10px 0px ${props.theme.black}44}`};
  }
`

const AvatarInitials = styled.div`
  overflow: hidden;

  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);

  border-radius: 50%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.white};
`

const AvatarImg = styled.img``

const Avatar = styled.div<{
  noAvatar?: boolean
  isUserGroup?: boolean
  isLoggedUser?: boolean
  userColor: string
  shrink?: boolean
  isChild?: boolean
  isScaledUp?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  --size: 45px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;

  ${(props) =>
    props.isUserGroup &&
    css`
      ${AvatarInitials} {
        font-size: 12px;
      }
    `}

  ${AvatarImg} {
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    object-fit: cover;
    overflow: hidden;
  }

  ${AvatarImg}, ${AvatarInitials} {
    ${(props) =>
      props.noBackground &&
      css`
        background: ${(props) => props.userColor}
          ${props.isChild ? '!important' : ''};

        box-shadow: 0px 2px 10px 0px ${(props) => props.theme.black}22
          ${props.isChild ? '!important' : ''};

        border: ${props.isScaledUp ? '2px' : props.isChild ? '10px' : '4px'}
          solid ${(props) => props.userColor}
          ${props.isChild ? '!important' : ''};
      `}

    transition: all 0.2s ease;
    ${(props) =>
      props.shrink &&
      css`
        transform: scale(0.7);
      `}
  }
`

const Badge = styled.div<{
  isBottom?: boolean
  isScaledUp?: boolean
  isAdminCheck?: boolean
}>`
  position: absolute;
  top: -2px;
  right: -6px;

  --size: 16px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.neutral};
  font-size: 12px;
  font-weight: 800;

  svg {
    color: ${(props) => props.theme.primary_light};
    width: 90%;
    height: 90%;
  }

  ${(props) =>
    props.isAdminCheck &&
    css`
      top: unset;
      bottom: -2px;
      svg {
        color: ${(props) => props.theme.warning};
      }
    `}

  ${(props) =>
    props.isBottom &&
    css`
      top: unset;
      bottom: -2px;
    `}

  ${(props) =>
    props.isScaledUp &&
    css`
      transform: scale(0.6);
    `}
`

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 140px;

  padding-left: 5px;

  & > *:first-child {
    font-size: 13px;
    font-weight: 600;
  }
  & > *:nth-child(2) {
    font-size: 12px;
    font-weight: 500;
  }
`

const Me = styled.div`
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 4px;
`

const ItemsList = styled.div`
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 60px;
  display: flex;
  align-items: center;
  & > * {
    margin: 0 -16px;
  }
`

const ItemsGrid = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  right: -50%;
  bottom: -50%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateY(-25px) translateX(-25px);
`

const ItemWrapper = styled.div<{ index: number; offsetDeg: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  transform-origin: 0 0;
  transform: rotate(
    ${(props) =>
      FILE_GROUP_MEM_ROT_INIT +
      props.offsetDeg +
      props.index * FILE_GROUP_MEM_ROT_STEP}deg
  );
`

const Item = styled.div<{ index: number; offsetDeg: number }>`
  position: relative;
  transition: all 0.2s ease;
  transform-origin: 0 0;
  transform: translateX(75%)
    rotate(
      ${(props) =>
        -1 *
        (FILE_GROUP_MEM_ROT_INIT +
          props.offsetDeg +
          props.index * FILE_GROUP_MEM_ROT_STEP)}deg
    );
`

const ItemAnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`
const ItemAnimated = styled.div<{ index: number }>`
  position: relative;
  transition: all 0.2s ease;
  animation-name: ${ItemAnimationIn};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: ${(props) => props.index * 0.05}s;
`

const UserGroupMemberWrapper = styled.div`
  transform: scale(0.4);
`

const UserGroupMemberWrapperList = styled.div`
  transform: scale(0.4);
`

export type UserBadgeType = 'edit'

/**
 *
 * COMPONENT
 *
 */
const UserCard = (props: {
  user?: UserType
  userGroup?: UserGroupType
  useLink?: boolean
  showName?: boolean
  badge?: UserBadgeType
  noStretch?: boolean
  noBackground?: boolean
  forceLoggedUserName?: boolean
  isActive?: boolean
  viewMode?: ViewMode
  isChild?: boolean
  isScaledUp?: boolean
  isMembersOpen?: boolean
}) => {
  const { t } = useTranslation()

  //const isActive = props.isActive /* can be given from File System */

  const [isMembersOpen, setIsMembersOpen] = useState(false)
  useEffect(() => {
    let timer = setTimeout(
      () =>
        setIsMembersOpen(
          typeof props.isMembersOpen === 'boolean' ? props.isMembersOpen : true
        ),
      600
    )
    return () => {
      clearTimeout(timer)
    }
  }, [])

  //
  // DATA
  //
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const showAsUserGroup = props.userGroup
  const userGroup = props.userGroup
  const user = !props.userGroup && (props.user || loggedUser)

  const isAppAdmin = user?.role?.code === USER_ROLES.admin
  const isLoggedUser = loggedUser?.id === user?.id

  const userColor = isAppAdmin
    ? theme.admin
    : isLoggedUser
    ? theme.primary_light
    : theme.neutral
  const userGroupColor = theme.neutral

  //
  // BADGES
  //
  const getBadge = () => {
    if (!props.badge) {
      return null
    }
    switch (props.badge) {
      case 'edit':
        return <SvgIcon code="icon-edit" />
      default:
        return null
    }
  }

  const renderBadge = () => {
    const badge = getBadge()
    return badge ? <Badge isScaledUp={props.isScaledUp}>{badge}</Badge> : null
  }

  const renderGroupCount = (count: number) => {
    return (
      <Badge isScaledUp={props.isScaledUp} isBottom>
        {count}
      </Badge>
    )
  }

  //
  // RENDERS - USER
  //
  const renderUserAvatar = () => {
    return (
      <Avatar
        isAppAdmin={isAppAdmin}
        noAvatar={!user?.avatar}
        isLoggedUser={isLoggedUser}
        noBackground={props.noBackground}
        userColor={userColor}
        isChild={props.isChild}
        isScaledUp={props.isScaledUp}
      >
        <AvatarImg src={getUserAvatar(user)} alt="avatar" />

        {!user?.avatar && (
          <AvatarInitials>
            {user.name?.split(' ').map((w) => w[0].toUpperCase())}
          </AvatarInitials>
        )}
        {renderBadge()}
        {user.role?.code === USER_ROLES.admin && (
          <Badge isScaledUp={props.isScaledUp} isAdminCheck>
            <SvgIcon code="icon-admin-badge" />
          </Badge>
        )}
      </Avatar>
    )
  }

  const renderUserName = () => {
    return !props.forceLoggedUserName && isLoggedUser ? (
      <Name>
        <Me>{t('me')}</Me>
      </Name>
    ) : (
      <Name>
        {user?.username && <div>{user.username}</div>}
        {user?.name && <div>{user.name}</div>}
      </Name>
    )
  }

  //
  // RENDERS - USER GROUP
  //
  const renderUserGroupMember = (userGroupMember: UserGroupMemberType) => {
    const { user } = userGroupMember

    return (
      <UserCard
        user={user}
        useLink={false}
        showName={false}
        badge={
          userGroupMember?.groupRole === USER_GROUP_ROLES.admin
            ? 'edit'
            : undefined
        }
        noStretch
        noBackground
        forceLoggedUserName={false}
        isActive={false}
        viewMode={undefined}
        isChild={true}
        isScaledUp={false}
      />
    )
  }

  const renderUserGroupAvatar = () => {
    const { viewMode } = props

    return (
      <Avatar
        noAvatar={!userGroup?.avatar}
        isUserGroup
        noBackground={props.noBackground}
        userColor={userGroupColor}
        shrink={isMembersOpen}
        isChild={props.isChild}
        isScaledUp={props.isScaledUp}
      >
        <AvatarImg src={getUserGroupAvatar(userGroup)} alt="group avatar" />

        {!userGroup?.avatar && (
          <AvatarInitials>{userGroup.name}</AvatarInitials>
        )}

        {isMembersOpen && userGroup.userGroupMembers?.length > 0 ? (
          viewMode === 'list' ? (
            <ItemsList>
              {userGroup.userGroupMembers?.map((userGroupMember, i) => (
                <ItemAnimated index={i}>
                  <UserGroupMemberWrapperList>
                    {renderUserGroupMember(userGroupMember)}
                  </UserGroupMemberWrapperList>
                </ItemAnimated>
              ))}
            </ItemsList>
          ) : (
            <ItemsGrid>
              {userGroup.userGroupMembers?.map((userGroupMember, i) => (
                <ItemWrapper key={i} index={i} offsetDeg={0}>
                  <Item index={i} offsetDeg={0}>
                    <ItemAnimated index={i}>
                      <UserGroupMemberWrapper>
                        {renderUserGroupMember(userGroupMember)}
                      </UserGroupMemberWrapper>
                    </ItemAnimated>
                  </Item>
                </ItemWrapper>
              ))}
            </ItemsGrid>
          )
        ) : null}

        {renderBadge()}
        {renderGroupCount(userGroup?.userGroupMembers?.length)}
      </Avatar>
    )
  }

  const renderUserGroupName = () => {
    return (
      <Name>
        <div>{userGroup.name}</div>
      </Name>
    )
  }

  const renderLinkContent = () => {
    return (
      <>
        {showAsUserGroup ? renderUserGroupAvatar() : renderUserAvatar()}
        {props.showName &&
          (showAsUserGroup ? renderUserGroupName() : renderUserName())}
      </>
    )
  }

  //
  // RENDER
  //
  return user || userGroup ? (
    <Wrapper
      isLoggedUser={isLoggedUser}
      isAdmin={isAppAdmin}
      useLink={props.useLink}
      userColor={userColor}
      noBackground={props.noBackground}
      noStretch={props.noStretch}
      isChild={props.isChild}
      title={
        !props.showName
          ? `${(user || userGroup).name}${
              user?.username ? ` (${user.username})` : ''
            }`
          : undefined
      }
    >
      {props.isChild ? (
        /* Link cannot be inside a Link */
        <UserWithoutLink>{renderLinkContent()}</UserWithoutLink>
      ) : (
        <UserLink
          to={
            showAsUserGroup
              ? `${ROUTES.users.slug}?userGroup=${userGroup.id.toString()}`
              : `${ROUTES.users.slug}?user=${user.id.toString()}`
          }
        >
          {renderLinkContent()}
        </UserLink>
      )}
    </Wrapper>
  ) : null
}

export default UserCard
