import { SongMemberType, SongRoleType } from 'types'
import { useQuery, useReactiveVar } from '@apollo/client'

import Droplist from 'components/Inputs/Droplist'
import { GET_SONG_ROLES } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import { SONG_ROLES } from 'const'
import SvgIcon from 'components/SvgIcon'
import UserCard from 'components/User/UserCard'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{
  isLoggedUser?: boolean
  isPlaylistAdmin?: boolean
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  row-gap: 0px;

  min-height: 40px;
  padding: 4px;
  padding-left: 6px;

  background: ${(props) =>
    props.isLoggedUser
      ? `${props.theme.light}`
      : props.isPlaylistAdmin
      ? `${props.theme.light}`
      : `${props.theme.light}`};

  border-radius: 4px;
  box-shadow: 5px 5px 10px 0px ${(props) => props.theme.black}08;

  select {
    background: ${(props) => props.theme.background};
    border-radius: 6px;
  }
`

const DeleteButton = styled.div<{ isLoggedUser?: boolean }>`
  ${resetButtonCss}
  padding: 10px;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(1.2);
  }
  svg {
    width: 24px;
    color: ${(props) => props.theme.black};
  }
`

const ColumnUser = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`

const ColumnOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 220px;
`

const ReadOnly = styled.div`
  margin-left: auto;
  margin-right: 4%;
`

/**
 *
 * COMPONENT
 *
 */
const SongMember = (props: {
  member: SongMemberType
  deleteMember: (songMember: SongMemberType) => void
  updateMemberRole: (songMember: SongMemberType, roleCode: string) => void
  allowUpdateMembers?: boolean
}) => {
  const { t } = useTranslation()
  const { data: SongRolesData } = useQuery(GET_SONG_ROLES)

  const isUserGroup = props.member.userGroup ? true : false

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)
  const isLoggedUser = loggedUser?.id === props.member?.user?.id
  const isSongAdmin = props.member?.songRole?.code === SONG_ROLES.admin

  //
  // RENDER
  //
  return (
    <Wrapper
      isLoggedUser={!isUserGroup && isLoggedUser}
      isSongAdmin={isSongAdmin}
    >
      <ColumnUser>
        {isUserGroup ? (
          <UserCard userGroup={props.member?.userGroup} noBackground showName />
        ) : (
          <UserCard user={props.member?.user} noBackground showName />
        )}
      </ColumnUser>

      {props.allowUpdateMembers ? (
        <ColumnOptions>
          <Droplist
            options={(
              (SongRolesData?.getSongRoles as SongRoleType[]) || []
            ).map((role) => {
              return {
                id: role.code,
                name: t('song.roles.' + role.code),
              }
            })}
            value={props.member.songRole?.code}
            onChange={(roleCode) => {
              props.updateMemberRole(props.member, roleCode as string)
            }}
          />

          <DeleteButton
            onClick={() => props.deleteMember(props.member)}
            isLoggedUser={isLoggedUser}
          >
            <SvgIcon code="icon-delete" />
          </DeleteButton>
        </ColumnOptions>
      ) : (
        <ReadOnly>{t('song.roles.' + props.member?.songRole?.code)}</ReadOnly>
      )}
    </Wrapper>
  )
}

export default SongMember
