/* eslint-disable react-hooks/exhaustive-deps */

import {
  InstrumentChordType,
  InstrumentSectionType,
  SongChordType,
  StringChordSchemaType,
} from 'types'
import {
  addExtraChordToInstrumentSection,
  isKeyboardInstrument,
  isStringInstrument,
  moveExtraChordInInstrumentSection,
  removeExtraChordFromInstrumentSection,
  transposeChord,
  updateInstrumentChord,
} from 'utils'
import { forwardRef, useEffect, useState } from 'react'

import FlipMove from 'react-flip-move'
import StringChord from 'components/Instruments/StringChord'
import SvgIcon from 'components/SvgIcon'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Items = styled.div<{ fretCount?: number }>`
  flex: 1;
  & > * {
    display: grid;
    gap: 30px;
    row-gap: 15px;

    grid-template-columns: repeat(
      auto-fit,
      minmax(
        min(calc(130px + 14px * ${(props) => props.fretCount || 0}), 100%),
        ${(props) => Math.min(1, 0.2 + 0.1 * (props.fretCount || 0))}fr
      )
    );

    ${(props) => props.theme.breakpoint.M} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(
          min(calc(110px + 14px * ${(props) => props.fretCount || 0}), 100%),
          ${(props) => Math.min(1, 0.3 + 0.1 * (props.fretCount || 0))}fr
        )
      );
    }

    ${(props) => props.theme.breakpoint.S} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(
          min(calc(120px + 14px * ${(props) => props.fretCount || 0}), 100%),
          1fr
        )
      );
    }
  }
`

const ChordWrapper = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s ease;

  margin-top: 15px;
  border-radius: 4px;
  border: 1px dotted ${(props) => props.theme.black}22;
  background: ${(props) => props.theme.black}02;

  color: ${(props) => props.theme.black}55;
  font-size: 14px;
  text-align: center;

  svg {
    width: 14px;
    height: 14px;
  }
  &:hover {
    box-shadow: 0 0 10px 0 ${(props) => props.theme.black}06;
  }
`

const ChordBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  min-height: 120px;
`

const ChordBoxControls = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: -4px;

  button {
    ${resetButtonCss}

    color: ${(props) => props.theme.black}44;
    &:hover {
      color: ${(props) => props.theme.primary_light};
    }

    padding: 2px;
    --size: 30px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  }
`

const HorizontalSpacer = styled.div`
  width: 26px;
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
type RenderChordType = {
  songChord?: SongChordType
  instrumentChord: InstrumentChordType
}

const AnimatedItem = forwardRef(
  (
    props: {
      chordToRender: RenderChordType
      instrumentSection: InstrumentSectionType
      setInstrumentSection: (v: InstrumentSectionType) => void
    },
    ref
  ) => {
    //
    // CHORD - STRING
    //
    const renderStringChord = ({ instrumentChord, chordName, chordSchema }) => {
      return (
        <StringChord
          autoFillSchema
          chordSchema={chordSchema as StringChordSchemaType}
          setChordSchema={(chordSchema: StringChordSchemaType) =>
            props.setInstrumentSection(
              updateInstrumentChord({
                instrumentSection: props.instrumentSection,
                updatedInstrumentChord: { ...instrumentChord, chordSchema },
              })
            )
          }
          chordName={chordName}
          setChordName={
            props.chordToRender.instrumentChord?.isExtraChord
              ? (chordName: string) =>
                  props.setInstrumentSection(
                    updateInstrumentChord({
                      instrumentSection: props.instrumentSection,
                      updatedInstrumentChord: {
                        ...instrumentChord,
                        extraChordName: chordName,
                      },
                    })
                  )
              : undefined
          }
          fretCount={props.instrumentSection.instrument.renderFretCount}
          liveUpdateName={
            props.chordToRender.instrumentChord?.isExtraChord
              ? false
              : undefined
          }
        />
      )
    }

    //
    // RENDER ANY CHORD
    //
    const renderChord = (chordToRender: RenderChordType) => {
      const { instrumentChord, songChord } = chordToRender
      if (!instrumentChord) {
        return null
      }
      const chordNameRaw = instrumentChord.extraChordId
        ? instrumentChord.extraChordName
        : songChord?.name

      const chordName =
        !instrumentChord.isExtraChord &&
        props.instrumentSection.instrument?.transpose
          ? transposeChord(
              chordNameRaw,
              props.instrumentSection.instrument?.transpose
            )
          : chordNameRaw

      const chordSchema = instrumentChord.chordSchema

      if (isStringInstrument(props.instrumentSection.instrument.code)) {
        return renderStringChord({ instrumentChord, chordName, chordSchema })
      }
      if (isKeyboardInstrument(props.instrumentSection.instrument.code)) {
        return <div>Piano {chordName}</div>
      }
      return <div>{chordName}</div>
    }

    return (
      <ChordWrapper ref={ref as any} style={{ overflowAnchor: 'none' }}>
        {props.chordToRender.instrumentChord?.isExtraChord ? (
          <ChordBox>
            {renderChord(props.chordToRender)}
            <ChordBoxControls>
              <HorizontalSpacer />
              <button
                onClick={() =>
                  props.setInstrumentSection(
                    moveExtraChordInInstrumentSection({
                      instrumentSection: props.instrumentSection,
                      extraChordId:
                        props.chordToRender.instrumentChord.extraChordId,
                      offset: -1,
                    })
                  )
                }
              >
                <SvgIcon code="icon-arrow-left" />
              </button>

              <button
                onClick={() =>
                  props.setInstrumentSection(
                    removeExtraChordFromInstrumentSection({
                      instrumentSection: props.instrumentSection,
                      extraChordId:
                        props.chordToRender.instrumentChord.extraChordId,
                    })
                  )
                }
              >
                <SvgIcon code="icon-delete" />
              </button>

              <button
                onClick={() =>
                  props.setInstrumentSection(
                    moveExtraChordInInstrumentSection({
                      instrumentSection: props.instrumentSection,
                      extraChordId:
                        props.chordToRender.instrumentChord.extraChordId,
                      offset: 1,
                    })
                  )
                }
              >
                <SvgIcon code="icon-arrow-right" />
              </button>
            </ChordBoxControls>
          </ChordBox>
        ) : (
          renderChord(props.chordToRender)
        )}
      </ChordWrapper>
    )
  }
)

/**
 *
 *  Component
 *
 */
const InstrumentChords = (props: {
  chords: SongChordType[]
  instrumentSection: InstrumentSectionType
  setInstrumentSection: (v: InstrumentSectionType) => void
  displayMode?: 'song-chords' | 'extra-chords'
}) => {
  const { t } = useTranslation()

  //
  // ORGANIZE CHORDS
  //
  const getInstrumentChordBySongChord = (songChord) => {
    const instrumentChord: InstrumentChordType =
      props.instrumentSection.instrument.chords?.find(
        (c) => c.songChordId === songChord.id
      )
    return instrumentChord
  }

  const [chordsToRender, setChordsToRender] = useState<RenderChordType[]>([])

  useEffect(() => {
    const chordsInSong: RenderChordType[] = []
    const chordsNotInSong: RenderChordType[] = []

    if (
      props.displayMode === 'song-chords' ||
      typeof props.displayMode === 'undefined'
    ) {
      props.chords.forEach((songChord) => {
        /* Organizing songChords separately to keep the same order without any work */
        chordsInSong.push({
          songChord: songChord,
          instrumentChord: getInstrumentChordBySongChord(songChord),
        })
      })
    }

    if (
      props.displayMode === 'extra-chords' ||
      typeof props.displayMode === 'undefined'
    ) {
      props.instrumentSection.instrument.chords.forEach((instrumentChord) => {
        if (instrumentChord?.isExtraChord) {
          chordsNotInSong.push({
            songChord: null,
            instrumentChord,
          })
        }
      })
    }

    setChordsToRender([...chordsInSong, ...chordsNotInSong])
  }, [props.chords, props.instrumentSection])

  //
  // RENDER
  //
  return (
    <Items fretCount={props.instrumentSection.instrument.renderFretCount}>
      <FlipMove
        easing="ease"
        duration={300} // milliseconds
        staggerDurationBy={160} // milliseconds
        staggerDelayBy={60} // milliseconds
        maintainContainerHeight={false}
        appearAnimation={{
          /* first item appearance */
          from: { transform: 'scale(0)' },
          to: { transform: 'none' },
        }}
        enterAnimation={{
          from: { transform: 'scale(0)', transformOrigin: '50% 50%' },
          to: { transform: 'none' },
        }}
        leaveAnimation={{
          from: { transform: 'none', transformOrigin: '50% 50%' },
          to: { transform: 'scale(0)' },
        }}
      >
        {chordsToRender.map((chordToRender) => (
          <AnimatedItem
            key={
              chordToRender.instrumentChord?.isExtraChord
                ? `extra_chord_${chordToRender.instrumentChord?.extraChordId}`
                : `in_song_${chordToRender.songChord?.id}`
            }
            chordToRender={chordToRender}
            instrumentSection={props.instrumentSection}
            setInstrumentSection={props.setInstrumentSection}
          />
        ))}

        {props.displayMode === 'extra-chords' && (
          <ChordBox>
            <ButtonWrapper
              onClick={() =>
                props.setInstrumentSection(
                  addExtraChordToInstrumentSection({
                    instrumentSection: props.instrumentSection,
                  })
                )
              }
              title={t('song.instrument editor options.add extra chord')}
            >
              <span>{t('song.instrument editor options.add extra chord')}</span>
              <SvgIcon code="icon-add" />
            </ButtonWrapper>
          </ChordBox>
        )}
      </FlipMove>
    </Items>
  )
}

export default InstrumentChords
