import { toast } from 'react-toastify'

export const copyToClipboard = (
  text: string,
  options?: { success?: string }
) => {
  if (navigator?.clipboard) {
    navigator.clipboard.writeText(text)
    if (options?.success) {
      toast.success(options.success)
    }
  }
}

export const getGlobalCaretPreLength = (textLines, lineIndex) => {
  let total = 0
  textLines
    .filter((textLine, i) => i < lineIndex)
    .forEach((textLine) => (total += textLine.length + 1)) /* +1 for newline */
  return total
}

export const getGlobalCaretIndex = (caretIndex, textLines, lineIndex) => {
  return caretIndex + getGlobalCaretPreLength(textLines, lineIndex)
}

export const convertGlobalCaretIndexToLocal = (
  globalCaretIndex,
  textLines,
  lineIndex
) => {
  return globalCaretIndex - getGlobalCaretPreLength(textLines, lineIndex)
}

export const calculateFontCharSizeMonospace = (fontSizePx) => {
  //return fontSizePx / 1.815 // original 'monospace' font (apparently is not the same on all devices!!)
  return fontSizePx / 2
}

export const createSearchString = (
  s: string,
  options?: {
    removeDashes?: boolean
    alphabetOnly?: boolean
  }
) => {
  if (!s?.length) {
    return ''
  }
  let str = s
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')

  if (options?.removeDashes) {
    str = str.replace(/-/g, '')
  }

  if (options?.alphabetOnly) {
    str = str.replaceAll('[^A-Za-z]+', '')
  }

  return str
}

export const compareSearchStrings = (
  searchIn: string,
  searchString: string,
  options?: {
    exactMatch?: boolean
    removeDashes?: boolean
    alphabetOnly?: boolean
  }
) => {
  const sIn = createSearchString(searchIn, options)
  const s = createSearchString(searchString, options)

  return options?.exactMatch ? sIn === s : sIn.includes(s)
}

export const getEmptyString = (length) => {
  return Array.apply(null, Array(length))
    .map((i) => ' ')
    .join('')
}

export const convertTextToShowSpaces = (textLine) => {
  return textLine.replace(/ /g, '␣')
}

export const convertTextToHideSpaces = (textLine) => {
  return textLine.replace(/␣/g, ' ')
}
