import ChordSequencer, {
  SequenceItemType,
} from 'components/Song/SongEdit/SongEditor/Lyrics/LyricSection/ChordSequencer'
import {
  CompositionSectionType,
  LyricDesignType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
} from 'types'
import {
  deleteLyricSectionFromSong,
  duplicateLyricSectionInSong,
  moveLyricSectionInSong,
  updateLyricSectionInSong,
} from 'utils'

import ContentBox from 'components/Boxes/ContentBox'
import { LYRICS_DEFAULT_DESIGN } from 'const'
import LyricDesignController from 'components/Song/SongDesignControllers/LyricDesignController'
import LyricText from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText'
import Options from './Options'
import PaperSheet from 'components/Boxes/Paper/PaperSheet'
import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const RESPONSIVE_MENU_BREAKPOINT = 'S'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  ${(props) => props.theme.breakpoint.M} {
    align-items: stretch;
    flex-direction: column;
  }
`

const GroupOptions = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoint.M} {
    min-width: 0;
  }
`

const GroupPaper = styled.div`
  position: relative;
  flex: 1.8;
  max-width: 100%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin-bottom: 2px;
  & > * {
    min-height: 100%;
  }

  ${(props) => props.theme.breakpoint.M} {
    min-width: 0px;
  }
`

const PaperContent = styled.div`
  flex: 1;
  padding: 60px 4%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;

  ${(props) => props.theme.breakpoint.M} {
    gap: 4%;
  }
  ${(props) => props.theme.breakpoint[RESPONSIVE_MENU_BREAKPOINT]} {
    padding-top: 70px;
    padding-bottom: 20px;
    margin-left: 0;
  }
`

const ControlsWrapper = styled.div`
  flex: 1;
  width: 135px;
  max-width: 135px;
  margin-top: -20px;
  margin-bottom: -20px;

  ${(props) => props.theme.breakpoint[RESPONSIVE_MENU_BREAKPOINT]} {
    display: none;
  }
`

const SmallControlsWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 130px;
  min-height: 0;
  display: none;
  ${(props) => props.theme.breakpoint[RESPONSIVE_MENU_BREAKPOINT]} {
    display: block;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const LyricSection = (props: {
  generalInfo: SongGeneralInfoType
  chords: SongChordType[]
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  lyricSection: LyricSectionType
  compositionSections: CompositionSectionType[]
  setCompositionSections: (data: CompositionSectionType[]) => void
  setOpenBox?: (isOpen: boolean) => void
  forcedIsOpen?: boolean
  onSubMenuToggle?: (value: boolean) => void
}) => {
  const { t } = useTranslation()

  const [chordSequence, setChordSequence] = useState<SequenceItemType[]>([])
  const [lyricsDesign, setLyricsDesign] = useState<LyricDesignType>({
    ...LYRICS_DEFAULT_DESIGN,
    showSpaces: true,
  })

  const updateSection = (updatedLyricSection) => {
    updateLyricSectionInSong({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      updatedLyricSection,
    })
  }

  const deleteSection = () => {
    deleteLyricSectionFromSong({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      compositionSections: props.compositionSections,
      setCompositionSections: props.setCompositionSections,
      lyricSectionId: props.lyricSection.id,
      t,
    })
  }

  const moveSection = (offset) => {
    moveLyricSectionInSong({
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      lyricSectionId: props.lyricSection.id,
      offset,
    })
  }

  const duplicateSection = () => {
    duplicateLyricSectionInSong({
      lyricSectionToDuplicate: props.lyricSection,
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
    })
  }

  const clearChordsAndNotes = () => {
    updateSection({
      ...props.lyricSection,
      lyricChords: [],
      lyricNotes: [],
    })
  }

  //
  // RENDER
  //
  return (
    <ContentBox
      title={
        props.lyricSection.role
          ? `${t('song.section.lyrics roles.' + props.lyricSection.role)}${
              props.lyricSection.roleIndex
                ? ` ${props.lyricSection.roleIndex}`
                : ''
            }`
          : '?'
      }
      onClickUp={() => moveSection(-1)}
      onClickDown={() => moveSection(1)}
      onClickDelete={deleteSection}
      onClickDuplicate={duplicateSection}
      forcedIsOpen={props.forcedIsOpen}
      callback={props.setOpenBox}
      tinted
    >
      <Wrapper>
        <GroupOptions>
          <Options
            lyricSection={props.lyricSection}
            setLyricSection={updateSection}
          />

          <ChordSequencer
            chords={props.chords}
            chordSequence={chordSequence}
            setChordSequence={setChordSequence}
          />
        </GroupOptions>

        <GroupPaper>
          <SmallControlsWrapper>
            <LyricDesignController
              lyricsDesign={lyricsDesign}
              setLyricsDesign={setLyricsDesign}
              onClear={clearChordsAndNotes}
              compactMode
              onToggle={props.onSubMenuToggle}
            />
          </SmallControlsWrapper>
          <PaperSheet>
            <PaperContent>
              <LyricText
                songLanguageCode={props.generalInfo.languageCode}
                lyricSections={props.lyricSections}
                setLyricSections={props.setLyricSections}
                lyricSection={props.lyricSection}
                chordSequence={chordSequence}
                setChordSequence={setChordSequence}
                songChords={props.chords}
                design={lyricsDesign}
                hideHeading
                center
              />

              <ControlsWrapper>
                <LyricDesignController
                  lyricsDesign={lyricsDesign}
                  setLyricsDesign={setLyricsDesign}
                  onClear={clearChordsAndNotes}
                />
              </ControlsWrapper>
            </PaperContent>
          </PaperSheet>
        </GroupPaper>
      </Wrapper>
    </ContentBox>
  )
}

export default LyricSection
