import { InstrumentSectionType, LyricSectionType, SongChordType } from 'types'
import {
  deleteChordFromChordSelection,
  getSelectedChordId,
  moveChordInChordSelection,
  updateChordInChordSelection,
} from 'utils'

import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  max-width: 110px;
  input {
    font-size: calc(min(13px + 0.4vw, 18px));
  }

  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.black}11;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.black}08;

  background: ${(props) => props.theme.white};
  backdrop-filter: blur(5px);

  ${(props) => props.theme.breakpoint.L} {
    max-width: 110px;
  }
  ${(props) => props.theme.breakpoint.M} {
    max-width: 90px;
  }
  ${(props) => props.theme.breakpoint.S} {
    max-width: 70px;
  }
`

const ChordName = styled.div`
  width: 100%;
  input {
    padding-top: 8%;
    padding-bottom: 8%;
    transition: all 0.2s ease;
    border: none;
    border-top: 10px solid ${(props) => props.theme.primary_light};
    color: ${(props) => props.theme.primary_light};
    font-weight: 600;
    text-align: center;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  ${resetButtonCss}
  box-shadow: none;
  padding: 5px;

  svg {
    transition: all 0.2s ease;
    color: ${(props) => props.theme.black}66;
  }
  &:hover {
    svg {
      color: ${(props) => props.theme.black}AA;
    }
  }
  ${(props) => props.theme.breakpoint.S} {
    padding: 2px;
  }
`

const NotUsed = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  font-size: 8px;
  font-weight: 800;
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.warning};

  text-align: center;
  padding: 0 4px;
`

/**
 *
 *  COMPONENT
 *
 */
const ChordSelection = (props: {
  chord: SongChordType
  chords: SongChordType[]
  setChords: (data: SongChordType[]) => void
  lyricSections: LyricSectionType[]
  setLyricSections: (data: LyricSectionType[]) => void
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
}) => {
  const { t } = useTranslation()

  const useCount = !props.lyricSections?.length
    ? 1 // do not mark chords as unused if there are no lyricSections
    : props.lyricSections
        ?.map(
          (lyricSection) =>
            lyricSection.lyricChords
              ?.map((lc) => lc.chordId)
              .filter((id) => id === props.chord.id).length
        )
        .reduce((a, b) => a + b, 0)

  const updateChord = (updatedChord) => {
    updateChordInChordSelection({
      chords: props.chords,
      setChords: props.setChords,
      updatedChord,
    })
  }

  const deleteChord = () => {
    deleteChordFromChordSelection({
      chordId: props.chord.id,
      chords: props.chords,
      setChords: props.setChords,
      lyricSections: props.lyricSections,
      setLyricSections: props.setLyricSections,
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
      t,
    })
  }

  const moveChord = (offset) => {
    moveChordInChordSelection({
      chords: props.chords,
      setChords: props.setChords,
      chordId: props.chord.id,
      offset,
    })
  }

  return (
    <Wrapper>
      {useCount === 0 && <NotUsed>{t('song.chord not used')}</NotUsed>}

      <ChordName>
        <StringInput
          placeholder={t('song.chord editor options.chord name')}
          value={props.chord.name}
          setValue={(value) => updateChord({ ...props.chord, name: value })}
          id={getSelectedChordId({ chordId: props.chord.id })}
        />
      </ChordName>

      <Buttons>
        <Button
          onClick={() => moveChord(-1)}
          title={t('song.chord editor options.move left tooltip')}
        >
          <SvgIcon code="icon-arrow-left" />
        </Button>

        <Button
          onClick={deleteChord}
          title={t('song.chord editor options.delete tooltip')}
        >
          <SvgIcon code="icon-delete" />
        </Button>

        <Button
          onClick={() => moveChord(1)}
          title={t('song.chord editor options.move right tooltip')}
        >
          <SvgIcon code="icon-arrow-right" />
        </Button>
      </Buttons>
    </Wrapper>
  )
}

export default ChordSelection
