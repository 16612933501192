import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query {
    getUsers {
      id
      email
      name
      username
      avatar
      role {
        code
      }
    }
  }
`

export const SEARCH_USERS = gql`
  query getUsers($search: String!, $excludeIds: [ID], $maxCount: Int) {
    getUsers(search: $search, excludeIds: $excludeIds, maxCount: $maxCount) {
      id
      email
      name
      username
      avatar
      role {
        code
      }
    }
  }
`

export const GET_LOGGED_USER = gql`
  query {
    getLoggedUser {
      id
      email
      name
      username
      languageCode
      avatar
      role {
        code
        permissions {
          code
        }
      }
      songCategories
    }
  }
`

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      username
      languageCode
      avatar
      role {
        code
      }
    }
  }
`

export const GET_USER_DELETE_CHECK = gql`
  query getUserDeleteCheck($userId: ID) {
    getUserDeleteCheck(userId: $userId) {
      songsToDelete {
        id
        name
      }
      userGroupsToDelete {
        id
        name
      }
      playlistsToDelete {
        id
        name
      }
    }
  }
`
