import styled, { css } from 'styled-components'

import ChordName from 'components/Instruments/_base/ChordName'
import Fretboard from 'components/Instruments/_base/Fretboard'
import { StringChordSchemaType } from 'types'
import SvgIcon from 'components/SvgIcon'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'

const Wrapper = styled.div<{ stretch?: boolean }>`
  display: flex;
  flex-direction: column;

  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 2px; /* takes care of cropped fingers at the bottom */

  min-width: 100px;

  ${(props) =>
    props.stretch &&
    css`
      width: 100%;
    `}
`

const Header = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  overflow: hidden;

  input {
    margin-top: 4px;
  }
`

const ChordNameWrapper = styled.div`
  max-width: 70%;
`

const ControlsWrapper = styled.div`
  display: flex;
`

const Button = styled.button`
  ${resetButtonCss}

  color: ${(props) => props.theme.primary_light}66;
  &:hover {
    color: ${(props) => props.theme.primary_light};
  }

  padding: 2px;
  --size: 30px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
`

const StringChord = (props: {
  chordSchema: StringChordSchemaType
  setChordSchema?: (chordSchema: StringChordSchemaType) => void
  chordName?: string
  setChordName?: (string) => void
  fretCount?: number
  stretch?: boolean
  stringHeight?: number
  liveUpdateName?: boolean
  autoFitSchema?: boolean // expands schema to fit the most distant frets
  autoFillSchema?: boolean // shifts the schema to fill the smallest possible space (offsets fret render)
}) => {
  let fretCount = props.fretCount || 3
  let chordSchema = props.chordSchema

  if (props.autoFitSchema || props.autoFillSchema) {
    const minFretCount = 3
    const frets = props.chordSchema.strings
      .map((s) => s.fret || undefined)
      .filter((s) => s)
    const minFret = Math.min(...frets) || 0
    const maxFret = Math.max(...frets) || 0
    const fretDistance = maxFret - minFret

    if (props.autoFitSchema) {
      fretCount = Math.max(minFretCount, fretCount, maxFret)
    } else if (props.autoFillSchema) {
      const offsetShift = Math.min(fretDistance - fretCount, -1)

      fretCount = Math.max(minFretCount, fretCount, fretDistance + 1)
      chordSchema = {
        ...chordSchema,
        renderFretOffset: Math.max(0, minFret + offsetShift),
      }
    }
  }

  const offsetFrets = (offset) => {
    props.setChordSchema &&
      props.setChordSchema({
        ...chordSchema,
        strings: [...chordSchema.strings].map((s) => {
          return {
            ...s,
            fret: !s.mute && s.fret > 0 && Math.max(s.fret + offset, 0),
          }
        }),
      })
  }

  return chordSchema?.strings.length ? (
    <Wrapper stretch={props.stretch}>
      <Header>
        <ChordNameWrapper>
          <ChordName
            name={props.chordName || '?'}
            setName={
              props.setChordName
                ? (name) => props.setChordName(name)
                : undefined
            }
            liveUpdate={props.liveUpdateName}
          />
        </ChordNameWrapper>

        {props.setChordSchema && (
          <ControlsWrapper>
            <Button onClick={() => offsetFrets(-1)} tabIndex="-1">
              {<SvgIcon code="icon-offset-left" />}
            </Button>
            <Button onClick={() => offsetFrets(1)} tabIndex="-1">
              {<SvgIcon code="icon-offset-right" />}
            </Button>
          </ControlsWrapper>
        )}
      </Header>

      <Fretboard
        strings={chordSchema.strings}
        setStrings={
          props.setChordSchema
            ? (strings) => props.setChordSchema({ ...chordSchema, strings })
            : undefined
        }
        fretCount={fretCount}
        renderFretOffset={chordSchema.renderFretOffset || 0}
        stringHeight={props.stringHeight}
      />
    </Wrapper>
  ) : null
}

export default StringChord
