import {
  Label,
  Value,
  ValueWrapper,
  Wrapper,
} from 'components/Inputs/TransposeInput'

import SvgIcon from 'components/SvgIcon'

const CheckboxSongInput = (props: {
  title: string
  value: boolean
  setValue?: (value: boolean) => void
  roundCorners?: boolean
  noBackground?: boolean
}) => {
  return (
    <Wrapper
      title={props.title}
      onClick={() => props.setValue && props.setValue(!props.value)}
      style={{ cursor: 'pointer' }}
    >
      <ValueWrapper
        roundCorners={props.roundCorners}
        noBackground={props.noBackground}
      >
        <Value>
          {props.value ? (
            <SvgIcon code="icon-checkmark" />
          ) : (
            <SvgIcon code="icon-close" style={{ opacity: 0.2 }} />
          )}
        </Value>
        <Label>{props.title}</Label>
      </ValueWrapper>
    </Wrapper>
  )
}

export default CheckboxSongInput
