import { GET_USER, GET_USER_GROUP } from 'api/graphql'
import { SongMemberType, UserGroupType, UserType } from 'types'
import { hasSongPermission, idAsNumber } from 'utils'
import styled, { keyframes } from 'styled-components'
import { useEffect, useState } from 'react'

import { File } from 'components/Files/FileSystem'
import FileSystemUsers from 'components/Files/FileSystemUsers'
import { SONG_ROLES } from 'const'
import SongMemberList from './SongMemberList'
import SvgIcon from 'components/SvgIcon'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const AddUserWrapper = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.2s;
  animation-fill-mode: backwards;

  align-self: stretch;
  flex: 1;
`

/**
 *
 *  COMPONENT
 *
 */
const SongMembers = (props: {
  initSongMembers: SongMemberType[]
  songMembers: SongMemberType[]
  setSongMembers?: (members: SongMemberType[]) => void
  isEditMode?: boolean
}) => {
  const { t } = useTranslation()

  const [isAddFocused, setIsAddFocused] = useState<boolean>(false)

  const allowUpdateSongMembers =
    !props.isEditMode ||
    hasSongPermission(props.initSongMembers, 'SONG_updateSongMembers')

  //
  // FETCH SELECTED USER
  //
  const [fetchUserId, setFetchUserId] = useState<number>()

  const { data: fetchedUserData, refetch: refetchUserData } = useQuery(
    GET_USER,
    {
      variables: { id: fetchUserId },
      skip: !fetchUserId,
    }
  )

  useEffect(() => {
    if (fetchedUserData) {
      refetchUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserId])

  useEffect(() => {
    const user = fetchedUserData?.getUser
    if (
      user &&
      props.songMembers
        .map((m) => idAsNumber(m.user?.id))
        .includes(idAsNumber(user.id))
    ) {
      toast.info(t('song.user already a member'))
      return
    }
    if (user) {
      addMemberUser(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserData])

  //
  // FETCH SELECTED USER GROUP
  //
  const [fetchUserGroupId, setFetchUserGroupId] = useState<number>()

  const { data: fetchedUserGroupData, refetch: refetchUserGroupData } =
    useQuery(GET_USER_GROUP, {
      variables: { id: fetchUserGroupId },
      skip: !fetchUserGroupId,
    })

  useEffect(() => {
    if (fetchedUserGroupData) {
      refetchUserGroupData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserGroupId])

  useEffect(() => {
    const userGroup = fetchedUserGroupData?.getUserGroup
    if (
      userGroup &&
      props.songMembers
        .map((m) => idAsNumber(m.userGroup?.id))
        .includes(idAsNumber(userGroup.id))
    ) {
      toast.info(t('song.user group already a member'))
      return
    }
    if (userGroup) {
      addMemberUserGroup(userGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserGroupData])

  //
  // FUNCTIONS
  //
  const addMemberUser = (user: UserType) => {
    props.setSongMembers([
      ...props.songMembers,
      {
        user: user,
        songRole: {
          code: SONG_ROLES.member,
        },
      },
    ])
  }

  const addMemberUserGroup = (userGroup: UserGroupType) => {
    props.setSongMembers([
      ...props.songMembers,
      {
        userGroup: userGroup,
        songRole: {
          code: SONG_ROLES.member,
        },
      },
    ])
  }

  //
  // FILE MANAGEMENT
  //
  const onUserDoubleClick = (file: File) => {
    if (file.id === fetchUserId) {
      toast.info(t('song.user already a member'))
      return
    }
    setFetchUserId(file.id)
  }

  const onUserGroupDoubleClick = (file: File) => {
    if (file.id === fetchUserGroupId) {
      toast.info(t('song.user group already a member'))
      return
    }
    setFetchUserGroupId(file.id)
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <SongMemberList
        songMembers={props.songMembers}
        setSongMembers={props.setSongMembers}
        onDeleteCallback={() => {
          setFetchUserId(undefined)
          setFetchUserGroupId(undefined)
        }}
        allowUpdateMembers={allowUpdateSongMembers}
      />

      {props.setSongMembers && allowUpdateSongMembers && (
        <ButtonWrapper>
          {isAddFocused ? (
            <>
              <AddUserWrapper>
                <FileSystemUsers
                  defaultViewMode="grid"
                  onFileAddClick={onUserDoubleClick}
                  onFileDoubleClick={onUserDoubleClick}
                  onFolderAddClick={onUserGroupDoubleClick}
                  onFolderDoubleClick={onUserGroupDoubleClick}
                  maxHeight="40vh"
                />
              </AddUserWrapper>

              <button
                onClick={() => setIsAddFocused(false)}
                title={t('song.close add user')}
                className="btn-action-circular"
              >
                <SvgIcon code="icon-close" />
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsAddFocused(true)}
              title={t('song.add user')}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-add" />
            </button>
          )}
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

export default SongMembers
