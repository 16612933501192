/* eslint-disable react-hooks/exhaustive-deps */
import {
  DBPlaylistType,
  DBSongType,
  PlaylistBodyType,
  PlaylistSongType,
  SongType,
} from 'types'
import { Fragment, useEffect, useState } from 'react'
import IconMenu, {
  A4_COMPONENT_EXPORT_ID,
} from 'components/Song/SongDetail/IconMenu'
import { ROUTES, TEXT_LYRICS_DEFAULT_DESIGN } from 'const'
import {
  getLocalizedDateString,
  getPlaylistSongAdditionalText,
  getPlaylistSongUserSettings,
  getPlaylistSongUserStartEndNames,
  getSongFromPlaylistSong,
  getTabName,
  idAsNumber,
  isPlaylistPrivate,
  parsePlaylistBody,
} from 'utils'
import styled, { keyframes } from 'styled-components'

import ContentBox from 'components/Boxes/ContentBox'
import { File } from 'components/Files/FileSystem'
import FileSystemSongsWithNavigation from 'components/Files/FileSystemSongsWithNavigation'
import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import { GET_PLAYLIST } from 'api/graphql'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import Members from 'components/Members'
import { Redirect } from 'react-router-dom'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import SvgIcon from 'components/SvgIcon'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
`

const PreviewWrapper = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;

  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Description = styled.div`
  padding: min(4%, 5px);
  white-space: pre-wrap;
`

const ContentBoxWrapper = styled.div`
  margin-bottom: 3vh;
`

const Footer = styled.div`
  margin-top: 7vh;
  flex: 1;
  display: flex;
  gap: 20px;

  flex-direction: column;
  align-items: stretch;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const PlaylistDetail = (props: {
  playlistId: string
  onDelete: () => void
  setOutsidePlaylistData?: (playlist: DBPlaylistType) => void
}) => {
  const { t, i18n } = useTranslation()

  const [playlist, setPlaylist] = useState<DBPlaylistType>()
  const [playlistBody, setPlaylistBody] = useState<PlaylistBodyType>()

  useEffect(() => {
    document.title = getTabName(playlist?.name)
  }, [playlist?.name])

  //
  // QUERY PLAYLIST DATA
  //
  const { data: fetchedPlaylistData, loading } = useQuery(GET_PLAYLIST, {
    variables: { id: props.playlistId },
  })

  useEffect(() => {
    if (fetchedPlaylistData?.getPlaylist) {
      const playlist: DBPlaylistType = fetchedPlaylistData?.getPlaylist
      setPlaylist(playlist)
      setPlaylistBody(parsePlaylistBody(playlist))
    }
    if (fetchedPlaylistData?.getPlaylist === null) {
      toast.warning(t('playlist.no_access_redirect'))
    }
  }, [fetchedPlaylistData])

  useEffect(() => {
    if (props.setOutsidePlaylistData) {
      props.setOutsidePlaylistData(playlist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist])

  //
  // PLAYLIST ZOOM
  //
  const [openedPlaylistSongId, setOpenedPlaylistSongId] = useState<number>()

  const onZoomClick = (playlistSong: PlaylistSongType) => {
    setOpenedPlaylistSongId(playlistSong.songId)
  }

  useEffect(() => {
    if (openedPlaylistSongId) {
      setOpenedPlaylistSongId(undefined)
    }
  }, [openedPlaylistSongId])

  //
  // FILE CLICKS
  //
  const onSongDoubleClick = (file: File) => {
    onZoomClick(
      playlistBody?.playlistSongs?.find(
        (ps) => idAsNumber(ps.songId) === file.id
      )
    )
  }

  const renderSongPaper = (options: {
    playlistSong: PlaylistSongType
    index: number
  }) => {
    const songBody: SongType = getSongFromPlaylistSong(options.playlistSong)
    const userSettings = getPlaylistSongUserSettings(options.playlistSong)

    const { userStart, userEnd } = getPlaylistSongUserStartEndNames({
      playSettings: options.playlistSong?.playSettings,
      playlistMembers: playlist.playlistMembers,
    })

    return (
      <FillScreenPaperWrapper>
        <SongPaperA4
          id={`${A4_COMPONENT_EXPORT_ID}_${options.playlistSong.songId}`}
          generalInfo={songBody}
          chords={songBody.chords}
          lyricSections={songBody.lyricSections}
          compositionSections={songBody.compositionSections}
          textLyricDesign={
            songBody.textLyricDesign || {
              ...TEXT_LYRICS_DEFAULT_DESIGN,
            }
          }
          readOnly
          transpose={userSettings.transpose}
          simplifyChords={userSettings.simplifyChords}
          capoOffset={options.playlistSong.capoOffset}
          onZoomClick={() => onZoomClick(options.playlistSong)}
          zoomSongBody={songBody}
          topHeaderText={getPlaylistSongAdditionalText({
            index: options.index,
            songCount: playlistBody?.playlistSongs?.length,
          })}
          userStart={userStart}
          userEnd={userEnd}
        />
      </FillScreenPaperWrapper>
    )
  }

  //
  // SUB-RENDERS
  //
  const renderMenu = () => {
    return (
      <IconMenu
        playlist={playlist}
        playlistBody={playlistBody}
        setPlaylistBody={setPlaylistBody}
        openedPlaylistSongId={openedPlaylistSongId}
        setOpenedPlaylistSongId={setOpenedPlaylistSongId}
      />
    )
  }

  //
  // RENDER
  //
  if (!loading && !fetchedPlaylistData?.getPlaylist) {
    return <Redirect to={ROUTES.playlists.slug} />
  }

  return (
    <LoaderPageLayout
      title={playlistBody?.name}
      subtitle={getLocalizedDateString(playlistBody?.eventDate, i18n.language, {
        month: 'long',
      })}
      isPrivate={isPlaylistPrivate(playlist)}
      loadingText={t('playlist.loading playlist data')}
      isLoading={loading}
      isReady={!!playlistBody}
      isSlimPage
    >
      {playlistBody && (
        <>
          {playlistBody?.description?.trim().length > 0 && (
            <ContentBoxWrapper>
              <ContentBox
                title={t('playlist.description')}
                icon={<SvgIcon code="icon-info" />}
                /* tinted */
              >
                <Description>{playlistBody?.description}</Description>
              </ContentBox>
            </ContentBoxWrapper>
          )}

          <FileSystemSongsWithNavigation
            preloadedSongs={playlistBody.playlistSongs?.map((ps) => {
              const song: DBSongType = {
                id: ps.songId,
                name: ps.songData?.name,
                author: ps.songData?.author,
                languageCode: ps.songData?.languageCode,
                body: null,
                songMembers: [],
              }
              return song
            })}
            onFilePreviewClick={onSongDoubleClick}
            onFileDoubleClick={onSongDoubleClick}
            defaultViewMode="grid"
            noSearch
            noFolders
            noMultiSelection
            noAlphabeticalSort
          />

          <PreviewWrapper>
            {renderMenu()}

            {playlistBody.playlistSongs?.length > 0 && (
              <>
                {playlistBody.playlistSongs?.map((ps, i) => (
                  <Fragment key={ps.songId}>
                    {ps.songData &&
                      renderSongPaper({ playlistSong: ps, index: i + 1 })}
                  </Fragment>
                ))}
              </>
            )}
          </PreviewWrapper>

          <Footer>
            <Members
              type="playlist"
              members={playlist.playlistMembers}
              useLink={true}
            />
          </Footer>
        </>
      )}
    </LoaderPageLayout>
  )
}

export default PlaylistDetail
