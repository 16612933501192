import SongZoomModal from './SongZoomModal'
import UserGroupModal from './UserGroupModal'
import UserModal from './UserModal'

const modalRenderFunctions = [
  () => <UserModal />,
  () => <UserGroupModal />,
  () => <SongZoomModal />,
]

export default modalRenderFunctions
