import './index.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-toastify/dist/ReactToastify.css'
import './i18n'

import React, { Suspense } from 'react'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from 'styles/GlobalStyle'
import GraphQLApollo from 'api/graphql'
import NotificationsContainer from 'components/NotificationContaiiner'
import ReactDOM from 'react-dom'
import ScrollRestoration from 'react-scroll-restoration'
import { ThemeProvider } from 'styled-components'
import breakpoint from 'styles/breakpoints'
import theme from 'styles/theme'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollRestoration />
        <GraphQLApollo>
          <ThemeProvider theme={{ ...theme, breakpoint }}>
            <GlobalStyle />

            <App />

            <NotificationsContainer />
          </ThemeProvider>
        </GraphQLApollo>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

if ((module as any).hot) {
  ;(module as any).hot.accept()
}
