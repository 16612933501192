import {
  PlaylistMemberType,
  PlaylistSongPlaySettingsType,
  PlaylistSongType,
  PlaylistSongUserSettingsType,
  UserType,
} from 'types'

import { LOGGED_USER_VAR } from 'App'
import { PLAYLIST_ROLES } from 'const'
import { trimUserForComparison } from './user'
import { trimUserGroupForComparison } from './userGroup'

export const getPlaylistSongUserSettings = (
  playlistSong?: PlaylistSongType
) => {
  const loggedUserId = LOGGED_USER_VAR().id

  if (!playlistSong) {
    return {
      userId: loggedUserId,
      transpose: 0,
      simplifyChords: false,
      note: '',
    }
  }

  const userSettings: PlaylistSongUserSettingsType =
    playlistSong?.userSettings?.find(
      (settings) => settings.userId === loggedUserId
    ) || {
      userId: loggedUserId,
      transpose: 0,
      simplifyChords: false,
      note: '',
    }
  return userSettings
}

export const getPlaylistSongWithUpdatedUserSettings = (
  playlistSong: PlaylistSongType,
  updatedSongSettings: {
    note?: string
    transpose?: number
    simplifyChords?: boolean
  }
) => {
  const loggedUserId = LOGGED_USER_VAR().id
  let userSettingsUpdated = false

  const userSettings: PlaylistSongUserSettingsType[] = [
    ...(playlistSong.userSettings || []),
  ].map((settings) => {
    if (settings && settings.userId === loggedUserId) {
      userSettingsUpdated = true
      return { ...settings, ...updatedSongSettings }
    }
    return settings
  })

  if (!userSettingsUpdated) {
    userSettings.push({
      userId: loggedUserId,
      transpose: 0,
      simplifyChords: false,
      note: '',
      ...updatedSongSettings,
    })
  }

  return {
    ...playlistSong,
    userSettings: userSettings,
  }
}

export type UserFromPlaylistMemberType = UserType & {
  canEdit: boolean
}

export const getUsersFromPlaylistMembers = (
  songMembers: PlaylistMemberType[]
) => {
  const users: UserFromPlaylistMemberType[] = [...songMembers]
    .map((member) => {
      if (member.userGroup) {
        return member.userGroup.userGroupMembers?.map((groupMember) => {
          return {
            ...groupMember.user,
            canEdit: member.playlistRole.code === PLAYLIST_ROLES.admin,
          }
        })
      }
      return {
        ...member.user,
        canEdit: member.playlistRole.code === PLAYLIST_ROLES.admin,
      }
    })
    .flat()

  const uniqueUsers: UserFromPlaylistMemberType[] = []

  users.forEach((user) => {
    if (!uniqueUsers.map((u) => u.id).includes(user.id)) {
      uniqueUsers.push(user)
    }
  })

  return uniqueUsers
}

export const trimMembersForComparison = (members: PlaylistMemberType[]) => {
  const updatedMembers: PlaylistMemberType[] = []

  members?.forEach((m) => {
    updatedMembers.push({
      playlistRole: m.playlistRole,
      user: trimUserForComparison(m.user),
      userGroup: trimUserGroupForComparison(m.userGroup),
    })
  })
  return updatedMembers
}

export const getPlaylistSongAdditionalText = (options: {
  index: number
  songCount: number
  /* playlistMembers: PlaylistMemberType[]
  playlistSong: PlaylistSongType */
}) => {
  let str = `${options.index}/${options.songCount}`

  /* if (options.playlistSong?.playSettings) {
    const separator = '   -   '
    const users = getUsersFromPlaylistMembers(options.playlistMembers)

    const { songStartUserId, songEndUserId } =
      options.playlistSong?.playSettings

    if (songStartUserId !== undefined) {
      const user = users.find((u) => u.id === songStartUserId)
      str += `${separator}${i18n
        .t('playlist.song.player start')
        .toLowerCase()}: ${user.username || user.name}`
    }
    if (songEndUserId !== undefined) {
      const user = users.find((u) => u.id === songEndUserId)
      str += `${separator}${i18n
        .t('playlist.song.player end')
        .toLowerCase()}: ${user.username || user.name}`
    }
  } */

  return str
}

export const getPlaylistSongUserStartEndNames = (options: {
  playSettings: PlaylistSongPlaySettingsType
  playlistMembers: PlaylistMemberType[]
}) => {
  let userStart
  let userEnd
  if (options.playSettings) {
    const users = getUsersFromPlaylistMembers(options.playlistMembers)
    const { songStartUserId, songEndUserId } = options.playSettings

    if (songStartUserId !== undefined) {
      const user = users.find((u) => u.id === songStartUserId)
      if (user) {
        userStart = user.username || user.name
      }
    }

    if (songEndUserId !== undefined) {
      const user = users.find((u) => u.id === songEndUserId)
      if (user) {
        userEnd = user.username || user.name
      }
    }
  }
  return { userStart, userEnd }
}
