import { SongChordType } from 'types'

//
// TRANSPOSITION
//
export const transposeChord = (chordName: string, semitones: number) => {
  let chord = chordName.replace('H', 'B')
  var scale = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']
  var normalizeMap = {
    Cb: 'B',
    Db: 'C#',
    Eb: 'D#',
    Fb: 'E',
    Gb: 'F#',
    Ab: 'G#',
    Bb: 'A#',
    'E#': 'F',
    'B#': 'C',
  }
  return chord.replace(/[CDEFGAB](b|#)?/g, function (match) {
    var i =
      (scale.indexOf(normalizeMap[match] ? normalizeMap[match] : match) +
        semitones) %
      scale.length
    return scale[i < 0 ? i + scale.length : i]
  })
}

export const transposeSongChords = (
  semitones: number,
  songChords: SongChordType[]
) => {
  if (!songChords?.length || !semitones) {
    return songChords
  }
  const transposedChords = [...songChords].map((c) => {
    return { ...c, name: transposeChord(c.name, semitones) }
  })
  return transposedChords
}

//
// SIMPLIFY CHORDS
//
export const simplifyChord = (chord: string) => {
  return chord.replace(/(add|sus|maj|min|aug|dim)[1-9]/g, '')
}

export const simplifySongChords = (songChords: SongChordType[]) => {
  if (!songChords?.length) {
    return songChords
  }
  const simplifiedChords = [...songChords].map((c) => {
    return { ...c, name: simplifyChord(c.name) }
  })
  return simplifiedChords
}

//
// GET CHORDS FROM TEXT
//

export const CHORD_REGEX =
  /[A-G](b|#)?(maj|min|m|M|\+|-|dim|aug)?[0-9]*(sus|add)?[0-9]*(\/[A-G](b|#)?)?/g

export const getChordsFromText = (text: string) => {
  const foundChords = text.match(CHORD_REGEX)
  return foundChords
}

//
// REPLACE CHORDS IN TEXT
//
export const replaceChordsInText = (options: {
  text: string
  chordsOld: string[]
  chordsNew: string[]
}) => {
  const { text, chordsOld, chordsNew } = options
  let updatedText = text

  chordsOld.forEach((chordOld, i) => {
    updatedText = updatedText.replace(chordOld, chordsNew[i])
  })
  return updatedText
}
