import { UserGroupType, UserType } from 'types'
import styled, { css, keyframes } from 'styled-components'

/* eslint-disable react-hooks/exhaustive-deps */
import UserCard from 'components/User/UserCard'

const FILE_GROUP_MEM_ROT_INIT = 0
const FILE_GROUP_MEM_ROT_STEP = 30

const AvatarWrapper = styled.div`
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6vh;

  overflow: hidden;
`

const AvatarAnimationIn = keyframes`
  0% {
    opacity: 0.1;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
  }
`
const Avatar = styled.div<{ hasUserCard?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: min(15%, 30px);
  --size: max(250px, min(80%, 400px));
  aspect-ratio: 1;
  width: var(--size);
  min-width: var(--size);
  border-radius: 50%;

  & > img {
    z-index: 2;
    border-radius: 50%;
    width: 50%;
    height: 50%;
    object-fit: cover;
    overflow: hidden;
    outline: 2px solid ${(props) => props.theme.primary_light};
    box-shadow: 0px 5px 20px 0px ${(props) => props.theme.black}44;

    ${(props) =>
      props.hasUserCard
        ? css`
            opacity: 0;
          `
        : css`
            animation-name: ${AvatarAnimationIn};
            animation-timing-function: ease-out;
            animation-duration: 0.3s;
            animation-fill-mode: backwards;
            animation-delay: ${(props) => props.index * 0.2 + 0.2}s;
          `};
  }
`

const UserCardWrapper = styled.div`
  animation-name: ${AvatarAnimationIn};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: ${(props) => props.index * 0.2 + 0.2}s;

  position: absolute;
  z-index: 10;
  transform: scale(3.7);

  ${(props) => props.theme.breakpoint.S} {
    transform: scale(2.7);
  }
  ${(props) => props.theme.breakpoint.XS} {
    transform: scale(2.2);
  }
`

const ItemArray = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  right: -50%;
  bottom: -50%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateY(-28px) translateX(-28px);
`

const ItemWrapper = styled.div<{ index: number; offsetDeg: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  transform-origin: 0 0;
  transform: rotate(
    ${(props) =>
      FILE_GROUP_MEM_ROT_INIT +
      props.offsetDeg +
      props.index * FILE_GROUP_MEM_ROT_STEP}deg
  );
`

const Item = styled.div<{ index: number; offsetDeg: number }>`
  position: relative;
  transition: all 0.2s ease;
  transform-origin: 0 0;
  transform: translateX(40%)
    rotate(
      ${(props) =>
        -1 *
        (FILE_GROUP_MEM_ROT_INIT +
          props.offsetDeg +
          props.index * FILE_GROUP_MEM_ROT_STEP)}deg
    );
`

const ItemAnimationIn = keyframes`
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`
const ItemAnimated = styled.div<{ index: number }>`
  position: relative;
  transition: all 0.2s ease;
  animation-name: ${ItemAnimationIn};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-delay: ${(props) => props.index * 0.2 + 0.4}s;
`

const InfoBox = styled.div`
  pointer-events: auto !important;
  position: absolute;
  left: -20px;
  width: 80px;
  background: ${(props) => props.theme.neutral};
  color: ${(props) => props.theme.white};
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0px 5px 20px 0px ${(props) => props.theme.black}44;
`

const AvatarExplodedView = (props: {
  avatar: string
  user?: UserType
  userGroup?: UserGroupType
  items1?: ({ component: any; isText?: boolean } | null)[]
  items2?: ({ component: any; isText?: boolean } | null)[]
}) => {
  const renderItems = (items, offsetDeg) => {
    return (
      items?.length > 0 && (
        <ItemArray>
          {items?.map((item, i) =>
            item?.component ? (
              <ItemWrapper key={i} index={i} offsetDeg={offsetDeg}>
                <Item index={i} offsetDeg={offsetDeg}>
                  <ItemAnimated index={i}>
                    {item.isText ? (
                      <InfoBox>{item.component}</InfoBox>
                    ) : (
                      item.component
                    )}
                  </ItemAnimated>
                </Item>
              </ItemWrapper>
            ) : undefined
          )}
        </ItemArray>
      )
    )
  }

  return (
    <AvatarWrapper>
      <Avatar hasUserCard={props.user || props.userGroup}>
        <img src={props.avatar} alt="avatar" />

        {props.user || props.userGroup ? (
          <UserCardWrapper>
            <UserCard
              user={props.user}
              userGroup={props.userGroup}
              noBackground
              isScaledUp
              isMembersOpen={false}
            />
          </UserCardWrapper>
        ) : null}

        {renderItems(props.items1, 50)}
        {renderItems(props.items2, -140)}
      </Avatar>
    </AvatarWrapper>
  )
}

export default AvatarExplodedView
