/* eslint-disable react-hooks/exhaustive-deps */
import {
  CREATE_PLAYLIST,
  GET_PLAYLIST,
  GET_PLAYLISTS,
  UPDATE_PLAYLIST,
} from 'api/graphql'
import {
  DBPlaylistType,
  PlaylistBodyType,
  PlaylistMemberType,
  PlaylistSongType,
} from 'types'
import {
  UserFromPlaylistMemberType,
  getUsersFromPlaylistMembers,
  sendEmailNewPlaylistMember,
} from 'utils'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import Checkbox from 'components/Inputs/Checkbox'
import { EmailLanguageType } from 'components/EmailTemplate'
import { LOGGED_USER_VAR } from 'App'
import PlaylistInformation from './Information'
import PlaylistMembers from 'components/Playlist/PlaylistEdit/PlaylistEditor/PlaylistMembers'
import PlaylistSongs from './PlaylistSongs'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h2 {
    margin-top: 160px;
    margin-bottom: 100px;
  }
`

const Buttons = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  gap: 50px;
  row-gap: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: calc(max(100px, 6vh));

  button {
    min-width: 220px;
    min-height: 60px;
  }
`

const CheckboxWrapper = styled.div`
  flex: 0;
  margin: auto;
  margin-top: calc(max(100px, 10vh));
`

const PlaylistEditor = (props: {
  playlist: DBPlaylistType
  playlistBody: PlaylistBodyType
  playlistMembers: PlaylistMemberType[]
  onSubmit: (newPlaylistId?: string) => void
  onCancel: () => void
  doSubmit: boolean
  setDoSubmit: (value: boolean) => void
  doCancel: boolean
  setDoCancel: (value: boolean) => void
  isEditMode?: boolean
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const [emailNewMembers, setEmailNewMembers] = useState<boolean>(true)

  const [isLoadingNewSong, setIsLoadingNewSong] = useState<boolean>()

  const [newUsers, setNewUsers] = useState<UserFromPlaylistMemberType[]>([])
  const [newPlaylistData, setNewPlaylistData] = useState<{ playlistName }>()

  const [submittedId, setSubmittedId] = useState<boolean>(false)

  //
  // DETECT CHANGES
  //
  const [initPlaylistState, setInitPlaylistState] = useState(
    props.isEditMode
      ? props.playlist?.body
      : '{"name":"","description":"","playlistSongs":[]}'
  ) // IS ALREADY STRINGIFIED!
  /* TODO create string above dynamically (debug - new playlist, close without changes > this prevents informing that there were changes, even if the weren't) */

  const [initPlaylistMembersState, setInitPlaylistMembersState] = useState(
    JSON.stringify(props.playlistMembers)
  )
  const [initPlaylistMembers, setInitPlaylistMembers] = useState(
    props.playlistMembers
  )

  const compareChanges = () => {
    return (
      initPlaylistState !== JSON.stringify(putPlaylistBodyTogether()) ||
      initPlaylistMembersState !== JSON.stringify(playlistMembers)
    )
  }

  //
  // PLAYLIST PARTS (SEPARATED FOR PERFORMANCE WHILE UPDATING)
  //
  const [playlistMembers, setPlaylistMembers] = useState<PlaylistMemberType[]>(
    props.playlistMembers
  )

  const [playlistBody, setPlaylistBody] = useState<PlaylistBodyType>(
    props.playlistBody
  )

  const [playlistSongs, setPlaylistSongs] = useState<PlaylistSongType[]>(
    props.playlistBody?.playlistSongs
  )

  //
  // PUT TOGETHER PLAYLIST OBJECT
  //
  const putPlaylistBodyTogether = () => {
    return {
      ...playlistBody,
      playlistSongs: playlistSongs.map((ps) => {
        const psTrimmed = { ...ps }
        delete psTrimmed.songData
        return psTrimmed
      }),
    }
  }

  //
  // MUTATIONS
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataPlaylists } =
    useQuery(GET_PLAYLISTS) /* NECESSARY FOR REFETCH */

  const [updatePlaylist, { data: updatedData }] = useMutation(UPDATE_PLAYLIST, {
    refetchQueries: [GET_PLAYLISTS, GET_PLAYLIST],
  })

  const [createPlaylist, { data: newData }] = useMutation(CREATE_PLAYLIST, {
    refetchQueries: [GET_PLAYLISTS, GET_PLAYLIST],
  })

  //
  // WATCH FOR EDIT/ADD RESPONSES
  //
  const updateInitState = () => {
    setInitPlaylistState(JSON.stringify(putPlaylistBodyTogether()))
    setInitPlaylistMembersState(JSON.stringify(playlistMembers))
    setInitPlaylistMembers(playlistMembers)
  }

  useEffect(() => {
    if (!props.isEditMode && newData?.createPlaylist) {
      if (newData?.createPlaylist?.success) {
        toast.success(t(newData.createPlaylist.message))
        props.onSubmit(newData?.createPlaylist.id)

        // store id after saving song without leaving (still thinks it's adding a brand new song without id otherwise)
        setSubmittedId(newData?.createPlaylist.id)
        updateInitState()
      } else {
        toast.error(t(newData.createPlaylist.message))
      }
    } else if (props.isEditMode && updatedData?.updatePlaylist) {
      if (updatedData?.updatePlaylist?.success) {
        toast.success(t(updatedData.updatePlaylist.message))
        props.onSubmit()
        updateInitState()
      } else {
        toast.error(t(updatedData.updatePlaylist.message))
      }
    }

    // Notify new members via email
    if (emailNewMembers) {
      newUsers.forEach((user) => {
        sendEmailNewPlaylistMember({
          playlistId: props.isEditMode
            ? props.playlist.id
            : newData?.createPlaylist?.id,
          playlistName: newPlaylistData.playlistName,
          userEmail: user.email,
          userLang: user.languageCode as EmailLanguageType,
          userCanEdit: user.canEdit,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, updatedData])

  //
  // SUBMIT / CANCEL FUNCTIONS
  //
  const onSubmit = async (
    updatedPlaylistBody: PlaylistBodyType,
    updatedPlaylistMembers: PlaylistMemberType[]
  ) => {
    let body = {
      ...updatedPlaylistBody,
      playlistSongs: updatedPlaylistBody?.playlistSongs?.map((ps) => {
        const trimmedPS = { ...ps }
        delete trimmedPS.songData
        return trimmedPS
      }),
    }

    const songIds = body?.playlistSongs?.map((ps) => ps.songId) || []

    if (props.isEditMode || submittedId) {
      updatePlaylist({
        variables: {
          id: props.playlist?.id || submittedId,
          name: updatedPlaylistBody.name,
          body: JSON.stringify(body),
          members: updatedPlaylistMembers.map((m) => {
            return {
              memberId: m.id,
              userId: m.user?.id || undefined,
              userGroupId: m.userGroup?.id || undefined,
              playlistRoleCode: m.playlistRole.code,
            }
          }),
          songIds,
        },
      })
    } else {
      createPlaylist({
        variables: {
          name: updatedPlaylistBody.name,
          body: JSON.stringify(body),
          members: updatedPlaylistMembers.map((m) => {
            return {
              userId: m.user?.id || undefined,
              userGroupId: m.userGroup?.id || undefined,
              playlistRoleCode: m.playlistRole.code,
            }
          }),
          songIds,
        },
      })
    }

    if (emailNewMembers) {
      const originalUsers = props.isEditMode
        ? getUsersFromPlaylistMembers(initPlaylistMembers)
        : []
      const updatedUsers = getUsersFromPlaylistMembers(updatedPlaylistMembers)
      const newUsers = [...updatedUsers].filter(
        (user) =>
          user.id !== loggedUser.id &&
          !originalUsers.map((u) => u.id).includes(user.id)
      )

      setNewUsers(newUsers)
      setNewPlaylistData({
        playlistName: updatedPlaylistBody.name,
      })
    }
  }

  const onCancel = () => {
    if (compareChanges()) {
      confirmedAction({
        title: t('playlist.close without saving.title'),
        description: t('playlist.close without saving.description'),
        okText: t('playlist.close without saving.yes'),
        cancelText: t('playlist.close without saving.no'),
        onSubmit: () => {
          props.onCancel()
        },
      })
    } else {
      props.onCancel()
    }
  }

  //
  // INVOKE FUNCTIONS (to be accessible from elsewhere in the app)
  //
  useEffect(() => {
    if (props.doSubmit) {
      onSubmit(putPlaylistBodyTogether(), playlistMembers)
      props.setDoSubmit(false)
    }
  }, [props.doSubmit])

  useEffect(() => {
    if (props.doCancel) {
      onCancel()
      props.setDoCancel(false)
    }
  }, [props.doCancel])

  //
  // RENDER
  //
  return (
    <Wrapper>
      <PlaylistInformation
        generalInfo={playlistBody}
        setGeneralInfo={setPlaylistBody}
      />

      {(playlistSongs?.length > 0 || isLoadingNewSong) && (
        <h2>{t('playlist.songs')}</h2>
      )}
      <PlaylistSongs
        playlistSongs={playlistSongs}
        setPlaylistSongs={setPlaylistSongs}
        playlistMembers={playlistMembers}
        isLoadingNewSong={isLoadingNewSong}
        setIsLoadingNewSong={setIsLoadingNewSong}
      />

      <h2>{t('playlist.members.edit')}</h2>
      <PlaylistMembers
        playlistMembers={playlistMembers}
        setPlaylistMembers={setPlaylistMembers}
        isEditMode={props.isEditMode}
      />

      <CheckboxWrapper>
        <Checkbox
          label={t('playlist.members.email_new_members')}
          value={emailNewMembers}
          setValue={setEmailNewMembers}
        />
      </CheckboxWrapper>

      <Buttons>
        <button
          className={'btn-primary'}
          onClick={() => props.setDoCancel(true)}
        >
          <SvgIcon code="icon-delete" />
          {props.isEditMode
            ? t('playlist.cancel edited playlist')
            : t('playlist.cancel new playlist')}
        </button>

        <button
          className={'btn-primary'}
          onClick={() => props.setDoSubmit(true)}
        >
          <SvgIcon code="icon-save" />
          {props.isEditMode
            ? t('playlist.save edited playlist')
            : t('playlist.save new playlist')}
        </button>
      </Buttons>
    </Wrapper>
  )
}

export default PlaylistEditor
