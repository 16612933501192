/* eslint-disable react-hooks/exhaustive-deps */
import { PAPER_HEIGHT, PAPER_WIDTH } from '../SongPaperA4'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import styled from 'styled-components'

export const MAX_PAPER_WIDTH_PX = 970
const PAPER_SCALE_DEFAULT = MAX_PAPER_WIDTH_PX / PAPER_WIDTH

const Wrapper = styled.div<{ scale: number }>`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: ${(PAPER_HEIGHT / PAPER_WIDTH) * 100}%; /* 1:1 Aspect Ratio */

  display: flex;
  align-items: center;
  justify-content: center;
`

const PaperA4Wrapper = styled.div<{ scale: number }>`
  position: absolute;
  top: 0;
  transform-origin: 50% 0;
  transform: scale(${(props) => props.scale * PAPER_SCALE_DEFAULT});
  box-shadow: 0 0 20px 0 ${(props) => props.theme.black}22;
`

/**
 *
 * COMPONENT
 *
 */
const FillScreenPaperWrapper = (props: { children: any }) => {
  const containerRef = useRef()
  const [dimensions, setDimensions] = useState<{
    width: number
    height: number
  }>({ width: MAX_PAPER_WIDTH_PX, height: MAX_PAPER_WIDTH_PX })

  const updateDimensions = useCallback(() => {
    if (containerRef && containerRef.current) {
      const { width, height } = (
        containerRef.current as any
      ).getBoundingClientRect()
      if (width && height) {
        setDimensions({ width, height })
      }
    }
  }, [containerRef])

  useEffect(() => {
    updateDimensions()
    /* setTimeout(updateDimensions, 1000) */
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [containerRef, updateDimensions])

  useLayoutEffect(() => {
    updateDimensions()
  }, [])

  //
  // RENDER
  //
  return (
    <Wrapper ref={containerRef}>
      <PaperA4Wrapper scale={dimensions.width / MAX_PAPER_WIDTH_PX}>
        {props.children}
      </PaperA4Wrapper>
    </Wrapper>
  )
}

export default FillScreenPaperWrapper
