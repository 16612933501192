import { UserRoleType, UserType } from 'types'
import { useQuery, useReactiveVar } from '@apollo/client'

import { APP_LANGUAGE_CODES } from 'const'
import AvatarUpload from 'components/Inputs/AvatarUpload'
import Droplist from 'components/Inputs/Droplist'
import { GET_USER_ROLES } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import SectionBox from 'components/Boxes/SectionBox'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import { hasPermission } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;

  & > * {
    background: ${(props) => props.theme.background};
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;

  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column-reverse;
  }
`

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-child {
    min-width: 168px;
    max-width: 168px;

    ${(props) => props.theme.breakpoint.S} {
      align-self: center;
    }

    ${(props) => props.theme.breakpoint.XS} {
      min-width: 100%;
      max-width: 100%;
    }
  }
`

const UserForm = (props: {
  user: UserType
  setUser: (user: UserType) => void
  initPassword?: string
  setInitPassword?: (pass: string) => void
  oldPassword?: string
  setOldPassword?: (pass: string) => void
  newPassword?: string
  setNewPassword?: (pass: string) => void
  invalidFields?: string[]
  setInvalidFields?: (fields: string[]) => void
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const { data: userRolesData } = useQuery(GET_USER_ROLES)

  const allowUpdateAnyUser = hasPermission('updateAnyUser')
  const allowUpdateUserRole = hasPermission('updateUserRole')
  const isUpdatingSelf = props.user.id === loggedUser.id

  const checkInvalidValue = (value, id) => {
    if (props.setInvalidFields && props.invalidFields) {
      if (props.invalidFields.includes(id) && value.length) {
        props.setInvalidFields([...props.invalidFields].filter((f) => f !== id))
      }
    }
  }

  return (
    <Wrapper>
      <SectionBox
        name={t('user.general info')}
        icon={<SvgIcon code="icon-user" />}
      >
        <Row>
          <Col>
            <StringInput
              label={t('user.email')}
              value={props.user.email}
              setValue={(value) => {
                props.setUser({ ...props.user, email: value })
                checkInvalidValue(value, 'email')
              }}
              isInvalid={props.invalidFields?.includes('email')}
            />

            <StringInput
              label={t('user.name')}
              value={props.user.name}
              setValue={(value) => {
                props.setUser({ ...props.user, name: value })
                checkInvalidValue(value, 'name')
              }}
              isInvalid={props.invalidFields?.includes('name')}
            />

            <StringInput
              label={t('user.username')}
              value={props.user.username}
              setValue={(value) => {
                props.setUser({ ...props.user, username: value })
                checkInvalidValue(value, 'username')
              }}
              isInvalid={props.invalidFields?.includes('username')}
            />
          </Col>
          <Col>
            <AvatarUpload
              label={t('user.avatar')}
              value={props.user.avatar}
              setValue={(value) => {
                props.setUser({ ...props.user, avatar: value })
              }}
            />
          </Col>
        </Row>
      </SectionBox>

      <SectionBox
        name={t('user.app settings')}
        icon={<SvgIcon code="icon-settings" />}
      >
        <Droplist
          label={t('user.language')}
          options={Object.values(APP_LANGUAGE_CODES).map((lang) => {
            return { id: lang, name: t('languages.' + lang) }
          })}
          value={props.user.languageCode}
          onChange={(value) =>
            props.setUser({ ...props.user, languageCode: value as string })
          }
        />

        {allowUpdateUserRole && (
          <Droplist
            label={t('user.role')}
            options={(
              (userRolesData?.getUserRoles as UserRoleType[]) || []
            ).map((role) => {
              return { id: role.code, name: t('user.roles.' + role.code) }
            })}
            value={props.user.role?.code || 'user'}
            onChange={(value) =>
              props.setUser({ ...props.user, role: { code: value as string } })
            }
          />
        )}
      </SectionBox>

      {props.setInitPassword && (
        <SectionBox
          name={t('user.set password')}
          icon={<SvgIcon code="icon-private" />}
        >
          <StringInput
            label={t('user.init password')}
            value={props.initPassword}
            setValue={(value) => {
              props.setInitPassword(value)
              checkInvalidValue(value, 'initPassword')
            }}
            isInvalid={props.invalidFields?.includes('initPassword')}
            isSecret
          />
        </SectionBox>
      )}

      {props.setOldPassword && props.setNewPassword && (
        <SectionBox
          name={t('user.change password')}
          icon={<SvgIcon code="icon-private" />}
        >
          {(!allowUpdateAnyUser || isUpdatingSelf) && (
            <StringInput
              label={t('user.old password')}
              value={props.oldPassword}
              setValue={(value) => {
                props.setOldPassword(value)
                checkInvalidValue(value, 'oldPassword')
              }}
              isInvalid={props.invalidFields?.includes('oldPassword')}
              isSecret
            />
          )}
          <StringInput
            label={t('user.new password')}
            value={props.newPassword}
            setValue={(value) => {
              props.setNewPassword(value)
              checkInvalidValue(value, 'newPassword')
            }}
            isInvalid={props.invalidFields?.includes('newPassword')}
            isSecret
          />
        </SectionBox>
      )}
    </Wrapper>
  )
}

export default UserForm
