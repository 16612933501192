import styled, { css } from 'styled-components'
import { useRef, useState } from 'react'

import { File } from 'components/Files/FileSystem'
import FileIconButton from '../FileIconButton'
import SvgIcon from 'components/SvgIcon'
import useClickOutside from 'hooks/useClickOutside'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{ isActive?: boolean; isCurrent?: boolean }>`
  position: relative;
  transition: all 0.2s ease;
  user-select: none;

  padding: 5px 12px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  ${(props) =>
    props.isCurrent
      ? css`
          color: ${(props) => props.theme.white};
          background: ${(props) => props.theme.primary_light};
        `
      : css`
          cursor: pointer;
          background: ${(props) => props.theme.lighter};
          outline: 1px solid ${(props) => props.theme.light};
          ${props.isActive &&
          css`
            outline: 1px solid ${(props) => props.theme.neutral};
          `}
        `}
`

const FileMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  pointer-events: none;
`

const BreadcrumbsFolder = (props: {
  folder: File
  isCurrent?: boolean
  handleGoToFolder: (folder: File) => void
  onMoveFilesClick: (file: File) => void
  onFolderMiddleClick?: (file: File) => void
  selectedFileCount: number
}) => {
  const { t } = useTranslation()
  const ref = useRef()
  const { folder } = props

  const [isActive, setIsActive] = useState<boolean>(false)
  const [clickTimeOut, setClickTimeout] = useState(null)

  useClickOutside(ref, () => setIsActive(false))

  const onSingleClick = () => setIsActive(!isActive)

  const onDoubleClick = () => {
    props.handleGoToFolder(folder)
    setIsActive(false)
  }

  const onMouseDown = (e) => {
    if (e.button === 1) {
      // middle mouse button click
      if (props.onFolderMiddleClick) {
        props.onFolderMiddleClick(props.folder)
      }
    }
  }

  const onClick = (e) => {
    if (clickTimeOut !== null) {
      onDoubleClick()
      clearTimeout(clickTimeOut)
      setClickTimeout(null)
    } else {
      onSingleClick()
      setClickTimeout(
        setTimeout(() => {
          clearTimeout(clickTimeOut)
          setClickTimeout(null)
        }, 400)
      )
    }
  }

  return (
    <Wrapper
      ref={ref}
      onClick={props.isCurrent ? undefined : onClick}
      onMouseDown={onMouseDown}
      isActive={isActive}
      isCurrent={props.isCurrent}
    >
      {folder.name}
      {folder.name2 && ` (${folder.name2})`}

      {isActive && props.selectedFileCount > 0 && (
        <FileMenu>
          {
            <FileIconButton
              id={`${folder.id}_breadcrumbs_move`}
              onClick={() => props.onMoveFilesClick(folder)}
              hint={t('file_system.move_to_folder')}
              isForBreadcrumbs
            >
              <SvgIcon code="icon-file-system-move-files" />
            </FileIconButton>
          }
        </FileMenu>
      )}
    </Wrapper>
  )
}

export default BreadcrumbsFolder
