import AvatarUpload from 'components/Inputs/AvatarUpload'
import Checkbox from 'components/Inputs/Checkbox'
import Counter from 'components/Inputs/Counter'
import DateInput from 'components/Inputs/DateInput'
import Droplist from 'components/Inputs/Droplist'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SongAutocomplete from 'components/Inputs/SongAutocomplete'
import StringInput from 'components/Inputs/StringInput'
import TextInput from 'components/Inputs/TextInput'
import TransposeInput from 'components/Inputs/TransposeInput'
import UserAutocomplete from 'components/Inputs/UserAutocomplete'
import UserGroupAutocomplete from 'components/Inputs/UserGroupAutocomplete'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useState } from 'react'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  column-gap: 60px;
  row-gap: 40px;

  & > *:first-child {
    max-width: 400px;
  }

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    & > *:first-child {
      max-width: 100%;
    }
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const Flex = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  & > * {
    flex: 1;
    min-width: 300px;
  }

  ${(props) => props.theme.breakpoint.S} {
    & > * {
      min-width: 100%;
    }
  }
`

const DevModuleInputs = (props: { isOpenDefault?: boolean }) => {
  const [valTranspose, setValTranspose] = useState<number>(0)
  const [valCapo, setValCapo] = useState<number>(0)
  const [valSimplify, setValSimplify] = useState<boolean>(false)

  const [valStringInput, setValStringInput] = useState<string>('String input.')
  const [valTextInput, setValTextInput] = useState<string>('String input.')
  const [valDroplistId, setValDroplistId] = useState<string>('id-1')
  const [valAvatar, setValAvatar] = useState<any>()
  const [valCheckbox, setValCheckbox] = useState<boolean>(false)
  const [valCounter, setValCounter] = useState<number>(0)
  const [valDate, setValDate] = useState<string>()

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="Input Components"
      subtitle="string, checkbox, counter, droplist, ..."
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        <Column>
          <ModuleSectionTitle
            title="Song Adjustment Inputs"
            subtitle="Inputs prepared for updating song display data"
          />

          <TransposeInput
            value={valTranspose}
            setValue={setValTranspose}
            roundCorners
          />

          <TransposeInput
            value={valCapo}
            setValue={setValCapo}
            roundCorners
            isCapo
          />

          <SimplifyInput
            value={valSimplify}
            setValue={setValSimplify}
            roundCorners
          />

          <ModuleSectionTitle
            title="Autocomplete Inputs"
            subtitle="Inputs that query backend for data by search string"
          />

          <SongAutocomplete
            label="SongAutocomplete"
            excludeSongIds={[]}
            maxCount={10}
            onSongClick={(song) => toast.info(song.name)}
          />

          <UserAutocomplete
            label="UserAutocomplete"
            excludeUserIds={[]}
            maxCount={10}
            onUserClick={(user) => toast.info(user.name)}
          />

          <UserGroupAutocomplete
            label="UserGroupAutocomplete"
            excludeUserGroupIds={[]}
            maxCount={10}
            onUserGroupClick={(userGroup) => toast.info(userGroup.name)}
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          <ModuleSectionTitle
            title="Basic Inputs"
            subtitle="Inputs for variety of data"
          />

          <Flex>
            <StringInput
              label="StringInput"
              value={valStringInput}
              setValue={setValStringInput}
            />

            <Droplist
              label="Droplist"
              value={valDroplistId}
              options={[
                { id: 'id-1', name: 'Option 1' },
                { id: 'id-2', name: 'Option 2' },
                { id: 'id-3', name: 'Option 3' },
                { id: 'id-4', name: 'Option 4' },
              ]}
              onChange={(id) => setValDroplistId(id as string)}
            />

            <Counter
              label="Counter"
              value={valCounter}
              setValue={setValCounter}
              valueMin={-5}
              valueMax={5}
              showResetButton
            />

            <Counter
              label="Counter"
              value={valCounter}
              setValue={setValCounter}
              valueMin={-5}
              valueMax={5}
              arrowIcons
            />

            <DateInput
              label="DateInput"
              value={valDate}
              setValue={setValDate}
            />

            <TextInput
              label="TextInput"
              value={valTextInput}
              setValue={setValTextInput}
              isMultiline
              isResizable
              height="84px"
            />

            <Checkbox
              label="Checkbox"
              value={valCheckbox}
              setValue={setValCheckbox}
            />

            <AvatarUpload
              label="AvatarUpload"
              value={valAvatar}
              setValue={setValAvatar}
            />
          </Flex>
        </Column>
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleInputs
