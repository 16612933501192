import {
  CompositionSectionType,
  InstrumentSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
  TextLyricDesignType,
} from 'types'

import { BreakpointEnumType } from 'styles/breakpoints'
import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import InstrumentsPaperA4 from 'components/Song/Paper/InstrumentsPaperA4'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import TextLyricDesignController from 'components/Song/SongDesignControllers/TextLyricDesignController'
import styled from 'styled-components'

const COMPACT_CONTROLS_BREAKPOINT: BreakpointEnumType = 'M_LenovoTab'

const Wrapper = styled.div`
  align-self: center;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 60px;

  width: 70%;
  ${(props) => props.theme.breakpoint.XL} {
    width: 50%;
    margin-left: -10%;
  }
  ${(props) => props.theme.breakpoint.L} {
    width: max(420px, 60%);
    margin-left: -14%;
  }
  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    width: 80%;
    margin-left: 0;
  }
  ${(props) => props.theme.breakpoint.S} {
    width: 100%;
    margin-left: 0;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 20px);

  background: ${(props) => props.theme.background};

  ${(props) => props.theme.breakpoint[COMPACT_CONTROLS_BREAKPOINT]} {
    display: none;
  }
`

const ControlsCompact = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  right: 8px;

  background: ${(props) => props.theme.background};

  display: none;
  ${(props) => props.theme.breakpoint[COMPACT_CONTROLS_BREAKPOINT]} {
    display: flex;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const PrintLayout = (props: {
  generalInfo: SongGeneralInfoType
  lyricSections: LyricSectionType[]
  compositionSections: CompositionSectionType[]
  instrumentSections: InstrumentSectionType[]
  chords: SongChordType[]
  textLyricDesign: TextLyricDesignType
  setTextLyricDesign: (d: TextLyricDesignType) => void
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <FillScreenPaperWrapper>
        <SongPaperA4
          generalInfo={props.generalInfo}
          lyricSections={props.lyricSections}
          compositionSections={props.compositionSections}
          chords={props.chords}
          textLyricDesign={props.textLyricDesign}
          setTextLyricDesign={props.setTextLyricDesign}
        />
      </FillScreenPaperWrapper>

      <ControlsCompact>
        <TextLyricDesignController
          textLyricsDesign={props.textLyricDesign}
          setTextLyricsDesign={props.setTextLyricDesign}
          compactMode={true}
        />
      </ControlsCompact>

      <Controls>
        <TextLyricDesignController
          textLyricsDesign={props.textLyricDesign}
          setTextLyricsDesign={props.setTextLyricDesign}
          compactMode={false}
        />
      </Controls>

      {props.instrumentSections?.length > 0 && (
        <FillScreenPaperWrapper>
          <InstrumentsPaperA4
            generalInfo={props.generalInfo}
            instrumentSections={props.instrumentSections}
            chords={props.chords}
          />
        </FillScreenPaperWrapper>
      )}
    </Wrapper>
  )
}

export default PrintLayout
