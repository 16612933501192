import styled, { css } from 'styled-components'

import SvgIcon from 'components/SvgIcon'
import breakpoint from 'styles/breakpoints'
import { confirmAlert } from 'react-confirm-alert'
import theme from 'styles/theme'

export const Fullscreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;

  padding: 0 5%;

  background: ${theme.primary}EE;

  display: flex;

  ${breakpoint.S} {
    padding: 0;
  }
`

export const Modal = styled.div`
  flex: 1;
  position: relative;
  max-width: 600px;
  max-height: 100vh;
  margin: auto;

  overflow-y: auto;

  background: ${theme.white};
  border: 1px solid ${theme.primary};
  border-radius: 3px;
  box-shadow: 0 0 10px 0px ${theme.black}0A;

  & * {
    white-space: pre-line;
  }
`

export const ModalContent = styled.div`
  padding: 60px 6%;
  ${breakpoint.S} {
    padding: 30px 5%;
  }
`

export const Button = styled.button<{ isWarning?: boolean }>`
  ${(props) =>
    props.isWarning &&
    css`
      color: ${theme.warning};
      border-color: ${theme.warning};
    `}
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.black};
`

export const Separator = styled.div`
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 40%;
  height: 2px;
  background: ${theme.primary_light};
`

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
export const Warning = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${theme.warning};
`

export const WarningIconWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;

  svg {
    margin: auto;
    color: ${theme.warning};
    width: 30px;
  }
`

export const Buttons = styled.div<{ center?: boolean }>`
  margin: auto;
  flex: 1;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};

  margin-top: 40px;

  button {
    font-size: 14px;
    width: 46%;
  }
`

const INPUT_ACTION_FOCUS_ID = 'INPUT_ACTION_FOCUS_ID'

const confirmedAction = (props: {
  title: string
  description: string
  descriptionWarning?: string | string[]
  okText: string
  cancelText?: string
  onSubmit?: () => void
}) => {
  const warnings = props.descriptionWarning
    ? (typeof props.descriptionWarning === 'string'
        ? [props.descriptionWarning]
        : props.descriptionWarning
      )?.filter((w) => w)
    : null

  confirmAlert({
    closeOnEscape: true,
    //closeOnClickOutside: true,
    overlayClassName: 'confirm-alert-overlay',

    customUI: ({ onClose }) => {
      return (
        <Fullscreen>
          <Modal>
            <ModalContent>
              <Title>{props.title}</Title>
              <Separator />
              <Description>{props.description}</Description>
              {warnings &&
                warnings.map((w) => (
                  <>
                    <WarningIconWrapper>
                      <SvgIcon code="icon-warning" />
                    </WarningIconWrapper>
                    <Description>
                      <Warning>{w}</Warning>
                    </Description>
                  </>
                ))}

              <Buttons center={!props.cancelText}>
                {props.cancelText && (
                  <Button onClick={onClose}>{props.cancelText}</Button>
                )}
                <Button
                  onClick={() => {
                    props.onSubmit && props.onSubmit()
                    onClose()
                  }}
                  isWarning={warnings?.length}
                >
                  {props.okText}
                </Button>
              </Buttons>
            </ModalContent>
          </Modal>
        </Fullscreen>
      )
    },
  })

  document.getElementById(INPUT_ACTION_FOCUS_ID)?.focus()
}

export default confirmedAction
