/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css } from 'styled-components'
import { useEffect, useRef } from 'react'

import { ToolType } from 'components/Tools'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{ isCompact?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ScrollAnchor = styled.div`
  position: absolute;
  width: 100vw;
  height: 5px;
  pointer-events: none;
`

const Item = styled.div<{
  isActive?: boolean
  isCompact?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  transition: all 0.2s ease;

  --size: 120px;
  ${(props) =>
    props.isCompact &&
    css`
      --size: 80px;
    `}

  ${(props) => props.theme.breakpoint.S} {
    --size: 100px;
    ${(props) =>
      props.isCompact &&
      css`
        --size: 80px;
      `}
  }

  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);

  cursor: pointer;
  color: ${(props) => props.theme.black};

  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.primary_light};
    `}
`

const ToolName = styled.div`
  transition: all 0.2s ease;
  transition-delay: 0.3s;

  position: absolute;
  z-index: 3;
  top: 60%;
  left: 4px;
  right: 4px;

  color: ${(props) => props.theme.primary};

  white-space: pre-wrap;
  text-align: center;
  font-size: 10px;
  font-weight: 800;
`

const ToolIcon = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 2px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primary_light};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.black}33;

  transition: all 0.2s ease;

  border-radius: 50%;
  --size: 50%;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);

  svg {
    width: 80%;
  }

  ${(props) =>
    props.isActive &&
    css`
      filter: brightness(1.3);
    `}
`

const ToolItem = (props: {
  setActiveItemRef: (v: any) => void
  setActiveItemTrueRef: (v: any) => void
  tool: ToolType
  activeToolId: string
  setActiveToolId: (v: string) => void
  isCompact?: boolean
}) => {
  const { t } = useTranslation()
  const ref = useRef()
  const trueRef = useRef()

  const isActive = props.tool.id === props.activeToolId

  useEffect(() => {
    if (props.tool.id === props.activeToolId) {
      props.setActiveItemRef(ref)
      props.setActiveItemTrueRef(trueRef)
    }
  }, [props.activeToolId])

  //
  // RENDER
  //
  return (
    <Wrapper isCompact={props.isCompact} ref={trueRef}>
      {props.isCompact ? <ScrollAnchor ref={ref} /> : null}
      <Item
        isCompact={props.isCompact}
        isActive={isActive}
        onClick={() =>
          props.setActiveToolId(isActive ? undefined : props.tool.id)
        }
      >
        <ToolIcon isActive={isActive}>{props.tool.icon}</ToolIcon>
        <ToolName>{t(props.tool.toolName)}</ToolName>
      </Item>
    </Wrapper>
  )
}

export default ToolItem
