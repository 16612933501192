import {
  CompositionSectionType,
  LyricSectionType,
  SongChordType,
  SongType,
  StringChordSchemaType,
} from 'types'
import {
  convertGlobalCaretIndexToLocal,
  getLocalTranslatedHeading,
  parseLyricItemsToLines,
} from 'utils'

import { getChordOptions } from 'const'

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace)
}

export const convertStringChordToText = (
  chordName: string,
  chordSchema: StringChordSchemaType
) => {
  const strings = []

  const STRING_CHAR = '-'
  const sectionLength = 3
  const minDistance = 3

  const frets = chordSchema.strings.map((s) => s.fret).filter((f) => f)

  const maxFretInSchema = frets?.length ? Math.max(...frets) : minDistance
  const minFretInSchema = frets?.length ? Math.min(...frets) : 0

  let minFret = minFretInSchema
  let maxFret = maxFretInSchema

  if (minFretInSchema) {
    const minFretSuggestion =
      maxFret - Math.max(maxFret - minFretInSchema, minDistance)

    if (minFretSuggestion >= 0) {
      minFret = minFretSuggestion
      if (minFretInSchema <= minFretSuggestion) {
        minFret = minFretInSchema - 1
      }
    } else {
      minFret = 0
      maxFret = Math.max(maxFretInSchema, maxFret + Math.abs(minFretSuggestion))
    }
  }

  chordSchema?.strings?.forEach((s) => {
    let string = ''
    if (s.mute) {
      string += 'X'
    } else if (!s.fret) {
      string += 'o'
    } else {
      string += ' '
    }
    string += '|'

    for (let i = minFret + 1; i <= maxFret; i++) {
      const fret = i === s.fret ? `${s.fret}` : STRING_CHAR
      const fretLength = fret.length
      string +=
        STRING_CHAR + fret + Array(sectionLength - fretLength).join(STRING_CHAR)

      string += '|'
    }

    strings.push(string)
  })

  const str = chordName + '\n' + strings.join('/n')
  return str
}

export const convertSongToText = (props: {
  songBody: SongType
  t: any
  skipInstruments?: boolean
}) => {
  const { songBody, t, skipInstruments } = props

  const LINE_REF = '{LINE}'
  const LINE_CHAR = '-'
  const DOUBLE_LINE_REF = '{DOUBLE_LINE}'
  const DOUBLE_LINE_CHAR = '='

  const txtLines = []

  //
  // CREATE SONG TEXT
  //
  txtLines.push(DOUBLE_LINE_REF)

  /* HEADER */
  txtLines.push(`${songBody.name}`)
  if (songBody.author) {
    txtLines.push(`${songBody.author}`)
  }

  txtLines.push(DOUBLE_LINE_REF)
  txtLines.push('')

  /* META */
  let anyMetaPushed = false

  if (songBody.capo !== undefined) {
    txtLines.push(`${t('song.capo')}: ${songBody.capo}`)
    anyMetaPushed = true
  }
  if (songBody.key) {
    txtLines.push(`${t('song.key')}: ${songBody.key}`)
    anyMetaPushed = true
  }
  if (songBody.bpm) {
    txtLines.push(`${t('song.bpm')}: ${songBody.bpm}`)
    anyMetaPushed = true
  }
  if (songBody.timeSignature) {
    txtLines.push(`${t('song.time signature')}: ${songBody.timeSignature}`)
    anyMetaPushed = true
  }

  if (songBody.chords) {
    if (anyMetaPushed) {
      txtLines.push('')
    }
    txtLines.push(
      `${t('song.chords')}:\n${songBody.chords.map((c) => c.name).join(', ')}`
    )
    anyMetaPushed = true
  }

  if (songBody.note) {
    if (anyMetaPushed) {
      txtLines.push('')
    }
    txtLines.push(`${t('song.note')}:\n${songBody.note}`)
  }

  txtLines.push('')
  txtLines.push(LINE_REF)
  txtLines.push('')

  /* LYRICS */
  const parsedCompSections = parseSongLyrics({
    lyricSections: songBody.lyricSections,
    compositionSections: songBody.compositionSections,
    songChords: songBody.chords,
    songLanguageCode: songBody.languageCode,
    t,
  })
  parsedCompSections?.forEach((cs, compositionSecIndex) => {
    /* Lyric Heading */
    if (compositionSecIndex > 0) {
      txtLines.push('')
      txtLines.push('')
    }
    txtLines.push(cs.name)
    if (cs.note) {
      txtLines.push(`(${cs.note})`)
    }
    if (cs.note2) {
      txtLines.push(`(${cs.note2})`)
    }
    txtLines.push('')

    /* Parse Lyric Items */
    cs.lines?.forEach((line) => {
      if (line.notes?.length) {
        txtLines.push(line.notes)
      }
      if (line.chordDurations?.length) {
        txtLines.push(line.chordDurations)
      }
      if (line.chords?.length) {
        txtLines.push(line.chords)
      }
      txtLines.push(line.lyricLine)
    })
  })

  /* INSTRUMENTS */
  if (!skipInstruments) {
    songBody?.instrumentSections?.forEach((instrumentSection) => {
      const instrument = instrumentSection?.instrument
      txtLines.push('')
      txtLines.push('')
      txtLines.push(DOUBLE_LINE_REF)
      txtLines.push(
        `${t('instruments.' + instrument.code + '.name')}${
          instrumentSection.name ? ` - ${instrumentSection.name}` : ''
        }`
      )
      txtLines.push(DOUBLE_LINE_REF)

      if (instrument?.strumming) {
        txtLines.push(t('song.strumming') + ': ' + instrument.strumming)
      }

      if (instrument?.tuning) {
        const tuning = [...instrument.tuning.notes].reverse().join('')
        txtLines.push(
          t('song.tuning') +
            ': ' +
            `${instrument.tuning.name ? `${instrument.tuning.name} ` : ''}` +
            `(${tuning})`
        )
      }

      instrument?.chords?.forEach((c) => {
        txtLines.push('')
        const chordName = c.isExtraChord
          ? c.extraChordName || '?'
          : getChordOptions(songBody.chords).find(
              (chord) => chord.id === c.songChordId
            )?.name

        const chordTxt = convertStringChordToText(
          chordName,
          c.chordSchema as StringChordSchemaType
        )
        chordTxt?.split('/n')?.forEach((chordTxtLine) => {
          txtLines.push(chordTxtLine)
        })
      })
    })
  }

  //
  // REPLACE DYNAMIC ELEMENTS (lines to fit max-width etc.)
  //
  const txt = txtLines.join('\n')

  const maxWidth = Math.max(...txt.split('\n').map((l) => l.length))

  let finalTxt = replaceAll(txt, LINE_REF, Array(maxWidth + 1).join(LINE_CHAR))

  finalTxt = replaceAll(
    finalTxt,
    DOUBLE_LINE_REF,
    Array(maxWidth + 1).join(DOUBLE_LINE_CHAR)
  )

  return finalTxt
}

export const parseLyricSection = (props: {
  lyricSection: LyricSectionType
  songChords: SongChordType[]
  songLanguageCode: string
  t: any
}) => {
  const parsedLines: ParsedLineType[] = []

  const textLines = props.lyricSection?.lyrics
    ? props.lyricSection?.lyrics.split('\n')
    : []

  const sectionHeading = getLocalTranslatedHeading(
    props.lyricSection,
    props.songLanguageCode
  )

  /* Parse Lyric Items */
  const { parsedLyricChords, parsedLyricNotes } = parseLyricItemsToLines(
    props.lyricSection,
    textLines
  )

  /* Compose Lyric Lines */
  textLines?.forEach((textLine, lineIndex) => {
    const parsedLine: ParsedLineType = {
      notes: null,
      chordDurations: null,
      chords: null,
      lyricLine: null,
    }

    let line = textLine
    let lineOffset = 0
    let chordDurationLine = ''
    let chordLine = ''
    let chordLineOffset = 0
    let chordLineOffsetUsed = false
    let notesLine = ''

    /* Lyric Chords */
    const chords = parsedLyricChords[lineIndex]?.sort((a, b) =>
      a.position < b.position ? -1 : 1
    )

    chords?.forEach((c, index) => {
      const name = getChordOptions(props.songChords).find(
        (chord) => chord.id === c.chordId
      )?.name
      const duration = c.duration
      const caret = convertGlobalCaretIndexToLocal(
        c.position,
        textLines,
        lineIndex
      )

      if (name) {
        const chordShift =
          caret - chordLine?.length - Math.floor(name?.length / 2)
        const durationShift = duration
          ? caret - chordDurationLine?.length - Math.floor(duration?.length / 2)
          : 0

        if (chordShift < 0 || durationShift < 0) {
          /* OFFSET LINE IF CHORD STARTS WITH NEGATIVE CENTERING */
          lineOffset = Math.abs(Math.min(chordShift, durationShift)) + 1
          line = Array(lineOffset).join(' ') + line

          const diff = Math.abs(chordShift) - Math.abs(durationShift)
          if (diff < 0) {
            chordLineOffset = Math.abs(diff)
          }
        }

        let blankSpacesCountChords =
          Math.max(0, chordShift + 1) + (index > 0 ? lineOffset : 0)

        if (!chordLineOffsetUsed) {
          blankSpacesCountChords += chordLineOffset
          chordLineOffsetUsed = true
        }

        chordLine = chordLine + Array(blankSpacesCountChords).join(' ') + name

        if (duration) {
          const blankSpacesCountDuration =
            Math.max(0, durationShift + 1) + (index > 0 ? lineOffset : 0)

          chordDurationLine =
            chordDurationLine +
            Array(blankSpacesCountDuration).join(' ') +
            duration
        }
      }
    })

    /* Lyric Notes */
    const notes = parsedLyricNotes[lineIndex].sort((a, b) =>
      a.position < b.position ? -1 : 1
    )
    notes?.forEach((n) => {
      const note = n.text
      const caret = convertGlobalCaretIndexToLocal(
        n.position,
        textLines,
        lineIndex
      )
      const shift = caret - notesLine.length - 1
      notesLine = notesLine + Array(Math.max(0, shift + 1)).join(' ') + note
    })

    if (notesLine.length) {
      parsedLine.notes = notesLine
    }
    if (chordDurationLine.length) {
      parsedLine.chordDurations = chordDurationLine
    }
    if (chordLine.length) {
      parsedLine.chords = chordLine
    }
    parsedLine.lyricLine = line?.length > 0 ? line : ' ' // ' ' handles empty lines

    parsedLines.push(parsedLine)
  })

  const parsedLyricSection: ParsedLyricSectionType = {
    name: sectionHeading,
    lines: parsedLines,
  }

  return parsedLyricSection
}

export const parseSongLyrics = (props: {
  lyricSections: LyricSectionType[]
  compositionSections: CompositionSectionType[]
  songChords: SongChordType[]
  songLanguageCode: string
  t: any
}) => {
  const sections = []

  props.compositionSections?.forEach((cs, compositionSecIndex) => {
    const lyricSection = props.lyricSections.find(
      (ls) => ls.id === cs.lyricSectionId
    )

    const parsedLyricSection = parseLyricSection({
      lyricSection,
      songChords: props.songChords,
      songLanguageCode: props.songLanguageCode,
      t: props.t,
    })

    const parsedSongSection: ParsedSongSectionType = {
      name: parsedLyricSection.name,
      note: cs.note,
      note2: cs.note2,
      lines: parsedLyricSection.lines,
    }

    sections.push(parsedSongSection)
  })

  return sections
}

export type ParsedLineType = {
  notes: string
  chordDurations: string
  chords: string
  lyricLine: string
}

export type ParsedLyricSectionType = {
  name: string
  lines: ParsedLineType[]
}

export type ParsedSongSectionType = {
  name: string
  note: string
  note2: string
  lines: ParsedLineType[]
}
