import { SONG_INSTRUMENTS, getInstrumentIcon } from 'const'

import { SongInstrumentType } from 'types'
import breakpoint from 'styles/breakpoints'
import { confirmAlert } from 'react-confirm-alert'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import styled from 'styled-components'
import theme from 'styles/theme'

const Fullscreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;

  padding: 0 5%;

  background: ${theme.primary}EE;

  display: flex;

  ${breakpoint.S} {
    padding: 0;
  }
`

const Modal = styled.div`
  flex: 1;
  position: relative;
  max-width: 600px;
  max-height: 100vh;
  margin: auto;

  overflow-y: auto;

  background: ${theme.white};
  border: 1px solid ${theme.primary};
  border-radius: 3px;
  box-shadow: 0 0 10px 0px ${theme.black}0A;
`

const ModalContent = styled.div`
  padding: 60px 15%;
  ${breakpoint.S} {
    padding: 30px 5%;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.black};
`

const Separator = styled.div`
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 40%;
  height: 2px;
  background: ${theme.primary_light};
`

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`

const Buttons = styled.div`
  margin: auto;
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  margin-top: 40px;

  button {
    font-size: 14px;
    width: 46%;
  }
`

const Icon = styled.button`
  ${resetButtonCss}
  --size: 70px;
  width: var(--size);
  height: var(--size);

  color: ${theme.white};
  background: ${theme.primary_light};
  border-radius: 50%;
  box-shadow: 0 0 10px 0 ${theme.black}22;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  svg {
    width: 70%;
    height: 70%;
  }
`

const InstrumentButtons = styled.div`
  margin: auto;
  flex: 1;
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  justify-content: center;
  align-items: flex-start;

  gap: 10px;
  row-gap: 20px;

  & > div {
    ${resetButtonCss}
    box-shadow: none;
    flex: 1;

    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 5px;
    padding: 0;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    ${breakpoint.S} {
      font-size: 13px;
    }

    &:hover ${Icon} {
      background: ${theme.primary_light};
      filter: brightness(1.1);
      transform: scale(1.1);
      box-shadow: 2px 2px 10px 0px ${theme.black}08;
    }
  }
`

const selectedInstrumentAction = (props: {
  t: any
  onSubmit: (songInstrument: SongInstrumentType) => void
}) => {
  confirmAlert({
    closeOnEscape: true,
    //closeOnClickOutside: true,
    overlayClassName: 'confirm-alert-overlay',

    customUI: ({ onClose }) => {
      return (
        <Fullscreen>
          <Modal>
            <ModalContent>
              <Title>
                {props.t(
                  'song.instrument editor options.add instrument select title'
                )}
              </Title>
              <Separator />
              <Description>
                {props.t(
                  'song.instrument editor options.add instrument select description'
                )}
              </Description>

              <InstrumentButtons>
                {Object.values(SONG_INSTRUMENTS).map((songInstrument, i) => {
                  songInstrument = songInstrument as SongInstrumentType

                  return (
                    <div
                      key={i}
                      onClick={() => {
                        props.onSubmit(SONG_INSTRUMENTS[songInstrument.code])
                        onClose()
                      }}
                    >
                      <Icon>{getInstrumentIcon(songInstrument.code)}</Icon>
                      {props.t('instruments.' + songInstrument.code + '.name')}
                    </div>
                  )
                })}
              </InstrumentButtons>

              <Buttons>
                <button onClick={onClose}>{props.t('cancel')}</button>
              </Buttons>
            </ModalContent>
          </Modal>
        </Fullscreen>
      )
    },
  })
}

export default selectedInstrumentAction
