import { InstrumentSectionType, SongChordType } from 'types'
import { forwardRef, useState } from 'react'

import FlipMove from 'react-flip-move'
import InstrumentSection from '../InstrumentSection'
import SvgIcon from 'components/SvgIcon'
import { addInstrumentSectionToSong } from 'utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Sections = styled.div`
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      chords
      instrumentSections
      setInstrumentSections
      instrumentSection
      setOpenBox
      openBoxMap
      index
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <InstrumentSection
          index={props.index}
          chords={props.chords}
          instrumentSections={props.instrumentSections}
          setInstrumentSections={props.setInstrumentSections}
          instrumentSection={props.instrumentSection}
          setOpenBox={(isOpen) =>
            props.setOpenBox(props.instrumentSection.id, isOpen)
          }
          forcedIsOpen={
            props.openBoxMap.find((s) => s.id === props.instrumentSection.id)
              ?.isOpen
          }
        />
      </div>
    )
  }
)

/**
 *
 *  UTILS, TYPES
 *
 */
const InstrumentSections = (props: {
  chords: SongChordType[]
  instrumentSections: InstrumentSectionType[]
  setInstrumentSections: (data: InstrumentSectionType[]) => void
}) => {
  const { t } = useTranslation()

  //
  // CONTENT BOX OPEN MAP (animations destroy the original functionality)
  //
  const [openBoxMap, setOpenBoxMap] = useState<
    { id: number; isOpen: boolean }[]
  >(
    props.instrumentSections.map((s) => {
      return { id: s.id, isOpen: false }
    })
  )

  const setOpenBox = (instrumentSectionId, isOpen) => {
    const updatedMap = [...openBoxMap]
    const i = updatedMap.map((s) => s.id).indexOf(instrumentSectionId)
    if (i >= 0) {
      updatedMap[i].isOpen = isOpen
    } else {
      // for newly added sections
      updatedMap.push({ id: instrumentSectionId, isOpen })
    }
    setOpenBoxMap(updatedMap)
  }

  //
  // UTILS
  //
  const addSection = () => {
    addInstrumentSectionToSong({
      instrumentSections: props.instrumentSections,
      setInstrumentSections: props.setInstrumentSections,
      t,
    })
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Sections>
        <FlipMove
          easing="ease"
          duration={300} // milliseconds
          staggerDurationBy={150} // milliseconds
          staggerDelayBy={60} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: { transform: 'scale(0)' },
            to: { transform: 'none' },
          }}
          enterAnimation={{
            from: { transform: 'scaleY(0)', transformOrigin: '50% 0' },
            to: { transform: 'none' },
          }}
          leaveAnimation={{
            from: { transform: 'none', transformOrigin: '50% 0' },
            to: { transform: 'scaleY(0)' },
          }}
        >
          {props.instrumentSections?.map((instrumentSection, i) => (
            <AnimatedItem
              index={i}
              key={instrumentSection.id}
              chords={props.chords}
              instrumentSections={props.instrumentSections}
              setInstrumentSections={props.setInstrumentSections}
              instrumentSection={instrumentSection}
              setOpenBox={setOpenBox}
              openBoxMap={openBoxMap}
            />
          ))}
        </FlipMove>
      </Sections>

      {!props.instrumentSections.length && (
        <h3>{t('song.instruments editor empty')}</h3>
      )}

      <ButtonWrapper>
        <button
          onClick={addSection}
          title={t('song.instrument editor options.add instrument')}
          className="btn-action-circular"
        >
          <SvgIcon code="icon-add" />
        </button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default InstrumentSections
