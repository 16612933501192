import styled, { keyframes } from 'styled-components'

const CenterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const CircleAnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
    border-width: 200px;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`
const Circle = styled.div<{ inverted?: boolean; animationDelay?: boolean }>`
  opacity: 0;
  min-width: 90vw;
  min-height: 90vw;
  ${props => props.theme.breakpoint.S} {
    min-width: 200vw;
    min-height: 200vw;
  }

  border-radius: 50%;
  border: 20px solid
    ${props =>
      props.inverted ? `${props.theme.white}` : `${props.theme.white}08`};
  animation-name: ${CircleAnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 2s;
  animation-delay: ${props => props.animationDelay || '0s'};
  animation-fill-mode: backwards;
`

/**
 *
 * COMPONENT
 *
 */
const AnimatedPulse = (props: {
  inverted?: boolean
  animationDelay?: string
}) => {
  return (
    <CenterWrapper>
      <Circle inverted={props.inverted} animationDelay={props.animationDelay} />
    </CenterWrapper>
  )
}

export default AnimatedPulse
