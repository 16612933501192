export const APP_LANGUAGE_CODES: {
  en: AppLanguageType
  cz: AppLanguageType
  sk: AppLanguageType
} = {
  en: 'en',
  cz: 'cz',
  sk: 'sk',
}

export type AppLanguageType = 'en' | 'cz' | 'sk'

export const HEADER_HEIGHT = '60px'
