import { css } from 'styled-components'

//
// Default Styles
//

const commonCss = css`
  padding: 0px 10px;
  margin: 0px;
  margin-bottom: 10px;

  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`

export const defaultHeadings = css`
  h1 {
    ${commonCss}
    font-size: calc(20px + 0.5vw);
    font-weight: bold;
    margin-top: 0px;
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.background};

    margin-bottom: min(60px, 5vh);

    &::after {
      content: '';
      width: 90%;
      height: 4px;
      border-top: 1px solid ${(props) => props.theme.primary_light};
      background: ${(props) => props.theme.background};
    }
  }

  h2 {
    ${commonCss}
    font-size: calc(18px + 0.4vw);
    font-weight: bold;
    margin-top: 80px;
    margin-bottom: 40px;
    color: ${(props) => props.theme.dark};
  }

  h3 {
    ${commonCss}
    font-size: calc(16px + 0.3vw);
    font-size: 20px;
    font-weight: 600;
    margin-top: 80px;
    margin-bottom: 40px;
    color: ${(props) => props.theme.black};
  }
`
