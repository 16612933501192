import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import SvgIcon from 'components/SvgIcon'
import { resetButtonCss } from 'styles/GlobalStyle/Buttons'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{
  noShadow?: boolean
  noBackground?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.black}11;
  backdrop-filter: blur(5px);
  background: ${(props) => !props.noBackground && `${props.theme.white}88`};

  ${(props) =>
    !props.noShadow &&
    css`
      box-shadow: 5px 5px 10px 0px ${(props) => props.theme.black}08;
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none !important;
      opacity: 0.4;
    `}
`

const Header = styled.div<{ tinted?: boolean; smallHeight?: boolean }>`
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  min-height: ${(props) => (props.smallHeight ? '30px' : '40px')};

  color: ${(props) => (props.tinted ? props.theme.white : props.theme.black)};
  background: ${(props) =>
    props.tinted ? props.theme.primary_light : props.theme.light};

  transition: filter 0.2s ease;
  &:hover {
    filter: brightness(${(props) => (props.tinted ? 1.1 : 0.9)});
  }
`

const Spacer = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
`

const Buttons = styled.div`
  flex: 1;
  justify-self: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: none;
`

const Button = styled.button<{
  rotate: string
  tinted?: string
  smallHeight?: boolean
}>`
  ${resetButtonCss}
  box-shadow: none;
  padding: 10px;
  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    padding: 5px;
  }

  ${(props) =>
    props.smallHeight &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}

  color: ${(props) => (props.tinted ? props.theme.white : props.theme.black)};

  svg {
    transition: all 0.2s ease;
    ${({ rotate }) =>
      rotate === 'true' &&
      css`
        transform: rotate(-90deg);
      `}
  }

  &:hover {
    background: ${(props) => props.theme.black}11;
  }
`

const Title = styled.div<{ largeTitle?: boolean; smallHeight?: boolean }>`
  cursor: pointer;
  padding: ${(props) => (props.smallHeight ? '0px' : '4px 10px')};
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  min-width: 140px;

  display: flex;
  align-items: center;
  gap: 15px;

  &:first-letter {
    text-transform: uppercase;
  }

  ${(props) =>
    props.largeTitle &&
    css`
      font-size: 16px;
      font-weight: 600;
      ${(props) => props.theme.breakpoint.M_LenovoTab} {
        font-size: 14px;
      }
    `}
`

const TitleIcon = styled.div<{ smallHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.smallHeight ? '18px' : '22px')};
  height: ${(props) => (props.smallHeight ? '18px' : '22px')};

  ${(props) => props.theme.breakpoint.M_LenovoTab} {
    width: 16px;
    height: 16px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

const AnimateHeightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Content = styled.div<{ isOpen?: boolean }>`
  padding: 2%;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: all 0.2s ease;
`

const ContentBox = (props: {
  children: any
  title: any
  icon?: any
  closed?: boolean
  disabled?: boolean
  onClickUp?: () => void
  onClickDown?: () => void
  onClickDelete?: () => void
  onClickDuplicate?: () => void
  callback?: (isOpen: boolean) => void
  forcedIsOpen?: boolean
  tinted?: boolean
  largeTitle?: boolean
  smallHeight?: boolean
  noShadow?: boolean
  noBackground?: boolean
  style?: CSSProperties
}) => {
  const { t } = useTranslation()

  const useForcedIsOpen =
    props.forcedIsOpen !== undefined && props.forcedIsOpen !== null

  const [isOpen, setOpen] = useState<boolean>(
    useForcedIsOpen
      ? props.forcedIsOpen
      : typeof props.closed === 'boolean'
      ? !props.closed
      : true
  )

  return (
    <Wrapper
      noShadow={props.noShadow}
      noBackground={props.noBackground}
      disabled={props.disabled}
      style={props.style}
    >
      <Header tinted={props.tinted} smallHeight={props.smallHeight}>
        <Title
          onClick={() => setOpen(!isOpen)}
          title={t('content box.open/close tooltip')}
          largeTitle={props.largeTitle}
          smallHeight={props.smallHeight}
        >
          {props.icon && (
            <TitleIcon smallHeight={props.smallHeight}>{props.icon}</TitleIcon>
          )}

          {props.title}
        </Title>

        <Buttons>
          <Spacer onClick={() => setOpen(!isOpen)} />
          <Button
            onClick={() => setOpen(!isOpen)}
            rotate={isOpen ? 'true' : 'false'}
            title={t('content box.open/close tooltip')}
            tinted={props.tinted}
            smallHeight={props.smallHeight}
          >
            <SvgIcon code="icon-chevron-left" />
          </Button>

          {props.onClickUp && (
            <Button
              onClick={props.onClickUp}
              title={t('content box.move up tooltip')}
              tinted={props.tinted}
            >
              <SvgIcon code="icon-arrow-up" />
            </Button>
          )}

          {props.onClickDown && (
            <Button
              onClick={props.onClickDown}
              title={t('content box.move down tooltip')}
              tinted={props.tinted}
            >
              <SvgIcon code="icon-arrow-down" />
            </Button>
          )}

          {props.onClickDuplicate && (
            <Button
              onClick={props.onClickDuplicate}
              title={t('content box.duplicate tooltip')}
              tinted={props.tinted}
            >
              <SvgIcon code="icon-duplicate" />
            </Button>
          )}

          {props.onClickDelete && (
            <Button
              onClick={props.onClickDelete}
              title={t('content box.delete tooltip')}
              tinted={props.tinted}
            >
              <SvgIcon code="icon-delete" />
            </Button>
          )}
        </Buttons>
      </Header>

      <AnimateHeightWrapper>
        <AnimateHeight
          height={isOpen ? 'auto' : 0.01}
          onAnimationEnd={(newHeight) =>
            props.callback && props.callback(isOpen)
          }
          animateOpacity
          style={{ opacity: isOpen ? 1 : 0 }}
        >
          <Content isOpen={isOpen}>{isOpen && props.children}</Content>
        </AnimateHeight>
      </AnimateHeightWrapper>
    </Wrapper>
  )
}

export default ContentBox
