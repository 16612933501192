/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css, keyframes } from 'styled-components'

import { FLOATING_MENU_BREAKPOINT } from '..'
import { useRef } from 'react'

const btnAnimationIn = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`

const iconAnimationIn = keyframes`
  0% {
    transform: rotateZ(90deg)
  }
`

const Button = styled.button<{
  largeIcon?: boolean
  color?: FloatingButtonColorType
  isDisabled?: boolean
  isIndented?: boolean
  noShadow?: boolean
}>`
  position: relative;

  ${(props) =>
    props.isDisabled
      ? css`
          pointer-events: none !important;
          opacity: 0.5;
        `
      : css`
          pointer-events: auto !important;
        `}

  animation-name: ${btnAnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;

  ${(props) =>
    !props.noShadow &&
    css`
      box-shadow: 0px 4px 12px 2px ${(props) => props.theme.black}22 !important;

      ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
        box-shadow: 0px 0px 0px 7px ${(props) => props.theme.background}88 !important;
      }

      ${(props) => props.theme.breakpoint.XXS} {
        box-shadow: 0px 0px 0px 2px ${(props) => props.theme.background}88 !important;
      }
    `}

  background: ${(props) =>
    props.color === 'warning' && `${props.theme.warning} !important`};

  ${(props) =>
    props.largeIcon &&
    css`
      svg {
        width: 24px;
        height: 24px;
      }
    `}

  svg {
    animation: ${iconAnimationIn} 0.15s backwards
      cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  ${(props) =>
    props.isIndented &&
    css`
      margin-right: auto;
    `}
`

const Tooltip = styled.div<{
  index: number
  isVisible: boolean
  color?: FloatingButtonColorType
}>`
  position: absolute;
  bottom: 100%;

  color: ${(props) =>
    props.color === 'warning'
      ? `${props.theme.warning} !important`
      : props.theme.primary_light};

  background: ${(props) => props.theme.background};
  font-size: 10px;
  font-weight: 600;
  margin-bottom: var(--pad);
  width: 60px;
  padding: 6px 4px;
  border-radius: 10px;

  transition: all 0.2s ease;
  transition-delay: ${(props) => 0.2 + props.index * 0.1}s;

  ${(props) =>
    !props.isVisible &&
    css`
      transition-delay: 0s;
      opacity: 0;
      transform: translateY(20px);
      background: ${(props) => props.theme.background}00;

      ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
        transform: translateX(20px);
        background: ${(props) => props.theme.white}00;
      }
    `}

  ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
    margin-bottom: 0px;
    bottom: auto;
    right: calc(100% + 4px + var(--pad));

    width: auto;
    min-width: 60px;
    padding: 6px 8px;

    color: ${(props) => props.theme.primary_light};
    background: ${(props) => props.theme.white}99;
    outline: 1px solid
      ${(props) =>
        props.color === 'warning'
          ? `${props.theme.warning}55`
          : `${props.theme.primary_light}55`};
    backdrop-filter: blur(15px);
  }
`

export type FloatingButtonColorType = 'primary' | 'warning'

export type FloatingButtonType = {
  onClick?: (e?: any) => void
  tooltip?: string
  tooltipShort?: string
  tooltipShortIsVisible?: boolean
  icon?: any
  largeIcon?: boolean
  upload?: {
    filetype: string
    multiple?: boolean
  }
  component?: any
  color?: FloatingButtonColorType
  isSubdued?: boolean
  isVisible?: boolean
  isDisabled?: boolean
  isIndented?: boolean
}

const FloatingButton = (props: {
  button: FloatingButtonType
  noShadow?: boolean
  index?: number
}) => {
  const uploadRef = useRef()

  const className = `btn-action-circular${
    props.button.isSubdued ? '' : ' btn-action-circular--primary'
  }`

  const renderButton = (onClick: () => void) => {
    return (
      <>
        <Button
          onClick={onClick}
          className={className}
          title={props.button.tooltip}
          largeIcon={props.button.largeIcon}
          color={props.button.color}
          isDisabled={props.button.isDisabled}
          isIndented={props.button.isIndented}
          noShadow={props.noShadow}
        >
          {props.button.icon}

          {props.button.tooltipShort && (
            <Tooltip
              index={props.index}
              isVisible={props.button.tooltipShortIsVisible}
              color={props.button.color}
            >
              {props.button.tooltipShort}
            </Tooltip>
          )}
        </Button>
      </>
    )
  }

  //
  // RENDER
  //
  if (props.button.isVisible) {
    if (props.button.upload) {
      return (
        <>
          <input
            ref={uploadRef}
            onChange={props.button.onClick}
            type="file"
            style={{ display: 'none' }}
            accept={props.button.upload.filetype}
            multiple={props.button.upload.multiple}
          />
          {renderButton(() => (uploadRef as any)?.current.click())}
        </>
      )
    }

    return renderButton(props.button.onClick)
  }
  return null
}

export default FloatingButton
