import styled, { keyframes } from 'styled-components'
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { File } from 'components/Files/FileSystem'
import FileSystemUsers from 'components/Files/FileSystemUsers'
import { GET_USER } from 'api/graphql'
import { LOGGED_USER_VAR } from 'App'
import SvgIcon from 'components/SvgIcon'
import UserCard from 'components/User/UserCard'
import { UserType } from 'types'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
`

const AnimationIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const AddUserWrapper = styled.div`
  animation-name: ${AnimationIn};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 0.2s;
  animation-fill-mode: backwards;

  align-self: stretch;
  flex: 1;
`

/**
 *
 *  COMPONENT
 *
 */
const SongCreatedBy = (props: {
  createdByUser: UserType
  setCreatedByUserId: (id) => void
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const [isAddFocused, setIsAddFocused] = useState<boolean>(false)

  const [createdByUser, setCreatedByUser] = useState<UserType>(
    props.createdByUser || loggedUser
  )

  //
  // FETCH SELECTED USER
  //
  const [fetchUserId, setFetchUserId] = useState<number>()

  const { data: fetchedUserData, refetch: refetchUserData } = useQuery(
    GET_USER,
    {
      variables: { id: fetchUserId },
      skip: !fetchUserId,
    }
  )

  useEffect(() => {
    if (fetchedUserData) {
      refetchUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserId])

  useEffect(() => {
    const user = fetchedUserData?.getUser
    if (user) {
      setCreatedByUser(user)
      props.setCreatedByUserId(user?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserData])

  //
  // FILE MANAGEMENT
  //
  const onUserDoubleClick = (file: File) => {
    if (file.id === fetchUserId) {
      toast.info(t('song.user already a member'))
      return
    }
    setFetchUserId(file.id)
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <ButtonWrapper>
        <UserCard user={createdByUser} showName forceLoggedUserName />

        {isAddFocused ? (
          <>
            <AddUserWrapper>
              <FileSystemUsers
                defaultViewMode="grid"
                onFileAddClick={onUserDoubleClick}
                onFileDoubleClick={onUserDoubleClick}
                maxHeight="40vh"
                noUserGroups
              />
            </AddUserWrapper>

            <button
              onClick={() => setIsAddFocused(false)}
              title={t('song.close add user')}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-close" />
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsAddFocused(true)}
            title={t('song.add user')}
            className="btn-action-circular"
          >
            <SvgIcon code="icon-edit" />
          </button>
        )}
      </ButtonWrapper>
    </Wrapper>
  )
}

export default SongCreatedBy
