import { File, ViewMode } from 'components/Files/FileSystem'
import { UserGroupType, UserType } from 'types'

export const fileFromUser = (
  user: UserType,
  renderCustomIcon: (props: { isActive: boolean; viewMode: ViewMode }) => void
) => {
  const file: File = {
    id: user.id,
    name: user.name,
    name2: user.username,
    type: 'file',
    icon: 'user',
    image: user.avatar,
    renderCustomIcon: renderCustomIcon,
  }
  return file
}

export const fileFromUserGroup = (
  userGroup: UserGroupType,
  renderCustomIcon: (props: { isActive: boolean; viewMode: ViewMode }) => void
) => {
  const file: File = {
    id: userGroup.id,
    name: userGroup.name,
    type: 'folder',
    icon: 'userGroup',
    image: userGroup.avatar,
    renderCustomIcon: renderCustomIcon,
  }
  return file
}
