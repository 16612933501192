import { UserGroupType } from 'types'

export const USER_GROUP_ROLES: {
  admin: string
  member: string
} = {
  admin: 'GR_admin',
  member: 'GR_member',
}

export const EMPTY_USER_GROUP: UserGroupType = {
  id: -1,
  name: '',
  description: '',
}
