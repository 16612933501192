import { css } from 'styled-components'

//
// Default Styles
//
export const resetInputCss = css`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
`

export const defaultInputCss = css`
  ${resetInputCss}
  flex: 1;
  padding: 0 10px;
  display: flex;
  align-items: center;
  min-height: 34px;

  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 400;

  color: ${props => props.theme.black};
  border: 1px solid ${props => props.theme.black}22;
  border-radius: 2px;

  transition: background 0.2s ease;

  &::placeholder {
    color: ${props => props.theme.black}66;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`

export const defaultInputs = css`
  input {
    ${defaultInputCss}
  }
`
