/* eslint-disable react-hooks/exhaustive-deps */
import {
  CREATE_SONG,
  GET_PLAYLIST,
  GET_PLAYLISTS,
  GET_SONG,
  GET_SONGS,
  UPDATE_SONG,
} from 'api/graphql'
import {
  CompositionSectionType,
  DBSongType,
  InstrumentSectionType,
  LyricSectionType,
  SongChordType,
  SongGeneralInfoType,
  SongMemberType,
  SongType,
  TextLyricDesignType,
} from 'types'
import { TEXT_LYRICS_DEFAULT_DESIGN, USER_ROLES } from 'const'
import { UserFromSongMembers, getUsersFromSongMembers } from 'utils'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import Checkbox from 'components/Inputs/Checkbox'
import ChordSelection from './ChordSelection'
import CompositionSections from './Composition/CompSections'
import InstrumentSections from './Instruments/InstrumentSections'
import { LOGGED_USER_VAR } from 'App'
import LyricSections from './Lyrics/LyricSections'
import PrintLayout from './PrintLayout'
import SongCreatedBy from './SongCreatedBy'
import SongInfoEditor from './Information'
import SongMembers from './SongMembers'
import SvgIcon from 'components/SvgIcon'
import TextToSong from './TextToSong'
import confirmedAction from 'modals/actions/confirmedAction'
import { sendEmailNewSongMember } from 'utils'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h2 {
    margin-top: 160px;
    margin-bottom: 100px;
  }
`

const Buttons = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  gap: 50px;
  row-gap: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: calc(max(100px, 10vh));

  button {
    min-width: 220px;
    min-height: 60px;
  }
`

const CheckboxWrapper = styled.div`
  flex: 0;
  margin: auto;
  margin-top: calc(max(100px, 10vh));
`

const SongEditor = (props: {
  song: DBSongType
  songBody: SongType
  songMembers: SongMemberType[]
  onSubmit: (newSongId?: string, folderId?: string) => void
  onCancel: () => void
  doSubmit: boolean
  setDoSubmit: (value: boolean) => void
  doCancel: boolean
  setDoCancel: (value: boolean) => void
  isEditMode?: boolean
  isTextMode?: boolean
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  const [emailNewMembers, setEmailNewMembers] = useState<boolean>(true)

  const [newUsers, setNewUsers] = useState<UserFromSongMembers[]>([])
  const [newSongData, setNewSongData] = useState<{ songName; songAuthor }>()

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)
  const isAppAdmin = loggedUser.role?.code === USER_ROLES.admin

  const [submittedId, setSubmittedId] = useState<boolean>(false)

  //
  // DETECT CHANGES
  //
  const [initSongState, setInitSongState] = useState<string>(
    props.isEditMode
      ? JSON.stringify(props.songBody)
      : `{"name":"","author":"","languageCode":"en","capo":0,"key":"","bpm":0,"timeSignature":"","note":"","chords":[],"instrumentSections":[],"lyricSections":[],"compositionSections":[],"textLyricDesign":{"contentPaddingTop":100,"contentPaddingLeft":100,"contentPaddingRight":0,"contentHeight":3000,"columnGap":90,"scaleX":100,"fontSize":35,"letterSpacing":-1,"lineGapY":0,"sectionGapY":80,"showLyricChords":true,"showLyricNotes":true,"showSectionNotes":true,"wrapIndexesSorted":[]}}`
  )
  /* TODO create string above dynamically (debug - new song, close without changes > this prevents informing that there were changes, even if the weren't) */

  const [initSongMembersState, setInitSongMembersState] = useState<string>(
    JSON.stringify(props.songMembers)
  )
  const [initSongMembers, setInitSongMembers] = useState<SongMemberType[]>(
    props.songMembers
  )

  const compareChanges = () => {
    return (
      initSongState !== JSON.stringify(putSongTogether()) ||
      initSongMembersState !== JSON.stringify(songMembers) ||
      createdByUserId !== undefined /* undefined if unchanged */
    )
  }

  //
  // SONG PARTS (SEPARATED FOR PERFORMANCE WHILE UPDATING)
  //
  const [songMembers, setSongMembers] = useState<SongMemberType[]>(
    props.songMembers
  )

  const [generalInfo, setGeneralInfo] = useState<SongGeneralInfoType>({
    name: props.songBody.name,
    author: props.songBody.author,
    languageCode: props.songBody.languageCode,
    capo: props.songBody.capo,
    key: props.songBody.key,
    bpm: props.songBody.bpm,
    timeSignature: props.songBody.timeSignature,
    note: props.songBody.note,
  })

  const [chords, setChords] = useState<SongChordType[]>(props.songBody.chords)

  const [instrumentSections, setInstrumentSections] = useState<
    InstrumentSectionType[]
  >(props.songBody.instrumentSections)

  const [lyricSections, setLyricSections] = useState<LyricSectionType[]>(
    props.songBody.lyricSections
  )

  const [compositionSections, setCompositionSections] = useState<
    CompositionSectionType[]
  >(props.songBody.compositionSections)

  const [textLyricDesign, setTextLyricDesign] = useState<TextLyricDesignType>(
    props.songBody.textLyricDesign
      ? { ...TEXT_LYRICS_DEFAULT_DESIGN, ...props.songBody.textLyricDesign }
      : { ...TEXT_LYRICS_DEFAULT_DESIGN }
  )

  const [createdByUserId, setCreatedByUserId] = useState(undefined)

  //
  // PUT TOGETHER SONG OBJECT
  //
  const putSongTogether = () => {
    return {
      ...generalInfo,
      chords,
      instrumentSections,
      lyricSections,
      compositionSections,
      textLyricDesign,
    }
  }

  //
  // MUTATIONS
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataSongs } = useQuery(GET_SONGS) /* NECESSARY FOR REFETCH */

  const [updateSong, { data: updatedData }] = useMutation(UPDATE_SONG, {
    refetchQueries: [GET_SONGS, GET_SONG, GET_PLAYLISTS, GET_PLAYLIST],
  })

  const [createSong, { data: newData }] = useMutation(CREATE_SONG, {
    refetchQueries: [GET_SONGS, GET_SONG],
  })

  //
  // WATCH FOR EDIT/ADD RESPONSES
  //
  const updateInitState = () => {
    setInitSongState(JSON.stringify(putSongTogether()))
    setInitSongMembersState(JSON.stringify(songMembers))
    setInitSongMembers(songMembers)
  }

  useEffect(() => {
    if (!props.isEditMode && newData?.createSong) {
      const searchParams = new URLSearchParams(location.search)
      const folderId = searchParams.get('folder')

      if (newData?.createSong?.success) {
        toast.success(t(newData.createSong.message))
        props.onSubmit(newData?.createSong.id, folderId)

        // store id after saving song without leaving (still thinks it's adding a brand new song without id otherwise)
        setSubmittedId(newData?.createSong.id)
        updateInitState()
      } else {
        toast.error(t(newData.createSong.message))
      }
    } else if (props.isEditMode && updatedData?.updateSong) {
      if (updatedData?.updateSong?.success) {
        toast.success(t(updatedData.updateSong.message))
        props.onSubmit()
        updateInitState()
      } else {
        toast.error(t(updatedData.updateSong.message))
      }
    }
    if (emailNewMembers) {
      newUsers.forEach((user) => {
        sendEmailNewSongMember({
          songId: props.isEditMode ? props.song.id : newData?.createSong?.id,
          songName: newSongData.songName,
          songAuthor: newSongData.songAuthor,
          userEmail: user.email,
          userLang: user.lang,
          userCanEdit: user.canEdit,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, updatedData])

  //
  // SUBMIT SONG UPDATES
  //
  const onSubmit = async (
    updatedSong: SongType,
    updatedSongMembers: SongMemberType[],
    updatedCreatedByUserId: number
  ) => {
    if (props.isEditMode || submittedId) {
      updateSong({
        variables: {
          id: props.song?.id || submittedId,
          name: updatedSong.name,
          author: updatedSong.author,
          languageCode: updatedSong.languageCode,
          body: JSON.stringify(updatedSong),
          members: updatedSongMembers.map((m) => {
            return {
              memberId: m.id,
              userId: m.user?.id || undefined,
              userGroupId: m.userGroup?.id || undefined,
              songRoleCode: m.songRole.code,
            }
          }),
          createdByUserId: updatedCreatedByUserId,
        },
      })
    } else {
      createSong({
        variables: {
          name: updatedSong.name,
          author: updatedSong.author,
          languageCode: updatedSong.languageCode,
          body: JSON.stringify(updatedSong),
          members: updatedSongMembers.map((m) => {
            return {
              userId: m.user?.id || undefined,
              userGroupId: m.userGroup?.id || undefined,
              songRoleCode: m.songRole.code,
            }
          }),
          createdByUserId: updatedCreatedByUserId,
        },
      })
    }

    if (emailNewMembers) {
      const originalUsers = props.isEditMode
        ? getUsersFromSongMembers(initSongMembers)
        : []
      const updatedUsers = getUsersFromSongMembers(updatedSongMembers)
      const newUsers = [...updatedUsers].filter(
        (user) =>
          user.userId !== loggedUser.id &&
          !originalUsers.map((u) => u.userId).includes(user.userId)
      )

      setNewUsers(newUsers)
      setNewSongData({
        songName: updatedSong.name,
        songAuthor: updatedSong.author,
      })
    }
  }

  //
  // CANCEL SONG UPDATES
  //
  const onCancel = () => {
    if (compareChanges()) {
      confirmedAction({
        title: t('song.close without saving.title'),
        description: t('song.close without saving.description'),
        okText: t('song.close without saving.yes'),
        cancelText: t('song.close without saving.no'),
        onSubmit: () => {
          props.onCancel()
        },
      })
    } else {
      props.onCancel()
    }
  }

  //
  // INVOKE FUNCTIONS (to be accessible from elsewhere in the app)
  //
  useEffect(() => {
    if (props.doSubmit) {
      onSubmit(putSongTogether(), songMembers, createdByUserId)
      props.setDoSubmit(false)
    }
  }, [props.doSubmit])

  useEffect(() => {
    if (props.doCancel) {
      onCancel()
      props.setDoCancel(false)
    }
  }, [props.doCancel])

  //
  // RENDER
  //
  return (
    <Wrapper>
      <SongInfoEditor
        generalInfo={generalInfo}
        setGeneralInfo={setGeneralInfo}
      />

      {props.isTextMode ? (
        <>
          {/***** TEXT TO SONG *****/}
          <h2>{t('song.text to song')}</h2>
          <TextToSong
            generalInfo={generalInfo}
            chords={chords}
            setChords={setChords}
            lyricSections={lyricSections}
            setLyricSections={setLyricSections}
            compositionSections={compositionSections}
            setCompositionSections={setCompositionSections}
            textLyricDesign={textLyricDesign}
            setTextLyricDesign={setTextLyricDesign}
          />
        </>
      ) : (
        <>
          {/***** TRADITIONAL SONG EDIT *****/}
          {chords.length > 0 && <h2>{t('song.chords')}</h2>}
          <ChordSelection
            chords={chords}
            setChords={setChords}
            lyricSections={lyricSections}
            setLyricSections={setLyricSections}
            instrumentSections={instrumentSections}
            setInstrumentSections={setInstrumentSections}
          />

          {instrumentSections.length > 0 && <h2>{t('song.instruments')}</h2>}
          <InstrumentSections
            chords={chords}
            instrumentSections={instrumentSections}
            setInstrumentSections={setInstrumentSections}
          />

          {lyricSections.length > 0 && <h2>{t('song.sections')}</h2>}
          <LyricSections
            generalInfo={generalInfo}
            chords={chords}
            lyricSections={lyricSections}
            setLyricSections={setLyricSections}
            compositionSections={compositionSections}
            setCompositionSections={setCompositionSections}
          />

          {compositionSections.length > 0 && <h2>{t('song.composition')}</h2>}
          <CompositionSections
            generalInfo={generalInfo}
            chords={chords}
            lyricSections={lyricSections}
            setLyricSections={setLyricSections}
            compositionSections={compositionSections}
            setCompositionSections={setCompositionSections}
          />

          <h2>{t('song.print layout')}</h2>
          <PrintLayout
            generalInfo={generalInfo}
            chords={chords}
            lyricSections={lyricSections}
            compositionSections={compositionSections}
            instrumentSections={instrumentSections}
            textLyricDesign={textLyricDesign}
            setTextLyricDesign={setTextLyricDesign}
          />
        </>
      )}

      <h2>{t('song.members.edit')}</h2>
      <SongMembers
        initSongMembers={initSongMembers}
        songMembers={songMembers}
        setSongMembers={setSongMembers}
        isEditMode={props.isEditMode}
      />

      <CheckboxWrapper>
        <Checkbox
          label={t('song.members.email_new_members')}
          value={emailNewMembers}
          setValue={setEmailNewMembers}
        />
      </CheckboxWrapper>

      {isAppAdmin && (
        <>
          <h2 className="admin-color">{t('song.created by user')}</h2>
          <SongCreatedBy
            createdByUser={props.song?.createdByUser}
            setCreatedByUserId={setCreatedByUserId}
          />
        </>
      )}

      <Buttons>
        <button
          className={'btn-primary'}
          onClick={() => props.setDoCancel(true)}
        >
          <SvgIcon code="icon-delete" />
          {props.isEditMode
            ? t('song.cancel edited song')
            : t('song.cancel new song')}
        </button>

        <button
          className={'btn-primary'}
          onClick={() => props.setDoSubmit(true)}
        >
          <SvgIcon code="icon-save" />
          {props.isEditMode
            ? t('song.save edited song')
            : t('song.save new song')}
        </button>
      </Buttons>
    </Wrapper>
  )
}

export default SongEditor
