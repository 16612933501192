import { CompositionSectionType, SongType } from 'types'
import { useEffect, useState } from 'react'

import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SongLyricsAsText from 'components/Song/Paper/SongPaperA4/SongLyricsAsText'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import { TEST_SONG } from 'assets/test/songText'
import TextInput from 'components/Inputs/TextInput'
import TransposeInput from 'components/Inputs/TransposeInput'
import { convertTextToSong } from 'utils/song/convertTextToSong'
import { getScaledTextLyricDesign } from 'utils'
import styled from 'styled-components'

const Row = styled.div`
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  gap: 20px;

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
  }
`

const Column = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;

  min-width: 400px;
  ${(props) => props.theme.breakpoint.M} {
    min-width: unset;
  }
`

const SongPreview = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;

  min-width: 500px;
  ${(props) => props.theme.breakpoint.M} {
    min-width: unset;
  }
`

const ColumnSections = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

const LyricSectionWrapper = styled.div`
  background: white;
  box-shadow: 2px 2px 10px 0px ${(props) => props.theme.black}08;

  padding: 20px;
  overflow: auto;

  ${(props) => props.theme.breakpoint.M} {
    & * {
      margin-right: 0;
    }
  }

  ${(props) => props.theme.breakpoint.XS} {
    max-width: 250px;
  }
`

const DevModuleConvertTextToSong = (props: { isOpenDefault?: boolean }) => {
  const [songText, setSongText] = useState<string>(TEST_SONG)

  const [parsedSong, setParsedSong] = useState<SongType>()
  const [
    compSectionsForLyricSectionsRender,
    setCompSectionsForLyricSectionsRender,
  ] = useState<CompositionSectionType[]>()

  const [transpose, setTranspose] = useState<number>(0)
  const [capoOffset, setCapoOffset] = useState<number>(0)
  const [simplifyChords, setSimplifyChords] = useState<boolean>(false)

  useEffect(() => {
    const song = convertTextToSong(songText)
    setParsedSong(song)

    if (song.compositionSections) {
      const uniqueCompSectionsByLyricId: CompositionSectionType[] = []
      song.compositionSections.forEach((compSection) => {
        if (
          !uniqueCompSectionsByLyricId
            .map((s) => s.lyricSectionId)
            .includes(compSection.lyricSectionId)
        ) {
          uniqueCompSectionsByLyricId.push(compSection)
        }
      })
      setCompSectionsForLyricSectionsRender(uniqueCompSectionsByLyricId)
    } else {
      setCompSectionsForLyricSectionsRender(undefined)
    }
  }, [songText])

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="Convert Text to Song Object"
      subtitle="convert word-like song text definition into song object with defined chords, lyric sections, composition etc."
      isOpen={props.isOpenDefault}
    >
      <Row>
        <Column>
          <ModuleSectionTitle
            title="Text Input"
            subtitle="Plain text with song lyrics, chords and sections."
          />

          <TextInput
            value={songText}
            setValue={setSongText}
            isMultiline
            isResizable
            isSongFont
            liveUpdate
            height="800px"
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <SongPreview>
          <ModuleSectionTitle
            title="Song Output"
            subtitle="Song object parsed from the input plain text."
          />

          {parsedSong && (
            <>
              <Controls>
                <TransposeInput
                  value={transpose}
                  setValue={setTranspose}
                  roundCorners
                />

                <TransposeInput
                  value={capoOffset}
                  setValue={setCapoOffset}
                  roundCorners
                  isCapo
                />

                <SimplifyInput
                  value={simplifyChords}
                  setValue={setSimplifyChords}
                  roundCorners
                />
              </Controls>

              <FillScreenPaperWrapper>
                <SongPaperA4
                  generalInfo={{ ...parsedSong, name: 'Converted Song' }}
                  chords={parsedSong.chords}
                  lyricSections={parsedSong.lyricSections}
                  compositionSections={parsedSong.compositionSections}
                  textLyricDesign={parsedSong.textLyricDesign}
                  readOnly
                  transpose={transpose}
                  simplifyChords={simplifyChords}
                  capoOffset={capoOffset}
                  zoomSongBody={{ ...parsedSong, name: 'Converted Song' }}
                />
              </FillScreenPaperWrapper>
            </>
          )}
        </SongPreview>

        <ModuleSectionVerticalSeparator />

        <ColumnSections>
          <ModuleSectionTitle
            title="Generated Lyric Sections"
            subtitle="List of unique lyric sections used in the song"
          />

          {compSectionsForLyricSectionsRender?.length > 0 && (
            <>
              {compSectionsForLyricSectionsRender.map((compositionSection) => (
                <LyricSectionWrapper key={compositionSection.id}>
                  <SongLyricsAsText
                    lyricSections={parsedSong.lyricSections}
                    compositionSections={[compositionSection]}
                    chords={parsedSong.chords}
                    languageCode={parsedSong.languageCode}
                    textLyricDesign={getScaledTextLyricDesign(
                      parsedSong.textLyricDesign,
                      0.3
                    )}
                    readOnly
                  />
                </LyricSectionWrapper>
              ))}
            </>
          )}
        </ColumnSections>
      </Row>
    </ModuleWrapper>
  )
}

export default DevModuleConvertTextToSong
