/* eslint-disable react-hooks/exhaustive-deps */
import FileSystem, { File, ViewMode } from 'components/Files/FileSystem'
import { GET_USERS, GET_USER_GROUPS } from 'api/graphql'
import { USER_GROUP_LIST_VAR, USER_LIST_VAR } from 'App'
import { UserGroupType, UserType } from 'types'
import {
  compareSearchStrings,
  fileFromUser,
  fileFromUserGroup,
  openInNewTab,
} from 'utils'
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { FileSystemIconButtonType } from '../FileSystem/FileSystemIconButton'
import Loader from 'components/App/Loader'
import { ROUTES } from 'const'
import UserCard from 'components/User/UserCard'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const LoaderWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UserCardWrapper = styled.div<{ viewMode: ViewMode }>`
  transform: scale(${(props) => (props.viewMode === 'grid' ? 0.8 : 0.6)});
`

const FileSystemUsers = (props: {
  preloadedUsers?: UserType[]
  preloadedUserGroups?: UserGroupType[]
  onFileAddClick?: (file: File) => void
  onFileOpenClick?: (file: File) => void
  onFilePreviewClick?: (file: File) => void
  onFileSingleClick?: (file: File) => void
  onFileDoubleClick?: (file: File) => void
  onFolderAddClick?: (file: File) => void
  onFolderOpenClick?: (file: File) => void
  onFolderSingleClick?: (file: File) => void
  onFolderDoubleClick?: (file: File) => void
  defaultViewMode?: ViewMode
  maxHeight?: string
  noSearch?: boolean
  noSelection?: boolean
  noBackground?: boolean
  noUserGroups?: boolean
  customButtons?: FileSystemIconButtonType[]
}) => {
  const { t } = useTranslation()

  const userList = useReactiveVar(USER_LIST_VAR)
  const userGroupList = useReactiveVar(USER_GROUP_LIST_VAR)

  const users: UserType[] = props.preloadedUsers || userList
  const userGroups: UserGroupType[] = props.preloadedUserGroups || userGroupList

  const [viewMode, setViewMode] = useState<ViewMode>(
    props.defaultViewMode || 'grid'
  )

  //
  // QUERY LIST USERS
  //
  const { data: dataUsers, loading: usersLoading } = useQuery(GET_USERS, {
    skip: props.preloadedUsers ? true : false,
  })

  useEffect(() => {
    if (dataUsers?.getUsers) {
      USER_LIST_VAR(dataUsers.getUsers)
    }
  }, [dataUsers])

  //
  // QUERY LIST USER GROUPS
  //
  const { data: dataUserGroups, loading: userGroupsLoading } = useQuery(
    GET_USER_GROUPS,
    {
      skip: props.preloadedUserGroups || props.noUserGroups ? true : false,
    }
  )

  useEffect(() => {
    if (!props.noUserGroups && dataUserGroups?.getUserGroups) {
      USER_GROUP_LIST_VAR(dataUserGroups.getUserGroups)
    }
  }, [dataUserGroups])

  //
  // CLICK HANDLERS - FILE
  //
  const onFileMiddleClick = (file: File) => {
    const params: any =
      file.type === 'file' ? { user: file.id } : { userGroup: file.id }
    openInNewTab(
      `${ROUTES.users.slug}?${new URLSearchParams(params).toString()}`
    )
  }

  //
  // FILE RENDERS
  //
  const renderCustomIcon = (options: {
    user?: UserType
    userGroup?: UserGroupType
    isActive: boolean
    viewMode: ViewMode
  }) => {
    return (
      <UserCardWrapper viewMode={options.viewMode}>
        <UserCard
          user={options.user}
          userGroup={options.userGroup}
          isActive={options.isActive}
          viewMode={options.viewMode}
          noBackground
        />
      </UserCardWrapper>
    )
  }

  //
  // FILE SYSTEM MANAGEMENT
  //
  const [files, setFiles] = useState<File[]>([])
  const [search, setSearch] = useState<string>('')

  const populateFiles = () => {
    if (users || userGroups) {
      let filesUserGroups: File[] = []
      let filesUsers: File[] = []

      if (users) {
        filesUsers = [...users].map((u) =>
          fileFromUser(u, ({ isActive, viewMode }) =>
            renderCustomIcon({ user: u, isActive, viewMode })
          )
        )
      }

      if (props.noUserGroups) {
        return setFiles(filesUsers)
      }

      if (userGroups) {
        filesUserGroups = [...userGroups].map((g) =>
          fileFromUserGroup(g, ({ isActive, viewMode }) =>
            renderCustomIcon({ userGroup: g, isActive, viewMode })
          )
        )
      }

      return setFiles([...filesUserGroups, ...filesUsers])
    }
  }

  const searchFiles = () => {
    const foundUsers: File[] = users
      ?.filter(
        (u) =>
          compareSearchStrings(u.name, search) ||
          compareSearchStrings(u.username, search) ||
          []
      )
      .map((u) =>
        fileFromUser(u, ({ isActive, viewMode }) =>
          renderCustomIcon({ user: u, isActive, viewMode })
        )
      )

    if (props.noUserGroups) {
      return foundUsers
    }

    const foundUserGroups: File[] = (
      userGroups?.filter((g) => compareSearchStrings(g.name, search)) || []
    ).map((g) =>
      fileFromUserGroup(g, ({ isActive }) =>
        renderCustomIcon({ userGroup: g, isActive, viewMode })
      )
    )

    return [...foundUserGroups, ...foundUsers]
  }

  useEffect(() => {
    if (props.noUserGroups ? users : users && userGroups) {
      if (search) {
        setFiles(searchFiles())
      } else {
        populateFiles()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, userGroups, search])

  //
  // RENDER
  //
  return usersLoading || userGroupsLoading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <FileSystem
      files={files}
      onFileAddClick={props.onFileAddClick}
      onFileOpenClick={props.onFileOpenClick}
      onFilePreviewClick={props.onFilePreviewClick}
      onFileSingleClick={props.onFileSingleClick}
      onFileDoubleClick={props.onFileDoubleClick}
      onFileMiddleClick={onFileMiddleClick}
      onFolderAddClick={props.onFolderAddClick}
      onFolderOpenClick={props.onFolderOpenClick}
      onFolderMiddleClick={onFileMiddleClick}
      onFolderSingleClick={props.onFolderSingleClick}
      onFolderDoubleClick={props.onFolderDoubleClick}
      viewMode={viewMode}
      setViewMode={setViewMode}
      search={props.noSearch ? undefined : search}
      setSearch={props.noSearch ? undefined : setSearch}
      searchPlaceholder={t('user.search placeholder')}
      maxHeight={props.maxHeight}
      noSelection={props.noSelection}
      noMultiSelection={true}
      noBackground={props.noBackground}
    />
  )
}

export default FileSystemUsers
