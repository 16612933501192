/* eslint-disable react-hooks/exhaustive-deps */
import FloatingButton, {
  FloatingButtonType,
} from 'components/App/FloatingMenu/FloatingButton'
import React, { ReactElement, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import { FLOATING_MENU_BREAKPOINT } from '..'
import SvgIcon from 'components/SvgIcon'
import { useClickOutside } from 'hooks'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: relative;
`

const ButtonSpacer = styled.div`
  opacity: 0;
  pointer-events: none !important;
`

const Icon = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
`

const Menu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 15px;
  border-radius: 40px;
  background: ${(props) => props.theme.light};

  box-shadow: 2px 2px 10px 0px ${(props) => props.theme.black}11;

  transition: all 0.2s ease;

  --pad: 4px;

  top: calc(-1 * var(--pad));
  right: calc(-1 * var(--pad));
  padding: var(--pad);
  padding-right: 70px;

  ${(props) =>
    !props.isOpen &&
    css`
      overflow: hidden;
      opacity: 0;
      width: 50px;
      padding-right: var(--pad);
    `}
`

const MenuVertical = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 2;
  --pad: 4px;

  bottom: calc(-1 * var(--pad));
  right: calc(-1 * var(--pad));
  border-radius: 40px;

  box-shadow: 2px 2px 10px 0px ${(props) => props.theme.black}11;

  transition: all 0.2s ease;
  ${(props) =>
    !props.isOpen &&
    css`
      overflow: hidden;
      opacity: 0;
    `}

  & > * {
    /* AnimateHeight component */
    border-radius: 40px;
  }
`

const MenuVerticalContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: ${(props) => props.theme.light};
  border-radius: 40px;
  padding: var(--pad);
  padding-bottom: 65px;
`

const FloatingButtonWrapper = styled.div<{ isOpen: boolean; index: number }>`
  transition: all 0.3s ease;

  ${(props) =>
    !props.isOpen
      ? css`
          transition-delay: 0s;
          /* NOTE - IF NEEDED, PLACE CLOSED TRANSFORM DEFINITION HERE */
        `
      : css`
          transition-delay: ${(props) => 0.1 + props.index * 0.1}s;
        `}
`

const Large = styled.div`
  ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
    display: none;
  }
`
const Small = styled.div`
  display: none;
  ${(props) => props.theme.breakpoint[FLOATING_MENU_BREAKPOINT]} {
    display: block;
  }
`

const IconWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg:nth-child(2) {
    /* SUBMENU INDICATOR */
    position: absolute;
    top: 26px;

    transition: all 0.2s ease;

    opacity: 0.6;
    width: 12px;
    height: 12px;

    ${(props) =>
      props.isOpen &&
      css`
        color: ${(props) => props.theme.black}99;
      `}
  }
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const FloatingButtonWithMenu = (props: {
  icon: ReactElement
  buttons: FloatingButtonType[]
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const menuRef = useRef()
  useClickOutside(menuRef, () => setIsOpen(false))

  const getIndex = (i) => {
    return props.buttons.length - 1 - i
  }

  const visibleButtons = props.buttons.filter(
    (b) => b.component?.props?.isVisible || b.isVisible
  )
  const isVisible = visibleButtons.length > 0

  const renderButton = (options: {
    button: FloatingButtonType
    index?: number
    noShadow?: boolean
    isOnlyOne?: boolean
  }) => {
    const { button: b, index: i } = options

    const noShadow = options.noShadow
    const tooltipShortIsVisible = isOpen && b.tooltipShortIsVisible
    const isDisabled = (!options.isOnlyOne && !isOpen) || b.isDisabled

    return (
      <FloatingButtonWrapper key={i} isOpen={isOpen} index={getIndex(i)}>
        {b.component ? (
          <b.component.type
            {...b.component.props}
            {...{
              index: i,
              noShadow,
              tooltipShortIsVisible,
              isDisabled,
            }}
          />
        ) : (
          <FloatingButton
            index={i}
            noShadow={noShadow}
            button={{
              ...b,
              tooltipShortIsVisible,
              isDisabled,
            }}
          />
        )}
      </FloatingButtonWrapper>
    )
  }

  //
  // RENDER
  //
  return isVisible ? (
    <Wrapper>
      <ButtonSpacer>
        <FloatingButton button={{ isVisible: true }} />
      </ButtonSpacer>

      <Large>
        <Menu isOpen={isOpen}>
          {visibleButtons.map((b, i) => renderButton({ button: b, index: i }))}
        </Menu>
      </Large>

      <Small>
        <MenuVertical isOpen={isOpen}>
          <AnimateHeight height={isOpen ? 'auto' : 58}>
            <MenuVerticalContent isOpen={isOpen}>
              {visibleButtons.map((b, i) =>
                renderButton({ button: b, index: i, noShadow: true })
              )}
            </MenuVerticalContent>
          </AnimateHeight>
        </MenuVertical>
      </Small>

      <Icon ref={menuRef}>
        {visibleButtons.length === 1 ? (
          <>
            <Large>
              {renderButton({ button: visibleButtons[0], isOnlyOne: true })}
            </Large>
            <Small>
              {renderButton({
                button: visibleButtons[0],
                isOnlyOne: true,
                noShadow: true,
              })}
            </Small>
          </>
        ) : (
          <FloatingButton
            button={{
              onClick: () => setIsOpen(!isOpen),
              tooltip: t('song.add new song'),
              icon: (
                <IconWrapper>
                  {isOpen ? (
                    <SvgIcon code="icon-submenu-close" />
                  ) : (
                    <IconWrapper isOpen={isOpen}>{props.icon}</IconWrapper>
                  )}
                  <SvgIcon code="icon-submenu-indicator" />
                </IconWrapper>
              ),
              isVisible: true,
              isSubdued: isOpen,
            }}
            noShadow={isOpen}
          />
        )}
      </Icon>
    </Wrapper>
  ) : null
}

export default FloatingButtonWithMenu
