import InputLabel from 'components/Inputs/_InputLabel'
import SvgIcon from 'components/SvgIcon'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${(props) => props.theme.black}22;
  border-radius: 2px;

  width: 32px;
  height: 32px;
`

const IconWrapper = styled.div`
  color: ${(props) => props.theme.black};

  svg {
    width: 20px;
  }
`

const Checkbox = (props: {
  label?: string
  value: boolean
  setValue?: (value: boolean) => void
}) => {
  return (
    <Wrapper onClick={() => props.setValue && props.setValue(!props.value)}>
      <ValueWrapper>
        <IconWrapper>
          {props.value ? <SvgIcon code="icon-checkmark" /> : null}
        </IconWrapper>
      </ValueWrapper>

      {props.label && <InputLabel label={props.label} noColon />}
    </Wrapper>
  )
}

export default Checkbox
