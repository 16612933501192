import styled, { css } from 'styled-components'

const Label = styled.div<{ isInvalid?: boolean; noColon?: boolean }>`
  margin-top: 8px;
  margin-bottom: 4px;

  font-size: 13px;

  ${props =>
    !props.noColon &&
    css`
      &:after {
        content: ':';
      }
    `}
  &:first-letter {
    text-transform: uppercase;
  }

  ${props =>
    props.isInvalid &&
    css`
      font-weight: 700;
      color: ${props => props.theme.warning};
    `}
`

export type DisplayOptionsType = { id: string | number; name: string }[]

const InputLabel = (props: {
  label: string
  isInvalid?: boolean
  noColon?: boolean
}) => {
  return (
    <Label isInvalid={props.isInvalid} noColon={props.noColon}>
      {props.label}
    </Label>
  )
}

export default InputLabel
