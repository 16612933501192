import { InstrumentCodeType, STRING_INSTRUMENT_TUNINGS } from 'const'
import { SCLJSONItemType, SCLJSONType } from 'types'

import ChordFinder from 'components/Tools/Sections/ChordFinder'
import ChordLibrary from 'components/Tools/Sections/ChordLibrary'
import ChordNamer from 'components/Tools/Sections/ChordNamer'
import StringTuning from 'components/Instruments/StringTuning'
import ToolSection from 'components/Tools/ToolSection'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`

const StringInstrument = (props: {
  tuning: string
  instrumentCode: InstrumentCodeType
  stringChordLibraryJSON: SCLJSONType
}) => {
  const { t } = useTranslation()

  const data: SCLJSONItemType = props.stringChordLibraryJSON.data?.find(
    (item) => item.tuningName.toLowerCase() === props.tuning
  )
  const tuning = STRING_INSTRUMENT_TUNINGS[props.instrumentCode].find(
    (t) => t.key === props.tuning
  )

  return (
    <Wrapper>
      <ToolSection title={t('tools.tuning.name')} isOpen>
        <StringTuning tuningName={tuning.name} notes={tuning.notes} stretch />
      </ToolSection>

      {data ? (
        <>
          <ToolSection title={t('tools.chord-finder.name')}>
            <ChordFinder tuning={tuning.notes} chordsByRoot={data.chords} />
          </ToolSection>

          <ToolSection title={t('tools.chord-namer.name')}>
            <ChordNamer
              instrumentCode={props.instrumentCode}
              tuning={tuning.notes}
              chordsByRoot={data.chords}
            />
          </ToolSection>

          <ToolSection title={t('tools.chord-library.name')}>
            <ChordLibrary tuning={tuning.notes} chordsByRoot={data.chords} />
          </ToolSection>
        </>
      ) : null}
    </Wrapper>
  )
}

export default StringInstrument
