import { APP_LANGUAGE_CODES } from './app'
import { UserType } from 'types'

export const USER_ROLES: {
  admin: string
  user: string
  guest: string
} = {
  admin: 'admin',
  user: 'user',
  guest: 'guest',
}

export const EMPTY_USER: UserType = {
  id: -1,
  email: '',
  name: '',
  username: '',
  role: {
    code: 'user',
  },
  languageCode: APP_LANGUAGE_CODES.en,
}
