import { gql } from '@apollo/client'

export const GET_SONGS = gql`
  query {
    getSongs {
      id
      name
      author
      languageCode

      songMembers {
        user {
          id
        }
      }

      createdByUser {
        id
        name
      }
    }
  }
`

export const GET_SONG = gql`
  query getSong($id: ID!) {
    getSong(id: $id) {
      id
      name
      author
      languageCode
      body

      songMembers {
        id
        songRole {
          code
          songPermissions {
            code
          }
        }

        user {
          id
          email
          name
          username
          avatar
          role {
            code
          }
        }

        userGroup {
          id
          name
          avatar
          userGroupMembers {
            user {
              id
              email
              languageCode
              name
              username
              avatar
            }
          }
        }
      }

      createdByUser {
        id
        email
        name
        username
        avatar
        role {
          code
        }
      }
    }
  }
`

export const SEARCH_SONGS = gql`
  query getSongs($search: String!, $excludeIds: [ID], $maxCount: Int) {
    getSongs(search: $search, excludeIds: $excludeIds, maxCount: $maxCount) {
      id
      name
      author
      languageCode
      body
    }
  }
`
