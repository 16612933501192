import styled, { css, keyframes } from 'styled-components'

const IconButtonAnimationIn = keyframes`
0% {
  transform: scale(0.6);
  opacity: 0;
}
60% {
  transform: scale(1.1);
  opacity: 1;
}
100% {
  transform: scale(1);
}
`

const IconButton = styled.button<{
  compact?: boolean
  isForBreadcrumbs?: boolean
}>`
  animation-name: ${IconButtonAnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.2s;
  animation-fill-mode: backwards;

  pointer-events: auto;
  padding: 6px !important;
  --size: 34px !important;

  ${(props) =>
    props.compact &&
    css`
      --size: 28px !important;
    `}

  ${(props) =>
    props.isForBreadcrumbs &&
    css`
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    `}
`

const FileIconButton = (props: {
  id: string
  onClick: () => void
  hint?: string
  isCompact?: boolean
  isForBreadcrumbs?: boolean
  children: any
}) => {
  return (
    <IconButton
      onClick={props.onClick}
      className="btn-action-circular"
      title={props.hint}
      compact={props.isCompact}
      isForBreadcrumbs={props.isForBreadcrumbs}
    >
      {props.children}
    </IconButton>
  )
}

export default FileIconButton
