import InputLabel from 'components/Inputs/_InputLabel'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  color: ${(props) => props.theme.black};
  cursor: pointer;

  &:focus {
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.black}0A;
  }
`

const DateInput = (props: {
  label?: string
  placeholder?: string
  value: string
  setValue: (value: string) => void
  disabled?: boolean
}) => {
  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}

      <Input
        type="date"
        value={props.value || ''}
        onChange={(event) => props.setValue(event.target.value)}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </Wrapper>
  )
}

export default DateInput
