import FlipMove from 'react-flip-move'
import { LOGGED_USER_VAR } from 'App'
import { PLAYLIST_ROLES } from 'const'
import PlaylistMember from 'components/Playlist/PlaylistEdit/PlaylistEditor/PlaylistMembers/PlaylistMember'
import { PlaylistMemberType } from 'types'
import confirmedAction from 'modals/actions/confirmedAction'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  & > * {
    background: ${(props) => props.theme.background};
  }
`

const Members = styled.div`
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 15px;
    margin-top: 20px;
  }
`

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      member
      deleteMember
      updateMemberRole
      allowUpdateMembers
    },
    ref
  ) => {
    return (
      <div ref={ref as any} style={{ overflowAnchor: 'none' }}>
        <PlaylistMember
          member={props.member}
          deleteMember={props.deleteMember}
          updateMemberRole={props.updateMemberRole}
          allowUpdateMembers={props.allowUpdateMembers}
        />
      </div>
    )
  }
)
/**
 *
 * COMPONENT
 *
 */
const PlaylistMembersList = (props: {
  playlistMembers: PlaylistMemberType[]
  setPlaylistMembers?: (members: PlaylistMemberType[]) => void
  onDeleteCallback?: () => void
  allowUpdateMembers?: boolean
}) => {
  const { t } = useTranslation()
  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  //
  // FUNCTIONS
  //
  const membersEqual = (m1: PlaylistMemberType, m2: PlaylistMemberType) => {
    return m1.user?.id === m2.user?.id && m1.userGroup?.id === m2.userGroup?.id
  }

  const getRemainingAdmins = (excludeMember: PlaylistMemberType) => {
    const remainingAdmins = props.playlistMembers.filter((m) => {
      return (
        !membersEqual(excludeMember, m) &&
        m.playlistRole?.code === PLAYLIST_ROLES.admin
      )
    })
    return remainingAdmins
  }

  //
  // UPDATE MEMBERS
  //
  const updateMember = (updatedMember: PlaylistMemberType) => {
    props.setPlaylistMembers(
      [...props.playlistMembers].map((m) =>
        membersEqual(m, updatedMember) ? updatedMember : m
      )
    )
  }

  const updateMemberRole = (
    member: PlaylistMemberType,
    playlistRoleCode: string
  ) => {
    updateMember({
      ...member,
      playlistRole: { code: playlistRoleCode },
    })
  }

  const safelyUpdateMemberRole = (
    member: PlaylistMemberType,
    playlistRoleCode: string
  ) => {
    const remainingAdmins = getRemainingAdmins(member)
    if (!remainingAdmins.length && playlistRoleCode !== PLAYLIST_ROLES.admin) {
      confirmedAction({
        title: t('playlist.members.giving up last admin.title'),
        description: t('playlist.members.giving up last admin.description'),
        okText: t('playlist.members.giving up last admin.ok'),
      })
    } else if (
      member.user?.id === loggedUser.id &&
      member.playlistRole?.code === PLAYLIST_ROLES.admin &&
      playlistRoleCode !== PLAYLIST_ROLES.admin
    ) {
      confirmedAction({
        title: t('playlist.members.giving up admin.title'),
        description: t('playlist.members.giving up admin.description'),
        okText: t('playlist.members.giving up admin.yes'),
        cancelText: t('playlist.members.giving up admin.no'),
        onSubmit: () => updateMemberRole(member, playlistRoleCode),
      })
    } else {
      updateMemberRole(member, playlistRoleCode)
    }
  }

  //
  // DELETE MEMBERS
  //
  const deleteMember = (memberToDelete: PlaylistMemberType) => {
    props.setPlaylistMembers(
      [...props.playlistMembers].filter((m) => !membersEqual(m, memberToDelete))
    )
    props.onDeleteCallback && props.onDeleteCallback()
  }

  const safelyDeleteMember = (memberToDelete: PlaylistMemberType) => {
    const isDeletingSelf = loggedUser?.id === memberToDelete?.user?.id
    const remainingAdmins = getRemainingAdmins(memberToDelete)

    if (isDeletingSelf && !remainingAdmins.length) {
      confirmedAction({
        title: t('playlist.members.leaving last admin.title'),
        description: t('playlist.members.leaving last admin.description'),
        okText: t('playlist.members.leaving last admin.ok'),
      })
    } else if (isDeletingSelf) {
      confirmedAction({
        title: t('playlist.members.leaving.title'),
        description: t('playlist.members.leaving.description'),
        okText: t('playlist.members.leaving.yes'),
        cancelText: t('playlist.members.leaving.no'),
        onSubmit: () => deleteMember(memberToDelete),
      })
    } else if (!remainingAdmins.length) {
      confirmedAction({
        title: t('playlist.members.removing last admin.title'),
        description: t(
          'playlist.members.removing last admin.description'
        ).replace(
          '{USER/GROUP}',
          memberToDelete?.user?.name || memberToDelete?.userGroup?.name
        ),
        okText: t('playlist.members.removing last admin.ok'),
      })
    } else {
      confirmedAction({
        title: t('playlist.members.removing member.title'),
        description: t('playlist.members.removing member.description').replace(
          '{USER/GROUP}',
          memberToDelete?.user?.name || memberToDelete?.userGroup?.name
        ),
        okText: t('playlist.members.removing member.yes'),
        cancelText: t('playlist.members.removing member.no'),
        onSubmit: () => deleteMember(memberToDelete),
      })
    }
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Members>
        <FlipMove
          easing="ease"
          duration={200} // milliseconds
          staggerDurationBy={80} // milliseconds
          staggerDelayBy={100} // milliseconds
          maintainContainerHeight={false}
          appearAnimation={{
            /* first item appearance */
            from: {
              opacity: '0',
              transform: 'scale(0.4)',
              transformOrigin: '50% 50%',
            },
            to: { opacity: '1', transform: 'none' },
          }}
        >
          {props.playlistMembers?.map((member: PlaylistMemberType) => (
            <AnimatedItem
              key={member.user?.id || `gr_${member.userGroup?.id}`}
              member={member}
              updateMemberRole={safelyUpdateMemberRole}
              deleteMember={safelyDeleteMember}
              allowUpdateMembers={props.allowUpdateMembers}
            />
          ))}
        </FlipMove>
      </Members>
    </Wrapper>
  )
}

export default PlaylistMembersList
