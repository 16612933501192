/* eslint-disable react-hooks/exhaustive-deps */
import { DBSongType, PlaylistBodyType, UserGroupType, UserType } from 'types'
import {
  DELETE_USER,
  GET_PLAYLISTS,
  GET_SONG,
  GET_SONGS,
  GET_USERS,
  GET_USER_DELETE_CHECK,
  GET_USER_GROUP,
  GET_USER_GROUPS,
} from 'api/graphql'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import FloatingButton from 'components/App/FloatingMenu/FloatingButton'
import { ROUTES } from 'const'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import { hasPermission } from 'utils'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ButtonDelete = styled.button`
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  padding-right: 25px;
  align-self: center;
  background: ${(props) => props.theme.warning} !important;
`

const UserDeleteButton = (props: {
  user: UserType
  isFloatingButton?: boolean
  isVisible?: boolean
  isIndented?: boolean
  noShadow?: boolean
  tooltipShortIsVisible?: boolean
  index?: number
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  //
  // CHECK BEFORE DELETE
  //
  const [checkBeforeDelete, setCheckBeforeDelete] = useState<boolean>()
  const [deleteData, setDeleteData] = useState<{
    songsToDelete: DBSongType[]
    userGroupsToDelete: UserGroupType[]
    playlistsToDelete: PlaylistBodyType[]
  }>()

  const { refetch: refetchDeleteCheck, data: deleteCheck } = useQuery(
    GET_USER_DELETE_CHECK,
    {
      variables: {
        userId: props.user?.id,
      },
      skip: !checkBeforeDelete || !props.user,
    }
  )

  useEffect(() => {
    if (checkBeforeDelete) {
      refetchDeleteCheck()
    }
  }, [checkBeforeDelete])

  useEffect(() => {
    if (deleteCheck?.getUserDeleteCheck) {
      setDeleteData({
        songsToDelete: deleteCheck?.getUserDeleteCheck?.songsToDelete,
        userGroupsToDelete: deleteCheck?.getUserDeleteCheck?.userGroupsToDelete,
        playlistsToDelete: deleteCheck?.getUserDeleteCheck?.playlistsToDelete,
      })
    }
  }, [deleteCheck])

  useEffect(() => {
    if (checkBeforeDelete && deleteData) {
      safelyDeleteUser(props.user, deleteData)
    }
  }, [deleteData])

  //
  // DELETE USER
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedSongs } =
    useQuery(GET_SONGS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedPlaylists } =
    useQuery(GET_PLAYLISTS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUserGroups } =
    useQuery(GET_USER_GROUPS) /* NECESSARY FOR REFETCH */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUsers } =
    useQuery(GET_USERS) /* NECESSARY FOR REFETCH */

  const getAllowDeleteUser = () => hasPermission('deleteUser')
  const [deleteUser, { data: delData }] = useMutation(DELETE_USER, {
    refetchQueries: [
      GET_USERS,
      GET_USER_GROUPS,
      GET_USER_GROUP,
      GET_SONGS,
      GET_SONG,
    ],
  })

  useEffect(() => {
    if (delData?.deleteUser) {
      if (delData?.deleteUser?.success) {
        toast.success(t(delData?.deleteUser?.message))
        history.push(ROUTES.users.slug)
      } else {
        toast.error(t(delData?.deleteUser?.message))
      }
    }
  }, [delData])

  const safelyDeleteUser = (
    userGroup: UserGroupType,
    deleteData: {
      songsToDelete: DBSongType[]
      userGroupsToDelete: UserGroupType[]
      playlistsToDelete: PlaylistBodyType[]
    }
  ) => {
    const userGroupNames = deleteData.userGroupsToDelete
      .map((s) => `'${s.name}'`)
      .join('\n')

    const songNames = deleteData.songsToDelete
      .map((s) => `'${s.name}'`)
      .join('\n')

    const playlistNames = deleteData.playlistsToDelete
      .map((s) => `'${s.name}'`)
      .join('\n')

    confirmedAction({
      title: t('user.delete.title').replace('{USER}', props.user.name),
      description: t('user.delete.description').replace(
        '{USER}',
        userGroup.name
      ),
      descriptionWarning: [
        deleteData.userGroupsToDelete.length &&
          t('user.delete.description user groups')
            .replace('{GROUPS}', userGroupNames)
            .replace('{USER}', props.user.name),

        deleteData.songsToDelete.length &&
          t('user.delete.description songs')
            .replace('{SONGS}', songNames)
            .replace('{USER}', props.user.name)
            .replace(
              '{DESCRIPTION_SONGS_GROUPS}',
              deleteData.userGroupsToDelete.length
                ? t('user.delete.description songs groups')
                : ''
            ),
        deleteData.playlistsToDelete.length &&
          t('user.delete.description playlists')
            .replace('{PLAYLISTS}', playlistNames)
            .replace('{USER}', props.user.name)
            .replace(
              '{DESCRIPTION_SONGS_GROUPS}',
              deleteData.userGroupsToDelete.length
                ? t('user.delete.description songs groups')
                : ''
            ),
      ],
      okText:
        deleteData.userGroupsToDelete.length && deleteData.songsToDelete.length
          ? t('user.delete.yes with songs and groups')
          : deleteData.songsToDelete.length
          ? t('user.delete.yes with songs')
          : deleteData.userGroupsToDelete.length
          ? t('user.delete.yes with groups')
          : t('user.delete.yes'),
      cancelText: t('user.delete.no'),
      onSubmit: () => {
        deleteUser({ variables: { id: props.user.id } })
      },
    })

    setCheckBeforeDelete(undefined)
    setDeleteData(undefined)
  }

  //
  // BUTTON CLICK
  //
  const onDeleteClick = () => {
    setCheckBeforeDelete(true)
  }

  //
  // RENDER
  //
  const renderButton = () => {
    if (props.isFloatingButton) {
      return (
        <FloatingButton
          button={{
            onClick: onDeleteClick,
            tooltip: t('user.delete.label'),
            tooltipShort: t('user.delete.label short'),
            tooltipShortIsVisible: props.tooltipShortIsVisible,
            icon: <SvgIcon code="icon-delete" />,
            color: 'warning',
            isVisible: props.isVisible,
            isIndented: props.isIndented,
          }}
          noShadow={props.noShadow}
          index={props.index}
        />
      )
    }
    return (
      <ButtonDelete className="btn-primary" onClick={onDeleteClick}>
        <SvgIcon code="icon-delete" /> {t('user.delete.label')}
      </ButtonDelete>
    )
  }

  return getAllowDeleteUser() ? renderButton() : null
}

export default UserDeleteButton
