import { CompositionSectionType, LyricSectionType, SongChordType } from 'types'
import styled, { css } from 'styled-components'

import Droplist from 'components/Inputs/Droplist'
import PaperSheet from 'components/Boxes/Paper/PaperSheet'
import SongLyricsAsText from 'components/Song/Paper/SongPaperA4/SongLyricsAsText'
import StringInput from 'components/Inputs/StringInput'
import SvgIcon from 'components/SvgIcon'
import { useTranslation } from 'react-i18next'

export const COLUMN_BREAKPOINT = 'M'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;

  ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
    align-items: stretch;
    flex-direction: column;
    gap: 0;
  }
`

const GroupPaper = styled.div`
  flex: 1.8;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 4px 0;

  & > * {
    min-height: 100%;
  }

  ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
    max-width: 100%;
  }
`

const PaperContent = styled.div`
  min-height: 60px;
  flex: 1;
  padding: 10px;
  padding-left: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;

  ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
    gap: 4%;
  }
  ${(props) => props.theme.breakpoint.S} {
    padding-left: 10%;
  }
`

const GroupOptions = styled.div<{ isBottomMobile?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.isBottomMobile &&
    css`
      display: none;
      ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
        display: flex;
      }
    `}
`

const Timeline = styled.div<{ isBottomMobile?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  left: 15%;
  min-height: 150px;

  ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
    min-height: 0px;
    height: 200px;
    left: 20%;
  }

  ${(props) =>
    props.isBottomMobile &&
    css`
      ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
        min-height: 0px;
        height: 50px;
        left: 20%;
      }
    `}
`

const Line = styled.div`
  width: 1px;
  height: 100%;
  border-left: 2px dotted ${(props) => props.theme.black}44;
`

const ButtonCSS = css`
  bottom: -20px;
  width: 40px !important;
  height: 40px !important;
`

const AddButton = styled.button<{
  hideOnMobile?: boolean
  hideOnDesktop?: boolean
}>`
  ${ButtonCSS}
  position: absolute;
  z-index: 10;

  ${(props) =>
    props.hideOnMobile &&
    css`
      ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
        display: none !important;
      }
    `}
  ${(props) =>
    props.hideOnDesktop &&
    css`
      display: none !important;
      ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
        display: block !important;
      }
    `}
`

const DeleteButton = styled.button`
  ${ButtonCSS}
  position: absolute;
  z-index: 10;
  background: ${(props) => props.theme.background};
  &:hover {
    background: ${(props) => props.theme.lighter};
  }

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AbsoluteTop = styled.div`
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainOptions = styled.div`
  position: absolute;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  right: 49%;
  top: calc(50% - 52px);
  width: 50%;
  margin-right: 30px;

  ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
    top: calc(50% - 50px);
  }

  select {
    min-height: 38px;
    border-radius: 20px;
    padding-right: 10px;
    background: ${(props) => props.theme.background};
    &:hover {
      background: ${(props) => props.theme.lighter};
    }
  }
`

const StringInputWrapper = styled.div<{ tinted?: boolean }>`
  input {
    font-family: 'Inconsolata', monospace;
    font-weight: 600;

    color: ${(props) =>
      props.tinted ? props.theme.chord : `${props.theme.black}77`};

    min-height: 25px;
    margin: 0 10px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.black}44;
    background: ${(props) => props.theme.background};
    &:hover {
      background: ${(props) => props.theme.lighter};
    }

    &::placeholder {
      color: ${(props) =>
        props.tinted ? props.theme.chord : `${props.theme.black}77`};
      opacity: 0.3;
    }
  }
`

const HorizontalLine = styled.div<{ hideOnMobile?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 58%;
  margin-right: 30px;
  width: 30%;
  border-bottom: 2px dotted ${(props) => props.theme.black}22;

  ${(props) =>
    props.hideOnMobile &&
    css`
      ${(props) => props.theme.breakpoint[COLUMN_BREAKPOINT]} {
        display: none !important;
      }
    `}
`

const MainOptionMove = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  margin-left: 20px;
  height: 100px;
`

const MoveButton = styled.button<{ locked?: boolean }>`
  ${ButtonCSS}
  padding: 0;
  border: 1px solid ${(props) => props.theme.black}11;

  transition: all 0.2s ease;
  ${(props) =>
    props.locked &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`

/**
 *
 *  COMPONENT
 *
 */
const CompSection = (props: {
  songLanguageCode: string
  chords: SongChordType[]
  lyricSections: LyricSectionType[]
  setLyricSections?: (data: LyricSectionType[]) => void
  compositionSections: CompositionSectionType[]
  compSection: CompositionSectionType
  setCompSection: (compSection: CompositionSectionType) => void
  deleteSection: () => void
  moveSection: (offset: number) => void
  addSectionToStart: () => void
  addSection: () => void
  i: number
  paddingTop?: string
}) => {
  const { t } = useTranslation()

  const lyricSection = props.lyricSections.find(
    (s) => s.id === props.compSection.lyricSectionId
  )

  const getLyricSectionName = (lyricSection: LyricSectionType) => {
    return `${t('song.section.lyrics roles.' + lyricSection.role)}${
      lyricSection.roleIndex ? ` ${lyricSection.roleIndex}` : ''
    }`
  }

  //
  // RENDER
  //
  return (
    <Wrapper paddingTop={props.paddingTop}>
      <GroupOptions>
        <Timeline>
          <DeleteButtonWrapper>
            <DeleteButton
              onClick={props.deleteSection}
              title={t(
                'song.composition editor options.delete composition section'
              )}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-delete" />
            </DeleteButton>
          </DeleteButtonWrapper>

          <MainOptions>
            <Droplist
              options={props.lyricSections.map((s) => {
                return {
                  id: s.id,
                  name: getLyricSectionName(s),
                }
              })}
              value={lyricSection?.id}
              onChange={(id) => {
                props.setCompSection({
                  ...props.compSection,
                  lyricSectionId: parseInt(id as string),
                })
              }}
            />

            <StringInputWrapper tinted>
              <StringInput
                placeholder={t(
                  'song.composition editor options.note placeholder'
                )}
                value={props.compSection?.note}
                setValue={(value) =>
                  props.setCompSection({ ...props.compSection, note: value })
                }
              />
            </StringInputWrapper>

            <StringInputWrapper>
              <StringInput
                placeholder={t(
                  'song.composition editor options.note placeholder'
                )}
                value={props.compSection?.note2}
                setValue={(value) =>
                  props.setCompSection({ ...props.compSection, note2: value })
                }
              />
            </StringInputWrapper>
          </MainOptions>

          <MainOptionMove>
            <MoveButton
              onClick={() => props.moveSection(-1)}
              title={t(
                'song.composition editor options.move composition section up'
              )}
              locked={props.i === 0}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-arrow-up" />
            </MoveButton>
            <MoveButton
              onClick={() => props.moveSection(1)}
              title={t(
                'song.composition editor options.move composition section down'
              )}
              locked={props.i === props.compositionSections.length - 1}
              className="btn-action-circular"
            >
              <SvgIcon code="icon-arrow-down" />
            </MoveButton>
          </MainOptionMove>

          <HorizontalLine hideOnMobile={true} />

          {props.i === 0 && (
            <AbsoluteTop>
              <AddButton
                onClick={props.addSectionToStart}
                title={t(
                  'song.composition editor options.add composition section'
                )}
                className="btn-action-circular"
              >
                <SvgIcon code="icon-add" />
              </AddButton>
            </AbsoluteTop>
          )}

          <Line />

          <AddButton
            hideOnMobile={true}
            onClick={props.addSection}
            title={t('song.composition editor options.add composition section')}
            className="btn-action-circular"
          >
            <SvgIcon code="icon-add" />
          </AddButton>
        </Timeline>
      </GroupOptions>

      <GroupPaper>
        <PaperSheet
          noTopBorder={props.i > 0}
          noBottomBorder={props.i < props.compositionSections.length - 1}
        >
          <PaperContent>
            <SongLyricsAsText
              lyricSections={props.lyricSections}
              compositionSections={[props.compSection]}
              chords={props.chords}
              languageCode={props.songLanguageCode}
              textLyricDesign={{
                fontSize: 10,
                letterSpacing: 0,
                lineGapY: 0,
                sectionGapY: 0,

                showLyricChords: true,
                showLyricNotes: true,
                showSectionNotes: true,
              }}
              readOnly
            />
          </PaperContent>
        </PaperSheet>
      </GroupPaper>

      <GroupOptions isBottomMobile={true}>
        <Timeline isBottomMobile={true}>
          <Line />

          <AddButton
            hideOnDesktop={true}
            onClick={props.addSection}
            title={t('song.composition editor options.add composition section')}
            className="btn-action-circular"
          >
            <SvgIcon code="icon-add" />
          </AddButton>
        </Timeline>
      </GroupOptions>
    </Wrapper>
  )
}

export default CompSection
