import Droplist, { DisplayOptionsType } from 'components/Inputs/Droplist'
import {
  PlaylistMemberType,
  PlaylistSongType,
  PlaylistSongUserSettingsType,
} from 'types'
import {
  getPlaylistSongAdditionalText,
  getPlaylistSongUserSettings,
  getPlaylistSongUserStartEndNames,
  getPlaylistSongWithUpdatedUserSettings,
  getSongFromPlaylistSong,
  getSongName,
  getUsersFromPlaylistMembers,
} from 'utils'
import { useEffect, useRef, useState } from 'react'

import ContentBox from 'components/Boxes/ContentBox'
import FillScreenPaperWrapper from 'components/Song/Paper/FillScreenPaperWrapper'
import SimplifyInput from 'components/Inputs/SimplifyInput'
import SongPaperA4 from 'components/Song/Paper/SongPaperA4'
import { TEXT_LYRICS_DEFAULT_DESIGN } from 'const'
import TextInput from 'components/Inputs/TextInput'
import TransposeInput from 'components/Inputs/TransposeInput'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  align-self: center;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 20px;
  row-gap: 10px;
  padding: 2%;
`

const SongInfo = styled.div`
  min-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${(props) => props.theme.breakpoint.XS} {
    min-width: 100%;
  }
`

const SongPreview = styled.div`
  flex: 0.75;
  min-width: 300px;

  ${(props) => props.theme.breakpoint.M} {
    flex: 1;
  }

  ${(props) => props.theme.breakpoint.XS} {
    min-width: 100%;
  }
`

const SettingsLabel = styled.div`
  border-top: 1px solid ${(props) => props.theme.neutral}44;
  padding-top: 4px;
  margin-top: 20px;
  margin-bottom: -8px;
  font-size: 15px;
`

const SettingsDescription = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.neutral};
  margin-bottom: 8px;
`

const Row = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;

  & > * {
    min-width: 150px;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const PlaylistSong = (props: {
  playlistSong: PlaylistSongType
  playlistSongs: PlaylistSongType[]
  setPlaylistSongs: (playlistSongs: PlaylistSongType[]) => void
  playlistMembers: PlaylistMemberType[]
  setOpenBox?: (isOpen: boolean) => void
  forcedIsOpen?: boolean
  onDeleteCallback?: () => void
}) => {
  const { t } = useTranslation()

  const [userSettings, setUserSettings] =
    useState<PlaylistSongUserSettingsType>(getPlaylistSongUserSettings())

  const song = getSongFromPlaylistSong(props.playlistSong)

  //
  // UTILS
  //
  const deleteSection = () => {
    props.setPlaylistSongs(
      [...props.playlistSongs].filter(
        (s) => s.songId !== props.playlistSong.songId
      )
    )
    props.onDeleteCallback && props.onDeleteCallback()
  }

  const moveSection = (offset) => {
    const index = props.playlistSongs.findIndex(
      (s) => s.songId === props.playlistSong.songId
    )
    const finalIndex = Math.min(
      props.playlistSongs.length,
      Math.max(0, index + offset)
    )
    const updatedPlaylistSongs = [...props.playlistSongs]
    var element = updatedPlaylistSongs[index]
    updatedPlaylistSongs.splice(index, 1)
    updatedPlaylistSongs.splice(finalIndex, 0, element)
    props.setPlaylistSongs(updatedPlaylistSongs)
  }

  //
  // USER SETTINGS
  //
  const playlistSongRef = useRef(props.playlistSong)
  const playlistSongsRef = useRef(props.playlistSongs)

  useEffect(() => {
    setUserSettings(getPlaylistSongUserSettings(props.playlistSong))
    playlistSongRef.current = props.playlistSong
    playlistSongsRef.current = props.playlistSongs
  }, [props.playlistSong, props.playlistSongs])

  const updatePlaylistSong = (playlistSong: PlaylistSongType) => {
    props.setPlaylistSongs(
      [...playlistSongsRef.current].map((s) =>
        s.songId === playlistSong.songId ? playlistSong : s
      )
    )
  }

  const updateUserSettings = (updatedSongSettings: {
    note?: string
    transpose?: number
    simplifyChords?: boolean
  }) => {
    updatePlaylistSong(
      getPlaylistSongWithUpdatedUserSettings(
        playlistSongRef.current,
        updatedSongSettings
      )
    )
  }

  const setTranspose = (value) => {
    updateUserSettings({ transpose: value })
  }

  const setSimplifyChords = (value) => {
    updateUserSettings({ simplifyChords: value })
  }

  const setNote = (value) => {
    updateUserSettings({ note: value })
  }

  const setCapoOffset = (value) => {
    updatePlaylistSong({
      ...playlistSongRef.current,
      capoOffset: value,
    })
  }

  //
  // PLAY SETTINGS
  //
  const updatePlaySettings = (options: {
    songStartUserId: number
    songEndUserId: number
  }) => {
    updatePlaylistSong({
      ...props.playlistSong,
      playSettings: {
        songStartUserId: options.songStartUserId,
        songEndUserId: options.songEndUserId,
      },
    })
  }

  const setSongStartUserId = (id: number) => {
    updatePlaySettings({
      songStartUserId: id,
      songEndUserId: props.playlistSong?.playSettings?.songEndUserId,
    })
  }

  const setSongEndUserId = (id: number) => {
    updatePlaySettings({
      songStartUserId: props.playlistSong?.playSettings?.songStartUserId,
      songEndUserId: id,
    })
  }

  const renderUserMemberDroplist = (options: {
    userId: number
    setUserId: (v: number) => void
    label: string
  }) => {
    const usersWhoAreMembers = getUsersFromPlaylistMembers(
      props.playlistMembers
    )

    const droplistOptions: DisplayOptionsType = usersWhoAreMembers.map(
      (user) => {
        return {
          id: user.id,
          name: user.username || user.name,
        }
      }
    )

    return (
      <Droplist
        options={droplistOptions}
        value={options.userId}
        onChange={(userId) => options.setUserId(userId as number)}
        label={options.label}
        placeholder={t('playlist.song.player placeholder')}
        placeholderSelectable
        height="52px"
      />
    )
  }

  //
  // RENDER
  //
  return (
    <ContentBox
      title={getSongName(song, { authorInParentheses: true })}
      largeTitle
      onClickUp={() => moveSection(-1)}
      onClickDown={() => moveSection(1)}
      onClickDelete={deleteSection}
      forcedIsOpen={props.forcedIsOpen}
      callback={props.setOpenBox}
    >
      <Wrapper>
        {song ? (
          <>
            <SongInfo>
              <Row>
                {renderUserMemberDroplist({
                  userId: props.playlistSong?.playSettings?.songStartUserId,
                  setUserId: setSongStartUserId,
                  label: t('playlist.song.player start'),
                })}
                {renderUserMemberDroplist({
                  userId: props.playlistSong?.playSettings?.songEndUserId,
                  setUserId: setSongEndUserId,
                  label: t('playlist.song.player end'),
                })}
              </Row>

              <TransposeInput
                value={props.playlistSong.capoOffset || 0}
                setValue={setCapoOffset}
                noBackground
                isCapo
              />

              <SettingsLabel>{t('playlist.song.user settings')}</SettingsLabel>
              <SettingsDescription>
                {t('playlist.song.user settings description')}
              </SettingsDescription>

              <TransposeInput
                value={userSettings.transpose}
                setValue={setTranspose}
                noBackground
              />

              <SimplifyInput
                value={userSettings.simplifyChords}
                setValue={setSimplifyChords}
                noBackground
              />

              <TextInput
                placeholder={t('playlist.song.note')}
                value={userSettings.note}
                setValue={setNote}
                isMultiline
                isResizable
                height="90px"
                liveUpdate
              />
            </SongInfo>

            <SongPreview>
              <FillScreenPaperWrapper>
                <SongPaperA4
                  id={`song_paper_${props.playlistSong.songId}`}
                  generalInfo={song}
                  chords={song.chords}
                  lyricSections={song.lyricSections}
                  compositionSections={song.compositionSections}
                  textLyricDesign={
                    song.textLyricDesign || {
                      ...TEXT_LYRICS_DEFAULT_DESIGN,
                    }
                  }
                  readOnly
                  transpose={userSettings.transpose}
                  setTranspose={setTranspose}
                  simplifyChords={userSettings.simplifyChords}
                  setSimplifyChords={setSimplifyChords}
                  capoOffset={props.playlistSong.capoOffset || 0}
                  setCapoOffset={setCapoOffset}
                  zoomSongBody={song}
                  topHeaderText={getPlaylistSongAdditionalText({
                    index:
                      props.playlistSongs.findIndex(
                        (s) => s.songId === props.playlistSong.songId
                      ) + 1,
                    songCount: props?.playlistSongs?.length,
                  })}
                  userStart={
                    getPlaylistSongUserStartEndNames({
                      playSettings: props.playlistSong?.playSettings,
                      playlistMembers: props.playlistMembers,
                    }).userStart
                  }
                  userEnd={
                    getPlaylistSongUserStartEndNames({
                      playSettings: props.playlistSong?.playSettings,
                      playlistMembers: props.playlistMembers,
                    }).userEnd
                  }
                />
              </FillScreenPaperWrapper>
            </SongPreview>
          </>
        ) : (
          <div> {'?'}</div>
        )}
      </Wrapper>
    </ContentBox>
  )
}

export default PlaylistSong
