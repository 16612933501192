import { SongChordType, SongType } from 'types'

import { APP_LANGUAGE_CODES } from './app'
import { TEXT_LYRICS_DEFAULT_DESIGN } from 'const'

export const CHORD_MARK_NEXT: SongChordType = {
  id: -1,
  name: '→',
}

export const getChordOptions = (chords: SongChordType[]) => {
  // NOTE: ALL ADDITIONAL CHORD OPTIONS HAVE TO BE AT THE END (OTHERWISE IT SCREWS UP THE INDEXING OF USER-DEFINED CHORDS IN EDITOR)
  return [...(chords || []), CHORD_MARK_NEXT] as SongChordType[]
}

export const EMPTY_SONG: SongType = {
  name: '',
  author: '',
  languageCode: APP_LANGUAGE_CODES.en,
  capo: 0,
  key: '',
  bpm: 0,
  timeSignature: '',
  note: '',

  chords: [],
  instrumentSections: [],
  lyricSections: [],
  compositionSections: [],
  textLyricDesign: { ...TEXT_LYRICS_DEFAULT_DESIGN },
}

export const SONG_ROLES: {
  admin: string
  member: string
} = {
  admin: 'SONG_admin',
  member: 'SONG_member',
}
