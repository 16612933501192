import EmailTemplate, {
  EMAIL_LANGUAGE_OPTIONS,
  EmailLanguageType,
} from 'components/EmailTemplate'
import { TEST_EMAIL_DATA, sendTestEmail } from 'utils'

import Droplist from 'components/Inputs/Droplist'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleWrapper from '../Layout/ModuleWrapper'
import StringInput from 'components/Inputs/StringInput'
import styled from 'styled-components'
import { useState } from 'react'

const TestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 400px;
`

const EmailViewportWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 100%;
  overflow: auto;
  padding: 100px;
  background: white;
`

const DevModuleEmail = (props: { isOpenDefault?: boolean }) => {
  const [emailTo, setEmailTo] = useState<string>('jan.hamernik+dev@gmail.com')
  const [subject, setSubject] = useState<string>('Hello There')
  const [lang, setLang] = useState<EmailLanguageType>(EMAIL_LANGUAGE_OPTIONS[0])

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="Email Template"
      subtitle="email template, button for testing sending emails"
      isOpen={props.isOpenDefault}
    >
      <ModuleSectionTitle
        title="Functionality Test"
        subtitle="Send email to provided email address"
      />

      <TestWrapper>
        <StringInput label="Email To" value={emailTo} setValue={setEmailTo} />

        <StringInput
          label="Email Subject"
          value={subject}
          setValue={setSubject}
        />

        <Droplist
          label="Language"
          options={EMAIL_LANGUAGE_OPTIONS}
          value={lang}
          onChange={(v) => setLang(v as EmailLanguageType)}
        />

        <button onClick={() => sendTestEmail({ emailTo, subject, lang })}>
          Send test email
        </button>
      </TestWrapper>

      <ModuleSectionTitle
        title="Email Template"
        subtitle="This is what the user will see in the received email"
      />

      <EmailViewportWrapper>
        <EmailTemplate {...TEST_EMAIL_DATA} lang={lang} />
      </EmailViewportWrapper>
    </ModuleWrapper>
  )
}

export default DevModuleEmail
