import { forwardRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import { ActionButton } from 'styles/GlobalStyle/Buttons'
import FlipMove from 'react-flip-move'
import InputLabel from 'components/Inputs/_InputLabel'
import { SongChordType } from 'types'
import SvgIcon from 'components/SvgIcon'
import { defaultInputCss } from 'styles/GlobalStyle/Inputs'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Box = styled.div`
  ${defaultInputCss}
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const Row = styled.div`
  flex: 1;
  display: flex;
`

const RowCenter = styled.div`
  margin: auto;
  display: flex;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.black}22;
`

const ChordSelection = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const ChordSequence = styled.div`
  flex: 1;
  min-height: 32px;

  & > * {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
`

const SequenceItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.black}0A;
  min-height: 30px;

  span {
    color: ${(props) => props.theme.black};
    font-size: 12px;
    font-weight: 600;
    padding-left: 8px;
  }
`

const ChordSeparatorAnimationIn = keyframes`
 0% {opacity: 0}
 100% {opacity: 1}
`

const ChordSeparator = styled.div`
  flex: 1;
  display: flex;
  color: ${(props) => props.theme.black}44;
  svg {
    width: 20px;
    margin: auto;
  }
  animation-name: ${ChordSeparatorAnimationIn};
  animation-timing-function: ease;
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
`

const ChordWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Chord = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
  min-width: 20px;
  cursor: pointer;

  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.black}11;
  background: ${(props) => props.theme.black}0A;
  min-height: 30px;

  color: ${(props) => props.theme.black};
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
`

/**
 *
 *  UTILS, TYPES
 *
 */
export type SequenceItemType = { sequenceId: number; chordId: number }

export const rotateChordSequenceGlobal = (props: {
  chordSequence: SequenceItemType[]
  setChordSequence: (sequence: SequenceItemType[]) => void
  offset: number
}) => {
  props.offset = props.offset % props.chordSequence.length
  props.setChordSequence([
    ...props.chordSequence
      .slice(props.offset, props.chordSequence.length)
      .concat(props.chordSequence.slice(0, props.offset)),
  ])
}

//
// ANIMATED ITEM (NEEDS TO BE OUTSIDE THE COMPONENTS, OTHERWISE RE-RENDERS AND LOSES INPUT FOCUS ETC.)
//
const AnimatedItem = forwardRef(
  (
    props: {
      index
      chordSequence
      chordName
      removeChordFromSequence
      t: any
    },
    ref
  ) => {
    return (
      <ChordWrapper ref={ref as any}>
        <SequenceItem>
          <span style={{ opacity: props.index > 0 ? 0.6 : 1 }}>
            {props.chordName}
          </span>
          <ActionButton
            onClick={() => props.removeChordFromSequence(props.index)}
            title={props
              .t('chord sequencer.remove chord tooltip')
              .replace('{chord}', props.chordName)}
          >
            <SvgIcon code="icon-close" />
          </ActionButton>
        </SequenceItem>

        {props.index < props.chordSequence.length - 1 && (
          <ChordSeparator>
            <SvgIcon code="icon-chevron-left" />
          </ChordSeparator>
        )}
      </ChordWrapper>
    )
  }
)

/**
 *
 *  COMPONENT
 *
 */
const ChordSequencer = (props: {
  chords: SongChordType[]
  chordSequence: SequenceItemType[]
  setChordSequence: (sequence: SequenceItemType[]) => void
}) => {
  const { t } = useTranslation()

  //
  // UPDATE SEQUENCE ON SONG CHORD CHANGES
  //
  useEffect(() => {
    const chordIds = props.chords.map((sc) => sc.id)
    const updatedSequence = [...props.chordSequence].filter((c) =>
      chordIds.includes(c.chordId)
    )
    props.setChordSequence(updatedSequence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chords])

  //
  // FUNCTIONALITY
  //
  const getUniqueItemId = () => {
    let i =
      (props.chordSequence?.length
        ? Math.max(...props.chordSequence.map((item) => item.sequenceId))
        : 0) + 1
    return i
  }

  const addChordToSequence = (chordId) => {
    const newItem: SequenceItemType = { sequenceId: getUniqueItemId(), chordId }
    props.setChordSequence([...props.chordSequence, newItem])
  }

  const removeChordFromSequence = (i: number) => {
    let arr = []
    props.chordSequence.forEach((item, index) => {
      if (index !== i) {
        arr.push(item)
      }
    })
    props.setChordSequence(arr)
  }

  const clearChordSequence = () => {
    props.setChordSequence([])
  }

  const rotateChordSequence = (offset: number) => {
    rotateChordSequenceGlobal({
      chordSequence: props.chordSequence,
      setChordSequence: props.setChordSequence,
      offset,
    })
  }

  //
  // RENDER
  //
  return (
    <Wrapper>
      <InputLabel label={t('chord sequencer.name')} />
      <Box>
        <Row>
          <ChordSelection>
            {props.chords.map((chord, i) => (
              <Chord
                key={i}
                onClick={() => addChordToSequence(chord.id)}
                title={t('chord sequencer.add chord tooltip').replace(
                  '{chord}',
                  chord.name || '?'
                )}
              >
                {chord.name || '?'}
              </Chord>
            ))}
          </ChordSelection>
        </Row>

        <Separator />

        <Row>
          <ChordSequence>
            <FlipMove
              easing="ease"
              duration={160} // milliseconds
              staggerDurationBy={100} // milliseconds
              staggerDelayBy={0} // milliseconds
              maintainContainerHeight={false}
            >
              {props.chordSequence.map((sequenceItem, i) => (
                <AnimatedItem
                  key={sequenceItem.sequenceId}
                  chordSequence={props.chordSequence}
                  chordName={
                    props.chords.find((c) => c.id === sequenceItem.chordId)
                      ?.name
                  }
                  removeChordFromSequence={removeChordFromSequence}
                  index={i}
                  t={t}
                />
              ))}
            </FlipMove>
          </ChordSequence>
        </Row>

        <RowCenter>
          <ActionButton
            onClick={() => rotateChordSequence(1)}
            title={t('chord sequencer.rotate sequence left tooltip')}
          >
            <SvgIcon code="icon-chevron-left" />
          </ActionButton>
          <ActionButton
            onClick={() => rotateChordSequence(-1)}
            title={t('chord sequencer.rotate sequence right tooltip')}
          >
            <SvgIcon code="icon-chevron-right" />
          </ActionButton>
          <ActionButton
            onClick={() => clearChordSequence()}
            title={t('chord sequencer.clear sequence tooltip')}
          >
            <SvgIcon code="icon-close" />
          </ActionButton>
        </RowCenter>
      </Box>
    </Wrapper>
  )
}

export default ChordSequencer
