/* eslint-disable react-hooks/exhaustive-deps */

import { DBSongType, UserGroupType } from 'types'
import {
  DELETE_USER_GROUP,
  GET_SONGS,
  GET_USER_GROUP,
  GET_USER_GROUPS,
  GET_USER_GROUP_DELETE_CHECK,
} from 'api/graphql'
import { ROUTES, USER_GROUP_ROLES } from 'const'
import { getTabName, getUserGroupAvatar } from 'utils'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import AvatarExplodedView from '../../Boxes/AvatarExplodedView'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import { Redirect } from 'react-router-dom'
import UserCard from 'components/User/UserCard'
import confirmedAction from 'modals/actions/confirmedAction'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Description = styled.div`
  margin: auto;
  flex: 1;
  max-width: 600px;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.black}AA;
  padding: 10px;
  background: ${(props) => props.theme.background};
  text-shadow: 0px 5px 20px ${(props) => props.theme.black}22;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const UserGroupDetail = (props: {
  userGroupId: string
  setOutsideUserData?: (playlist: UserGroupType) => void
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [userGroup, setUserGroup] = useState<UserGroupType>()

  useEffect(() => {
    document.title = getTabName(userGroup?.name)
  }, [userGroup?.name])

  //
  // QUERY USER GROUP DATA
  //
  const { data: fetchedUserGroupData, loading } = useQuery(GET_USER_GROUP, {
    variables: { id: props.userGroupId },
  })

  useEffect(() => {
    if (fetchedUserGroupData) {
      if (fetchedUserGroupData?.getUserGroup) {
        setUserGroup(fetchedUserGroupData?.getUserGroup)
      }
    }
  }, [fetchedUserGroupData])

  useEffect(() => {
    if (props.setOutsideUserData) {
      props.setOutsideUserData(userGroup)
    }
  }, [userGroup])

  //
  // CHECK BEFORE DELETE
  //
  const [checkBeforeDelete, setCheckBeforeDelete] = useState<boolean>()
  const [deleteSongs, setDeleteSongs] = useState<DBSongType[]>()

  const { refetch: refetchDeleteCheck, data: deleteCheck } = useQuery(
    GET_USER_GROUP_DELETE_CHECK,
    {
      variables: {
        userGroupId: userGroup?.id,
      },
      skip: !checkBeforeDelete || !userGroup,
    }
  )

  useEffect(() => {
    if (checkBeforeDelete) {
      refetchDeleteCheck()
    }
  }, [checkBeforeDelete])

  useEffect(() => {
    if (deleteCheck?.getUserGroupDeleteCheck) {
      setDeleteSongs(deleteCheck?.getUserGroupDeleteCheck?.songsToDelete)
    }
  }, [deleteCheck])

  useEffect(() => {
    if (checkBeforeDelete && deleteSongs) {
      safelyDeleteUserGroup(userGroup, deleteSongs)
    }
  }, [deleteSongs])

  //
  // DELETE USER GROUP
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: refetchedUserGroups } =
    useQuery(GET_USER_GROUPS) /* NECESSARY FOR REFETCH */

  const [deleteUserGroup, { data: delData }] = useMutation(DELETE_USER_GROUP, {
    refetchQueries: [GET_USER_GROUPS, GET_SONGS],
  })

  useEffect(() => {
    if (delData?.deleteUserGroup) {
      if (delData?.deleteUserGroup?.success) {
        toast.success(t(delData?.deleteUserGroup?.message))
        history.push(ROUTES.users.slug)
      } else {
        toast.error(t(delData?.deleteUserGroup?.message))
      }
    }
  }, [delData])

  //
  // SAFELY DELETE USER GROUP
  //
  const safelyDeleteUserGroup = (
    userGroup: UserGroupType,
    songsToDelete: DBSongType[]
  ) => {
    const songNames = songsToDelete.map((s) => `'${s.name}'`).join('\n')

    confirmedAction({
      title: t('user group.delete.title'),
      description: t('user group.delete.description').replace(
        '{GROUP}',
        userGroup.name
      ),
      descriptionWarning: songsToDelete.length
        ? t('user group.delete.description songs').replace('{SONGS}', songNames)
        : undefined,
      okText: songsToDelete.length
        ? t('user group.delete.yes with songs')
        : t('user group.delete.yes'),
      cancelText: t('user group.delete.no'),
      onSubmit: () => {
        deleteUserGroup({ variables: { id: userGroup.id } })
      },
    })

    setCheckBeforeDelete(undefined)
    setDeleteSongs(undefined)
  }

  //
  // RENDER
  //
  if (!loading && !fetchedUserGroupData) {
    return <Redirect to={ROUTES.playlists.slug} />
  }

  return (
    <LoaderPageLayout
      title={userGroup?.name}
      subtitle={undefined}
      isPrivate={false}
      loadingText={t('user group.loading user group data')}
      isLoading={loading}
      isReady={!!userGroup}
    >
      {userGroup && (
        <>
          {userGroup.description && (
            <Description>{userGroup.description}</Description>
          )}

          <AvatarExplodedView
            avatar={getUserGroupAvatar(userGroup)}
            userGroup={userGroup}
            items2={userGroup.userGroupMembers.map((m) => {
              return {
                component: (
                  <UserCard
                    user={m.user}
                    useLink
                    noStretch
                    noBackground
                    badge={
                      m.groupRole?.code === USER_GROUP_ROLES.admin
                        ? 'edit'
                        : undefined
                    }
                  />
                ),
              }
            })}
          />

          {/* MOVED TO FLOATING MENU */}
          {/* <UserGroupDeleteButton userGroup={userGroup} /> */}
        </>
      )}
    </LoaderPageLayout>
  )
}

export default UserGroupDetail
