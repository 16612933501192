import { SongInstrumentType, StringTuningType } from 'types'

import SvgIcon from 'components/SvgIcon'

/** HOW TO ADD A NEW INSTRUMENT:
 *
 * below:
 *
 * 1) add its type
 * 2) add instrument code + TRANSLATION
 * 3) add instrument to group (string instrument codes, e.g.)
 * 4) add standard tuning
 * 5) add to song instruments (to be available in editor)
 * 6) add to song instrument icons
 *
 */

export type InstrumentCodeType =
  | 'guitar'
  | 'electricGuitar'
  | 'bassGuitar'
  | 'ukulele'
  | 'baritoneUkulele'
  | 'piano'

export const INSTRUMENT_CODES: {
  guitar: InstrumentCodeType
  electricGuitar: InstrumentCodeType
  bassGuitar: InstrumentCodeType
  ukulele: InstrumentCodeType
  baritoneUkulele: InstrumentCodeType
  piano: InstrumentCodeType
} = {
  guitar: 'guitar',
  electricGuitar: 'electricGuitar',
  bassGuitar: 'bassGuitar',
  ukulele: 'ukulele',
  baritoneUkulele: 'baritoneUkulele',
  piano: 'piano',
}

export const STRING_INSTRUMENT_CODES: string[] = [
  INSTRUMENT_CODES.guitar,
  INSTRUMENT_CODES.electricGuitar,
  INSTRUMENT_CODES.bassGuitar,
  INSTRUMENT_CODES.ukulele,
  INSTRUMENT_CODES.baritoneUkulele,
]

export const KEYBOARD_INSTRUMENT_CODES: string[] = [INSTRUMENT_CODES.piano]

export const STRING_INSTRUMENT_TUNINGS: {
  guitar: StringTuningType[]
  electricGuitar: StringTuningType[]
  bassGuitar: StringTuningType[]
  ukulele: StringTuningType[]
} = {
  guitar: [
    {
      key: 'standard',
      name: 'Standard',
      notes: ['E', 'B', 'G', 'D', 'A', 'E'], // in reverse order
    },
    {
      key: 'drop-d',
      name: 'Drop-D',
      notes: ['E', 'B', 'G', 'D', 'A', 'D'],
    },
    {
      key: 'open-a',
      name: 'Open-A',
      notes: ['E', 'A', 'E', 'C#', 'A', 'E'],
    },
    {
      key: 'open-b',
      name: 'Open-B',
      notes: ['D#', 'B', 'F#', 'B', 'F#', 'B'],
    },
    {
      key: 'open-c',
      name: 'Open-C',
      notes: ['E', 'C', 'G', 'C', 'G', 'C'],
    },
    {
      key: 'open-d',
      name: 'Open-D',
      notes: ['D', 'A', 'F#', 'D', 'A', 'D'],
    },
    {
      key: 'open-e',
      name: 'Open-E',
      notes: ['E', 'B', 'G#', 'E', 'B', 'E'],
    },
    {
      key: 'open-f',
      name: 'Open-F',
      notes: ['F', 'A', 'F', 'C', 'F', 'C'],
    },
    {
      key: 'open-g',
      name: 'Open-G',
      notes: ['D', 'B', 'G', 'D', 'G', 'D'],
    },
    {
      key: 'daeaae',
      name: 'DAEAAE',
      notes: ['E', 'A', 'A', 'E', 'A', 'D'],
    },
  ],
  electricGuitar: [
    {
      key: 'standard',
      name: 'Standard',
      notes: ['E', 'B', 'G', 'D', 'A', 'E'],
    },
  ],
  bassGuitar: [
    { key: 'standard', name: 'Standard', notes: ['G', 'D', 'A', 'E'] },
  ],
  ukulele: [
    { key: 'soprano', name: 'Soprano', notes: ['A', 'E', 'C', 'G'] },
    { key: 'baritone', name: 'Baritone', notes: ['D', 'G', 'B', 'E'] },
    { key: 'd-tuning', name: 'D-Tuning', notes: ['B', 'F#', 'D', 'A'] },
    { key: 'slack-key', name: 'Slack-Key', notes: ['G', 'E', 'C', 'G'] },
  ],
}

//
// SONG INSTRUMENTS
//
export const SONG_INSTRUMENTS: {
  guitar: SongInstrumentType
  electricGuitar: SongInstrumentType
  bassGuitar: SongInstrumentType
  ukulele: SongInstrumentType
  baritoneUkulele: SongInstrumentType
  piano: SongInstrumentType
} = {
  guitar: {
    code: INSTRUMENT_CODES.guitar,
    tuning: STRING_INSTRUMENT_TUNINGS.guitar[0],
    chords: [],
    translation: {
      en: 'Guitar',
      cz: 'Kytara',
      sk: 'Gitara',
    },
  },
  electricGuitar: {
    code: INSTRUMENT_CODES.electricGuitar,
    tuning: STRING_INSTRUMENT_TUNINGS.electricGuitar[0],
    chords: [],
    translation: {
      en: 'Electric Guitar',
      cz: 'Elektrická kytara',
      sk: 'Elektrická gitara',
    },
  },
  bassGuitar: {
    code: INSTRUMENT_CODES.bassGuitar,
    tuning: STRING_INSTRUMENT_TUNINGS.bassGuitar[0],
    chords: [],
    translation: {
      en: 'Bass Guitar',
      cz: 'Baskytara',
      sk: 'Basgitara',
    },
  },
  ukulele: {
    code: INSTRUMENT_CODES.ukulele,
    tuning: STRING_INSTRUMENT_TUNINGS.ukulele.find((t) => t.key === 'soprano'),
    chords: [],
    translation: {
      en: 'Ukulele',
      cz: 'Ukulele',
      sk: 'Ukulele',
    },
  },
  baritoneUkulele: {
    code: INSTRUMENT_CODES.baritoneUkulele,
    tuning: STRING_INSTRUMENT_TUNINGS.ukulele.find((t) => t.key === 'baritone'),
    chords: [],
    translation: {
      en: 'Baritone Ukulele',
      cz: 'Barytonové ukulele',
      sk: 'Barytonové ukulele',
    },
  },
  piano: {
    code: INSTRUMENT_CODES.piano,
    chords: [],
    translation: {
      en: 'Piano',
      cz: 'Klavír',
      sk: 'Klavír',
    },
  },
}

//
// INSTRUMENT ICONS
//
export const SONG_INSTRUMENT_ICONS: { code: string; icon: any }[] = [
  {
    code: INSTRUMENT_CODES.guitar,
    icon: <SvgIcon code="icon-instrument-guitar" />,
  },
  {
    code: INSTRUMENT_CODES.electricGuitar,
    icon: <SvgIcon code="icon-instrument-electric-guitar" />,
  },
  {
    code: INSTRUMENT_CODES.bassGuitar,
    icon: <SvgIcon code="icon-instrument-bass-guitar" />,
  },
  {
    code: INSTRUMENT_CODES.ukulele,
    icon: <SvgIcon code="icon-instrument-ukulele" />,
  },
  {
    code: INSTRUMENT_CODES.baritoneUkulele,
    icon: <SvgIcon code="icon-instrument-baritone-ukulele" />,
  },
  {
    code: INSTRUMENT_CODES.piano,
    icon: <SvgIcon code="icon-instrument-piano" />,
  },
]

export const getInstrumentIcon = (instrumentCode) => {
  return SONG_INSTRUMENT_ICONS.find(
    (instrument) => instrument.code === instrumentCode
  )?.icon
}
