import {
  ColumnLayout,
  Separator,
  renderButtonControl,
  renderCounterControl,
  renderHamburgerMenu,
  renderToggleControl,
} from '..'
import { useRef, useState } from 'react'

import SvgIcon from 'components/SvgIcon'
import { TEXT_LYRICS_DEFAULT_DESIGN } from 'const'
import { TextLyricDesignType } from 'types'
import { useClickOutside } from 'hooks'
import { useTranslation } from 'react-i18next'

const TextLyricDesignController = (props: {
  textLyricsDesign: TextLyricDesignType
  setTextLyricsDesign: (textLyricsDesign: TextLyricDesignType) => void
  textLyricsDesignDefault?: TextLyricDesignType
  compactMode?: boolean
}) => {
  const { t } = useTranslation()

  //
  // MENU OVERLAY
  //
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const menuRef = useRef()

  useClickOutside(menuRef, () => {
    setIsOpen(false)
  })

  //
  // RENDER
  //
  const renderMenu = () => {
    return (
      <>
        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-padding-top" />,
          prop: 'contentPaddingTop',
          options: { valueMin: -5000, valueMax: 5000, step: 25 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-padding-left" />,
          prop: 'contentPaddingLeft',
          options: { valueMin: -5000, valueMax: 5000, step: 25 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-height" />,
          prop: 'contentHeight',
          options: { valueMin: 0, valueMax: 3500, step: 25 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-column-gap" />,
          prop: 'columnGap',
          options: { valueMin: -2000, valueMax: 2000, step: 25 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-scale-x" />,
          prop: 'scaleX',
          options: { valueMin: 1, valueMax: 200, step: 5 },
          t,
        })}

        <Separator>
          <SvgIcon code="icon-paper-design-separator" />
        </Separator>

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-font-size" />,
          prop: 'fontSize',
          options: { valueMin: 1, valueMax: 50 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-letter-spacing" />,
          prop: 'letterSpacing',
          options: { valueMin: -10, valueMax: 10 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-line-gap-y" />,
          prop: 'lineGapY',
          options: { valueMin: 0, valueMax: 200, step: 5 },
          t,
        })}

        {renderCounterControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: <SvgIcon code="icon-paper-design-section-gap-y" />,
          prop: 'sectionGapY',
          options: { valueMin: 0, valueMax: 200, step: 10 },
          t,
        })}

        <Separator>
          <SvgIcon code="icon-paper-design-separator" />
        </Separator>

        {renderToggleControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: 'chords',
          prop: 'showLyricChords',
          t,
        })}

        {renderToggleControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: 'notes',
          prop: 'showLyricNotes',
          t,
        })}

        {renderToggleControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: 'section notes',
          prop: 'showSectionNotes',
          t,
        })}

        <Separator>
          <SvgIcon code="icon-paper-design-separator" />
        </Separator>

        {renderButtonControl({
          design: props.textLyricsDesign,
          setDesign: props.setTextLyricsDesign,
          label: 'reset',
          icon: <SvgIcon code="icon-reset" />,
          onClick: () => {
            props.setTextLyricsDesign(
              props.textLyricsDesignDefault
                ? {
                    ...TEXT_LYRICS_DEFAULT_DESIGN,
                    ...props.textLyricsDesignDefault,
                  }
                : {
                    ...TEXT_LYRICS_DEFAULT_DESIGN,
                  }
            )
          },
          t,
        })}
      </>
    )
  }

  return props.compactMode ? (
    renderHamburgerMenu({
      isOpen,
      setIsOpen,
      renderMenu,
      menuRef: menuRef,
      t,
    })
  ) : (
    <ColumnLayout>{renderMenu()}</ColumnLayout>
  )
}

export default TextLyricDesignController
