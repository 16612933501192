import { DBSongType, SongCategoryType, SongType } from 'types'
import { File, ViewMode } from 'components/Files/FileSystem'
import FileSystemSongs, {
  ROOT_CATEGORY_ID,
} from 'components/Files/FileSystemSongs'
import {
  GET_LOGGED_USER,
  GET_SONGS,
  UPDATE_USER_SONG_CATEGORIES,
} from 'api/graphql'
import { LOGGED_USER_VAR, USER_SONG_LIST_VAR } from 'App'
import {
  hasSongPermission,
  idAsNumber,
  parseFetchedSongCategories,
} from 'utils'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import { FloatingButtonType } from 'components/App/FloatingMenu/FloatingButton'
import FloatingButtonWithMenu from 'components/App/FloatingMenu/FloatingButtonWithMenu'
import FloatingMenu from 'components/App/FloatingMenu'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import SongButtonDelete from 'components/Song/SongButtonDelete'
import SongButtonDuplicate from 'components/Song/SongButtonDuplicate'
import SongDetail from 'components/Song/SongDetail'
import SongEdit from 'components/Song/SongEdit'
import SvgIcon from 'components/SvgIcon'
import inputAction from 'modals/actions/inputAction'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const PARAM_NEW = 'new'
export const PARAM_NEW_FROM_TEXT = 'new-text'
export const PARAM_NEW_FROM_IMPORT = 'import'

const SongsPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const isAdminCreateUserFolders = true

  const user = useReactiveVar(LOGGED_USER_VAR)
  const songs = useReactiveVar(USER_SONG_LIST_VAR)

  //
  // QUERY ALL SONGS
  //
  const { data: dataSongs, loading: songsLoading } = useQuery(GET_SONGS)

  useEffect(() => {
    if (dataSongs?.getSongs) {
      USER_SONG_LIST_VAR(dataSongs.getSongs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSongs])

  //
  // STATE
  //
  const [songData, setSongData] = useState<DBSongType>()
  const [songCategories, setSongCategories] = useState<SongCategoryType[]>()
  const [importedSongBody, setImportedSongBody] = useState<SongType>()
  const [isUnselecting, setIsUnselecting] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<ViewMode>('grid')
  const [noFolders, setNoFolders] = useState<boolean>(false)

  const redirectAfterSubmitRef = useRef(false)

  //
  // DELEGATED FUNCTIONS
  //
  const [doSongSubmit, setDoSongSubmit] = useState<boolean>(false)
  const [doSongCancel, setDoSongCancel] = useState<boolean>(false)
  const [doFileSelect, setDoFileSelect] = useState<boolean>(false)

  const [doCreateFolder, setDoCreateFolder] =
    useState<{ folder: File; parentId?: number }>(undefined)

  //
  // NAVIGATION
  //
  const onFolderDoubleClick = (file: File) => {
    updateParams({ folderId: file.id, parentId: file.parentId })
  }

  const onFileDoubleClick = (file: File) => {
    setSongData(undefined)
    setEditParam('')
    updateParams({ song: file.id })
  }

  const onSongEditClick = () => {
    setEditParam('')
    updateParams({ edit: songParam })
  }

  const onSongAddClick = () => {
    setEditParam('')
    updateParams({ edit: PARAM_NEW })
  }

  const onSongFromTextAddClick = () => {
    setEditParam('')
    updateParams({ edit: PARAM_NEW_FROM_TEXT })
  }

  const onClose = () => {
    if (location.state?.onBackOnClose) {
      // handle closing song opened from different location (e.g. playlist)
      return history.goBack()
    }

    const searchParams = new URLSearchParams(location.search)
    const parentId = searchParams.get('parent')
    const folderId = searchParams.get('folder')

    let folderParams: any = {}
    if (folderId) {
      // necessary for opening song in a folder in a NEW TAB
      folderParams.folderId = folderId
      folderParams.parentId = parentId
    }

    if (
      editParam &&
      editParam !== PARAM_NEW &&
      editParam !== PARAM_NEW_FROM_TEXT &&
      editParam !== PARAM_NEW_FROM_IMPORT
    ) {
      setEditParam('')
      updateParams({ song: editParam, ...folderParams })
    } else {
      setEditParam('')
      updateParams({ ...folderParams })
    }
  }

  //
  // FETCH / STORE SONG CATEGORIES
  //
  const [updateUserSongCategories, { data: updatedSongCategoriesData }] =
    useMutation(UPDATE_USER_SONG_CATEGORIES, {
      refetchQueries: [GET_LOGGED_USER],
    })

  useEffect(() => {
    if (updatedSongCategoriesData?.updateUserSongCategories) {
      if (updatedSongCategoriesData?.updateUserSongCategories?.success) {
        toast.success(
          t(updatedSongCategoriesData?.updateUserSongCategories.message)
        )
      } else {
        toast.error(
          t(updatedSongCategoriesData?.updateUserSongCategories.message)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSongCategoriesData])

  const storeSongCategories = (categories: SongCategoryType[]) => {
    updateUserSongCategories({
      variables: {
        id: Number(user?.id),
        songCategories: JSON.stringify(
          categories.filter(
            (c) =>
              c.id !== ROOT_CATEGORY_ID &&
              c.id >= 0 /* negative ids are used for temp folders in admin */
          )
        ),
      },
    })
  }

  //
  // FUNCTIONS
  //
  const onFolderCreate = () => {
    inputAction({
      title: t('file_system.create_folder.title'),
      cancelText: t('file_system.create_folder.no'),
      okText: t('file_system.create_folder.yes'),
      inputNames: [
        t('file_system.create_folder.name'),
        t('file_system.create_folder.name2'),
      ],
      onSubmit: (values: string[]) => {
        const newFolder: File = {
          id: undefined,
          name: values[0],
          name2: values[1] && values[1] !== '' ? values[1] : undefined,
          type: 'folder',
        }

        setDoCreateFolder({
          folder: newFolder,
          parentId: folderIdParam?.length
            ? parseInt(folderIdParam)
            : ROOT_CATEGORY_ID,
        })
      },
    })
  }

  const addSongToGivenCategory = (options: {
    songCategories: SongCategoryType[]
    songId: string
    categoryId: string
  }) => {
    const { songId, categoryId } = options

    return [...options.songCategories].map((c) => {
      let updatedCategory: SongCategoryType = { ...c }

      if (idAsNumber(updatedCategory.id) === idAsNumber(categoryId)) {
        const songIds = [...updatedCategory.songIds, idAsNumber(songId)]
        updatedCategory = { ...updatedCategory, songIds }
      }

      return updatedCategory
    })
  }

  const onSongSubmit = (newSongId?: string, folderId?: string) => {
    if (newSongId) {
      if (redirectAfterSubmitRef.current) {
        setEditParam('')
        updateParams({ song: newSongId })
      }

      if (folderId) {
        let originalSongCategories: SongCategoryType[] = songCategories

        if (!originalSongCategories) {
          const rootCategory: SongCategoryType = {
            id: ROOT_CATEGORY_ID,
            name: t('song categories.root_folder'),
            songIds: [],
          }

          originalSongCategories = parseFetchedSongCategories({
            user,
            rootCategory,
            allSongs: songs,
            isAdminCreateUserFolders,
          })
        }

        if (originalSongCategories) {
          /* Assign newly added song to the current folder */
          const updatedSongCategories: SongCategoryType[] =
            addSongToGivenCategory({
              songCategories: originalSongCategories,
              songId: newSongId,
              categoryId: folderId,
            })

          // changes need to be stored on BE
          storeSongCategories(updatedSongCategories)
        }
      }
    } else if (redirectAfterSubmitRef.current) {
      onClose()
    }
  }

  const onSongCancel = () => {
    if (editParam) {
      setDoSongCancel(true)
    }
  }

  const onSongDuplicate = (songBody: SongType) => {
    setImportedSongBody({
      ...songBody,
      name: (songBody?.name || '') + ' (2)',
    })
  }

  const onSongImport = (event) => {
    const file = event.target.files[0]
    if (file) {
      const readFile = new FileReader()
      readFile.onload = function (event) {
        var contents = event.target.result
        var importedSongBody = JSON.parse(contents as any)
        setImportedSongBody(importedSongBody)
      }
      readFile.readAsText(file)
    }
  }

  useEffect(() => {
    if (importedSongBody) {
      setEditParam('')
      updateParams({ edit: PARAM_NEW_FROM_IMPORT })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedSongBody])

  //
  // URL PARAMS
  //
  const [songParam, setSongParam] = useState('')
  const [editParam, setEditParam] = useState('')
  const [parentIdParam, setParentIdParam] = useState('')
  const [folderIdParam, setFolderIdParam] = useState(`${ROOT_CATEGORY_ID}`)

  const createSongsUrlParams = (options: {
    song?: any
    edit?: any
    parentId?: any
    folderId?: any
  }) => {
    const { song, edit, parentId, folderId } = options
    let searchParams: {
      song?: any
      edit?: any
      parent?: any
      folder?: any
    } = {}

    /* Pre-existing location */
    if (parentIdParam?.length) {
      searchParams.parent = parentIdParam
    }
    if (folderIdParam?.length) {
      searchParams.folder = folderIdParam
    }

    /* New location */
    if (parentId || parentId === 0) {
      searchParams.parent = parentId
    }
    if (folderId || folderId === 0) {
      searchParams.folder = folderId
    }

    /* Root location */
    if (searchParams.folder === ROOT_CATEGORY_ID) {
      delete searchParams.folder
      delete searchParams.parent
    }

    if (song || song === 0) {
      searchParams.song = song
    }
    if (edit || edit === 0) {
      searchParams.edit = edit
      delete searchParams.song
    }
    return searchParams
  }

  const updateParams = (options: {
    song?: any
    edit?: any
    parentId?: any
    folderId?: any
  }) => {
    history.push({
      pathname: location.pathname,
      search: new URLSearchParams(createSongsUrlParams(options)).toString(),
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const songId = searchParams.get('song')
    const editId = searchParams.get('edit')
    const parentId = searchParams.get('parent')
    const folderId = searchParams.get('folder')

    setSongParam(songId || '')
    setEditParam(editId || '')

    if (folderId && parentId) {
      const currentSongCategory = songCategories?.find(
        (c) => c.id === parseInt(folderId)
      )

      if (
        currentSongCategory &&
        currentSongCategory.parentCategoryId === parseInt(parentId)
      ) {
        setParentIdParam(parentId)
        setFolderIdParam(folderId)
      } else {
        /* NON-EXISTENT FOLDER LOCATION */
        if (songCategories?.length) {
          history.replace({
            pathname: location.pathname,
            search: new URLSearchParams(
              createSongsUrlParams({
                folderId: ROOT_CATEGORY_ID,
                song: songId,
                edit: editId,
              })
            ).toString(),
          })
        }
      }
    } else {
      setParentIdParam('')
      setFolderIdParam('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, songCategories])

  //
  // FLOATING MENU
  //
  const renderFloatingMenu = () => {
    const filesVisible = songParam || editParam ? false : true

    const buttons: FloatingButtonType[] = [
      {
        component: (
          <FloatingButtonWithMenu
            icon={<SvgIcon code="icon-add" />}
            buttons={[
              {
                onClick: onSongImport,
                tooltip: t('upload.song json'),
                tooltipShort: t('upload.song json short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-import-json" />,
                upload: {
                  filetype: '.json',
                  multiple: false,
                },
                isVisible: true,
              },
              /* {
                onClick: () => alert('todo'),
                tooltip: t('song.add new song from image'),
                tooltipShort: t('song.add new song from image short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-photo" />,
                isVisible: true,
              }, */
              {
                onClick: onSongFromTextAddClick,
                tooltip: t('song.add new song from text'),
                tooltipShort: t('song.add new song from text short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-text" />,
                isVisible: true,
              },
              {
                onClick: onSongAddClick,
                tooltip: t('song.add new song'),
                tooltipShort: t('song.add new song short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-add" />,
                isVisible: true,
              },
            ]}
          />
        ),
        isVisible: !editParam,
      },

      {
        component: (
          <FloatingButtonWithMenu
            icon={<SvgIcon code="icon-edit" />}
            buttons={[
              {
                tooltipShortIsVisible: true,
                component: (
                  <SongButtonDelete
                    isFloatingButton
                    song={songData}
                    tooltipShortIsVisible={true}
                    isVisible={hasSongPermission(
                      songData?.songMembers,
                      'SONG_deleteSong'
                    )}
                    onDelete={onClose}
                  />
                ),
              },
              {
                tooltipShortIsVisible: true,
                component: (
                  <SongButtonDuplicate
                    isFloatingButton
                    song={songData}
                    tooltipShortIsVisible={true}
                    isVisible={hasSongPermission(
                      songData?.songMembers,
                      'SONG_duplicateSong'
                    )}
                    onDuplicate={onSongDuplicate}
                  />
                ),
              },
              {
                onClick: onSongEditClick,
                tooltip: t('song.edit'),
                tooltipShort: t('song.edit short'),
                tooltipShortIsVisible: true,
                icon: <SvgIcon code="icon-edit" />,
                isVisible: hasSongPermission(
                  songData?.songMembers,
                  'SONG_updateSong'
                ),
              },
            ]}
          />
        ),
        isVisible: !!(songParam && songData),
      },

      {
        onClick: () => {
          redirectAfterSubmitRef.current = true
          setDoSongSubmit(true)
        },
        tooltip:
          editParam === PARAM_NEW ||
          editParam === PARAM_NEW_FROM_TEXT ||
          editParam === PARAM_NEW_FROM_IMPORT
            ? t('song.save new song')
            : t('song.save edited song'),
        icon: <SvgIcon code="icon-save" />,
        isVisible:
          editParam &&
          (editParam === PARAM_NEW ||
            editParam === PARAM_NEW_FROM_TEXT ||
            editParam === PARAM_NEW_FROM_IMPORT ||
            (songData &&
              hasSongPermission(songData?.songMembers, 'SONG_updateSong'))),
      },

      {
        onClick: () => {
          redirectAfterSubmitRef.current = false
          setDoSongSubmit(true)
        },
        tooltip:
          editParam === PARAM_NEW ||
          editParam === PARAM_NEW_FROM_TEXT ||
          editParam === PARAM_NEW_FROM_IMPORT
            ? t('song.save new song without leaving')
            : t('song.save edited song without leaving'),
        icon: <SvgIcon code="icon-save-without-leaving" />,
        isVisible:
          editParam &&
          (editParam === PARAM_NEW ||
            editParam === PARAM_NEW_FROM_TEXT ||
            editParam === PARAM_NEW_FROM_IMPORT ||
            (songData &&
              hasSongPermission(songData?.songMembers, 'SONG_updateSong'))),
      },

      {
        onClick: onFolderCreate,
        tooltip: t('file_system.create_folder.label'),
        icon: <SvgIcon code="icon-file-system-create-folder" />,
        isVisible: filesVisible,
      },

      {
        onClick: () => setDoFileSelect(true),
        tooltip: isUnselecting
          ? t('file_system.unselect_all')
          : t('file_system.select_all'),
        icon: isUnselecting ? (
          <SvgIcon code="icon-file-system-deselect-files" />
        ) : (
          <SvgIcon code="icon-file-system-select-files" />
        ),
        isVisible: filesVisible,
      },

      {
        onClick: editParam ? onSongCancel : onClose,
        tooltip: t('close'),
        icon: <SvgIcon code="icon-close" />,
        isSubdued: true,
        isVisible: songParam || editParam ? true : false,
      },
    ]

    return <FloatingMenu buttons={buttons} />
  }

  //
  // RENDER
  //
  const doRenderSongDetail = () => songParam?.length > 0
  const doRenderSongEdit = () => editParam?.length > 0

  return (
    <>
      {/*
       ** VIEW - FILES
       */}
      {!doRenderSongDetail() && !doRenderSongEdit() && (
        <LoaderPageLayout
          title={t('navigation.songs')}
          isLoading={false}
          isReady={true}
        >
          <FileSystemSongs
            songCategories={songCategories}
            setSongCategories={setSongCategories}
            doCreateFolder={doCreateFolder}
            setDoCreateFolder={setDoCreateFolder}
            folderId={folderIdParam}
            parentId={parentIdParam}
            onFileDoubleClick={onFileDoubleClick}
            onFolderDoubleClick={onFolderDoubleClick}
            setIsUnselecting={setIsUnselecting}
            doFileSelect={doFileSelect}
            setDoFileSelect={setDoFileSelect}
            viewMode={viewMode}
            setViewMode={setViewMode}
            noFolders={noFolders}
            setNoFolders={setNoFolders}
            preloadedSongs={songs}
            preloadedSongsLoading={songsLoading}
            isAdminCreateUserFolders={isAdminCreateUserFolders}
            showFileCount
          />
        </LoaderPageLayout>
      )}

      {/*
       ** VIEW - SONG DETAIL
       */}
      {doRenderSongDetail() && (
        <SongDetail
          songId={songParam}
          onDelete={onClose}
          onDuplicate={onSongDuplicate}
          setOutsideSongData={setSongData}
        />
      )}

      {/*
       ** VIEW - SONG EDIT
       */}
      {doRenderSongEdit() && (
        <SongEdit
          songId={editParam}
          doSubmit={doSongSubmit}
          setDoSubmit={setDoSongSubmit}
          doCancel={doSongCancel}
          setDoCancel={setDoSongCancel}
          onSubmit={onSongSubmit}
          onCancel={onClose}
          setOutsideSongData={setSongData}
          importedSongBody={importedSongBody}
        />
      )}

      {renderFloatingMenu()}
    </>
  )
}

export default SongsPage
