/* eslint-disable react-hooks/exhaustive-deps */
import { APP_LANGUAGE_CODES, USER_GROUP_ROLES, USER_ROLES } from 'const'
import { UserGroupMemberType, UserGroupType, UserType } from 'types'

import { FIRST_NAMES } from 'assets/test/firstNames'
import FileSystemUsers from 'components/Files/FileSystemUsers'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import styled from 'styled-components'
import { useMemo } from 'react'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;

  & > *:last-child {
    max-width: 30%;
    min-width: 350px;
  }

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    & > *:last-child {
      max-width: 100%;
    }
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const DevModuleFileSystemUsers = (props: { isOpenDefault?: boolean }) => {
  const maxHeight = '50vh'

  const preloadedUsers: UserType[] = useMemo(
    () =>
      Array.apply(null, Array(20)).map((u, i) => {
        let name = `${
          FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]
        } ${FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]}`
        let username = ''

        if (Math.random() > 0.4) {
          username =
            FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]
        }

        return {
          id: i,
          email: '',
          name,
          username,
          role:
            i === 0 ? { code: USER_ROLES.admin } : { code: USER_ROLES.user },
          languageCode: APP_LANGUAGE_CODES.en,
        }
      }),
    []
  )

  const preloadedUserGroups: UserGroupType[] = useMemo(
    () =>
      Array.apply(null, Array(5)).map((u, i) => {
        let name =
          FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]

        return {
          id: i,
          name,
          userGroupMembers: Array.apply(
            null,
            Array(Math.round(Math.random() * 5))
          ).map((u, i) => {
            const user =
              preloadedUsers[
                Math.round(Math.random() * (preloadedUsers.length - 1))
              ]
            return {
              id: user.id,
              groupRole:
                i === 0
                  ? { code: USER_GROUP_ROLES.admin }
                  : { code: USER_GROUP_ROLES.member },
              user,
            } as UserGroupMemberType
          }),
        }
      }),
    []
  )

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="File System - Users"
      subtitle="File system for organizing users and user groups"
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        <Column>
          <ModuleSectionTitle title="User File System" subtitle="Gird View" />

          <FileSystemUsers
            preloadedUsers={preloadedUsers}
            preloadedUserGroups={preloadedUserGroups}
            defaultViewMode="grid"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileOpenClick={() => alert('onFileOpenClick')}
            onFolderDoubleClick={() => alert('onFolderDoubleClick')}
            onFolderOpenClick={() => alert('onFolderOpenClick')}
            maxHeight={maxHeight}
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          <ModuleSectionTitle title="User File System" subtitle="List View" />

          <FileSystemUsers
            preloadedUsers={preloadedUsers}
            preloadedUserGroups={preloadedUserGroups}
            defaultViewMode="list"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileOpenClick={() => alert('onFileOpenClick')}
            onFolderDoubleClick={() => alert('onFolderDoubleClick')}
            onFolderOpenClick={() => alert('onFolderOpenClick')}
            maxHeight={maxHeight}
          />
        </Column>
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleFileSystemUsers
