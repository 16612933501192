import { UserGroupMemberType, UserGroupType } from 'types'

import AvatarPlaceholderGroup from 'assets/avatar_group.jpg'
import { EmailLanguageType } from 'components/EmailTemplate'
import { USER_GROUP_ROLES } from 'const'

export const getUserGroupAvatar = (userGroup: UserGroupType) => {
  return userGroup?.avatar || AvatarPlaceholderGroup
}

export type UserFromUserGroupMemberType = {
  userId: number
  email: string
  lang: EmailLanguageType
  canEdit: boolean
}

export const getUsersFromUserGroupMembers = (
  userGroupMembers: UserGroupMemberType[]
) => {
  const users: UserFromUserGroupMemberType[] = [...userGroupMembers]
    .map((member) => {
      return {
        userId: member.user?.id,
        email: member.user?.email,
        lang: member.user?.languageCode as EmailLanguageType,
        canEdit: member.groupRole.code === USER_GROUP_ROLES.admin,
      }
    })
    .flat()

  const uniqueUsers: UserFromUserGroupMemberType[] = []

  users.forEach((user) => {
    if (!uniqueUsers.map((u) => u.userId).includes(user.userId)) {
      uniqueUsers.push(user)
    }
  })

  return uniqueUsers
}

export const trimUserGroupForComparison = (userGroup: UserGroupType) => {
  if (!userGroup) {
    return undefined
  }
  const { id, name } = userGroup
  return {
    id,
    name,
  } as UserGroupType
}
