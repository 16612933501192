import { gql } from '@apollo/client'

export const GET_USER_GROUPS = gql`
  query {
    getUserGroups {
      id
      name
      description
      avatar
      userGroupMembers {
        id
        groupRole {
          code
          userGroupPermissions {
            code
          }
        }
        user {
          id
          name
          username
          email
          avatar
          role {
            code
          }
        }
      }
    }
  }
`

export const SEARCH_USER_GROUPS = gql`
  query getUserGroups($search: String!, $excludeIds: [ID], $maxCount: Int) {
    getUserGroups(
      search: $search
      excludeIds: $excludeIds
      maxCount: $maxCount
    ) {
      id
      name
      description
      avatar
      userGroupMembers {
        id
        groupRole {
          code
          userGroupPermissions {
            code
          }
        }
        user {
          id
          name
          username
          email
          avatar
          role {
            code
          }
        }
      }
    }
  }
`

export const GET_USER_GROUP = gql`
  query getUserGroup($id: ID!) {
    getUserGroup(id: $id) {
      id
      name
      description
      avatar
      userGroupMembers {
        id
        groupRole {
          code
          userGroupPermissions {
            code
          }
        }
        user {
          id
          email
          languageCode
          name
          username
          email
          avatar
          role {
            code
          }
        }
      }
    }
  }
`

export const GET_USER_GROUP_DELETE_CHECK = gql`
  query getUserGroupDeleteCheck($userGroupId: ID) {
    getUserGroupDeleteCheck(userGroupId: $userGroupId) {
      songsToDelete {
        id
        name
      }
      playlistsToDelete {
        id
        name
      }
    }
  }
`
