import styled, { css } from 'styled-components'

import SvgIcon from 'components/SvgIcon'
import { useTranslation } from 'react-i18next'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  min-width: 220px;
`

export const ValueWrapper = styled.div<{
  roundCorners?: boolean
  noBackground?: boolean
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.black}22;
  border-radius: 2px;
  height: 50px;

  ${(props) =>
    props.roundCorners &&
    css`
      border-radius: 25px;
      padding: 0 4px;
    `}

  ${(props) =>
    props.noBackground &&
    css`
      background: none;
    `}
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  color: ${(props) => props.theme.black}88;

  --size: 25px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);

  ${(props) => props.theme.breakpoint.M} {
    --size: 35px;
    margin: 0 -2px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:last-child {
    padding-right: 5px;
  }

  &:hover {
    transform: scale(1.2);
    text-shadow: 0px 5px 20px ${(props) => props.theme.black}22;

    ${(props) => props.theme.breakpoint.M} {
      transform: none;
      text-shadow: none;
    }
  }
`

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 70%;

  border-right: 1px solid ${(props) => props.theme.black}22;

  color: ${(props) => props.theme.primary_light};
  font-size: 20px;
  font-weight: 600;

  svg {
    width: 30px;
  }
`

export const Label = styled.div`
  flex: 1;
  margin-left: 4%;

  color: ${(props) => props.theme.black}AA;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

const TransposeInput = (props: {
  hideLabel?: boolean
  value: number
  setValue: (value: number) => void
  roundCorners?: boolean
  noBackground?: boolean
  isCapo?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper title={t('song.transpose')}>
      <ValueWrapper
        roundCorners={props.roundCorners}
        noBackground={props.noBackground}
      >
        <Value>{props.value > 0 ? '+' + props.value : props.value}</Value>

        {!props.hideLabel && (
          <Label>
            {t(props.isCapo ? 'song.capo offset' : 'song.transpose')}
          </Label>
        )}

        <IconWrapper onClick={() => props.setValue(props.value + 1)}>
          <SvgIcon code="icon-arrow-up" />
        </IconWrapper>

        <IconWrapper onClick={() => props.setValue(props.value - 1)}>
          <SvgIcon code="icon-arrow-down" />
        </IconWrapper>

        <IconWrapper onClick={() => props.setValue(0)}>
          <SvgIcon code="icon-close" style={{ transform: 'scale(0.9)' }} />
        </IconWrapper>
      </ValueWrapper>
    </Wrapper>
  )
}

export default TransposeInput
