import { LyricRolesType } from 'types'

export const LYRICS_ROLES: LyricRolesType = {
  verse: 'verse',
  preChorus: 'preChorus',
  chorus: 'chorus',
  bridge: 'bridge',
  interlude: 'interlude',
  intro: 'intro',
  outro: 'outro',
}

export const LYRICS_ROLES_TRANSLATED: {
  en: LyricRolesType
  cz: LyricRolesType
  sk: LyricRolesType
} = {
  en: {
    verse: 'Verse',
    preChorus: 'Pre-Chorus',
    chorus: 'Chorus',
    bridge: 'Bridge',
    interlude: 'Interlude',
    intro: 'Intro',
    outro: 'Outro',
  },
  cz: {
    verse: 'Sloka',
    preChorus: 'Před-Refrén',
    chorus: 'Refrén',
    bridge: 'Bridge',
    interlude: 'Mezihra',
    intro: 'Intro',
    outro: 'Outro',
  },
  sk: {
    verse: 'Sloha',
    preChorus: 'Pred-Refrén',
    chorus: 'Refrén',
    bridge: 'Bridge',
    interlude: 'Medzihra',
    intro: 'Intro',
    outro: 'Outro',
  },
}

export const LYRIC_ROLES_MISC: { input: string; roleId: string }[] = [
  { input: 'VER', roleId: 'verse' },
  { input: 'CHOR', roleId: 'chorus' },
  { input: 'INT', roleId: 'interlude' },
]
