import DevModuleConvertImageToText from './Modules/ConvertImageToText'
import DevModuleConvertTextToSong from './Modules/ConvertTextToSong'
import DevModuleEmail from './Modules/Email'
import DevModuleFileSystemPlaylists from './Modules/FileSystemPlaylists'
import DevModuleFileSystemSongs from './Modules/FileSystemSongs'
import DevModuleFileSystemUsers from './Modules/FileSystemUsers'
import DevModuleIcons from './Modules/Icons'
import DevModuleInputs from './Modules/Inputs'
import DevModuleStringChords from './Modules/StringChords'
import LoaderPageLayout from 'layouts/LoaderPageLayout'
import { ROUTES } from 'const'
import { Redirect } from 'react-router-dom'
import { isLoggedAppAdmin } from 'utils/auth'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const DevPage = () => {
  //
  // ADMIN CHECK
  //
  useEffect(() => {
    if (!isLoggedAppAdmin()) {
      toast.warning('Admin access only')
    }
  }, [])
  if (!isLoggedAppAdmin()) {
    return <Redirect to={ROUTES.home.slug} />
  }

  //
  // RENDER
  //
  return (
    <LoaderPageLayout isReady title="Development" isAdminOnly isFullWidthPage>
      <Wrapper>
        <DevModuleConvertImageToText isOpenDefault={false} />
        <DevModuleConvertTextToSong isOpenDefault={false} />
        <DevModuleStringChords isOpenDefault={false} />
        <DevModuleFileSystemPlaylists isOpenDefault={false} />
        <DevModuleFileSystemSongs isOpenDefault={false} />
        <DevModuleFileSystemUsers isOpenDefault={false} />
        <DevModuleIcons isOpenDefault={false} />
        <DevModuleInputs isOpenDefault={false} />
        <DevModuleEmail isOpenDefault={false} />
      </Wrapper>
    </LoaderPageLayout>
  )
}

export default DevPage
