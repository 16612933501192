import {
  CompositionSectionType,
  LyricDesignType,
  LyricSectionType,
  SongChordType,
} from 'types'
import {
  SequenceItemType,
  rotateChordSequenceGlobal,
} from 'components/Song/SongEdit/SongEditor/Lyrics/LyricSection/ChordSequencer'
import {
  addChordToLyricSection,
  addNoteToLyricSection,
  calculateFontCharSizeMonospace,
  convertTextToShowSpaces,
  getGlobalCaretIndex,
  getLocalTranslatedHeading,
  getLyricNoteId,
  parseLyricItemsToLines,
  updateLyricSectionInSong,
} from 'utils'
import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'

import LyricChords from './LyricChords'
import LyricNotes from './LyricNotes'
import PaperLine from 'components/Boxes/Paper/PaperLine'
import { resetInputCss } from 'styles/GlobalStyle/Inputs'

const Wrapper = styled.div<{ minWidth: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Line = styled.div<{ width: number }>`
  flex: 1;
  display: flex;
  width: ${(props) => props.width}px;
  transition: width 0.1s ease;
`

const LineContent = styled.div<{
  width: number
  center: boolean
}>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.center ? 'auto' : '0')};

  width: ${(props) => props.width}px;
  transition: width 0.1s ease;
`

const Text = styled.input<{
  fontSize: number
  width: number
  isEditable: boolean
}>`
  ${resetInputCss}
  min-height: auto;
  background: none;
  border: none;
  outline: none;
  display: block;
  font-family: 'Inconsolata', monospace;
  font-size: ${(props) => props.fontSize}px;
  width: ${(props) => props.width}px;
  padding-bottom: 1px;

  transition: all 0.1s ease;
  transition-property: font-size, width;

  ${(props) =>
    props.isEditable &&
    css`
      &:hover {
        cursor: pointer;
        background: ${(props) => props.theme.black}0A;
      }
    `}
`

const Heading = styled.div<{
  fontSize: number
}>`
  margin-left: -4px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  & > * {
    display: flex;
  }

  font-family: 'Inconsolata', monospace;
  font-size: ${(props) => props.fontSize}px;
  transition: font-size 0.1s ease;
`

const HeadingTitle = styled.div`
  font-weight: 800;
  &:first-letter {
    text-transform: uppercase;
  }
`

const HeadingNotes = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  transform: skew(-8deg);
  color: ${(props) => props.theme.chord};
  & > *:last-child {
    color: ${(props) => props.theme.black}77;
    margin-left: 2px;
  }
`

/**
 *
 *  COMPONENT
 *
 */
const LyricText = (props: {
  songLanguageCode: string
  lyricSections: LyricSectionType[]
  setLyricSections?: (data: LyricSectionType[]) => void
  lyricSection: LyricSectionType
  compSection?: CompositionSectionType
  chordSequence?: SequenceItemType[]
  setChordSequence?: (sequence: SequenceItemType[]) => void
  songChords: SongChordType[]
  design: LyricDesignType
  center?: boolean
  hideHeading?: boolean
  forcedMaxLineWidth?: number
}) => {
  const textLines = props.lyricSection?.lyrics?.split('\n') || []
  const textLineLengthMax = Math.max(...textLines.map((t) => t.length))
  const maxLineWidth = Math.max(
    160,
    props.forcedMaxLineWidth ||
      (textLineLengthMax + 0.5) *
        calculateFontCharSizeMonospace(props.design.fontSize)
  )

  //
  // FOCUS ON NEW ELEMENTS
  //
  const [newNoteId, setNewNoteId] = useState<number>(null)

  useEffect(() => {
    if (newNoteId) {
      const htmlId = getLyricNoteId({
        lyricNoteId: newNoteId,
      })
      document.getElementById(htmlId)?.focus()
      setNewNoteId(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNoteId])

  //
  // UTILS
  //
  const updateSection = (updatedLyricSection) => {
    if (props.setLyricSections) {
      updateLyricSectionInSong({
        lyricSections: props.lyricSections,
        setLyricSections: props.setLyricSections,
        updatedLyricSection,
      })
    }
  }

  const getClickedIndex = (e, lineIndex) => {
    const caretIndex = e.target.selectionStart
    const caretIndexGlobal = getGlobalCaretIndex(
      caretIndex,
      textLines,
      lineIndex
    )
    return caretIndexGlobal
  }

  const addLyricChord = (e, lineIndex) => {
    if (props.setLyricSections) {
      addChordToLyricSection({
        chords: props.songChords,
        lyricSections: props.lyricSections,
        setLyricSections: props.setLyricSections,
        lyricSection: props.lyricSection,
        position: getClickedIndex(e, lineIndex),
        chordId: props.chordSequence && props.chordSequence[0]?.chordId,
      })
      props.chordSequence &&
        rotateChordSequenceGlobal({
          chordSequence: props.chordSequence,
          setChordSequence: props.setChordSequence,
          offset: 1,
        })
    }
  }

  const addLyricNote = (e, lineIndex) => {
    if (props.setLyricSections) {
      const newNoteId = addNoteToLyricSection({
        lyricSections: props.lyricSections,
        setLyricSections: props.setLyricSections,
        lyricSection: props.lyricSection,
        position: getClickedIndex(e, lineIndex),
      })
      setNewNoteId(newNoteId)
    }
  }

  //
  // LYRIC ITEMS (CHORDS, NOTES)
  //
  const { parsedLyricChords, parsedLyricNotes } = parseLyricItemsToLines(
    props.lyricSection,
    textLines
  )

  //
  // RENDER
  //
  return (
    <Wrapper minWidth={maxLineWidth}>
      {!props.hideHeading && (
        <PaperLine
          lineGap={props.design?.lineGap}
          showPaperLine={false}
          minHeight="15px"
        >
          <Line width={maxLineWidth}>
            <LineContent width={maxLineWidth} center={props.center}>
              <Heading fontSize={props.design.fontSize}>
                {props.lyricSection && (
                  <HeadingTitle>
                    {getLocalTranslatedHeading(
                      props.lyricSection,
                      props.songLanguageCode
                    )}
                  </HeadingTitle>
                )}
                <HeadingNotes>
                  <span>{props.compSection.note}</span>
                  <span>{props.compSection.note2}</span>
                </HeadingNotes>
              </Heading>
            </LineContent>
          </Line>
        </PaperLine>
      )}

      {textLines.map((textLine, lineIndex) => (
        <PaperLine
          key={lineIndex}
          lineGap={props.design?.lineGap}
          showPaperLine={props.design.showPaperLine}
        >
          <Line key={lineIndex} width={maxLineWidth}>
            <LineContent width={maxLineWidth} center={props.center}>
              {props.design.showLyricNotes && (
                <LyricNotes
                  notes={parsedLyricNotes[lineIndex]}
                  lyricSection={props.lyricSection}
                  setLyricSection={updateSection}
                  textLines={textLines}
                  lineIndex={lineIndex}
                  design={props.design}
                />
              )}

              {props.design.showLyricChords && (
                <LyricChords
                  chords={parsedLyricChords[lineIndex]}
                  lyricSection={props.lyricSection}
                  setLyricSection={updateSection}
                  textLines={textLines}
                  lineIndex={lineIndex}
                  songChords={props.songChords}
                  design={props.design}
                />
              )}

              <Text
                onClick={(e) =>
                  props.design.editMode === 'chords'
                    ? addLyricChord(e, lineIndex)
                    : props.design.editMode === 'notes'
                    ? addLyricNote(e, lineIndex)
                    : null
                }
                value={
                  props.design.showSpaces
                    ? convertTextToShowSpaces(textLine)
                    : textLine
                }
                onFocus={(e) =>
                  /* HIDE KEYBOARD ON ANDROID/MOBILE - try to onfocus onClick (similar to auto-focus of newly added chord) */
                  setTimeout(() => e.target.blur(), 1)
                }
                onChange={(e) => {}}
                fontSize={props.design.fontSize}
                width={
                  textLine.length *
                    calculateFontCharSizeMonospace(props.design.fontSize) +
                  25 // = padding-right
                }
                isEditable={props.design.editMode}
              />
            </LineContent>
          </Line>
        </PaperLine>
      ))}
    </Wrapper>
  )
}

export default LyricText
