/* eslint-disable react-hooks/exhaustive-deps */
import { DELETE_PLAYLIST, GET_PLAYLISTS } from 'api/graphql'
import { getUserDisplayName, hasPlaylistPermission } from 'utils'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import { DBPlaylistType } from 'types'
import FloatingButton from 'components/App/FloatingMenu/FloatingButton'
import { LOGGED_USER_VAR } from 'App'
import SvgIcon from 'components/SvgIcon'
import confirmedAction from 'modals/actions/confirmedAction'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ButtonDelete = styled.button`
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  padding-right: 25px;
  align-self: center;
  background: ${(props) => props.theme.warning} !important;
`

/**
 *
 *
 * COMPONENT
 *
 *
 */
const PlaylistButtonDelete = (props: {
  playlist: DBPlaylistType
  onDelete: () => void
  isFloatingButton?: boolean
  isVisible?: boolean
  isIndented?: boolean
  noShadow?: boolean
  tooltipShortIsVisible?: boolean
  index?: number
}) => {
  const { t } = useTranslation()

  const loggedUser = useReactiveVar(LOGGED_USER_VAR)

  const getAllowDeletePlaylist = () =>
    hasPlaylistPermission(
      props.playlist?.playlistMembers,
      'PLAYLIST_deletePlaylist'
    )

  //
  // DELETE PLAYLIST
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataPlaylists } =
    useQuery(GET_PLAYLISTS) /* NECESSARY FOR REFETCH */

  const [deletePlaylist, { data: delData }] = useMutation(DELETE_PLAYLIST, {
    refetchQueries: [GET_PLAYLISTS],
  })

  useEffect(() => {
    if (delData?.deletePlaylist) {
      if (delData?.deletePlaylist?.success) {
        toast.success(t(delData?.deletePlaylist?.message))
        props.onDelete()
      } else {
        toast.error(t(delData?.deletePlaylist?.message))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delData])

  const safelyDeletePlaylist = () => {
    const members = []
    props.playlist.playlistMembers
      .filter((m) => m.user?.id !== loggedUser.id)
      .forEach((m) => {
        if (m.user?.name) {
          const name = getUserDisplayName(m.user)
          if (!members.includes(name)) {
            members.push(name)
          }
        } else if (m.userGroup) {
          m.userGroup.userGroupMembers?.forEach((gm) => {
            const name = getUserDisplayName(gm.user)
            if (!members.includes(name)) {
              members.push(name)
            }
          })
        }
      })

    const memberList = members.map((m) => `'${m}'`).join('\n')

    confirmedAction({
      title: t('playlist.delete.title'),
      description: t('playlist.delete.description').replace(
        '{PLAYLIST}',
        props.playlist.name
      ),
      descriptionWarning:
        memberList.length > 0 &&
        t('playlist.delete.description users').replace(
          '{USERS/GROUPS}',
          memberList
        ),
      okText:
        memberList.length > 0
          ? t('playlist.delete.yes for all')
          : t('playlist.delete.yes'),
      cancelText: t('playlist.delete.no'),
      onSubmit: () => {
        deletePlaylist({ variables: { id: props.playlist.id } })
      },
    })
  }

  //
  // BUTTON CLICK
  //
  const onDeleteClick = () => {
    safelyDeletePlaylist()
  }

  //
  // RENDER
  //
  const renderButton = () => {
    if (props.isFloatingButton) {
      return (
        <FloatingButton
          button={{
            onClick: onDeleteClick,
            tooltip: t('playlist.delete.label'),
            tooltipShort: t('playlist.delete.label short'),
            tooltipShortIsVisible: props.tooltipShortIsVisible,
            icon: <SvgIcon code="icon-delete" />,
            color: 'warning',
            isVisible: props.isVisible,
            isIndented: props.isIndented,
          }}
          noShadow={props.noShadow}
          index={props.index}
        />
      )
    }
    return (
      <ButtonDelete className="btn-primary" onClick={onDeleteClick}>
        <SvgIcon code="icon-delete" /> {t('playlist.delete.label')}
      </ButtonDelete>
    )
  }

  return getAllowDeletePlaylist() ? renderButton() : null
}

export default PlaylistButtonDelete
