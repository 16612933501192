import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      message
      token
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser(
    $roleCode: String
    $email: String!
    $name: String!
    $username: String!
    $languageCode: String!
    $password: String!
    $avatar: String
  ) {
    createUser(
      roleCode: $roleCode
      email: $email
      name: $name
      username: $username
      languageCode: $languageCode
      password: $password
      avatar: $avatar
    ) {
      success
      message
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $roleCode: String
    $email: String
    $name: String
    $username: String
    $languageCode: String
    $oldPassword: String
    $newPassword: String
    $avatar: String
  ) {
    updateUser(
      id: $id
      roleCode: $roleCode
      email: $email
      name: $name
      username: $username
      languageCode: $languageCode
      oldPassword: $oldPassword
      newPassword: $newPassword
      avatar: $avatar
    ) {
      success
      message
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`

export const UPDATE_USER_SONG_CATEGORIES = gql`
  mutation updateUserSongCategories($id: ID!, $songCategories: String) {
    updateUserSongCategories(id: $id, songCategories: $songCategories) {
      success
      message
    }
  }
`
