/* eslint-disable react-hooks/exhaustive-deps */
import { DBSongType, SongCategoryType } from 'types'

import { FIRST_NAMES } from 'assets/test/firstNames'
import FileSystemSongsWithNavigation from 'components/Files/FileSystemSongsWithNavigation'
import ModuleSectionTitle from '../Layout/ModuleSectionTitle'
import ModuleSectionVerticalSeparator from '../Layout/ModuleSectionVerticalSeparator'
import ModuleWrapper from '../Layout/ModuleWrapper'
import styled from 'styled-components'
import { useMemo } from 'react'

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;

  & > *:last-child {
    max-width: 30%;
    min-width: 350px;
  }

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
    & > *:last-child {
      max-width: 100%;
    }
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const DevModuleFileSystemSongs = (props: { isOpenDefault?: boolean }) => {
  const maxHeight = '50vh'

  const preloadedSongs: DBSongType[] = useMemo(
    () =>
      Array.apply(null, Array(20)).map((u, i) => {
        let author = `${
          FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]
        } ${FIRST_NAMES[Math.round(Math.random() * (FIRST_NAMES.length - 1))]}`

        const name = `Song Name ${i + 1}`

        return {
          id: i,
          name,
          author,
          body: '',
          songMembers: [],
        }
      }),
    []
  )

  const preloadedSongCategories: SongCategoryType[] = useMemo(
    () =>
      Array.apply(null, Array(5)).map((u, i) => {
        return {
          id: i,
          parentCategoryId: i % 2 === 0 ? i + 1 : 0,
          name: `Folder ${i + 1}`,
          name2: Math.random() > 0.5 ? 'Folder Name 2' : undefined,
          songIds: preloadedSongs
            .filter((s) => Math.random() > 0.2)
            .map((s) => s.id),
        }
      }),
    []
  )

  //
  // RENDER
  //
  return (
    <ModuleWrapper
      title="File System - Songs"
      subtitle="File system for organizing songs and folders"
      isOpen={props.isOpenDefault}
    >
      <Wrapper>
        <Column>
          <ModuleSectionTitle title="Song File System" subtitle="Gird View" />

          <FileSystemSongsWithNavigation
            preloadedSongs={preloadedSongs}
            preloadedSongCategories={preloadedSongCategories}
            defaultViewMode="grid"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileAddClick={() => alert('onFileAddClick')}
            maxHeight={maxHeight}
            showFileCount
            doNotStoreAnyData
          />
        </Column>

        <ModuleSectionVerticalSeparator />

        <Column>
          <ModuleSectionTitle title="Song File System" subtitle="List View" />

          <FileSystemSongsWithNavigation
            preloadedSongs={preloadedSongs}
            preloadedSongCategories={preloadedSongCategories}
            defaultViewMode="list"
            onFileDoubleClick={() => alert('onFileDoubleClick')}
            onFileAddClick={() => alert('onFileAddClick')}
            maxHeight={maxHeight}
            showFileCount
            doNotStoreAnyData
          />
        </Column>
      </Wrapper>
    </ModuleWrapper>
  )
}

export default DevModuleFileSystemSongs
