import styled, { css } from 'styled-components'
import { LyricDesignType, LyricNoteType, LyricSectionType } from 'types'
import { calculateFontCharSizeMonospace, getLyricNoteId } from 'utils'

import EditableItem from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText/EditableItem'
import {
  editableCSS,
  boxCSS,
} from 'components/Song/SongEdit/SongEditor/Lyrics/LyricText/LyricChord'
import StringInput from 'components/Inputs/StringInput'
import { useTranslation } from 'react-i18next'

const Note = styled.div<{
  fontSize: number
  width?: number
  allowEdit?: boolean
}>`
  ${editableCSS}
  ${props =>
    props.allowEdit &&
    css`
      &:hover {
        ${boxCSS}
      }
    `}

  margin-top: -2px;
  margin-bottom: -3px;

  input {
    transition: all 0.1s ease;
    transition-property: font-size, width, color;

    color: ${props => props.theme.black}88;
    font-size: ${props => props.fontSize}px;
    font-weight: 600;
    font-family: 'Inconsolata', monospace;
    transform: skew(-8deg);

    padding: 0px;
    padding-left: 8px;
    border-radius: 10px;
    border: none;
    min-height: 20px;
    width: ${props => props.width + 8}px;

    ${props =>
      props.allowEdit &&
      css`
        &:hover {
          color: ${props => props.theme.black};
        }
      `}
  }
`

const LyricNote = (props: {
  note: LyricNoteType
  design: LyricDesignType
  updateLyricNote?: (updatedLyricNote: LyricNoteType) => void
  deleteLyricNote?: (deleteId: number) => void
  lyricSection?: LyricSectionType
  isHeightRef?: boolean
}) => {
  const { t } = useTranslation()

  const allowEdit = props.design?.ALLOW_EDIT || !props.design

  return allowEdit /* HIDE EMPTY NOTES IN READ-ONLY MODE */ ||
    props.note?.text?.length ? (
    <EditableItem
      alignLeft
      onMoveLeft={() =>
        props.updateLyricNote({
          ...props.note,
          position: Math.max(0, props.note.position - 1),
        })
      }
      onMoveRight={() =>
        props.updateLyricNote({
          ...props.note,
          position: Math.min(
            props.lyricSection?.lyrics?.length,
            props.note.position + 1
          ),
        })
      }
      onDelete={() => props.deleteLyricNote(props.note.itemId)}
      allowEdit={allowEdit}
    >
      <Note
        fontSize={props.design.noteSize}
        width={
          calculateFontCharSizeMonospace(props.design.noteSize) *
          Math.max(
            props.note.text?.length ? 4 : 10,
            props.note.text?.length || 0
          )
        }
        allowEdit={allowEdit}
        title={t('song.lyric text options.note')}
      >
        <StringInput
          placeholder={t('song.lyric text options.note placeholder')}
          value={props.note.text || ''}
          setValue={value =>
            props.updateLyricNote({
              ...props.note,
              text: value,
            })
          }
          disabled={!allowEdit}
          id={
            !props.isHeightRef &&
            getLyricNoteId({ lyricNoteId: props.note.itemId })
          }
          monospace
        />
      </Note>
    </EditableItem>
  ) : null
}

export default LyricNote
